import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import {Grid, Button, Card, CardActions, CardContent, Typography, Avatar} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { getAdapter } from '../adapters/adapter';
import LoadingIndicator from '../LoadingIndicator';

export default function NumberOfUsersCard(props){

    const history = useHistory();

    function onViewReportBtn(){
        history.push("/dashboard/reports/users");
    }
   
    //Is component currently mounted?
	const isMounted = useRef(false)
	useEffect(() => {
		isMounted.current = true;
		return () => { isMounted.current = false }
	}, []);

    //States:
	const [isLoading, setIsLoading] = useState(false);
    const [numberOfUsersPOC, setNumberOfUsersPOC] = useState(0);
    const [numberOfUsersFF, setNumberOfUsersFF] = useState(0);
    const [numberOfUsersPA, setNumberOfUsersPA] = useState(0);
    const [numberOfUsersQI, setNumberOfUsersQI] = useState(0);

    //Initialize view:
    useEffect(()=>{
		async function fetchData(){
			setIsLoading(true);6
			try {
				var adapter = await getAdapter();
                //Make some call to adapter:
                var users = await adapter.user.listUsers();
			} 
			catch (error) {
				console.error(error);
                var users = [];
			}
			if(isMounted.current){
                var numPOC = 0, numFF = 0, numPA = 0, numQI = 0;
                users.forEach((user)=>{
                    switch(user.role_id){
                        case "oted_pa":
                            numPA++;
                            break;
                        case "applicant_poc":
                            numPOC++;
                            break;
                        case "oted_ff":
                            numFF++;
                            break;
                        case "qualified_instructor":
                            numQI++;
                            break;    
                        default:
                            break;
                    }
                });
                //Set some state:
                setNumberOfUsersPOC(numPOC);
                setNumberOfUsersFF(numFF);
                setNumberOfUsersPA(numPA);
                setNumberOfUsersQI(numQI);
				setIsLoading(false);
			}
		};
		
		fetchData();
    }, []);

    return(
        <Card {...props}>
            <CardContent>
                <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
                    <Grid item xs={10}>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="overline"
                        >
                            Users
                        </Typography>
                        { isLoading ?
                            <LoadingIndicator/> :
                            <Grid item container>
                                <Grid item xs={6}>Point of Contact: <strong>{isLoading ? "-" : numberOfUsersPOC}</strong></Grid>
                                <Grid item xs={6}>Fulfillment: <strong>{isLoading ? "-" : numberOfUsersFF}</strong></Grid>
                                <Grid item xs={6}>Qualified Instructor: <strong>{isLoading ? "-" : numberOfUsersQI}</strong></Grid>
                                <Grid item xs={6}>Program Administrator: <strong>{isLoading ? "-" : numberOfUsersPA}</strong></Grid>
                            </Grid>
                        }
                    </Grid>
                    <Grid item>
                        <Avatar
                            sx={{
                                backgroundColor: '#FFB020',
                                height: 56, 
                                width: 56
                            }}
                        >
                            <PersonIcon />
                        </Avatar>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions sx={{padding:1}}>
                <Button 
                    size="small"
                    onClick={ onViewReportBtn}
                >
                    View Report
                </Button>
            </CardActions>
        </Card>
    );
}
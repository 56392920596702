import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import React from 'react';

export default function LoadingIndicator(props){
    return(
        <React.Fragment>
            <Grid container justifyContent="center" {...props}>
                <CircularProgress/>
            </Grid>
        </React.Fragment>
    );
}
import { useLocation, useParams } from 'react-router-dom';

export function handleInvalidPermissions(error, history, StatusManager){
	let response = error.response.data;
	if(response.errors === "Invalid permissions"){
		if(history != null){
			StatusManager.setStatus("Access denied. You have been returned to the dashboard home screen.");	
			history.push("/dashboard");
		}	
	}
}

export default function useBasePath(){
	const location = useLocation();
	const params = useParams();

	return Object.values(params).reduce(
		(path, param) => path.replace('/' + param, ''),
		location.pathname,
	);
}

//Maps guid to id:
export function idToGuidMapper(e){
	e.id = e.guid;
	return e;
}

export const validStates = [ "AK","AL","AR","AS","AZ","CA","CO","CT","DC","DE","FL","GA","GU","HI","IA","ID","IL","IN","KS","KY","LA","MA","MD","ME","MI","MN","MO","MS","MT","NC","ND","NE","NH","NJ","NM","NV","NY","OH","OK","OR","PA","PR","RI","SC","SD","TN","TX","UT","VA","VI","VT","WA","WI","WV","WY"];

export function isValidState(stateStr){
	return validStates.includes(stateStr.toUpperCase())
}

export function isValidZipCode(zipCode){
	//Contains only digits:
	let isNum = /^\d+$/.test(zipCode);
	//Is 5 digits:
	var isProperLength = zipCode.length === 5;
	return (isNum && isProperLength);
}

export function roleToFriendlyRole(role)
{
	switch(role)
	{
		case "oted_pa": return "Program Administrator";
		case "oted_ff": return "Fulfillment";
		case "applicant_poc": return "Point of Contact";
		case "superadmin": return "System Administrator";
		case "qualified_instructor": return "Qualified Instructor";
		default: return "Unknown role";
	}
}

//expects date => new Date(dateString)
export function getFormattedDate(date) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
  
    return month + '/' + day + '/' + year;
}

//expects date => new Date(dateString)
export function getFormattedDateTime(date) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
	let amPm = date.getHours() >= 12 ? "PM" : "AM";
	let hour = date.getHours() % 12;
	if(hour === 0) hour = 12;
	let hms = String(hour).padStart(2,0) + ":" + 
		String(date.getMinutes()).padStart(2,0) + ":" + 
		String(date.getSeconds()).padStart(2,0) + " " + amPm;
  
    return month + '/' + day + '/' + year + " " + hms;
}

export function isValidEmailFormat(email) {
	if(email == "poc@test") return true;
	var re = /\S+@\S+\.\S+/;
	return re.test(email);
}

export function isValidPhoneNumberFormat(phoneNumber) {
	if(!phoneNumber) return false;
	var digits = phoneNumber.toString().match(/\d/g).length;
	var re = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
	return re.test(phoneNumber) && digits > 9 && digits < 14;
}

export function isValidDateFormat(date) {
	if(!date) return false;
	const RegExpDate  = new RegExp('^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$');
	return RegExpDate.test(date);
}

export function capitalize(sentence) {
	return sentence.charAt(0).toUpperCase() + sentence.slice(1);
}

import React, {useState, useEffect, useRef} from 'react';
import Dialog from '@mui/material/Dialog';
import { DialogContent} from '@mui/material';
import { BootstrapDialogTitle } from './BootStrapDialogTitle';
import { CreateButton } from './theme/PortalTheme';
import Grid from '@mui/material/Grid';
import FormField from './FormField';
import { isValidEmailFormat } from './Utility';

export default function AddUnqualifiedInstructorDialog(props){
    var { open, setOpen, onAddInstructorBtnCallback } = props;

    const [addInstructorBtnDisabled, setAddInstructorBtnDisabled] = useState(true);
	const [instructorLast, setInstructorLast] = useState("");
	const [instructorFirst, setInstructorFirst] = useState("");
	const [instructorEmail, setInstructorEmail] = useState("");

    const isMounted = useRef(false)
	useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false }
	}, []);

    useEffect(()=>{
        if(isMounted.current){
            setInstructorFirst("");
            setInstructorLast("");
            setInstructorEmail("");
        }
    }, [setInstructorFirst,
        setInstructorLast,
        setInstructorEmail,
        open]);

    useEffect(()=>{
        var requiredFields = [instructorLast, instructorFirst, instructorEmail];
        setAddInstructorBtnDisabled(requiredFields.some(formField=> formField.trim().length === 0) || !isValidEmailFormat(instructorEmail));
    }, [instructorLast,
        instructorFirst,
        instructorEmail]);

    function onAddInstructorBtn(){
        var newInstructor={
            firstName: instructorFirst.trim(),
            lastName: instructorLast.trim(),
            email: instructorEmail.trim(),
        };
        newInstructor.id = newInstructor.email;
        //Pass the selected instructors to the callback:
        onAddInstructorBtnCallback(newInstructor);
        //Close the dialog:
        onDialogClose();
    }

    function onDialogClose(){
        setOpen(false);
    }

    return (
        <Dialog
            maxWidth={"lg"}
            fullWidth={true}
            open={open}    
            container={() => document.getElementById('dialogParent')}
        >            
            <BootstrapDialogTitle color="primary" onClose={onDialogClose}>Add New Instructor</BootstrapDialogTitle>
            <DialogContent>
                <Grid container spacing={1} marginTop={1}>
                    <Grid item xs={4}><FormField required label="Last Name" value={instructorLast} setValue={setInstructorLast}/></Grid>
                    <Grid item xs={4}><FormField required label="First Name" value={instructorFirst} setValue={setInstructorFirst}/></Grid>
                    <Grid item xs={4}><FormField required label="Email" value={instructorEmail} setValue={setInstructorEmail}/></Grid>
                </Grid>
				<Grid container justifyContent="flex-end">
					<CreateButton
                        onClick = {onAddInstructorBtn}
                        disabled = {addInstructorBtnDisabled}
                    >Add Instructor</CreateButton>
				</Grid>
            </DialogContent>
        </Dialog>
    )
}
import Container from '@mui/material/Container';
import { FormControlLabel, Grid, Paper, Switch } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from "react-router-dom";
import { getAdapter } from './adapters/adapter';
import BetterFormField from './FormField';
import LoadingIndicator from './LoadingIndicator';
import { StatusMessageContext } from './StatusMessage';
import theme, { SaveButton } from './theme/PortalTheme';
import ViewTitle from './ViewTitle';
import { isValidState, isValidZipCode, isValidPhoneNumberFormat, isValidEmailFormat } from './Utility';
import SavingIndicator from './SavingIndicator';
import useForm from './UseForm';

function UserForm(props){
    const { userId } = useParams();

    //Is component currently mounted?
    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false }
    }, []);

    //Status message:
	var { StatusManager } = useContext(StatusMessageContext);

	//Loading/Saving states:
	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

    //Form validation:
    function validate(){
        let _errors = { ...errors };
        console.log("Validating");
        console.log(formValues);

        //Roles other than superadmin and oted_pa currently require an organization. This rule may need to be adjusted if new roles are added:
		_errors.userLast = (formValues.userLast === "") ? " " : "";
        _errors.userFirst = (formValues.userFirst === "") ? " " : "";
        _errors.userEmail = (isValidEmailFormat(formValues.userEmail)) ? "" : " ";
		_errors.userPhone = (isValidPhoneNumberFormat(formValues.userPhone)) ? "" : " ";	
        _errors.userStreet = (formValues.userStreet === "") ? " " : "";
        _errors.userCity = (formValues.userCity === "") ? " " : "";
        //Is state valid?
        _errors.userState = (isValidState(formValues.userState))? "" : " ";
		//Is zip valid?
		_errors.userZip = (isValidZipCode(formValues.userZip)) ? "" : " ";

        setErrors({
            ..._errors
        });

        var result = Object.values(_errors).every(x => x === "");
        console.log("Validation " + (result ? "SUCCESS" : "FAILED"));
        return result;
    }

    //Initial Form Values:
	const initialFormValues = {
        organization:{},
        userLast: "",
        userMiddle: "",
        userFirst: "",
        userEmail: "",
        userPhone: "",
        userStreet: "",
        userCity: "",
        userState: "",
        userZip: "",
        loggedInUserRole: "",
        userId: "",
        requireOTP: true,
        sendNotificationEmails: false
	};

    //Form-related vars:
	const {
		formValues,
		setFormValue,
		errors,
		setErrors,
		handleInputChange,
	} = useForm(initialFormValues, false, validate);

	//Create refs to mutable objects that would otherwise be passed as dependencies to useEffect hook, to prevent triggering re-renders unnecessarily.
	const setFormValueRef = useRef(setFormValue);

	//On view load:
    useEffect(() => {
        let setFormValue = setFormValueRef.current;
        async function fetchData(){
            setIsLoading(true);
            try {
                var organization = {};
                var adapter = await getAdapter();
                var user = await adapter.getLoggedInUser();
                console.log(user);
                if(user.organizationID != null){
                    organization = await adapter.organization.get(user.organizationID);   
                }         
            }
            catch (error) {
                console.error(error);
            }
            if(isMounted.current){
                setFormValue("userLast", user.lastName || "");
                setFormValue("userMiddle", user.middleInitial || "");
                setFormValue("userFirst", user.firstName || "");
                setFormValue("userEmail", user.email || "");
                setFormValue("userPhone", user.phone || "");
                setFormValue("userStreet", user.addressStreet || "");
                setFormValue("userCity", user.addressCity || "");
                setFormValue("userState", user.addressState || "");
                setFormValue("userZip", user.addressZip || "");
                setFormValue("loggedInUserRole", user.role || "");
                setFormValue("userId", user.guid || "");
                setFormValue("requireOTP", user.requireOTP);
                setFormValue("sendNotificationEmails", user.sendNotificationEmails);
                if(organization != null){
					setFormValue("organization", organization);
				}
                setIsLoading(false);
            }
        }
        fetchData();
    },  
    [
        setIsLoading,
		userId
    ]);

    //Save:
    async function saveData( successCallback = () => {} ){
        var success = true;
        setIsSaving(true);
        try {
            var adapter = await getAdapter();
			let user = {
				organizationID: formValues.organization.guid,
				firstName: formValues.userFirst,
				lastName: formValues.userLast,
				middleInitial: formValues.userMiddle,
				email: formValues.userEmail,
				phone: formValues.userPhone,
				addressStreet: formValues.userStreet,
				addressCity: formValues.userCity,
				addressState: formValues.userState,
				addressZip: formValues.userZip,
                role: formValues.loggedInUserRole,
                requireOTP: formValues.requireOTP,
                sendNotificationEmails: formValues.sendNotificationEmails                
			};
			await adapter.user.update(formValues.userId, user);
		}
        catch (error) {
			success = false;
            console.error(error);
            //If user with specified email address already exists within the system:         
            if(error.response.data.status === "duplicate user" && isMounted.current){
                setErrors({
                    ...errors,
                    userEmail: "Invalid email. A user with this email address already exists within the system."
                });
            }
        }
        if(isMounted.current){
            setIsSaving(false);
            var successStr = success ? "successful." : "failed.";
            console.log("Save " + successStr );
            StatusManager.setStatus("Save user " + successStr);
            if(success) successCallback();
        }
    }

    //Button handlers:
    function onSaveBtn(){
		if(validate()){
			saveData(()=>{
				//Redirect user or leave on page?
			});
		}
    }    

    //Conditional Render:
    if(isLoading){
        return <LoadingIndicator/>;
    }
    else {
        return (
            <>
                <p>Listed below is your current contact information. Should your contact information change, you may update the information within the corresponding fields as needed.</p>
                {
                    (formValues.loggedInUserRole === "applicant_poc") &&
                    <>
                        <ul><li><strong>NOTE</strong>: Your contact email cannot be changed. If there has been a change to your contact email address, please message your ICD PA.</li></ul>
                        <p style={{marginBottom:theme.spacing(1)}}><strong>Organization</strong></p>
                        <Grid container spacing={1}>
                            <Grid item xs alignItems="center" container>
                                <BetterFormField required label="Organization" name="organization" variant="filled" readOnly={true} value={(formValues.organization && formValues.organization.name) || ""} error={errors.organization}/>
                            </Grid>
                        </Grid>
                    </>
                }
                <p style={{marginBottom:theme.spacing(1)}}><strong>Name</strong></p>
                <Grid container spacing={1}>
                    <Grid item xs={5}><BetterFormField required label="Last Name" name="userLast" value={formValues.userLast} error={errors.userLast} onChange={handleInputChange} /></Grid>
                    <Grid item xs={2}><BetterFormField label="Middle Initial" name="userMiddle"  value={formValues.userMiddle} error={errors.userMiddle} onChange={handleInputChange} /></Grid>
                    <Grid item xs={5}><BetterFormField required label="First Name" name="userFirst" value={formValues.userFirst} error={errors.userFirst} onChange={handleInputChange} /></Grid>
                </Grid>
                <p style={{marginBottom:theme.spacing(1)}}><strong>Contact</strong></p>
                <Grid container spacing={1}>
                    <Grid item xs={6}><BetterFormField variant="filled" required readOnly={true} label="Email" name="userEmail" value={formValues.userEmail} error={errors.userEmail} onChange={handleInputChange} /></Grid>
                    <Grid item xs={6}><BetterFormField required label="Phone" name="userPhone" value={formValues.userPhone} error={errors.userPhone} onChange={handleInputChange} /></Grid>
                    <Grid item xs={12}><BetterFormField required label="Street" name="userStreet" value={formValues.userStreet} error={errors.userStreet} onChange={handleInputChange} /></Grid>
                    <Grid item xs={5}><BetterFormField required label="City" name="userCity" value={formValues.userCity} error={errors.userCity} onChange={handleInputChange} /></Grid>
                    <Grid item xs={2}><BetterFormField required label="State" name="userState" value={formValues.userState} error={errors.userState} onChange={handleInputChange} /></Grid>
                    <Grid item xs={5}><BetterFormField required label="Zip" name="userZip" value={formValues.userZip} error={errors.userZip} onChange={handleInputChange} /></Grid>
                </Grid>
                <p style={{marginBottom:theme.spacing(1)}}><strong>Preferences</strong></p>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <FormControlLabel 
                            control={
                                <Switch                      
                                    checked={formValues.sendNotificationEmails}
                                    onChange={(e)=>{
                                        setFormValue("sendNotificationEmails", e.target.checked);
                                    }}
                                    inputProps={{ 'aria-label': 'Send Notification Emails?', title:'Send Notification Emails?'}}
                                />
                            } 
                            label="Send Notification Emails"
                        />
                    </Grid>
                </Grid>
                <Grid container justifyContent="center" alignItems="bottom" sx={{marginTop:4}}>
                    { isSaving ? <SavingIndicator /> :
                        <>
                            <SaveButton onClick={onSaveBtn} />
                        </>
                    }
                </Grid>
            </>
        );
    }
}

export default function ViewEditProfile(props) {
	return (
		<Container component="main" maxWidth="lg">
			<Paper style={{minHeight:'70vh'}} padding="large">
				<ViewTitle>My Profile</ViewTitle>
                <UserForm />
			</Paper>
		</Container>
    );
}
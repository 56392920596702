import React, {useEffect, useRef, useContext } from 'react';
import { useHistory, useParams, generatePath } from "react-router-dom";
import { Container} from '@mui/material';
import ICDApplicationFormDeliveryInfo from './ICDApplicationForm_DeliveryInfo';
import ICDApplicationFormInstructorInfo from './ICDApplicationForm_InstructorInfo';
import ICDApplicationFormContactInfo from './ICDApplicationForm_ContactInfo';
import ICDApplicationFormAgreements from './ICDApplicationForm_Agreements';
import ICDApplicationFormApplicationStatus from './ICDApplicationForm_ApplicationStatus';
import getBasePath, { handleInvalidPermissions } from './Utility';
import {StepLogicController, StepNavigator, CustomStepper} from './StepLogic';
import { getAdapter } from './adapters/adapter';
import LoadingIndicator from './LoadingIndicator';
import { getLoggedInRole } from './adapters/baseAdapter';
import { StatusMessageContext } from './StatusMessage';

/*
	ICDApplications should be broken up into several screens.
    Need to figure out how to get an initial; application ID into the route path by the time this view is loaded when creating a new application.
*/

export default function ICDApplicationForm(){
    const history = useHistory();
    const {applicationId, currentStep} = useParams();
    
    //Base path of current route, without params:
    const basePath = getBasePath();

    //Status message:
	var { StatusManager } = useContext(StatusMessageContext);    

    //Is component currently mounted?
	const isMounted = useRef(false)
	useEffect(() => {
		isMounted.current = true;
		return () => { isMounted.current = false }
	}, []);

    //Track application:
    var [application, setApplication] = React.useState({});
    //Step labels for Stepper widget:
    var [stepLabels, setStepLabels ] = React.useState([]);
    //Track completed steps:
    var [completedSteps, setCompletedSteps] = React.useState([]);
    //Update signal:
    var [updateSignal, setUpdateSignal] = React.useState(0);
    //Loading progress:
    var [isLoading, setIsLoading] = React.useState(false);

    //Logic handler for step navigation, easily passed as prop:
    var stepLogic = new StepLogicController(currentStep, stepLabels, completedSteps, setCompletedSteps, function(stepNum){
        var path = basePath + "/:applicationId/:currentStep";
        var data = { applicationId:applicationId, currentStep:stepNum };
        history.push(generatePath(path,data));
    });

    //On view load:
    useEffect(() => {
        async function fetchData(){
            if(isMounted.current){
                setIsLoading(true);
            }
			try {
				let adapter = await getAdapter();
                let appData = await adapter.application.get(applicationId);
                if(isMounted.current){
                    setApplication(appData);
                    //Based on logged in user role, adjust the "steps" of the Stepper widget:
                    if(["oted_pa", "superadmin"].includes(getLoggedInRole())){
                        setStepLabels(["Delivery Information", "Instructors", "Contact Information", "Agreements", "Application Status"]);
                    }
                    //For now any other situation, but will almost certainly need to refine this:
                    else {
                        setStepLabels(["Delivery Information", "Instructors", "Contact Information", "Agreements"]);
                    }
                }
			} 
			catch (error) {
                handleInvalidPermissions(error, history, StatusManager);
			}
			if(isMounted.current){
                setIsLoading(false);
			}
        }
        fetchData();
    }, [ applicationId, currentStep, updateSignal ]);

    /*
    FOR DEBUGGING ONLY:
    useEffect(()=>{
        if(application.guid){
            console.log(application);
        }        
    }, [application]);
    */


    //Update stepper completion indicators if the step labels or current step change:
    useEffect(() => {  
        //Check whether steps are completed:
        function checkSteps (completedStepsFromDB, stepLabels){
            var temp = [];
            var stepsNoWhiteSpace = stepLabels.map(element => {
                return element.trim().replace(" ", "").replace("/", "");
            });            
            //Magic assumption here that the expected step strings will be the same as the step labels, minus the whitespace. For example "Delivery Information" step expects "DeliveryInformation" string.
            stepsNoWhiteSpace.forEach(function(stepStr, index){
                temp.push(completedStepsFromDB.indexOf(stepStr) > -1);
            });
            return temp;
        }

        //If stepLabels is populated:
        if(stepLabels.length > 0){
            var completedSteps = checkSteps(application.step || "", stepLabels);
            setCompletedSteps(completedSteps);
        }

    }, [stepLabels, application.step]);

    //If step is invalid, then render nothing. We can change this behavior later:
    if(!stepLogic.isValidStep(currentStep)){
        return null;
    }

    //Otherwise, render based on current step:
    var markup = null;

    function currentStepUnlocked(){
        var temp = [null];
        temp.push(...completedSteps);
        if(currentStep == 1) return true;
        else return temp[currentStep-1]==true;
    }
    
    if(currentStepUnlocked())
    {
        switch(parseInt(currentStep,10)){
            case 1:
                markup = <ICDApplicationFormDeliveryInfo stepLogic={stepLogic} application={application} setApplication={setApplication} />;
                break;
            case 2:
                markup = <ICDApplicationFormInstructorInfo stepLogic={stepLogic} application={application} />;
                break;
            case 3:
                markup = <ICDApplicationFormContactInfo stepLogic={stepLogic} application={application} />;
                break;            
            case 4:
                markup = <ICDApplicationFormAgreements stepLogic={stepLogic} application={application} />;
                break;
            case 5:
                markup = <ICDApplicationFormApplicationStatus stepLogic={stepLogic} application={application} updateSignal={updateSignal} setUpdateSignal={setUpdateSignal}/>;
                break;
            default:
                markup = null;
        }
    }

    function onStepClick(stepLabel, index){
        var path = basePath + "/:applicationId/:currentStep";
        var data = { applicationId:applicationId, currentStep:index+1 };
        history.push(generatePath(path,data));
    }

    //Render:
    return (
        <>
        <Container sx={{p:2}} maxwidth="xl"  id="dialogParent">
            { (["oted_pa", "superadmin", "applicant_poc"].includes(getLoggedInRole()) && currentStepUnlocked()) && 
                isLoading ? <LoadingIndicator/> : 
                <>
                    <CustomStepper activeStep={parseInt(currentStep,10)} steps={stepLabels} handleStep={onStepClick} completed={completedSteps} stepLogic={stepLogic}/>
                    {markup}                   
                </>
            }
        </Container>
        </>
    );

}

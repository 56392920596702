import React, {useEffect, useState} from 'react';
import { useHistory, useParams } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Container from '@mui/material/Container';
import FDALogo from "./images/fda_logo.png";
import { getAdapter } from './adapters/adapter';
import theme from './theme/PortalTheme';
import NoEncryptionGmailerrorredIcon from '@mui/icons-material/NoEncryptionGmailerrorred';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { outlinedInputClasses } from '@mui/material';
import { ForkRight } from '@mui/icons-material';

function LoginTextField({value, setValue, label, type="text", autoComplete, autoFocus=false})
{
	return (
		<TextField
			variant="outlined"
			margin="normal"
			required
			fullWidth
			label={label}
			type={type}
			autoComplete={autoComplete}
			autoFocus={autoFocus}
			value={value}
			onChange={(e) => setValue(e.target.value)}
		/>
	);
}

function LoginButton({text="Reset"})
{
	return (
		<Button
			style={{margin: theme.spacing(3, 0, 2)}}
			type="submit"
			fullWidth
			variant="contained"
			color="primary"
		>
			{text}
		</Button >
	);
}

function ResetAction({email, setEmail, currentPassword, setCurrentPassword, 
	newPassword, setNewPassword, verifyNewPassword, setVerifyNewPassword, handleReset}) {
	function handleSubmit(event)
	{
		event.preventDefault();
		handleReset();
	}

	const passwordRequirements = `Passwords must contain at least 9 characters.
	Passwords must be a combination of uppercase letters, lowercase letters, numbers, and symbols.
	Accepted symbols are (*&^%$#@!).
	Passwords cannot contain the user's name or email address.`;

	return (
		<Box marginTop={1}>
			<form style={{width:'100%'}} onSubmit={handleSubmit} noValidate>
				<LoginTextField value={email} setValue={setEmail} label="Email Address" autoComplete="email" autoFocus={true}/>
				<LoginTextField value={currentPassword} setValue={setCurrentPassword} label="Current Password" type="password" autoComplete="password" />
				<LoginTextField value={newPassword} setValue={setNewPassword} label="New Password" type="password" autoComplete="password" />
				<LoginTextField value={verifyNewPassword} setValue={setVerifyNewPassword} label="Verify New Password" type="password" autoComplete="password" />

				<LoginButton />
				<Grid container>
					<Grid item xs>
						<Link href="/" variant="body2">
							Login
						</Link>						
					</Grid>
					<Grid item xs container justifyContent="flex-end">
						<Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{passwordRequirements}</div>}>
							<InfoIcon color="primary" />
						</Tooltip>
					</Grid>
					<Grid>
						Password Requirements
					</Grid>
				</Grid>
			</form>
		</Box>
	);
}

export default function Reset() {
	const history = useHistory();
	const [resetStatus, setResetStatus] = useState("ab-initio");
	const [resettMessage, setResetMessage] = useState("");
	const [email, setEmail] = useState("");
	const [currentPassword, setCurrentPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [verifyNewPassword, setVerifyNewPassword] = useState("");

	async function handleReset()
	{
		if(email == null || email.length === 0) {
			setResetStatus("failed");
			setResetMessage("Your email cannot be empty");
		} else if(newPassword == null || newPassword.length < 9) {
			setResetStatus("failed");
			setResetMessage("Your new password cannot be less than 9 characters");
		} else if(currentPassword === newPassword) {
			setResetStatus("failed");
			setResetMessage("Your new password cannot be the same as your current password");
		} else if (newPassword !== verifyNewPassword) {
			setResetStatus("failed");
			setResetMessage("Your new password does not match the verified password");
		} else {
			var adapter = await getAdapter();
			var result = await adapter.reset(email, currentPassword, newPassword);

			if (result.status === 'success') {
				setResetStatus("successful");
			} else {
				setResetStatus("failed");
				setResetMessage("Error resetting your password: " + result.data);
			}
		}
	}

	return (
		<Container maxWidth="sm">
			<Box 
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				marginTop={8}
				display = 'flex'
			>				
				<img src={FDALogo} alt="FDA Logo" style={{maxWidth:"50%"}}/>
				<Typography
						component="h1"
						variant="h4"
						color="#333"
 						margin={2}
						textAlign="center"
					>
						ICD Management Portal
				</Typography>

				<Avatar sx={{
					margin: theme.spacing(1),
					backgroundColor: theme.palette.secondary.main				
				}}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h6">
					Reset Password
				</Typography>
				
				<ResetAction email={email} setEmail={setEmail} 
							currentPassword={currentPassword} setCurrentPassword={setCurrentPassword}
							newPassword={newPassword} setNewPassword={setNewPassword}
							verifyNewPassword={verifyNewPassword} setVerifyNewPassword={setVerifyNewPassword}
							handleReset={handleReset} />
				{ resetStatus !== "ab-initio" && 
					<Paper padding="large" sx={{backgroundColor: theme.palette.background.statusMessage}}>
						<Stack direction="row" alignItems="center" gap={1}>
							{ resetStatus === "successful" && <><LockOpenIcon color="success"/><Typography variant="body1">{"Reset successful. "}<a href="/" >Login</a></Typography></> }					
							{ resetStatus === "failed" && <><NoEncryptionGmailerrorredIcon color="secondary"/><Typography variant="body1">{"Reset failed: " + resettMessage}</Typography></> }
						</Stack>
					</Paper>
				}
			</Box>
		</Container>
		);
}


import React, { useEffect, createContext } from 'react';
import theme from './theme/PortalTheme';
import Paper from '@mui/material/Paper';
import InfoIcon from '@mui/icons-material/Info';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export class StatusManager{

    statusMessage = "";

    constructor(statusMessage, setStatusMessage, numMessages, setNumMessages){
        this.statusMessage = statusMessage;
        this.setStatusMessage = setStatusMessage;
        this.numMessages = numMessages;
        this.setNumMessages = setNumMessages;
    }

    setStatus = function(message){
        this.setStatusMessage(message);
        this.setNumMessages(this.numMessages + 1);
    }
}

export const StatusMessageContext = createContext({StatusManager: new StatusManager()});

/*
    Why does this look a little complicated? See explanation here:
    https://dev.to/zhiyueyi/how-to-create-a-simple-react-countdown-timer-4mc3
*/

export default function StatusMessage(props) {
    
    const { StatusManager } = props;
    
    const [counter, setCounter] = React.useState(props.displayInterval || 10);
    
    useEffect(() => {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    useEffect(()=>{
        setCounter(props.displayInterval || 10);
    }, [ StatusManager.numMessages ]);

    return(
        counter > 0 && StatusManager.statusMessage.length > 0 &&
        <Paper padding="large" sx={{backgroundColor: theme.palette.background.statusMessage}}>
            <Stack direction="row" alignItems="center" gap={1}>
                <InfoIcon color="primary"/>
                <Typography variant="body1">{StatusManager.statusMessage}</Typography>
            </Stack>
        </Paper>    
	);
}
import React, { useEffect, useRef } from 'react';
import { getAdapter } from './adapters/adapter';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export default function SelectRole(props) {
	const {value, setValue} = props;
	const [role, setRole] = React.useState('ab-initio');

    //Is component currently mounted?
    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false }
    }, []);	

	//On view load:
	useEffect(() => {
		async function fetchData(){		
			try {
				let adapter = await getAdapter();
				let role = adapter.getLoggedInRole();
				if(isMounted.current){
					setRole(role);
				}			
			}
			catch (error){
				console.log(error);
			}
		}
		fetchData();
	}, []);

	function handleChange(e)
	{
		if(isMounted.current){
			setValue(e.target.value);
		}
	}

	return (
		<FormControl variant="outlined">
			<InputLabel id="role-select-label">Role</InputLabel>
			<Select
				labelId="role-select-label"
				id="role-select"
				label="Role"
				value={value}
				onChange={handleChange}
				defaultValue={"applicant_poc"}
			>
				<MenuItem value={"applicant_poc"} >Point of Contact</MenuItem>
				<MenuItem value={"oted_ff"} >Fulfillment</MenuItem>
				<MenuItem value={"oted_pa"} >Program Administrator</MenuItem>
				{	!props.hideQI && 
					<MenuItem value={"qualified_instructor"} >Qualified Instructor</MenuItem>
				}
				<MenuItem value={"superadmin"} disabled={role!=="superadmin"}>System Administrator</MenuItem>
			</Select>
		</FormControl>
    );
}

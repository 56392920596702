import axios from 'axios';
import {parseResponse, API_URL} from "./utils";
import { getAdapter } from './adapter';

/**
 * application_id and message_id are nullable
 * @param {title: "title", body: "body", application_id: application_id, message_id: message_id, course_id: course_id, user_id: user_id} data 
 * @returns guid of newly created notification
 */
export async function create(data)
{
	return parseResponse(await axios.post(`${API_URL}/api/notifications`, data, (await getAdapter()).getAuthHeader));
}

export async function list()
{
	return parseResponse(await axios.get(`${API_URL}/api/notifications`, (await getAdapter()).getAuthHeader));
}

export async function get(notificationID)
{
	return parseResponse(await axios.get(`${API_URL}/api/notifications/${notificationID}`, (await getAdapter()).getAuthHeader));
}

export async function acknowledgeNotification(notificationID) {
	return await axios.patch(`${API_URL}/api/notifications/ack`, {id: notificationID}, (await getAdapter()).getAuthHeader);
}

export async function removeNotification(notificationID) {
	return await axios.patch(`${API_URL}/api/notifications/delete`, {id: notificationID}, (await getAdapter()).getAuthHeader);
}

export async function removeAllNotifications() {
	return await axios.patch(`${API_URL}/api/notifications/deleteall`, (await getAdapter()).getAuthHeader);
}
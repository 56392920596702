import React, {useState, useEffect, useRef, useContext } from 'react';
import { useHistory } from "react-router-dom";
import ViewTitle from './ViewTitle';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { getAdapter } from './adapters/adapter';
import theme, { CreateButton }from './theme/PortalTheme';
import Grid from '@mui/material/Grid';
import { idToGuidMapper } from './Utility';
import { StatusMessageContext } from './StatusMessage';
import ConfirmationDialog from './ConfirmationDialog';
import { getLoggedInRole } from './adapters/baseAdapter';

export function OrganizationGrid(props){
	//Organization Columns:
	const ORGANIZATION_COLUMNS = [
		{ field: 'name', headerName: 'Organization Name', flex:5, disableColumnMenu: true }
	];
	if(props.actionsColumnRenderCell != null){
		ORGANIZATION_COLUMNS.push({
			field: "",
			filterable:false,
			disableColumnMenu:true,
			flex:1,
			minWidth:100,
			headerName: "Actions",
			sortable: false,
			disableClickEventBubbling: true,
			renderCell: props.actionsColumnRenderCell
		});
	}

	return(
		<DataGrid
			columns = {ORGANIZATION_COLUMNS}
			initialState={{
				sorting: {
				  sortModel: [{ field: 'name', sort: 'asc' }],
				}
			}}			
			disableSelectionOnClick = {true}
			components = {{Toolbar:GridToolbar}}
			density = "compact"
			autoHeight
			pageSize = {10}
			rowsPerPageOptions = {[10, 25, 50]}
			{...props}		
		/>
	);
}

export default function ManageOrganizations () {
	var stateRefs = {};
	const history = useHistory();

	//States related to data fetching:
	const [isLoading, setIsLoading] = stateRefs.isLoading = useState(false);
	const [organizations, setOrganizations] = useState([]);
	// eslint-disable-next-line	
	const [selected, setSelected] = stateRefs.selected = useState([]);
	// eslint-disable-next-line
	const [pleaseWait, setPleaseWait] = stateRefs.pleaseWait = useState(false);
	//simplest solution to retrigger the onload useeffect and properly cleanup from a button press
	const [updateGrid, setUpdateGrid] = useState(0);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const rowToDelete = useRef(null);

	//Status message:
	var { StatusManager } = useContext(StatusMessageContext);

	const isMounted = useRef(false);
	useEffect(() => {
		isMounted.current = true;
		return () => { isMounted.current = false }
	}, []);

	useEffect(() => {
        if(isMounted.current){
            if(!["oted_pa", "superadmin"].includes(getLoggedInRole())){
                StatusManager.setStatus("Access denied. You have been returned to the dashboard home screen.");
                history.push("/dashboard");
            }    
        }		
		async function fetchData(){
			setIsLoading(true);
			try {
				var adapter = await getAdapter();
				var organizationList = await adapter.organization.list();
		
				if(isMounted.current){
					organizationList = organizationList.map(idToGuidMapper);
					setOrganizations(organizationList);
				}
			} 
			catch (error) {
				console.error(error);
			}
			if(isMounted.current){
				setIsLoading(false);
			}
		};
		
		fetchData();

	}, [setIsLoading, 
		updateGrid]);

	function onDeleteBtn(_params){
		setDeleteDialogOpen(true);
		rowToDelete.current = _params;
	}

	function onConfirmDelete(){
		deleteOrg(rowToDelete.current);
	}

	async function deleteOrg(params){
		var success = true;
		setIsLoading(true);
		//delete selected applications
		var adapter = await getAdapter();
		try{
			var organizationId = params.guid;
			console.log("Deleting organization: " + organizationId);
			await adapter.organization.delete(organizationId);
			if(isMounted.current) {
				//Refresh datagrid:
				setUpdateGrid(updateGrid + 1);
				setIsLoading(false);				
			}				
		}
		catch(error){
			console.error(error);
			if(isMounted.current) {			
				setIsLoading(false);
			}
			success = false;			
		}
		if(isMounted.current) {
			var successStr = success ? "successful." : "failed.";
			console.log("Delete " + successStr );
			StatusManager.setStatus("Delete organization " + successStr);
			setUpdateGrid(updateGrid + 1);
			setIsLoading(false);				
		}	
	}	

	function actionsColumnRenderCell(params){

		function onViewBtn(params){
			var organizationId = params.guid;
			history.push("/dashboard/organizations/view_edit/" + organizationId);
		}

		return (
			<React.Fragment>
				<button
					style={{marginRight:theme.spacing(1), cursor:"pointer"}}
					onClick={() => onViewBtn(params.row)}
				>
					View/Edit
				</button>
				<button
					style={{marginRight:theme.spacing(1), cursor:"pointer"}}
					onClick={() => onDeleteBtn(params.row)}
				>
					Delete
				</button>
			</React.Fragment>
		);
	}

	function onCreateBtn(){
		history.push("/dashboard/organizations/create/");
	};

	return (
		<Container component="main" maxWidth="lg">
			<Paper style={{minHeight:'70vh'}} padding="large">				
				<ViewTitle>Manage Organizations</ViewTitle>
				{/*Delete Confirmation Dialog*/}
				<ConfirmationDialog
					open={deleteDialogOpen}
					setOpen={setDeleteDialogOpen}
					dialogTitle="Confirm Deletion"
					dialogContent="Are you sure you want to delete?"
					leftButtonLabel="No"
					leftButtonCallback={()=>{}}
					rightButtonLabel="Yes"
					rightButtonCallback={onConfirmDelete}
				/>										
				<p style={{marginBottom:0}}>Listed below are the organizations tracked within the system.</p>
				<ul>
					<li style={{marginBottom:theme.spacing(1)}}>Select the <strong>Create Organization</strong> button to create a new organization.</li>				
					<li style={{marginBottom:theme.spacing(1)}}>Select the <strong>View/Edit</strong> button to view or edit information about an organization.</li>
					<li style={{marginBottom:theme.spacing(1)}}>Select the <strong>Delete</strong> button if you wish to remove an organization from the system.
					<ul><li style={{marginTop:theme.spacing(1)}}><strong>NOTE:</strong> This will permanently delete the selected organization.</li></ul></li>
				</ul>
				<Grid container justifyContent="flex-end">
					<CreateButton onClick={onCreateBtn}>Create Organization</CreateButton>
				</Grid>
				<OrganizationGrid
					actionsColumnRenderCell = {actionsColumnRenderCell}
					loading = {isLoading}
					rows = {organizations}
				/>
			</Paper>
		</Container>
	);
}

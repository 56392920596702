import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export default function SelectCourseDeliveryMode(props) {
	const {value, name, onChange, error="", disabled=false} = props;

	return (
        <FormControl variant="outlined">
            <InputLabel id="delivery-mode-select-label">Delivery Mode</InputLabel>
            <Select
                labelId="delivery-mode-select-label"
                id="delivery-mode-select"
                label="Delivery Mode"
                value={value}
                onChange={onChange}
                name={name}
                {...(error && {error:true, helperText:error})}
                defaultValue={"ILT"}
                disabled={disabled}
            >
                <MenuItem value={"ILT"} >Instructor-Led Training (ILT)</MenuItem>
                <MenuItem value={"vILT"} >Virtual Instructor-Led Training (vILT)</MenuItem>
            </Select>
        </FormControl>
    );
}


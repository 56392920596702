import React, { useEffect, useState, useRef, useContext } from 'react';
import { getAdapter } from './adapters/adapter';
import { handleError } from './adapters/utils';
import AuditApp from './AuditApp';
import ViewTitle from './ViewTitle';
import BetterFormField from './FormField';
import { FormSection, FormSectionHeading } from "./theme/PortalTheme";
import {Box, Button, Grid, IconButton, Paper, Stack, Typography, Switch } from '@mui/material';
import theme, { DeleteButton,  DownloadButton, UploadButton } from './theme/PortalTheme';
import LoadingIndicator from './LoadingIndicator';
import { StatusMessageContext } from './StatusMessage';
import SavingIndicator from './SavingIndicator';
import Step from '@mui/material/Step';
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import useForm from './UseForm';
import { AutoDelete, Save, ArrowBack, PictureAsPdf, FileDownload, Delete } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { getLoggedInRole } from './adapters/baseAdapter';
import ConfirmationDialog from './ConfirmationDialog';


export function FileEntry(props){
    const { id, applicationId, file, canDelete, onDelete, updateSignal, setUpdateSignal } = props;

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    //Is component currently mounted?
    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false }
    }, []);  

    //Status message:
    var { StatusManager } = useContext(StatusMessageContext);
 
    async function onDownloadBtn(){
        try{
            let adapter = await getAdapter();
            let response = await adapter.application.delivery.getOtedOeReport(applicationId, file);
            const blob = new Blob([response.data], {type: response.headers['content-type']});
            
            let link = document.createElement('a');
            let url = window.URL.createObjectURL(blob);
            link.href = url;
            link.download = file;
            link.click();
            link.remove();
            setTimeout(() => window.URL.revokeObjectURL(url), 100);     
        }
        catch(error){
            console.log(error);
        }
    }

    function onDeleteBtn(){
        setDeleteDialogOpen(true);
    }

    async function onConfirmDeletion(file){
        if(isMounted.current){
            setIsDeleting(true);
        }
        await onDelete();
        if(isMounted.current){
            setIsDeleting(false);
            setUpdateSignal(updateSignal + 1);
        }
    }

    let fileTypeIcon = <PictureAsPdf sx={{marginRight:1, fontSize:32}}/>;
 
    return(
        <>
            <ConfirmationDialog
                open={deleteDialogOpen}
                setOpen={setDeleteDialogOpen}
                dialogTitle="Please Confirm"
                dialogContent="Are you sure you want to delete this file?"
                leftButtonLabel="Cancel"
                leftButtonCallback={()=>{}}
                rightButtonLabel="Delete"
                rightButtonCallback={()=>{onConfirmDeletion(file)}}
            />
            { isDeleting ? 
                <Box sx={{backgroundColor:"#fffcf7", padding:1, marginBottom:1, border:"1px dashed black"}}>
                    <Grid container justifyContent="center" alignItems="center" sx={{padding:1}}>
                        <AutoDelete sx={{mr:1, fontSize:40}}/>                        
                    </Grid>
                    <p style={{textAlign:"center", marginTop:0, fontWeight:"bold"}}>Deleting <span style={{color:theme.palette.primary.main}}>{file}</span></p>
                    <LoadingIndicator/>
                </Box> :
                <Grid container>
                    <Grid item>
                        <Box sx={{backgroundColor:"#fffcf7", padding:1, marginBottom:1, border:"1px dashed black"}}>
                            <Grid container justifyContent="flex-start" alignItems="center" sx={{padding:1}}>
                                <Grid item>{ fileTypeIcon }</Grid>
                                <Grid item><span style={{color:theme.palette.primary.main, fontWeight:"bold"}}>{file}</span></Grid>
                                <Grid item flexGrow={1}>
                                    <Grid container justifyContent="flex-end" alignItems="center" sx={{ ml:2, padding:1}} >
                                        <Grid item>
                                            <IconButton title="Download" onClick={onDownloadBtn}>
                                                <FileDownload />
                                            </IconButton>
                                        </Grid>
                                        { canDelete &&                        
                                        <Grid item>
                                            <IconButton title="Delete" onClick={onDeleteBtn}>
                                                <Delete />
                                            </IconButton>
                                        </Grid> }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>

            }            
        </> 
    );
}


export default function ICDApplicationFormApplicationStatus(props) {

	const {application, stepLogic, updateSignal, setUpdateSignal } = props;
	
	//Is component currently mounted?
	const isMounted = useRef(false)
	useEffect(() => {
		isMounted.current = true;
		return () => { isMounted.current = false }
	}, []);

    //Status message:
	var { StatusManager } = useContext(StatusMessageContext);

    //History:
    var history = useHistory();
	
	//Loading/Saving states:
	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [activeStep, setActiveStep] = useState(1);
    const [applicationAction, setApplicationAction] = useState("");
    const [validStatuses, setValidStatuses] = useState(["In-Progress", "Submitted", "Processed", "Approved", "Closeout", "Completed"]);
    const [deliveryInfo, setDeliveryInfo] = useState({});
    
    //Reference to the hidden file input element:
    const hiddenFileInputReport = React.useRef(null);
    const hiddenFileInputResponse = React.useRef(null);
    
	//Form validation:
    function validate(){
        let _errors = { ...errors };
		console.log("Validating");
		console.log(formValues);
		
        //Validation goes here:
        if(applicationAction === "action_reject" && !["closeout", "completed"].includes(application.status)){
            _errors.rejectionReason = (formValues.rejectionReason.length == 0) ? "Please enter a detailed reason for Non-Acceptance." : "";
        }
        else{
            _errors.rejectionReason = "";
        }

        setErrors({
            ..._errors
        });

		var result = Object.values(_errors).every(x => x === "");
		console.log("Validation " + (result ? "SUCCESS" : "FAILED"));
		return result;
		
	}

	//Initial Form Values:
	const initialFormValues = {
        rejectionReason:"",
        actualParticipants:"",
        oeReportRequired: false,
        certificatesAllowed: false
	};

	//Form-related vars:
	const {
        formValues,
		setFormValue,
        errors,
        setErrors,
        handleInputChange,
    } = useForm(initialFormValues, false, validate);

	//Create refs to mutable objects that would otherwise be passed as dependencies to useEffect hook, to prevent triggering re-renders unnecessarily.
	const setFormValueRef = useRef(setFormValue);

	//On view load:
	useEffect(() => {
        //Compare provided status string to list of valid statuses and return matching index to populate stepper component:
        function getActiveStep(currentStatus){
            for(var i = 0; i < validStatuses.length; i++){
                if(currentStatus.toLowerCase() === validStatuses[i].toLowerCase()){
                    return i;
                }
            }
            return 0;
        }
        if(isMounted.current){
            setActiveStep(getActiveStep(application.status));
            if(application.status === "completed"){
                setApplicationAction("action_reject");
            }
            else if (application.status ==="closeout"){
                setApplicationAction("action_none");
            }
            else{
                setApplicationAction("action_accept");
            }
        }
		let setFormValue = setFormValueRef.current;
        async function fetchData(){
			setIsLoading(true);
			try {
				let adapter = await getAdapter();
				let _deliveryInfo = await adapter.application.delivery.get(application.guid);
				
                if(isMounted.current){
					console.log(_deliveryInfo);
					setFormValue("actualParticipants", _deliveryInfo.actualParticipants.toString() || "");
					setDeliveryInfo(_deliveryInfo);
                    setFormValue("oeReportRequired", _deliveryInfo.oeReportRequired == true);
                    setFormValue("certificatesAllowed", _deliveryInfo.certificatesAllowed == true);
                }
			}
			catch (error) {
				console.error(error);
			}
			if(isMounted.current){
				setIsLoading(false);
			}
		}	

        if(application.status === "closeout") fetchData();
	}, [ application.guid, application.status, validStatuses ]);

	function onBackBtn(){
        stepLogic.prevStep();
	}

	function onNextBtn(){
        saveData(()=>{
            stepLogic.nextStep();
        });
	}

    function handleChange(e){
        setFormValue("rejectionReason", "");
        setApplicationAction(e.target.value);      
    }

    function onReturnBtn(){
        history.push("/dashboard/applications/manage/");
    }

    //Update status:
    async function updateStatus(newStatus, reason=null){
        try{
            let adapter = await getAdapter();
            let response = await adapter.application.updateStatus(application.guid, newStatus, reason); 
        }
        catch(error){
            console.log(error);
        }
    } 

    //Save:
    async function saveData(successCallback = () => {} ){
        console.log("Updating information for [ application.guid: " + application.guid + " ]");
        var success = true;
        if(isMounted.current){
            setIsSaving(true);
        }
        try {
            var adapter = await getAdapter();
            let deliveryInfo = {
                actualParticipants: formValues.actualParticipants || null,
                certificatesAllowed: formValues.certificatesAllowed,
                oeReportRequired: formValues.oeReportRequired
            };			
            await adapter.application.delivery.update(application.guid, deliveryInfo);
        } 
        catch (error) {
            success = false;
            console.error(error);
        }
        var successStr = success ? "successful." : "failed.";
        StatusManager.setStatus("Save application closeout information " + successStr);
        if(isMounted.current){
            setIsSaving(false);
            if(success){
                successCallback();
            }
        }
    }     

    async function onSaveBtn(){
        console.log(applicationAction);

        const statusMap = {
            "in-progress": {
                "prev": null,
                "next": "submitted"
            },
            "submitted":{
                "prev": "in-progress",
                "next": "processed"
            },
            "processed":{
                "prev": "in-progress",
                "next": "approved",
            },
            "approved":{
                "prev": "in-progress",
                "next": "closeout"
            },
            "closeout":{
                "prev": "approved",
                "next": "completed"
            },
            "completed":{
                "prev": "closeout",
                "next": null
            }
        };
        if(validate()){
            if(applicationAction == "action_accept" && statusMap[application.status].next != null ){
                await updateStatus(statusMap[application.status].next);
            }
            if(applicationAction == "action_reject" && statusMap[application.status].prev != null ){
                await updateStatus(statusMap[application.status].prev, formValues.rejectionReason);
            }
            if(application.status ==="closeout"){
                saveData(()=>{
                    if(isMounted.current){
                        setUpdateSignal(updateSignal+1);
                    }
                });
            }
            else{
                if(isMounted.current){
                    setUpdateSignal(updateSignal+1);
                }                
            }
        }
    }

    //Conditional props for Actual Participants:
	let conditionalProps = ["applicant_poc", "superadmin"].includes(getLoggedInRole()) ? {} : {variant: "filled", readOnly: true};

    //Reset file picker:
    function resetFilePicker(e){
        e.target.value = null;
    }

    async function uploadOEReport(file){
        console.log("UPLOAD REPORT");
        var success = true;
        if(isMounted.current){
            setIsUploading(true);
        }
        try{
            let adapter = await getAdapter();
            let response = await adapter.application.delivery.uploadOtedOeReport(application.guid, file);                          
        }
        catch(error){
            console.log(error);
            handleError(error, StatusManager, error.response?.data?.data?.response);
            success = false;
        }
        if(isMounted.current){
            setIsUploading(false);
            setUpdateSignal(updateSignal + 1);
        }
    }

    async function uploadOEResponse(file){
        console.log("UPLOAD RESPONSE");
        var success = true;
        if(isMounted.current){
            setIsUploading(true);
        }
        try{
            let adapter = await getAdapter();
            let response = await adapter.application.delivery.uploadOeResponse(application.guid, file);                          
        }
        catch(error){
            console.log(error);
            handleError(error, StatusManager, error.response?.data?.data?.response);
            success = false;
        }
        if(isMounted.current){
            setIsUploading(false);
            setUpdateSignal(updateSignal + 1);
        }
    }    

    //When a file is selected:
    async function handleChangeOEReport(e){
        let filesToUpload = e.target.files;
        if(!filesToUpload || filesToUpload.length == 0) return;
        
        let fileToUpload = filesToUpload[0];
        var re = /(?:\.([^.]+))?$/;
        var extension = re.exec(fileToUpload.name)[1];
        if(extension){
            if(['pdf'].includes(extension.toLowerCase())) {
                //Upload begins here:
                await saveData();
                await uploadOEReport(fileToUpload);
            }
        }
    }

    async function handleChangeOEResponse(e){
        let filesToUpload = e.target.files;
        if(!filesToUpload || filesToUpload.length == 0) return;
        
        let fileToUpload = filesToUpload[0];
        var re = /(?:\.([^.]+))?$/;
        var extension = re.exec(fileToUpload.name)[1];
        if(extension){
            if(['pdf'].includes(extension.toLowerCase())) {
                //Upload begins here:
                await saveData();
                await uploadOEResponse(fileToUpload);
            }
        }
    }    


    async function onDeleteOeReport(){
        try{
            let adapter = await getAdapter();
            let response = await adapter.application.delivery.deleteOtedOeReport(application.guid);
        }
        catch(error){
            console.log(error);
        }
    }

    async function onDeleteOeResponse(){
        try{
            let adapter = await getAdapter();
            let response = await adapter.application.delivery.deleteOeResponse(application.guid);
        }
        catch(error){
            console.log(error);
        }        
    }


    async function onUploadOEReportBtn(e){
        hiddenFileInputReport.current.click();
    }

    async function onUploadOEResponseBtn(e){
        hiddenFileInputResponse.current.click();
    }
    
    async function onGenerateCertificatesBtn(e){
		try{
			let adapter = await getAdapter();
			var response = await adapter.application.getLatestCertificationFile(application.guid);
			let blob=new Blob([response.data]);
			let link=document.createElement('a');
			let url=window.URL.createObjectURL(blob); 
			link.href = url;
			link.download=`applicationCert_${application.guid}.pdf`;
			link.click();
			link.remove();
			setTimeout(() => window.URL.revokeObjectURL(url), 100);
		} 
		catch(err){
			//TODO handle this, give to status manager or something
			console.error(err);
		}
	}

    //Hide OE-related features (set to "false" to show O&E-related functionality):
    const hideOEFeatures = true;

	return (
		<React.Fragment>
			<Paper padding="huge" sx={{margin:0}} elevation={8}>
				<Grid container alignItems="center" justifyContent="center">
					<ViewTitle>Create ICD Application &ndash; {stepLogic.getCurrentStepName()}</ViewTitle>
				</Grid>
				{ isLoading ? <LoadingIndicator padding={4}/> :
				<>
                    <FormSectionHeading>Current Status</FormSectionHeading>
					<FormSection>
                        <Stepper nonLinear alternativeLabel sx={{marginBottom:2}} activeStep={activeStep}>
                            {validStatuses.map((label) => (                
                                <Step 
                                    key={label}
                                    sx={{
                                        '& .MuiStepLabel-root .Mui-completed': {
                                            color: 'primary.main', // circle color (COMPLETED)
                                        },
                                        '& .MuiStepLabel-root .Mui-active': {
                                            color: 'secondary.main', // circle color (ACTIVE)
                                        }
                                    }}
                                >                
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
					</FormSection>
                    <FormSectionHeading>Available Actions</FormSectionHeading>
                    { application.status === "in-progress" &&
                        <FormSection>
                            <p>The current application status is: <span style={{color:theme.palette.secondary.main, fontWeight:"bold"}}>In-Progress</span></p>                            
                            <p>After reviewing the contents of this ICD Application and making a determination regarding its status, use the drop-down option below to select an appropriate course of action.</p>
                            <p>Select <strong>Update to Submitted</strong> to submit this application.</p>
                            <Box sx={{mt:4}}>
                                <FormControl variant="outlined">
                                    <InputLabel id="action-select-label">Action</InputLabel>
                                    <Select
                                        labelId="action-select-label"
                                        id="action-select"
                                        label="Action"
                                        value={applicationAction}
                                        onChange={handleChange}
                                        defaultValue={"action_accept"}
                                    >
                                        <MenuItem value={"action_accept"}>Update to Submitted</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            { applicationAction === "action_reject" &&
                                <>
                                    <Grid container spacing={1}>   
                                        <Grid item xs={12} mt={2}><BetterFormField multiline={true} required rows={6} label="Reason for Non-Acceptance" name="rejectionReason" value={formValues.rejectionReason} error={errors.rejectionReason} onChange={handleInputChange}/></Grid>						
                                    </Grid>
                                </>
                            }
                        </FormSection>
                    }                    
                    { application.status === "submitted" &&
                        <FormSection>                        
                            <p>The current application status is: <span style={{color:theme.palette.secondary.main, fontWeight:"bold"}}>Submitted</span></p>
                            <p>After reviewing the contents of this ICD Application and making a determination regarding its status, use the drop-down option below to select an appropriate course of action.</p>                                                        
                            <ul>
                                <li style={{marginBottom:theme.spacing(1)}}>Select the <strong>Update to Processed</strong> option if you have determined that the ICD Application is satisfactory and wish to progress it to the <em>Processed</em> status.</li>
                                <li>Select the <strong>Revert to In-Progress</strong> option if you have determined that the ICD Application is either incomplete or unsatisfactory and wish to move it back to <em>In-Progress</em> status. Please provide a clear and complete explanation in the <strong>Reason for Non-Acceptance</strong> text input area that appears.</li>
                            </ul>
                            <Box sx={{mt:4}}>
                                <FormControl variant="outlined">
                                    <InputLabel id="action-select-label">Action</InputLabel>
                                    <Select
                                        labelId="action-select-label"
                                        id="action-select"
                                        label="Action"
                                        value={applicationAction}
                                        onChange={handleChange}
                                        defaultValue={"action_accept"}
                                    >
                                        <MenuItem value={"action_accept"}>Update to Processed</MenuItem>
                                        <MenuItem value={"action_reject"}>Revert to In-Progress</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            { applicationAction === "action_reject" &&
                                <>
                                    <Grid container spacing={1}>   
                                        <Grid item xs={12} mt={2}><BetterFormField multiline={true} required rows={6} label="Reason for Non-Acceptance" name="rejectionReason" value={formValues.rejectionReason} error={errors.rejectionReason} onChange={handleInputChange}/></Grid>						
                                    </Grid>
                                </>
                            }
                        </FormSection>
                    }
                    { application.status === "processed" &&
                        <FormSection>
                            <p>The current application status is: <span style={{color:theme.palette.secondary.main, fontWeight:"bold"}}>Processed</span></p>                            
                            <p>After reviewing the contents of this ICD Application and making a determination regarding its status, use the drop-down option below to select an appropriate course of action.</p>
                            <ul>
                                <li style={{marginBottom:theme.spacing(1)}}>Select the <strong>Update to Approved</strong> option if the organization has fulfilled all pre-ICD requirements, and you wish to progress it to <em>Approved</em> status.</li>
                                <li>Select the <strong>Revert to In-Progress</strong> option if you have determined that the ICD Application is either incomplete or unsatisfactory and wish to move it back to <em>In-Progress</em> status. Please provide a clear and complete explanation in the <strong>Reason for Non-Acceptance</strong> text input area that appears.</li>
                            </ul>
                            <Box sx={{mt:4}}>
                                <FormControl variant="outlined">
                                    <InputLabel id="action-select-label">Action</InputLabel>
                                    <Select
                                        labelId="action-select-label"
                                        id="action-select"
                                        label="Action"
                                        value={applicationAction}
                                        onChange={handleChange}
                                        defaultValue={"action_accept"}
                                    >
                                        <MenuItem value={"action_accept"}>Update to Approved</MenuItem>
                                        <MenuItem value={"action_reject"}>Revert to In-Progress</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            { applicationAction === "action_reject" &&
                                <>
                                    <Grid container spacing={1}>   
                                        <Grid item xs={12} mt={2}><BetterFormField multiline={true} required rows={6} label="Reason for Non-Acceptance" name="rejectionReason" value={formValues.rejectionReason} error={errors.rejectionReason} onChange={handleInputChange}/></Grid>						
                                    </Grid>
                                </>
                            }
                        </FormSection>
                    }
                    { application.status === "approved" &&
                        <FormSection>
                            <p>The current application status is: <span style={{color:theme.palette.secondary.main, fontWeight:"bold"}}>Approved</span></p>                            
                            <p>After reviewing the contents of this ICD Application and making a determination regarding its status, use the drop-down option below to select an appropriate course of action.</p>
                            <ul>
                                <li style={{marginBottom:theme.spacing(1)}}>Select the <strong>Update to Completed</strong> option if the ICD has been fully completed and all post-delivery requirements have been met.</li>
                                <li>Select the <strong>Revert to In-Progress</strong> option if you have determined that the ICD Application is either incomplete or unsatisfactory and wish to move it back to <em>In-Progress</em> status. Please provide a clear and complete explanation in the <strong>Reason for Non-Acceptance</strong> text input area that appears.</li>
                            </ul>
                            <Box sx={{mt:4}}>
                                <FormControl variant="outlined">
                                    <InputLabel id="action-select-label">Action</InputLabel>
                                    <Select
                                        labelId="action-select-label"
                                        id="action-select"
                                        label="Action"
                                        value={applicationAction}
                                        onChange={handleChange}
                                        defaultValue={"action_accept"}
                                    >
                                        <MenuItem value={"action_accept"}>Update to Closeout</MenuItem>
                                        <MenuItem value={"action_reject"}>Revert to In-Progress</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            { applicationAction === "action_reject" &&
                                <>
                                    <Grid container spacing={1}>   
                                        <Grid item xs={12} mt={2}><BetterFormField multiline={true} required rows={6} label="Reason for Non-Acceptance" name="rejectionReason" value={formValues.rejectionReason} error={errors.rejectionReason} onChange={handleInputChange}/></Grid>						
                                    </Grid>
                                </>
                            }
                        </FormSection>
                    }
                    
                    {   /*Closeout*/
                        application.status === "closeout" &&
                        <>
                            <FormSection>
                                <p>The current application status is: <span style={{color:theme.palette.secondary.main, fontWeight:"bold"}}>Closeout</span></p>                            
                                <p>Select the <strong>Revert to Approved</strong> option if this ICD was marked <em>Closeout</em> in error. This will revert the ICD back to the <em>Approved</em> status.</p>
                                <Box sx={{mt:4}}>
                                    <FormControl variant="outlined">
                                        <InputLabel id="action-select-label">Action</InputLabel>
                                        <Select
                                            labelId="action-select-label"
                                            id="action-select"
                                            label="Action"
                                            value={applicationAction}
                                            onChange={handleChange}
                                            defaultValue={"action_accept"}
                                        >
                                            <MenuItem value={"action_none"}>Remain in Closeout</MenuItem>
                                            <MenuItem value={"action_accept"}>Update to Completed</MenuItem>
                                            <MenuItem value={"action_reject"}>Revert to Approved</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </FormSection>
                            <FormSectionHeading>Participant Number Confirmation</FormSectionHeading>
                            {/* <FormSection>
                                <p>Once the delivery of the ICD course is complete, the organization POC will input the actual number of participants that successfully completed the course. Once that number has been entered, it will display below for your reference. No further action is needed.</p>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <BetterFormField 
                                            {...conditionalProps}
                                            InputLabelProps={{ shrink: true }}
                                            required
                                            maxLength={3}
                                            sx={{minWidth:300}}
                                            label="Actual Number of Participants Completed" 
                                            name="actualParticipants" 
                                            value={formValues.actualParticipants} 
                                            error={errors.actualParticipants}
                                            onChange={(e)=>{
                                                
                                                //    Only allow numeric characters. 
                                                //    This is necessary, because type="number" on an MUI TextField still allows mathematical alphabet characters such as "e" per the HTML5 technical spec. 
                                                //    Furthermore, when an MUI TextField has type="number" set, it ignores max character length restrictions.
                                                //
                                                const re = /^[0-9\b]+$/;
                                                // if value is not blank, then test the regex
                                                if (e.target.value === '' || re.test(e.target.value)) {
                                                setFormValue(e.target.name, e.target.value);
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </FormSection> */}
                            <FormSectionHeading>Allow Certificate Generation</FormSectionHeading>
                                    <FormSection>
                                        <p>Indicate below whether certificates of completion may be generated for this ICD.</p>
                                        <Grid container>
                                            <Stack direction="row" spacing={1} alignItems="center">                        
                                                <Typography>Not Allowed</Typography>
                                                <Switch checked={formValues.certificatesAllowed} inputProps={{ 'aria-label': 'Allow certificate generation?' }} onChange={(e)=>{
                                                    setFormValue("certificatesAllowed", e.target.checked);
                                                }} />
                                                <Typography>Allowed</Typography>
                                            </Stack>  
                                        </Grid>
                                    </FormSection>      
                            { deliveryInfo.certificatesAllowed == true &&
							<>
								<FormSectionHeading>Generate Certificates</FormSectionHeading>
								<FormSection>
									<p>Select the button below to download certificates of completion for this course.</p>
									<ul>
										<li>Certificates will be downloaded as a .ZIP file containing certificates in .PDF format.</li>
									</ul>
									<DownloadButton variant="contained" color="primary" size="large" onClick={onGenerateCertificatesBtn}>Generate Certificates</DownloadButton>
								</FormSection>
							</>	}	                     
                            { !hideOEFeatures &&
                                <>
                                    <FormSectionHeading>Confirm O&E Report Requirements</FormSectionHeading>
                                    <FormSection>
                                        <p>Indicate below whether an OTED O&E Report must be created by OTED for this course delivery. If an OTED O&E Team attended this ICD course, a report is required.</p>
                                        <Grid container>
                                            <Stack direction="row" spacing={1} alignItems="center">                        
                                                <Typography>Not Required</Typography>
                                                <Switch checked={formValues.oeReportRequired} inputProps={{ 'aria-label': 'Is An O&E Report Required?' }} onChange={(e)=>{
                                                    setFormValue("oeReportRequired", e.target.checked);
                                                }} />
                                                <Typography>Required</Typography>
                                            </Stack>  
                                        </Grid>
                                    </FormSection>
                                </>
                            }
                            { !hideOEFeatures && formValues.oeReportRequired == true &&
                                <>
                                    <FormSectionHeading>Upload OTED's O&E Report</FormSectionHeading>
                                    <FormSection>
                                        <p>If an OTED O&E Report is required, upload a .PDF version below once it has been completed by the O&E Team and approved by OTED Leadership. This file will be shared with the organization POC.</p>
                                        { deliveryInfo.otedOeReport != null &&
                                            <FileEntry
                                                id="oeReport"
                                                applicationId={application.guid}
                                                file={deliveryInfo.otedOeReport}
                                                canDelete={["oted_pa", "superadmin"].includes(getLoggedInRole())}
                                                updateSignal={updateSignal}
                                                setUpdateSignal={setUpdateSignal}
                                                onDelete={onDeleteOeReport}
                                            />
                                        }
                                        { !isUploading && deliveryInfo.otedOeReport == null && 
                                            <Grid container alignItems="center" spacing={1}>                
                                                <Grid item><UploadButton disabled={isUploading} onClick={onUploadOEReportBtn}>Upload File</UploadButton></Grid>
                                            </Grid>
                                        }
                                        {/*Hidden file input. We'll use a ref to reach it.*/}
                                        <input
                                            type="file"
                                            ref={hiddenFileInputReport}
                                            onChange={handleChangeOEReport}
                                            onClick={resetFilePicker}
                                            style={{display: 'none'}}
                                            accept=".pdf" 
                                        />                                    
                                    </FormSection>
                                </>	
                            }
                            { !hideOEFeatures && formValues.oeReportRequired == true &&
                                <>
                                    <FormSectionHeading>Confirm Organization O&E Report Response</FormSectionHeading>
                                    <FormSection>
                                        <p>After the organization has had the opportunity to review the OTED O&E Report, the organization POC will submit a formal written response. Once this O&E Report response has been submitted by the organization POC, it will appear below. Download and review this file to ensure that no further action is necessary.</p>
                                        { deliveryInfo.oeResponse != null &&
                                            <FileEntry
                                                id="oeResponse"
                                                applicationId={application.guid}
                                                file={deliveryInfo.oeResponse}
                                                onDelete={onDeleteOeResponse}
                                                canDelete={["applicant_poc", "superadmin"].includes(getLoggedInRole())}
                                                updateSignal={updateSignal}
                                                setUpdateSignal={setUpdateSignal}
                                            />
                                        }
                                        { !isUploading && deliveryInfo.oeResponse == null && ["applicant_poc", "superadmin"].includes(getLoggedInRole()) &&
                                        <>
                                            <Grid container alignItems="center" spacing={1}>                
                                                <Grid item><UploadButton disabled={isUploading} onClick={onUploadOEResponseBtn}>Upload File</UploadButton></Grid>
                                            </Grid>
                                            <input
                                                type="file"
                                                ref={hiddenFileInputResponse}
                                                onChange={handleChangeOEResponse}
                                                onClick={resetFilePicker}
                                                style={{display: 'none'}}
                                                accept=".pdf" 
                                            />                                    
                                        </>
                                        }                                
                                    </FormSection>
                                </>	
                            }
                        </>
                    }
                    { application.status === "completed" &&
                        <FormSection>
                            <p><span style={{color:theme.palette.secondary.main, fontWeight:"bold"}}>This ICD has been completed.</span></p>
                            <p>Select the <strong>Revert to Approved</strong> option if this ICD was marked <em>Completed</em> in error. This will revert the ICD back to the <em>Approved</em> status.</p>
                            <Box sx={{mt:4}}>
                                <FormControl variant="outlined">
                                    <InputLabel id="action-select-label">Action</InputLabel>
                                    <Select
                                        labelId="action-select-label"
                                        id="action-select"
                                        label="Action"
                                        value={applicationAction}
                                        onChange={handleChange}
                                        defaultValue={"action_reject"}
                                    >
                                        <MenuItem value={"action_reject"}>Revert to Closeout</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>                            
                        </FormSection>
                    }
                    {
                        isSaving ? <SavingIndicator/> :
                        <>
                        <Grid sx={{marginTop: 4}} container alignItems="center" justifyContent="center">
                            <Button variant="contained" color="primary" size="large" onClick={onSaveBtn} startIcon={<Save/>}>Save and Continue</Button>
                            <Button startIcon={<ArrowBack/>} variant="contained" color="primary" size="large" onClick={onReturnBtn} >Return to "Manage Applications"</Button>
                        </Grid>
                        </>
                    }                                         
                    <FormSectionHeading>Application Audit</FormSectionHeading>
					<FormSection maxHeight={'30vh'} sx={{overflowY:'scroll'}} >
                        <AuditApp applicationId={application.guid} />
					</FormSection>
				</>
				}
			</Paper>          
		</React.Fragment>
	);
}

import React, { useState, useContext } from 'react';
import Typography from '@mui/material/Typography';
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import PreviewIcon from '@mui/icons-material/Preview';
import { getFormattedDateTime, isValidDateFormat, capitalize } from './Utility';
import { StatusMessageContext } from './StatusMessage';
import { handleError } from './adapters/utils';

const Audit = (props) => {
    const [showBody, setShowBody] = React.useState(false);
    var { StatusManager } = useContext(StatusMessageContext);
    var align = (props.map_index % 2) ? "right" : "left";
    var color= (props.map_index % 2) ? "grey" : "primary";
    var rows = [];

    try {
        for(const sectionTitle of Object.keys(props.delta)) {
            cleanKeys(props.delta[sectionTitle]);

            if(Object.keys(props.delta[sectionTitle]).length > 0) {
                rows.push(<div key={Math.random()}>{capitalize(mapFields(sectionTitle))}</div>);
                cleanKeys(props.delta[sectionTitle]);
                let sectionBody = props.delta[sectionTitle];
                
                for(const sectionKey of Object.keys(sectionBody)) {
                    let sectionValue = formatBodyValue(sectionBody[sectionKey]);
                    let keyDisplay = /^\d+$/.test(sectionKey) ? "- " : mapFields(sectionKey) + ":"; // if key is digit, display hyphen
                    rows.push(<div key={Math.random()} style={{paddingLeft : 10}}>{capitalize(keyDisplay)}&nbsp;{mapFields(sectionValue)}</div>);
                }
            } else if(sectionTitle === 'qualified' || sectionTitle === 'unqualified') { // in this case, instructors have been removed
                rows.push(<div key={Math.random()}>{capitalize(mapFields(sectionTitle))}</div>);
                rows.push(<div key={Math.random()} style={{paddingLeft : 10}}>Empty</div>)
            }
        }
    } catch(err) {
        // handleError(err, StatusManager);
        console.log(err2);
    }

    if(rows.length === 0) {
        rows.push(<div key={Math.random()} style={{paddingLeft : 10}}>N/A</div>);
    }

    var body_jsx = <Typography component='div'>{rows}</Typography>

    function cleanKeys(section) {
        const keysToRemove = ['step', 'courseID', 'organizationID', 'applicationID','guid'];
        keysToRemove.forEach(key => delete section[key]);
    }

    function mapFields(word) {
        const dict = {
            courseName: 'Course Name',
            courseNumber: 'Course Number',
            courseTitle: 'Course Title',
            createdOn: 'Created On',
            icdEligible: 'ICD Eligible',
            isVirtualILT: 'Virtual ILT',
            facilityName: 'Facility Name',
            addressStreet: 'Address Street',
            addressCity: 'Address City',
            addressState: 'Address State',
            addressZip: 'Address Zip',
            startDate: 'Start Date',
            endDate: 'End Date',
            firstName: 'First Name',
            lastName: 'Last Name',
            MiddleInitial: 'Middle Initial',
            orgName: 'Org. Name',
            agreementResponse1: 'Agreement Response 1',
            agreementResponse2: 'Agreement Response 2',
            agreementResponse3: 'Agreement Response 3',
            agreementResponse4: 'Agreement Response 4',
            agreementResponse5: 'Agreement Response 5',
            agreementResponse6: 'Agreement Response 6',
            agreementResponse7: 'Agreement Response 7',
            courseInfo: 'Course Info',
            poc: 'POC',
            submitted: 'Submitted',
            processed: 'Processed',
            approved: 'Approved',
            closeout: 'Closeout',
            'in-progress': 'In-Progress',
            completed: 'Completed'
        };

        return dict[word] ? dict[word] : word;
    }

    function formatBodyValue(value) {
        if(value == null) return "";
        if(isValidDateFormat(value)) {
            return getFormattedDateTime(new Date(value));
        }
        if(typeof value === 'object' && value !== null) { //nested object
            var nestedRows = [];

            cleanKeys(value);
            for(const key of Object.keys(value)) {
                nestedRows.push(<div key={Math.random()} style={{paddingLeft : 20}}>{capitalize(mapFields(key))}:&nbsp;{mapFields(String(value[key]))}</div>)
            }
            return nestedRows;
        }

        return String(value);
    }   

    function handleClick() {
        setShowBody(!showBody);
    }

    return (
        <TimelineItem>
            <TimelineOppositeContent sx={{ m: 'auto 0'}} align={align} variant="body2" color="text.secondary" >
                {props.pretty_date}
                <br/>
                {props.created_by_name}
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineConnector />
                    <TimelineDot color={color} >
                        <PreviewIcon onClick={()=>{handleClick()}} style={{cursor:"pointer"}} />
                    </TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2}} >
                <Typography component='div' variant="h6" maxWidth={'30vh'}>
                    {props.table_action }
                    { showBody ? body_jsx : null }
                </Typography >
            </TimelineContent>
        </TimelineItem>   
    )
}

export default Audit

import React, { useEffect, useState, useRef, useContext } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ViewTitle from './ViewTitle';
import LoadingIndicator from './LoadingIndicator';
import { getAdapter } from './adapters/adapter';
import BetterFormField from './FormField';
import SavingIndicator from './SavingIndicator';
import { useParams, useHistory } from "react-router-dom";
import { BackButton, SaveButton } from './theme/PortalTheme';
import SelectCourseDeliveryMode from './SelectCourseDeliveryMode';
import { StatusMessageContext } from './StatusMessage';
import useForm from './UseForm';
import { getLoggedInRole } from './adapters/baseAdapter';

function CourseForm(props){

    const { courseId } = useParams();
    const history = useHistory();

	//Is component currently mounted?
    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false }
    }, []);
    
    //Status message:
	var { StatusManager } = useContext(StatusMessageContext);

	//Loading/Saving states:
	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
    
	//Form validation:
    function validate(){
        let _errors = { ...errors };
		console.log("Validating");
		console.log(formValues);

		_errors.courseNumber = (formValues.courseNumber === "") ? " " : "";
		_errors.courseTitle = (formValues.courseTitle === "") ? " " : "";

        setErrors({
            ..._errors
        });

		var result = Object.values(_errors).every(x => x === "");
		console.log("Validation " + (result ? "SUCCESS" : "FAILED"));
		return result;
	}

	//Initial Form Values:
	const initialFormValues = {
		courseNumber: "",
        courseTitle: "",
        courseDeliveryMode: "ILT",
		courseDescription: ""
	};

	//Form-related vars:
	const {
		formValues,
		setFormValue,
		errors,
		setErrors,
		handleInputChange,
	} = useForm(initialFormValues, false, validate);

	//Create refs to mutable objects that would otherwise be passed as dependencies to useEffect hook, to prevent triggering re-renders unnecessarily.
	const setFormValueRef = useRef(setFormValue);

	//On view load:
    useEffect(() => {
        let setFormValue = setFormValueRef.current;

		if(isMounted.current) {
            if(!["oted_pa", "superadmin"].includes(getLoggedInRole())){
                StatusManager.setStatus("Access denied. You have been returned to the dashboard home screen.");
                history.push("/dashboard");
            }
		}        

        async function fetchData(){
            setIsLoading(true);
            try {
                var adapter = await getAdapter();               
                var course = await adapter.course.get(courseId);
            } 
            catch (error) {
                console.error(error);	
            }
            if(isMounted.current){
                setFormValue("courseNumber", course.courseNumber || "");
                setFormValue("courseTitle", course.courseTitle || "");
                setFormValue("courseDeliveryMode", course.isVirtualILT === true ? "vILT" : "ILT");
                setFormValue("courseDescription", course.description || "");
                setFormValue("courseICDEligible", course.icdEligible || true);
                setIsLoading(false);
            }
        }        
        fetchData();
    },
    [
        setIsLoading,
        courseId
    ]);

    //Save:
    async function saveData( successCallback = () => {} ){
        var success = true;
        setIsSaving(true);
		try {
			var adapter = await getAdapter(); 
			let course = {
                courseNumber: formValues.courseNumber,
                courseTitle: formValues.courseTitle,
                isVirtualILT: (formValues.courseDeliveryMode === "vILT"),
                description: formValues.courseDescription,
                icdEligible: true,
            };
            await adapter.course.update(courseId, course);
		} 
        catch (error) {
			success = false;
            console.error(error);
        }
        if(isMounted.current){
            setIsSaving(false);
            let successStr = success ? "successful." : "failed.";
            console.log("Save " + successStr );
            StatusManager.setStatus("Save course " + successStr);
            if(success) successCallback();
        }   
    } 

    //Button handlers:
    function onBackBtn(){
        history.goBack();
    }

    function onSaveBtn(){
		if(validate()){
			saveData(()=>{
				history.goBack();
			});
		}
    }

    //Render:
	return (
        isLoading ? <LoadingIndicator/> :
		<>
            <Grid container spacing={1}>
                <Grid item xs={3}><BetterFormField required label="FDA Course Number" name="courseNumber" value={formValues.courseNumber} error={errors.courseNumber} onChange={handleInputChange} /></Grid>
                <Grid item xs={9}><BetterFormField required label="Course Title" name="courseTitle" value={formValues.courseTitle} error={errors.courseTitle} onChange={handleInputChange} /></Grid>
                <Grid item xs={12}><SelectCourseDeliveryMode required label="Course Delivery Mode" name="courseDeliveryMode" value={formValues.courseDeliveryMode} error={errors.courseDeliveryMode} onChange={handleInputChange}/></Grid>                
                <Grid item xs={12} mt={1}><BetterFormField multiline={true} rows={6} label="Course Description" name="courseDescription" value={formValues.courseDescription} error={errors.courseDescription} onChange={handleInputChange}/></Grid>						
            </Grid>
            <Grid container justifyContent="center" alignItems="bottom" sx={{marginTop:4}}>
                { isSaving ? <SavingIndicator /> :
                    <>
                        <BackButton onClick={onBackBtn} /> 
                        <SaveButton onClick={onSaveBtn} />                      
                    </>
                }
			</Grid>
		</>
	);
}

export default function ViewEditCourse() {
	return (
		<Container component="main" maxWidth="lg">
			<Paper style={{minHeight:'70vh'}} padding="large">
				<ViewTitle>View/Edit Course</ViewTitle>
                <CourseForm />
			</Paper>
		</Container>
    );
}
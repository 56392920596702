import axios from 'axios';
import {parseResponse, API_URL} from "./utils";
import { getAdapter } from './adapter';

/**
input: {
  courseNumber,
  courseTitle,
  description,
  icdEligible,
  isVirtualILT
}
output: "<guid>"
*/
export async function create(data)
{
  return parseResponse(await axios.post(`${API_URL}/api/courses`, data, (await getAdapter()).getAuthHeader));
}

export async function list()
{
  return parseResponse(await axios.get(`${API_URL}/api/courses`,(await getAdapter()).getAuthHeader));
}

export async function listCoursesMetrics() {
	var data = parseResponse(await axios.get(`${API_URL}/api/courses`, (await getAdapter()).getAuthHeader));
	return {coursesCount: data.length};
}

export async function getCourseMeta(filter)
 {
   return parseResponse(await axios.get(`${API_URL}/api/courses/meta/${filter.startDate}/${filter.endDate}`));
 }

export async function findByNumber(number)
{
  return parseResponse(await axios.post(`${API_URL}/api/courses/find`, {number}, (await getAdapter()).getAuthHeader));
}

export async function get(guid)
{
  return parseResponse(await axios.get(`${API_URL}/api/courses/${guid}`, (await getAdapter()).getAuthHeader));
}

export async function update(guid, data)
{
  return parseResponse(await axios.patch(`${API_URL}/api/courses/${guid}`, data, (await getAdapter()).getAuthHeader));
}

async function _delete(guid)
{
  return parseResponse(await axios.delete(`${API_URL}/api/courses/${guid}`, (await getAdapter()).getAuthHeader));
}

/**
 * 
 * @param {*} guid is the course content guid
 * @param {*} data is an object containing some of {description}
 */
export async function updateCourseContent(guid, data, file=null)
{
  var headers = (await getAdapter()).getAuthHeader();
  headers.headers['Content-Type']='multipart/form-data';
  var formData = new FormData();
  formData.append('guid', guid);
  formData.append('file', file);
  formData.append('lastModified', file ? file.lastModified : null);
  formData.append('description', data.description); 

  return await axios.post(`${API_URL}/api/courses/content/update`, 
    formData, headers);

}

export async function downloadCourseContent(course_content_guid)
{
  var header = (await getAdapter()).getAuthHeader;
  return await axios({
    method:'get',
    url:`${API_URL}/api/courses/content/${course_content_guid}`,
    responseType:'blob',
    header: header
  })
}

export async function listCourseContent(course_id)
{
  return parseResponse(await axios.get(`${API_URL}/api/courses/content/list/${course_id}`, (await getAdapter()).getAuthHeader));
}

export async function getZippedCourseContent(course_id)
{
  var header = (await getAdapter()).getAuthHeader;
  return await axios({
    method:'get',
    url:`${API_URL}/api/courses/content/zipped/${course_id}`,
    responseType:'blob',
    header: header
  })
  // responseType:'stream',
  //   stream:true,
}

export async function postCourseContent(file, courseID, title, description)
{
  var headers = (await getAdapter()).getAuthHeader();
  headers.headers['Content-Type']='multipart/form-data';
  var formData = new FormData();
  formData.append('file', file);
  formData.append('lastModified', file.lastModified);
  formData.append('title', title);
  formData.append('description', description); 
  formData.append('courseID', courseID); 

  return await axios.post(`${API_URL}/api/courses/content`, 
    formData, headers);

}

export async function deleteCourseContent(course_content_guid)
{
  return parseResponse(await axios.delete(`${API_URL}/api/courses/content/${course_content_guid}`, (await getAdapter()).getAuthHeader));
}


export {
  _delete as delete
}

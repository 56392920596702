import React, {useState, useEffect, useRef} from 'react';
import { getAdapter } from './adapters/adapter';
import { UserGrid } from './ManageUsers.js';
import { idToGuidMapper } from './Utility';
import theme from './theme/PortalTheme';
import Dialog from '@mui/material/Dialog';
import { DialogContent } from '@mui/material';
import { BootstrapDialogTitle } from './BootStrapDialogTitle';


//Dialog to select a "Point of Contact" user.
//At time of writing this comment, can only imagine one common reason this needs to exist:
//So that PA/Admin users can assign a valid point of contact to an application that was created from scratch by an admin or PA user:
export default function SelectPOCUserDialog(props){

    var { open, setOpen, setUser, organization } = props;

    const isMounted = useRef(false)
	useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false }
	}, []);

    const [isLoading, setIsLoading] = useState(false);
    // eslint-disable-next-line
	const [isError, setIsError] = useState(false);
    const [users, setUsers] = useState([]);

    function actionsColumnRenderCell(params){

        function onSelectBtn(params){
            setUser(params);
            setOpen(false);
        }

        return (
            <React.Fragment>
                <button
                    style={{marginRight:theme.spacing(1), cursor:"pointer"}}
                    onClick={() => onSelectBtn(params.row)}
                >
                    Select
                </button>
            </React.Fragment>
        );
    }

    useEffect(() => {
        async function fetchData(){
            setIsError(false);
            setIsLoading(true);
            try {
                var adapter = await getAdapter();               
                console.log("org: " + organization.name + " (" + organization.guid + ")");
                var userList = await adapter.user.listPocUsersByOrg(organization.guid);
                console.log(userList);
                userList = userList.map(idToGuidMapper);
            } 
            catch (error) {
                if(isMounted.current){
                    setIsError(true);
                }
                console.error(error);
            }
            if(isMounted.current){
                setUsers(userList);
                setIsLoading(false);
            }
        }
        if(organization && organization.guid){
            fetchData();
        }        
        
    }, [setIsError, 
        setIsLoading, 
        setUsers,
        organization]);

    function onDialogClose(){
        setOpen(false);
    }

    return (
        <Dialog
            maxWidth={"lg"}
            fullWidth={true}
            open={open}    
            container={() => document.getElementById('dialogParent')}
        >            
            <BootstrapDialogTitle color="primary" onClose={onDialogClose}>Select User</BootstrapDialogTitle>
            <DialogContent>
                <UserGrid
                    actionsColumnRenderCell = {actionsColumnRenderCell}
                    rows = {users}
                    loading = {isLoading}
                />
            </DialogContent>
        </Dialog>
    )
}
import { createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { styled } from '@mui/system';
import { Typography } from "@mui/material";
import ArrowBack from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import { Attachment } from "@mui/icons-material";

//Compose theme in steps so we can access theme variables inside the theme:
var theme = createTheme({
	typography: {
	  // In Chinese and Japanese the characters are usually larger,
	  // so a smaller fontsize may be appropriate.
	  fontFamily:"Arial"
	},
});

theme = createTheme(theme, {
	palette: {
		background: {
			dashboardContent: "#eee",
			statusMessage: "#fffeed",
			sessionExpiryMessage: "#fffeed"
		},
		primary: {
			main: "#007dbc"
		},
		fdaDarkBlue: {
			main: "#001871"
		},
		lightOnDark: {
			main: "#ffffff"
		}
	},
	components: {
		MuiPaper: {
			variants: [
				{
					props: {"padding":"large"},
					style:{
						padding: theme.spacing(2),
						margin: theme.spacing(2),
						overflow:'auto'
					}
				},
				{
					props: {"padding":"huge"},
					style:{
						padding: theme.spacing(4),
						margin: theme.spacing(2),
						overflow:'auto'
					}
				}
			]
		},
		MuiFormControl: {
			styleOverrides: {
				root: {
					marginTop:0
				},
			},
		},
		MuiGrid: {
			styleOverrides: {
				root: {
					padding:2
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					margin: theme.spacing(1),
					marginRight:"1px"
				},
			},
		},
	},
});

//Application Related Styles defined here for now:
const FormSection = styled(Box)({});
FormSection.defaultProps = {
	border: "1px dashed #DDD",
	padding:2
};

const FormSectionHeading = styled(Typography)({});
FormSectionHeading.defaultProps = {
	component:"h6",
	variant:"h6",
	color:"primary",
	marginTop: 2
};

function ExportReportButton(props){
	return(
		<Button
			variant="contained"
			color="primary" 
			startIcon={<DownloadIcon/>}
			{...props}
		>
			{props.children}
		</Button>
	);	
}

function GetReportButton(props){
	return(
		<Button
			variant="contained"
			color="primary" 
			startIcon={<NoteAddIcon/>}
			{...props}
		>
			{props.children}
		</Button>
	);	
}

function SaveButton(props){
	return(
		<Button
			variant="contained"
			color="primary" 
			startIcon={<SaveIcon/>}
			{...props}
		>
			Save
		</Button>
	);	
}

function CancelButton(props){
	return(
		<Button
			variant="contained"
			color="primary"
			startIcon={<ArrowBack/>}
			{...props}
		>
			Cancel
		</Button>
	);
}

function BackButton(props){
	return(
		<Button
			variant="contained"
			color="primary"
			startIcon={<ArrowBack/>}
			{...props}
		>
			Back
		</Button>
	);
}

function ExitButton(props){
	return(
		<Button
			variant="contained"
			color="primary"
			startIcon={<ArrowBack/>}
			{...props}
		>
			{props.children}
		</Button>
	);
}

function CreateButton(props){
	return(
		<Button
			variant="contained"
			color="primary"
			startIcon={<AddIcon/>}
			{...props}
		>
			{props.children}
		</Button>
	);
}

function AttachButton(props){
	return(
		<Button
			variant="contained"
			color="primary"
			startIcon={<Attachment/>}
			{...props}
		>
			{props.children}
		</Button>
	);
}

function UploadButton(props){
	return(
		<Button
			variant="contained"
			color="primary"
			startIcon={<UploadIcon/>}
			{...props}
		>
			{props.children}
		</Button>
	);
}

function DownloadButton(props){
	return(
		<Button
			variant="contained"
			color="primary"
			startIcon={<DownloadIcon/>}
			{...props}
		>
			{props.children}
		</Button>
	);
}

function DeleteButton(props){
	return(
		<Button
			variant="contained"
			color="primary"
			startIcon={<DeleteIcon/>}
			{...props}
		>
			{props.children}
		</Button>
	);
}

export { AttachButton, BackButton, CancelButton, CreateButton, DeleteButton, DownloadButton, ExitButton, ExportReportButton, FormSection, FormSectionHeading, GetReportButton, SaveButton, UploadButton };
export default theme;
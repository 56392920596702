import React, { useEffect, useState, useRef, useContext } from 'react';
import { Box, Button, Container, Grid, Paper} from '@mui/material';
import { ManageSearchOutlined } from '@mui/icons-material/';
import ViewTitle from './ViewTitle';
import { getAdapter } from './adapters/adapter';
import BetterFormField from './FormField';
import { useHistory } from "react-router-dom";
import { StatusMessageContext } from './StatusMessage';
import useForm from './UseForm';
import { BackButton, FormSection, UploadButton} from './theme/PortalTheme';
import theme from './theme/PortalTheme';
import SelectCourseDialog from './SelectCourseDialog';
import LoadingIndicator from './LoadingIndicator';

function ImportInstructorsForm(props){

    //Is component currently mounted?
    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false }
    }, []);

    const history = useHistory();

    //Reference to the hidden file input element:
    const hiddenFileInput = React.useRef(null);

    //Status message:
	var { StatusManager } = useContext(StatusMessageContext);    

    //File to upload:
    const [selectedFile, setSelectedFile] = useState([]);
    //Is currently uploading?
    const [isUploading, setIsUploading] = useState(false);
	//Is "Select Course" dialog open?
	const [courseDialogOpen, setCourseDialogOpen] = useState(false);
    //Error lists:
    const [errorArr, setErrorArr] = useState([]);
    //Success log:
    const [successArr, setSuccessArr] = useState([]);

    //Form validation:
    function validate(){
        let _errors = { ...errors };

        _errors.courseNumber = (formValues.courseNumber === "") ? " " : "";
        _errors.courseTitle = (formValues.courseTitle === "") ? " " : "";
        _errors.filename = (formValues.filename === "") ? " " : "";

        setErrors({
            ..._errors
        });

        var result = Object.values(_errors).every(x => x === "");
        console.log("Validation " + (result ? "SUCCESS" : "FAILED"));
        return result;
    }

    //Initial Form Values:
    const initialFormValues = {
        filename: "No file specified.",
        course: null
    };

    //Form-related vars:
    const {
        formValues,
        setFormValue,
        errors,
        setErrors,
        handleInputChange,
    } = useForm(initialFormValues, false, validate);


    //Reset file picker:
    function resetFilePicker(e){
        e.target.value = null;
    }

    //When a file is selected:
    function handleChange(e){
        if(isMounted.current && e.target.files.length > 0){
            setSelectedFile(e.target.files[0]);
            setFormValue("filename", e.target.files[0].name);
        }
    }

    //Open "Select Course" dialog:
	function onSelectCourseBtn(){
        if(isMounted.current){
            setErrorArr([]);
            setSuccessArr([]);
        }        
		setCourseDialogOpen(true);
	}    

    //Select a file containing instructor information to process:
    function onSelectFileBtn(e){
        if(isMounted.current){
            setErrorArr([]);
            setSuccessArr([]);            
        }
        hiddenFileInput.current.click();
    }

    //Process the selected file containing instructor information:
    async function onImportBtn(){
        if(isMounted.current){
            setErrorArr([]);
            setSuccessArr([]);            
            setIsUploading(true);
        }
        let success = true;
        try{
            let adapter = await getAdapter();
            let response = await adapter.instructor.createFromCsv(formValues.course.guid, selectedFile);
            if(isMounted.current){
                setSuccessArr(response.data.data.response.meta);
            }

        }
        catch(error){
            success = false;
            console.log(error.response.data.data);
            var temp = error.response;
            if(isMounted.current){
                if(temp.data && temp.data.data){
                    setErrorArr(error.response.data.data);
                }
            }
        }
        if(isMounted.current){
            setIsUploading(false);            
            var successStr = success ? "successful." : "failed.";
            StatusManager.setStatus("Bulk import instructors " + successStr);
            //Reset form:
            setSelectedFile([]);
            setFormValue("course", null);
            setFormValue("filename", "No file specified.");

        }
    }

    function generateErrorList(){
        var content = [];
        errorArr.forEach(function(item, index){
            content.push(<li key={index}>{item}</li>);
        });
        return (<ul>{content}</ul>);
    }

    function generateSuccessList(){
        var content = [];
        successArr.forEach(function(item, index){
            content.push(<li key={index}>{item}</li>);
        });
        return (<ul>{content}</ul>);
    }    

    function onBackBtn(){
        history.goBack();
    }

    //Render:
    return (
        <>
            <SelectCourseDialog
                open = {courseDialogOpen} 
                setOpen = {setCourseDialogOpen}
                setCourse={(value) => {
                    setFormValue("course", value);
                }}
            />
            <Box>
                <p>On this screen, you can bulk-upload instructors for a particular course using a .CSV file.</p>
            </Box>
            <FormSection style={{marginBottom:10}}>
                <p><strong><span style={{color: theme.palette.secondary.main}}>Step 1:</span> Select Course</strong></p>
                <p>You may only import instructors for one course at a time.</p>
                <ul>
                    <li style={{marginBottom:theme.spacing(1)}}>Select the <strong>Select Course</strong> button  to select a course you wish to upload instructors for.</li>
                </ul>                
                <Grid container spacing={1}>
                    <Grid item xs={3}><BetterFormField required readOnly={true} variant="filled" label="FDA Course Number" name="courseNumber" value={(formValues.course && formValues.course.courseNumber) || ""} error={errors.courseNumber}/></Grid>
                    <Grid item xs><BetterFormField required readOnly={true} variant="filled" label="Course Title" name="courseTitle" value={(formValues.course && formValues.course.courseTitle) || ""} error={errors.courseTitle}/></Grid>
                    <Grid item xs="auto" alignItems="center" direction="column" container>
                        <SelectCourseBtn onClick={ onSelectCourseBtn } />
                    </Grid>
                </Grid>
            </FormSection>
            { formValues.course != null && 
            <FormSection style={{marginBottom:10}}>
                <p><strong><span style={{color: theme.palette.secondary.main}}>Step 2:</span> Select File to Import</strong></p>
                <p>Select a properly formatted file containing bulk instructor information.</p>
                <ul>
                    <li style={{marginBottom:theme.spacing(1)}}>Select the <strong>Select File</strong> button to specify a valid .CSV file.</li>
                </ul>                 
                <Grid container justifyContent="flex-start">
                    <Grid item xs={6}><BetterFormField variant="filled" readOnly={true} label="File to Upload" name="filename" value={formValues.filename} error={errors.filename} /></Grid>
                    <Grid item>		
                        <SelectFileBtn disabled={ formValues.course == null } onClick={ onSelectFileBtn } />
                    </Grid>
                </Grid>
            </FormSection> }
            { isUploading && <LoadingIndicator/> }
            { ( formValues.course != null && selectedFile.length != 0 && isUploading == false) && 
            <FormSection style={{marginBottom:10}}>
                <p><strong><span style={{color: theme.palette.secondary.main}}>Step 3:</span> Process Selected File</strong></p>
                <p>When ready to proceed, select the <strong>Import</strong> button.</p>
                <Grid container justifyContent="flex-start">
                    <Grid item>		
                        <ImportBtn disabled={ formValues.course == null || selectedFile.length == 0 } onClick={ onImportBtn } />
                    </Grid>
                </Grid>
            </FormSection> }
            { ( errorArr.length > 0 ) && 
            <FormSection style={{marginBottom:10, border: "1px solid #FF0000"}}>
                <p><strong><span style={{color: "#FF0000"}}>Import Operation Failed</span>:</strong></p>
                { generateErrorList() }
            </FormSection> }
            { ( successArr.length > 0 ) && 
            <FormSection style={{marginBottom:10}}>
                <p><strong><span style={{color: theme.palette.primary.main}}>Import Operation Successful</span>:</strong></p>
                { generateSuccessList() }
            </FormSection> }            
            <Grid container justifyContent="center" alignItems="bottom" sx={{marginTop:4}}>
                <BackButton onClick={onBackBtn} /> 
			</Grid>
            {/*Hidden file input. We'll use a ref to reach it.*/}
            <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                onClick={resetFilePicker}
                style={{display: 'none'}}
                accept=".csv"
            />
        </>
    );
}

function SelectCourseBtn(props){
    return(
        <Button
            variant="contained"
            startIcon={<ManageSearchOutlined/>} 
            style={{marginRight:theme.spacing(1), cursor:"pointer"}}
            {...props}
        >
            Select Course
        </Button>
    );
}

function SelectFileBtn(props){
    return(
        <Button
            variant="contained"
            style={{marginRight:theme.spacing(1), cursor:"pointer"}} 
            title="Select File"
            {...props}
        >
            Select File
        </Button>        
    );
}

function ImportBtn(props){
    return(
        <UploadButton
            variant="contained"
            style={{marginRight:theme.spacing(1), cursor:"pointer"}} 
            title="Import"
            {...props} 
        >
            Import
        </UploadButton>
    ); 
}

export default function ImportInstructors() {
	return (
		<Container component="main" maxWidth="lg">
			<Paper style={{minHeight:'70vh'}} padding="large">
				<ViewTitle>Bulk Import Instructors</ViewTitle>
                <ImportInstructorsForm/>
			</Paper>
		</Container>
    );
}
import React from 'react';
import Box from '@mui/material/Box';
import LoadingIndicator from './LoadingIndicator';

export default function SavingIndicator(){
	return(
		<Box sx={{padding:2, margin:2}} elevation={8}>
			<p style={{textAlign:"center"}}>Saving. Please wait...</p>
			<LoadingIndicator padding={0}/>
		</Box> 
	);
}
import axios from 'axios';
import {parseResponse, API_URL} from "./utils";
import { getAdapter } from './adapter';

function organizationRoute(id) {
	var route = `${API_URL}/api/organizations/`;

	if(id != null) {
		route += id;
	}

	return route;
}

/**
input: {
  name,
  addressStreet,
  addressCity,
  addressState,
	addressZip
}
output: "<guid>"
*/
export async function create(data)
{
	return parseResponse(await axios.post(organizationRoute(), data, (await getAdapter()).getAuthHeader));
}

export async function list()
{
	return parseResponse(await axios.get(organizationRoute(), (await getAdapter()).getAuthHeader));
}

export async function listOrganizationMetrics() {
	var data = parseResponse(await axios.get(`${API_URL}/api/organizations`, (await getAdapter()).getAuthHeader));
	return {organizationsCount: data.length};
}

export async function getOrganizationMeta(filter)
 {
   return parseResponse(await axios.get(`${API_URL}/api/organizations/meta/${filter.startDate}/${filter.endDate}`));
 }

export async function get(guid)
{
	var organization = parseResponse(await axios.get(organizationRoute(guid), (await getAdapter()).getAuthHeader));

	if(organization == null)
	{
		throw new Error("The given GUID does not exist.");
	}

	return organization;
}

export async function update(guid, data)
{
	return parseResponse(await axios.patch(organizationRoute(guid), data, (await getAdapter()).getAuthHeader));
}

async function _delete(ids)
{
	//coerce to array
	ids = Array.isArray(ids) ? ids : [ids];

	return parseResponse(await axios.post(organizationRoute() + "delete-multiple", ids, (await getAdapter()).getAuthHeader));
}

export {
	_delete as delete
}

import axios from 'axios';
import {parseResponse, API_URL} from "./utils";
import { getAdapter } from './adapter';

function userRoute(id) {
	var route = `${API_URL}/api/users/`;

	if(id != null) {
		route += id;
	}

	return route;
}

export async function create(data)
{
	return parseResponse(await axios.post(userRoute(), data, (await getAdapter()).getAuthHeader));
}

export async function list()
{
	return parseResponse(await axios.get(userRoute(), (await getAdapter()).getAuthHeader));
}

export async function listUsers()
{
  var users = parseResponse(await axios.get(`${API_URL}/api/users`));

  users.forEach((e) =>
  {
    e.firstName = e.first_name;
    e.lastName = e.last_name;
    e.role = e.role_id;
  });

  return users;
}

export async function listPocUsersByOrg(organization_id)
{
	return parseResponse(await axios.get(`${API_URL}/api/users/org/${organization_id}`, (await getAdapter()).getAuthHeader));
}

/**
 * filter expects an object, example below
 * {startDate: '1970-01-01T01:00:00Z',endDate:'2024-07-18T05:00:00Z'}
 */

 export async function getUserMeta(filter)
 {
   return parseResponse(await axios.get(`${API_URL}/api/users/meta/${filter.startDate}/${filter.endDate}`));
 }

export async function get(guid)
{
	var user = parseResponse(await axios.get(userRoute(guid), (await getAdapter()).getAuthHeader));

	if(!user)
	{
		throw new Error("The given GUID does not exist.");
	}

	return user;
}

export async function update(guid, data)
{
	return parseResponse(await axios.patch(userRoute(guid), data, (await getAdapter()).getAuthHeader));
}

async function _delete(ids)
{
	//coerce to array
	ids = Array.isArray(ids) ? ids : [ids];

	return parseResponse(await axios.post(userRoute() + "delete", ids, (await getAdapter()).getAuthHeader));
}

export {
	_delete as delete
}

import axios from 'axios';
import {parseResponse, API_URL} from "./utils";
import { isValidDateFormat } from '../Utility';
import { getAdapter } from './adapter';

function qualificationsRoute() {
	var route = `${API_URL}/api/qualifications/`;
	return route;
}

export async function add(instructorID, courseID, certifiedOn, expiresOn)
{
  return parseResponse(await axios.post(`${qualificationsRoute()}`, {instructorID, courseID, certifiedOn, expiresOn}, (await getAdapter()).getAuthHeader));
}

// export async function update(guid, data)
// {
// 	return parseResponse(await axios.patch(`${qualificationsRoute()}/update/${guid}`, data, (await getAdapter()).getAuthHeader));
// }

/** returns list of courses that the instructor is qualified for */
export async function listByInstructor(instructorID)
{
  var courses = parseResponse(await axios.get(`${qualificationsRoute()}/list-by-instructor/${instructorID}`, (await getAdapter()).getAuthHeader));
  return courses;
}

/** returns list of instructors qualified for the given course */
export async function listByCourse(courseID)
{
  var instructors = parseResponse(await axios.get(`${qualificationsRoute()}/list-by-course/${courseID}`, (await getAdapter()).getAuthHeader));
  return instructors;
}

export async function get(instructorID, courseID)
{
  return parseResponse(await axios.get(`${qualificationsRoute()}/${instructorID}/${courseID}`, (await getAdapter()).getAuthHeader));
}

/**
 * optional filter would expect an object, example below
 * {startDate: '1970-01-01T01:00:00Z',endDate:'2024-07-18T05:00:00Z'}
 */
export async function getQualificationsMeta(filter=null)
{
  let startDate = filter && isValidDateFormat(filter.startDate) ? Date.parse(filter.startDate) : 0;
  let endDate = filter && isValidDateFormat(filter.endDate) ? Date.parse(filter.endDate) : Date.now();
  return parseResponse(await axios.get(`${qualificationsRoute()}/meta/${startDate}/${endDate}`));
}

/** unassigns and reassigns courseArray qualifications for instructor */
export async function set(instructorID, courseArray) {
  return parseResponse(await axios.patch(`${qualificationsRoute()}`, {instructorID, courseArray}, (await getAdapter()).getAuthHeader));
}

export async function remove(instructorID, courseID)
{
  return parseResponse(await axios.delete(`${qualificationsRoute()}/${instructorID}/${courseID}`, (await getAdapter()).getAuthHeader));
}

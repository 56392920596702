import React, { useEffect, useState, useRef, useContext } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import ViewTitle from './ViewTitle';
import { getAdapter } from './adapters/adapter';
import BetterFormField from './FormField';
import LoadingIndicator from './LoadingIndicator';
import SavingIndicator from './SavingIndicator';
import { useHistory} from "react-router-dom";
import theme, {CancelButton, SaveButton } from './theme/PortalTheme';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import SelectOrganizationDialog from './SelectOrganizationDialog';
import SelectRole from './SelectRole';
import { StatusMessageContext } from './StatusMessage';
import { isValidState, isValidZipCode, isValidPhoneNumberFormat, isValidEmailFormat } from './Utility';
import { getLoggedInRole } from './adapters/baseAdapter';
import useForm from './UseForm';

function UserForm(props){
    var history = useHistory();

    //Is component currently mounted?
    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false }
    }, []);

    //Status message:
	var { StatusManager } = useContext(StatusMessageContext);

	//Loading/Saving states:
	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

    //Is "Select Organization" dialog open?
    var [organizationDialogOpen, setOrganizationDialogOpen] = useState(false);

    //Form validation:
    function validate(){
        let _errors = { ...errors };
        console.log("Validating");
        console.log(formValues);

        //Roles other than superadmin and oted_pa currently require an organization. This rule may need to be adjusted if new roles are added:
        var roleRequiresOrganization = !(formValues.userRole === "superadmin" || formValues.userRole === "oted_pa" || formValues.userRole === "qualified_instructor" || formValues.userRole==="oted_ff");
		_errors.organization = (roleRequiresOrganization && formValues.organization.guid === undefined) ? "Please select an organization." : "";
		_errors.userLast = (formValues.userLast === "") ? " " : "";
        _errors.userFirst = (formValues.userFirst === "") ? " " : "";
        _errors.userEmail = (isValidEmailFormat(formValues.userEmail)) ? "" : " ";
        _errors.userEmailConfirm = formValues.userEmail === formValues.userEmailConfirm ? "" : " ";
		_errors.userPhone = (isValidPhoneNumberFormat(formValues.userPhone)) ? "" : " ";	
        _errors.userStreet = (formValues.userStreet === "") ? " " : "";
        _errors.userCity = (formValues.userCity === "") ? " " : "";
        //Is state valid?
        _errors.userState = (isValidState(formValues.userState))? "" : " ";
		//Is zip valid?
		_errors.userZip = (isValidZipCode(formValues.userZip)) ? "" : " ";
        _errors.userRole = (formValues.userRole === "") ? " " : "";

        setErrors({
            ..._errors
        });

        var result = Object.values(_errors).every(x => x === "");
        console.log("Validation " + (result ? "SUCCESS" : "FAILED"));
        console.log(_errors);
        return result;
    }

    //Initial Form Values:
	const initialFormValues = {
        organization:{},
        userLast: "",
        userMiddle: "",
        userFirst: "",
        userEmail: "",
        userEmailConfirm: "",
        userPhone: "",
        userStreet: "",
        userCity: "",
        userState: "",
        userZip: "",
        userRole: "applicant_poc",
        loggedInUserRole: ""
	};

    //Form-related vars:
	const {
		formValues,
		setFormValue,
		errors,
		setErrors,
		handleInputChange,
	} = useForm(initialFormValues, false, validate);

	//Create refs to mutable objects that would otherwise be passed as dependencies to useEffect hook, to prevent triggering re-renders unnecessarily.
	const setFormValueRef = useRef(setFormValue);

	//On view load:
    useEffect(() => {
        let setFormValue = setFormValueRef.current;
        if(isMounted.current){
            if(!["oted_pa", "superadmin"].includes(getLoggedInRole())){
                StatusManager.setStatus("Access denied. You have been returned to the dashboard home screen.");
                history.push("/dashboard");
            }    
        }
        async function fetchData(){
            setIsLoading(true);
            try {
                var adapter = await getAdapter();
                var loggedInUserRole = await adapter.getLoggedInRole();      
            }
            catch (error) {
                console.error(error);
            }
            if(isMounted.current){
                setFormValue("loggedInUserRole", loggedInUserRole || "");
                setIsLoading(false);
            }
        }
        fetchData();
    },  
    [
        setIsLoading
    ]);

    //Save:
    async function saveData( successCallback = () => {} ){
        var success = true;
        setIsSaving(true);
        try {
            var adapter = await getAdapter();
			let user = {
				organizationID: formValues.organization.guid,
				firstName: formValues.userFirst,
				lastName: formValues.userLast,
				middleInitial: formValues.userMiddle,
				email: formValues.userEmail,
				phone: formValues.userPhone,
				addressStreet: formValues.userStreet,
				addressCity: formValues.userCity,
				addressState: formValues.userState,
				addressZip: formValues.userZip,
                role: formValues.userRole
			};
			await adapter.user.create(user);
		}
        catch (error) {   
			success = false;
            console.error(error);
            //If user with specified email address already exists within the system:
            if(error.response.data.status === "duplicate user" && isMounted.current){
                setErrors({
                    ...errors,
                    userEmail: "Invalid email. A user with this email address already exists within the system."
                });
            }
        }
        if(isMounted.current){
            setIsSaving(false);
            var successStr = success ? "successful." : "failed.";
            console.log("Save " + successStr );
            StatusManager.setStatus("Save user " + successStr);
            if(success) successCallback();
        }
    }

	function onSelectOrganizationBtn(){
        setOrganizationDialogOpen(true);
    }

    //Button handlers:
    function onCancelBtn(){
        history.goBack();
    }

    function onSaveBtn(){
		if(validate()){
			saveData(()=>{
				history.goBack();
			});
		}
    }    

    //Conditional Render:
    if(isLoading){
        return <LoadingIndicator/>;
    }
    else{
        return (
            <>
                <SelectOrganizationDialog
                    open={organizationDialogOpen}
                    setOpen={setOrganizationDialogOpen}
                    setOrganization={(value) => {
                        setFormValue("organization", value);
                    }}
                />
                <p style={{marginBottom:theme.spacing(1)}}><strong>Name</strong></p>
                <Grid container spacing={1}>
                    <Grid item xs={5}><BetterFormField required label="Last Name" name="userLast" value={formValues.userLast} error={errors.userLast} onChange={handleInputChange} /></Grid>
                    <Grid item xs={2}><BetterFormField label="Middle Initial" name="userMiddle"  value={formValues.userMiddle} error={errors.userMiddle} onChange={handleInputChange} /></Grid>
                    <Grid item xs={5}><BetterFormField required label="First Name" name="userFirst" value={formValues.userFirst} error={errors.userFirst} onChange={handleInputChange} /></Grid>
                </Grid>
                <p style={{marginBottom:theme.spacing(1)}}><strong>Contact</strong></p>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <BetterFormField required label="Email" name="userEmail" value={formValues.userEmail} error={errors.userEmail} 
                        onChange={function(e){
                            e.target.value = e.target.value.toLowerCase();
                            handleInputChange(e)}} />
                    </Grid>
                    <Grid item xs={6}>        
                        <BetterFormField required label="Confirm Email" name="userEmailConfirm" value={formValues.userEmailConfirm} error={errors.userEmailConfirm} 
                        onChange={function(e){
                            e.target.value = e.target.value.toLowerCase();
                            handleInputChange(e)}} />    
                            
                    </Grid>
                    <Grid item xs={4}><BetterFormField required label="Phone" name="userPhone" value={formValues.userPhone} error={errors.userPhone} onChange={handleInputChange} /></Grid>
                    <Grid item xs={8}><BetterFormField required label="Street" name="userStreet" value={formValues.userStreet} error={errors.userStreet} onChange={handleInputChange} /></Grid>
                    <Grid item xs={5}><BetterFormField required label="City" name="userCity" value={formValues.userCity} error={errors.userCity} onChange={handleInputChange} /></Grid>
                    <Grid item xs={2}><BetterFormField required label="State" name="userState" value={formValues.userState} error={errors.userState} onChange={handleInputChange} /></Grid>
                    <Grid item xs={5}><BetterFormField required label="Zip" name="userZip" value={formValues.userZip} error={errors.userZip} onChange={handleInputChange} /></Grid>
                </Grid>
                <ul><li>User email address will be converted to lowercase letters.</li></ul>
                <p style={{marginBottom:theme.spacing(1)}}><strong>Role</strong></p>
                <Grid container spacing={1}>
                    {
                        formValues.loggedInUserRole === "superadmin" || formValues.loggedInUserRole ==="oted_pa" ?
                        <Grid item xs={12}>
                            <SelectRole 
                                hideQI='1' 
                                value={formValues.userRole}
                                setValue={(value) => {
                                    setFormValue("userRole", value);
                                }}
                            />
                        </Grid> :
                        <Grid item xs={12}>
                            <BetterFormField 
                                required label="Role"
                                variant="filled"
                                readOnly={true}
                                value={formValues.userRole}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    }                
                </Grid>
                {
                    (formValues.userRole === "applicant_poc") &&
                    <>
                        <p style={{marginBottom:theme.spacing(1)}}><strong>Organization</strong></p>
                        <Grid container spacing={1}>
                            <Grid item xs alignItems="center" container>
                                <BetterFormField required label="Organization" name="organization" variant="filled" readOnly={true} value={(formValues.organization && formValues.organization.name) || ""} error={errors.organization}/>
                            </Grid>
                            <Grid item xs="auto" alignItems="center" container>
                                <Button
                                    variant="outlined"
                                    startIcon={<ManageSearchOutlinedIcon/>}
                                    style={{marginRight:theme.spacing(1), cursor:"pointer"}}
                                    onClick={onSelectOrganizationBtn}
                                >
                                    Select Organization
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                }
                <Grid container justifyContent="center" alignItems="bottom" sx={{marginTop:4}}>
                    { isSaving ? <SavingIndicator /> :
                        <>
                            <CancelButton onClick={onCancelBtn} />
                            <SaveButton onClick={onSaveBtn} />
                        </>
                    }
                </Grid>
            </>
        );
    }
}

export default function CreateUser(){
	return (
		<Container component="main" maxWidth="lg">
			<Paper style={{minHeight:'70vh'}} padding="large">
				<ViewTitle>Create User</ViewTitle>
                <UserForm/>
			</Paper>
		</Container>
    );
}
import axios from 'axios';
import {parseResponse, appRoute, API_URL} from "../utils";
import { getAdapter } from '../adapter';

function pocRoute(appID)
{
  return appRoute(appID) + "/poc";
}

export async function get(applicationID)
{
  return parseResponse(await axios.get(pocRoute(applicationID), (await getAdapter()).getAuthHeader));
}

/** Data should include
  {
    firstName,
    lastName,
    middleInitial,
    email,
    phone,
    addressStreet,
    addressCity,
    addressState,
    addressZip
  }
*/
export async function update(applicationID, data)
{
  return parseResponse(await axios.post(pocRoute(applicationID), data, (await getAdapter()).getAuthHeader));
}

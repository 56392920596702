import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

export default function ViewTitle(props) {
	return (
		<Typography component="h2" variant="h6" color="primary" gutterBottom >
			{props.children}
		</Typography>
	);
}

ViewTitle.propTypes = {
	children: PropTypes.node,
};

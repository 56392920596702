import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ConfirmationDialog(props) {

    const { open, setOpen, dialogTitle, dialogContent, leftButtonLabel, rightButtonLabel, leftButtonCallback, rightButtonCallback } = props;

    function onLeftBtn(){
        setOpen(false);
        if(leftButtonCallback) leftButtonCallback();
    }

    function onRightBtn(){
        setOpen(false);
        if (rightButtonCallback) rightButtonCallback();
    }
    function onClose(){
        setOpen(false);
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{dialogContent}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onLeftBtn}>{leftButtonLabel}</Button>
                <Button onClick={onRightBtn} autoFocus>{rightButtonLabel}</Button>
            </DialogActions>
        </Dialog>
    );
}
import React, { useEffect, useState, useRef, useContext } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ViewTitle from './ViewTitle';
import LoadingIndicator from './LoadingIndicator';
import { getAdapter } from './adapters/adapter';
import BetterFormField from './FormField';
import SavingIndicator from './SavingIndicator';
import { useHistory, useParams } from "react-router-dom";
import {BackButton, SaveButton} from './theme/PortalTheme';
import { StatusMessageContext } from './StatusMessage';
import useForm from './UseForm';
import { isValidState, isValidZipCode } from './Utility';
import { getLoggedInRole } from './adapters/baseAdapter';

function OrganizationForm(props){
    var { organizationId } = useParams();
    var history = useHistory();

	//Is component currently mounted?
    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false }
    }, []);

    //Status message:
	var { StatusManager } = useContext(StatusMessageContext);

    //Loading/Saving states:
	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

    //Form validation:
    function validate(){
        let _errors = { ...errors };
        console.log("Validating");
        console.log(formValues);

        _errors.organizationName = (formValues.organizationName === "") ? " " : "";
        _errors.organizationStreet = (formValues.organizationStreet === "") ? " " : "";
        _errors.organizationCity = (formValues.organizationCity === "") ? " " : "";

        //Is organizationState valid?
		_errors.organizationState = (isValidState(formValues.organizationState))? "" : " ";
		//Is organizationZip valid?
		_errors.organizationZip = (isValidZipCode(formValues.organizationZip)) ? "" : " ";

        setErrors({
            ..._errors
        });

        var result = Object.values(_errors).every(x => x === "");
        console.log("Validation " + (result ? "SUCCESS" : "FAILED"));
        return result;
    }

	//Initial Form Values:
	const initialFormValues = {
		organizationName: "",
        organizationStreet: "",
        organizationCity: "",
		organizationState: "",
        organizationZip: ""
	};

	//Form-related vars:
	const {
		formValues,
		setFormValue,
		errors,
		setErrors,
		handleInputChange,
	} = useForm(initialFormValues, false, validate);

	//Create refs to mutable objects that would otherwise be passed as dependencies to useEffect hook, to prevent triggering re-renders unnecessarily.
	const setFormValueRef = useRef(setFormValue);

    //On view load:
    useEffect(() => {
        let setFormValue = setFormValueRef.current;
		
        if(isMounted.current) {
            if(!["oted_pa", "superadmin"].includes(getLoggedInRole())){
                StatusManager.setStatus("Access denied. You have been returned to the dashboard home screen.");
                history.push("/dashboard");
            }
		}

        async function fetchData(){
            setIsLoading(true);
            try {
                var adapter = await getAdapter();               
                var organization = await adapter.organization.get(organizationId);
            } 
            catch (error) {
                console.error(error);	
            }
            if(isMounted.current){
                setFormValue("organizationName", organization.name || "");   
                setFormValue("organizationStreet", organization.addressStreet || ""); 
                setFormValue("organizationCity", organization.addressCity || ""); 		               
                setFormValue("organizationState", organization.addressState || ""); 
                setFormValue("organizationZip", organization.addressZip || ""); 
                setIsLoading(false);
            }
        }        
        fetchData();
    },
    [
        setIsLoading,
        organizationId        
    ]);

    //Save:
    async function saveData( successCallback = () => {} ){
        var success = true;
        setIsSaving(true);
		try {
			var adapter = await getAdapter(); 
			let organization = {
                name: formValues.organizationName,
                addressStreet: formValues.organizationStreet,
                addressCity: formValues.organizationCity,
                addressState: formValues.organizationState,
				addressZip: formValues.organizationZip
            };            
            await adapter.organization.update(organizationId, organization);
		} 
        catch (error) {
			success = false;
            console.error(error);
        }
        if (isMounted.current) {
            setIsSaving(false);
            var successStr = success ? "successful." : "failed.";
            console.log("Save " + successStr );
            StatusManager.setStatus("Save organization " + successStr);
            if(success) successCallback();
        }
	}

    //Button handlers:
    function onBackBtn(){
        history.goBack();
    }

    function onSaveBtn(){
		if(validate()){
			saveData(()=>{
				history.goBack();
			});
		}
    }

    //Render:
	return (
        <>{ 
            isLoading ? <LoadingIndicator/> :
            <> 
                <Grid container spacing={1}>
                    <Grid item xs={12}><BetterFormField required label="Organization Name" name="organizationName" value={formValues.organizationName} error={errors.organizationName} onChange={handleInputChange} /></Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12}><BetterFormField required label="Street" name="organizationStreet" value={formValues.organizationStreet} error={errors.organizationStreet} onChange={handleInputChange} /></Grid>
                    <Grid item xs={5}><BetterFormField required label="City" name="organizationCity" value={formValues.organizationCity} error={errors.organizationCity} onChange={handleInputChange} /></Grid>
                    <Grid item xs={2}><BetterFormField required label="State" name="organizationState" value={formValues.organizationState} error={errors.organizationState} onChange={handleInputChange} /></Grid>
                    <Grid item xs={5}><BetterFormField required label="Zip" name="organizationZip" value={formValues.organizationZip} error={errors.organizationZip} onChange={handleInputChange} /></Grid>
                </Grid>
                <Grid container justifyContent="center" alignItems="bottom" sx={{marginTop:4}}>
                    { isSaving ? <SavingIndicator /> :
                        <>  
                            <BackButton onClick={onBackBtn} />                                
                            <SaveButton onClick={onSaveBtn} />
                        </>
                    }
                </Grid>
            </>
        }</>
	);
}

export default function ViewEditOrganization() {
	return (
		<Container component="main" maxWidth="lg">
			<Paper style={{minHeight:'70vh'}} padding="large">
				<ViewTitle>View/Edit Organization</ViewTitle>
                <OrganizationForm/>
			</Paper>
		</Container>
    );
}
import React, {useEffect, useState} from 'react';
import { useHistory, useParams } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Container from '@mui/material/Container';
import FDALogo from "./images/fda_logo.png";
import { getAdapter } from './adapters/adapter';
import theme from './theme/PortalTheme';
import NoEncryptionGmailerrorredIcon from '@mui/icons-material/NoEncryptionGmailerrorred';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import StatusMessage, { StatusManager, StatusMessageContext } from './StatusMessage';
import InfoIcon from '@mui/icons-material/Info';
import { handleError } from './adapters/utils';

const localStoreageEmailKey = "oted-portal-email"

function LoginTextField({value, setValue, label, type="text", autoComplete, autoFocus=false})
{
	return (
		<TextField
			variant="outlined"
			margin="normal"
			required
			fullWidth
			label={label}
			type={type}
			autoComplete={autoComplete}
			autoFocus={autoFocus}
			value={value}
			onChange={(e) => setValue(e.target.value)}
		/>
	);
}

function LoginButton({text="Sign In"})
{
	return (
		<Button
			style={{margin: theme.spacing(3, 0, 2)}}
			type="submit"
			fullWidth
			variant="contained"
			color="primary"
		>
			{text}
		</Button>
	);
}

function LoginStep1({email, setEmail, password, setPassword, rememberMe, setRememberMe, handleLogin}) {

	//call login function
	function handleSubmit(event)
	{
		event.preventDefault();
		handleLogin();
	}

	return (
		<Box marginTop={1}>
			<form style={{width:'100%'}} onSubmit={handleSubmit} noValidate>
				<LoginTextField label="Email Address" value={email} setValue={setEmail} autoComplete="email" autoFocus={true}/>
				<LoginTextField label="Password" value={password} setValue={setPassword} type="password" autoComplete="password" />
				<FormControlLabel
					control={<Checkbox checked={rememberMe} value={rememberMe} color="primary" onChange={(e) => setRememberMe(e.target.checked)} />}
					label="Remember me"
				/>
				<LoginButton />
				<Grid container>
					<Grid item xs>
						<Link href="/forgot" variant="body2">
							Forgot password?
						</Link>
					</Grid>
				</Grid>
			</form>
		</Box>
	);
}

function LoginStep2({rememberOtp, setRememberOtp, otp, setOTP, handleLogin, handleResendOtp}) {
	//call login function
	function handleSubmit(event)
	{
		event.preventDefault();
		handleLogin();
	}

	function handleResendOtpClick(event) {
		event.preventDefault();
		handleResendOtp();
	};

	return (
		<React.Fragment>
			<Typography marginTop={2}>
				A six-digit code has been sent to your email address.
			</Typography>
			<Box marginTop={1}>
				<form style={{width:'100%'}} onSubmit={handleSubmit} noValidate>
					<LoginTextField label="Six-digit code" value={otp} setValue={setOTP} />

					<FormControlLabel
						control={<Checkbox value={rememberOtp} color="primary" onChange={(e) => setRememberOtp(e.target.checked)} />}
						label="Don't ask me again for 24 hours"
					/>
					<LoginButton />
					<Grid container>
						<Grid item xs>
							<Link href="#" variant="body2" onClick={(e) => handleResendOtpClick(e)}>
								Resend code
							</Link>
						</Grid>
					</Grid>
				</form>
			</Box>
		</React.Fragment>
	)
}

export default function Login() {
	const history = useHistory();
	var { status } = useParams();

	const [step2, setStep2] = useState(false);
	const [password, setPassword] = useState("");
	const [otp, setOTP] = useState("");
	const [rememberOtp, setRememberOtp] = useState(false);
	const [loginStatus, setLoginStatus] = useState("ab-initio");
	const [loginMessage, setLoginMessage] = useState("");
	const [statusMessage, setStatusMessage] = useState("");
	const [numMessages, setNumMessages] = React.useState(1);
	const [email, setEmail] = useState("");
	const [rememberMe, setRememberMe] = useState(false);
	var statusManager = new StatusManager(statusMessage, setStatusMessage, numMessages, setNumMessages);

	useEffect(()=>{
		var emailFromLocalStorage = localStorage.getItem(localStoreageEmailKey);
		if(emailFromLocalStorage) {
			setEmail(emailFromLocalStorage);
			setRememberMe(true);
		} 

		if(status === "session-terminated"){
			setLoginStatus("logged-out");
		} else if(window.location.hash === "#expired") {
			setLoginStatus("session-expired");
		}

	},
	[status]);

	async function handleResendOtp()
	{
		var adapter = await getAdapter();
		var result = await adapter.resendOtp(email, password);
		if(result.status === 200) {
			var minutes = result.data.data.ttl / 60;
			setNumMessages(numMessages + 1);
			setStatusMessage(`A one-time password, valid for ${minutes} minutes, has been sent to: ${email}.  Please check your spam folder if you need not receive it`);
		} else {
			setNumMessages(numMessages + 1);
			setStatusMessage('There was an error sending the OTP to ' + email);
		}
	}

	async function handleLogin()
	{
		setLoginStatus("ab-initio");
		//console.log(email, otp, rememberOtp, rememberMe);
		try{
			var adapter = await getAdapter();
			var result = await adapter.login(email, password, otp, rememberOtp, rememberMe);
			var loginStatus = result.status;
			setLoginMessage(result.data.message);
			if(rememberMe) {
				localStorage.setItem(localStoreageEmailKey, email);
			} else {
				localStorage.removeItem(localStoreageEmailKey);
			}
	
			//Successful login:
			if (loginStatus === "login-success") {
				var role = await adapter.getLoggedInRole();
				//console.log("our role is", role);
				setLoginStatus("successful");
				history.push("/dashboard");
			}
			//OTP:
			else if (loginStatus === "login-otp") {
				var minutes = result.data.ttl / 60;
				setNumMessages(numMessages + 1);
				setStatusMessage(`A one-time password, valid for ${minutes} minutes, has been sent to: ${email}.  Please check your spam folder if you need not receive it`);
				setStep2(true);
			} else if (result.data === 'expired password') {
				console.log("expired password");
				setLoginStatus("expired");
			}
			//Login failure:
			else {
				setLoginStatus("failed");
				console.log("login failure");
			}			
		}
		catch(error){
			handleError(error, statusManager);
		}
	}

	return (
		<Container maxWidth="sm">
			<Box 
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				marginTop={8}
				display = 'flex'
			>				
				<img src={FDALogo} alt="FDA Logo" style={{maxWidth:"50%"}}/>
				<Typography
						component="h1"
						variant="h4"
						color="#333"
 						margin={2}
						textAlign="center"
					>
						ICD Management Portal
				</Typography>

				<Avatar sx={{
					margin: theme.spacing(1),
					backgroundColor: theme.palette.secondary.main				
				}}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h6">
					Sign in
				</Typography>
				{/* switch between step 1 (email/pass) and step 2 (otp) based on flow state */}
				{ step2
					? <LoginStep2 rememberOtp={rememberOtp} setRememberOtp={setRememberOtp} otp={otp} setOTP={setOTP} handleLogin={handleLogin} handleResendOtp={handleResendOtp}/>
					: <LoginStep1 email={email} setEmail={setEmail} password={password} setPassword={setPassword} rememberMe={rememberMe} setRememberMe={setRememberMe} handleLogin={handleLogin}/>
				}
				{ loginStatus === "ab-initio" && <></> }
				{ loginStatus !== "ab-initio" && 
					<Paper padding="large" sx={{backgroundColor: theme.palette.background.statusMessage}}>
						<Stack direction="row" alignItems="center" gap={1}>
							{ loginStatus === "successful" && <><LockOpenIcon color="success"/><Typography variant="body1">{"Login successful."}</Typography></> }					
							{ loginStatus === "failed" && <><NoEncryptionGmailerrorredIcon color="secondary"/><Typography variant="body1">{"Login failed. " + loginMessage}</Typography></> }
							{ loginStatus === "session-expired" && <><NoEncryptionGmailerrorredIcon color="secondary"/><Typography variant="body1">{"Your session has expired."}</Typography></> }
							{ loginStatus === "expired" && <><NoEncryptionGmailerrorredIcon color="secondary"/><Typography variant="body1">{"Please update your password now. "}<a href="/reset">Reset</a></Typography></> }
							{ loginStatus === "logged-out" && <><NoEncryptionGmailerrorredIcon color="secondary"/><Typography variant="body1">{"You have been logged out."}</Typography></> }
						</Stack>
					</Paper>
				}
			</Box>
			<StatusMessage StatusManager={statusManager} displayInterval="20"/>
		</Container>
		);
}

import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link, useHistory, useParams } from "react-router-dom";
import LinkIcon from '@mui/icons-material/Link';
import {Button, Box, Container, Grid, Paper} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import ViewTitle from './ViewTitle';
import BetterFormField from './FormField';
import SavingIndicator from './SavingIndicator';
import { getAdapter } from './adapters/adapter';
import LoadingIndicator from './LoadingIndicator';
import theme, { BackButton, SaveButton, CreateButton, DeleteButton, FormSection, FormSectionHeading } from './theme/PortalTheme';
import { StatusMessageContext } from './StatusMessage';
import useForm from './UseForm';
import moment from 'moment';
import { idToGuidMapper } from './Utility';
import SelectQualificationDialog from './SelectQualificationDialog';
import { getLoggedInRole } from './adapters/baseAdapter';

export function QualificationGrid(props){
	//Course Columns:
	const QUALIFICATION_COLUMNS = [
		{ field: 'courseNumber', headerName: 'Course Number', minWidth:140, disableColumnMenu: true, resizeable: true },
		{ field: 'courseTitle', headerName: 'Course Title', flex:1.5, disableColumnMenu: true, resizeable: true },
		{ 
			field: 'isVirtualILT', 
			headerName: 'Mode',
			width: 100,
			disableColumnMenu: true,
			resizeable:true,
			renderCell: function(params){
				return(
					params.row.isVirtualILT ? "vILT" : "ILT"
				);
			}
		},
		{ 
			field: 'certifiedOn', headerName: 'Certified On', disableColumnMenu: true, resizeable: true, 
			renderCell: function(params){
				return(
					moment.utc(params.row.certifiedOn).local().format("MM/DD/YYYY")
				);
			}
		},
		{
			field: 'expiresOn', headerName: 'Expires On', disableColumnMenu: true, resizeable: true,
			renderCell: function(params){
				return(
					moment.utc(params.row.expiresOn).local().format("MM/DD/YYYY")
				);
			}
		},
	];
	if(props.actionsColumnRenderCell != null){
		QUALIFICATION_COLUMNS.push({
			field: "",
			filterable:false,
			disableColumnMenu:true,
			flex:1,
			headerName: "Actions",
			sortable: false,
			disableClickEventBubbling: true,
			renderCell: props.actionsColumnRenderCell
		});
	}

	return(
		<DataGrid
			columns = {QUALIFICATION_COLUMNS}
			disableSelectionOnClick = {true}
			components = {{Toolbar:GridToolbar}}		
			density = "compact"
			autoHeight
			pageSize = {10}
			rowsPerPageOptions = {[10, 25, 50]}
			{...props}		
		/>
	);
}


function InstructorForm(props) {
    var history = useHistory();
	const {instructorId} = useParams();

	//Is component currently mounted?
    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false }
    }, []);
	
	//Status message:
	var { StatusManager } = useContext(StatusMessageContext);

	//Loading/Saving states:
	const [isLoading, setIsLoading] = useState(false);
	const [isGridLoading, setIsGridLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	//Currently attached qualifications:	
	const [qualifications, setQualifications] = useState([]);
	const [qualificationSelectionModel, setQualificationSelectionModel] = useState([]);
	const [selectQualificationDialogOpen, setSelectQualificationDialogOpen] = useState(false);
	const [instructorUserAccountId, setInstructorUserAccountId] = useState("");
	const url = "/dashboard";

	//Form validation:
    function validate(){
        let _errors = { ...errors };
		console.log("Validating");
		console.log(formValues);

		_errors.lastName = (formValues.instructor && formValues.instructor.lastName === "") ? " " : "";
		_errors.firstName = (formValues.instructor && formValues.instructor.firstName === "") ? " " : "";
		_errors.email = (formValues.instructor && formValues.instructor.email === "") ? " " : "";

        setErrors({
            ..._errors
        });

		var result = Object.values(_errors).every(x => x === "");
		console.log("Validation " + (result ? "SUCCESS" : "FAILED"));
		return result;
	}	

	//Initial Form Values:
	const initialFormValues = {
		instructor:{ 
			firstName: "", 
			middleInitial:"", 
			lastName:"", 
			email:"" 
		}
	};

	//Form-related vars:
	const {
		formValues,
		setFormValue,
		errors,
		setErrors,
		handleInputChange,
	} = useForm(initialFormValues, false, validate);	

	//Create refs to mutable objects that would otherwise be passed as dependencies to useEffect hook, to prevent triggering re-renders unnecessarily.
	const setFormValueRef = useRef(setFormValue);
		
	//On view load:
	useEffect(() => {
		let setFormValue = setFormValueRef.current;

        if(isMounted.current) {
            if(!["oted_pa", "superadmin"].includes(getLoggedInRole())){
                StatusManager.setStatus("Access denied. You have been returned to the dashboard home screen.");
                history.push("/dashboard");
            }
		}		

		async function fetchData(){
			setIsLoading(true);
			try {
				var adapter = await getAdapter();
				var instructor = await adapter.instructor.get(instructorId);
				console.log(instructor);

				//Fetch instructor qualifications, which returns an array of course objects:
				var _qualifications = await adapter.qualification.listByInstructor(instructorId);
				//console.log(_qualifications);
				
			}
			catch (error) {
				console.error(error);
			}
			if(isMounted.current) {
				_qualifications = _qualifications.map(idToGuidMapper);	
				setQualifications(_qualifications);
				setFormValue("instructor", instructor);				
				setInstructorUserAccountId(instructor.userID);			
				setIsLoading(false);
			}
		}

		fetchData();
	}, 
	[
		setIsLoading,
		setQualifications,
		instructorId		
	]);	

	//Save:
	async function saveData( successCallback = () => {} ){
		var success = true;
		var successStr = "successful.";
		setIsSaving(true);
		try {
			var adapter = await getAdapter();
			await adapter.instructor.update(instructorId, formValues.instructor);
			console.log(qualifications);
			var qualificationResponse = await adapter.qualification.set(instructorId, qualifications);
			for(var entry of qualificationResponse) {
				if(entry.error) {
					successStr += `  ${entry.error}`;
				}
			}
		} 
		catch(error){
			success = false;
			successStr = "failed";
			console.error(error);
			//If an instructor with this email already exists within the system:
			if(error.response.data.status === "duplicate instructor" && isMounted.current){
                setErrors({
                    ...errors,
                    instructorEmail: "Invalid email. An instructor with this email address already exists within the system."
                });
            }
		}
		if(isMounted.current){
			setIsSaving(false);
			console.log("Save " + successStr );
			StatusManager.setStatus("Save instructor " + successStr);
            if(success) successCallback();
		}
	}

	function onViewUserBtn(e){		
		history.push("/dashboard/users/view_edit/" + instructorUserAccountId);
	}	

    //Button handlers:
	function onBackBtn(){
		history.goBack();
	}

    function onSaveBtn(){
		if(validate()){
			saveData(()=>{
				history.goBack();
			});
		}
    }

	function onAddQualificationBtn(){
		if(isMounted.current){
			setSelectQualificationDialogOpen(true);
		}
	}

	function onRemoveQualificationBtn(e){
		e.preventDefault();
		//Form an array of qualification objects to remove:
		var qualificationsToRemove = qualifications.filter(qualification => qualificationSelectionModel.includes(qualification.guid));
		//Remove those qualifications from the grid:
		var newGridSource = qualifications.filter( qualification => !qualificationsToRemove.includes(qualification));
		//Avoids a race condition where the MUI grid tries to set focus to the cell row that was removed.  *known MUI bug*
		setTimeout(() => {
			if(isMounted.current){
				setQualifications(newGridSource);
			}
		});
	}

	function actionsColumnRenderCell(params){
		function onViewBtn(params){
			var courseId = params.guid;
			history.push("/dashboard/courses/view/" + courseId);
		}
		const buttonSpacing = theme.spacing(1);
		//to access course data in callback (eg params.row.guid), pass the row to the callback
		return (
			<React.Fragment>

				<button
					style={{marginRight:buttonSpacing, cursor:"pointer"}}
					onClick={() => onViewBtn(params.row)}
				>
					View
				</button>			
			</React.Fragment>
		);
	}

	function onSelectionModelChange(selectionModel) {
		if(isMounted.current){
			setQualificationSelectionModel(selectionModel);
		}
	}

	//Callback for when user has confirmed selections from the Select Qualified Instructor(s) dialog:
	function onSelectQualificationsCallback(selectedQualification){
		if(isMounted.current){
			console.log(selectedQualification);
			//Attach the selected qualifications (but no duplicates):
			var unique = [...new Set(qualifications.concat(selectedQualification))];
			//console.log(unique);
			setQualifications(unique);
		}
	}

	function handleInputChangeOverload(e){
		var temp = formValues.instructor;
		temp[e.target.name] = e.target.value;
		setFormValue("instructor", temp);
	}

	//Render:
	return (
        isLoading ? <LoadingIndicator/> :
        <> 
			<SelectQualificationDialog
				open={selectQualificationDialogOpen}
				setOpen={setSelectQualificationDialogOpen}
				qualifications={qualifications}
				onSelectQualificationsCallback={onSelectQualificationsCallback}
			/>
			<Grid container spacing={1}>
				<Grid item xs={5}><BetterFormField variant="filled" readOnly={true} label="Last Name" name="lastName" value={formValues.instructor.lastName || "" } error={errors.lastName} onChange={handleInputChangeOverload} /></Grid>
				<Grid item xs={2}><BetterFormField variant="filled" readOnly={true} label="Middle Initial" name="middleInitial" value={formValues.instructor.middleInitial || ""} error={errors.middleInitial} onChange={handleInputChangeOverload} /></Grid>
				<Grid item xs={5}><BetterFormField variant="filled" readOnly={true} label="First Name" name="firstName" value={formValues.instructor.firstName || ""} error={errors.firstName} onChange={handleInputChangeOverload} /></Grid>
				<Grid item xs={12}><BetterFormField variant="filled" readOnly={true} label="Email" name="email" value={formValues.instructor.email || ""} error={errors.email} onChange={handleInputChangeOverload} /></Grid>
			</Grid>
			<p>The fields above are automatically populated from the instructor's user account and can only be edited from the View/Edit User screen.</p>
			<Button
				variant="contained"
				color="primary" 
				startIcon={<LinkIcon/>}
				onClick={onViewUserBtn}
			>
				View/Edit Instructor's User Account
			</Button>
			<FormSectionHeading>Qualifications</FormSectionHeading>
			<FormSection>
				<Grid container justifyContent="flex-end">
					<CreateButton onClick={onAddQualificationBtn} >Add Qualification</CreateButton>
					<DeleteButton onClick={onRemoveQualificationBtn} disabled={qualificationSelectionModel.length === 0}>Remove Qualification(s)</DeleteButton>
				</Grid>
				<QualificationGrid
					actionsColumnRenderCell = {actionsColumnRenderCell}
					isMounted = {isMounted}
					loading = {isGridLoading}
					rows = {isGridLoading ? [] : qualifications}
					onSelectionModelChange={onSelectionModelChange}
					checkboxSelection = {true}
					selectionModel={qualificationSelectionModel}
				/>
			</FormSection>
            <Grid container justifyContent="center" alignItems="bottom" sx={{marginTop:4}}>
				{ isSaving ? <><SavingIndicator /></> :
                    <>
                        <BackButton onClick={onBackBtn} />                                
                        <SaveButton onClick={onSaveBtn} />
                    </>
                }
			</Grid>
        </>
	);
}

export default function ViewEditInstructor() {
	return (
		<Container component="main" maxWidth="lg">
			<Paper style={{minHeight:'70vh'}} padding="large">
				<ViewTitle>View/Edit Instructor</ViewTitle>
				<InstructorForm />
			</Paper>
		</Container>
	);
}

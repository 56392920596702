import axios from 'axios';
import {parseResponse, appRoute, API_URL} from "../utils";
import { getAdapter } from '../adapter';

function unqualifiedRoute(appID)
{
  return appRoute(appID) + "/unqualified";
}

/** data should contain
[{
  firstName,
  lastName,
  email
}]
*/
export async function create(applicationID, instructors)
{
  instructors = Array.isArray(instructors) ? instructors : [instructors];
  return parseResponse(await axios.post(unqualifiedRoute(applicationID), {instructors: instructors}, (await getAdapter()).getAuthHeader));
}

/** while unqualified instructors do have their own guids, they will be accessed as if they belong directly to an application */
export async function get(applicationID, instructorID)
{
  return parseResponse(await axios.get(unqualifiedRoute(applicationID) + `/${instructorID}`, (await getAdapter()).getAuthHeader));
}

export async function set(applicationID, instructors)
{
  //coerce to array
  instructors = Array.isArray(instructors) ? instructors : [instructors];

  await clear(applicationID);
  return create(applicationID, instructors);
}

export async function list(applicationID)
{
  return parseResponse(await axios.get(unqualifiedRoute(applicationID), (await getAdapter()).getAuthHeader));
}

export async function update(applicationID, instructorID, data)
{
  return parseResponse(await axios.patch(unqualifiedRoute(applicationID) + `/${instructorID}`, data, (await getAdapter()).getAuthHeader))
}

async function _delete(applicationID, instructorID)
{
  return parseResponse(await axios.delete(unqualifiedRoute(applicationID) + `/${instructorID}`), (await getAdapter()).getAuthHeader)
}

/** clears the list of unqualified instructors from the given application */
export async function clear(applicationID)
{
  return parseResponse(await axios.delete(unqualifiedRoute(applicationID), (await getAdapter()).getAuthHeader));
}

export {
  _delete as delete
}

import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory } from "react-router-dom";
import ViewTitle from './ViewTitle';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { getAdapter } from './adapters/adapter';
import theme, { DeleteButton, CreateButton } from './theme/PortalTheme';
import { idToGuidMapper, roleToFriendlyRole } from './Utility';
import Grid from '@mui/material/Grid';
import { StatusMessageContext } from './StatusMessage';
import ConfirmationDialog from './ConfirmationDialog';
import { getLoggedInRole } from './adapters/baseAdapter';

export function UserGrid(props){
	//User Columns:
	const USER_COLUMNS = [
		{ field: 'email', headerName: 'Email', width: 200, disableColumnMenu:true, resizeable:true },
		{ field: 'lastName', headerName: 'Last Name', width: 120, disableColumnMenu:true},
		{ field: 'firstName', headerName: 'First Name', width: 120, disableColumnMenu:true},
		{ field: 'friendlyRole', headerName: 'Role', width: 250, disableColumnMenu:true}
	];
	if(props.actionsColumnRenderCell != null){
		USER_COLUMNS.push({
			field: "",
			filterable:false,
			disableColumnMenu:true,
			flex:1,
			minWidth:100,
			headerName: "Actions",
			sortable: false,
			disableClickEventBubbling: true,
			renderCell: props.actionsColumnRenderCell
		});
	}

	return(
		<DataGrid
			columns = {USER_COLUMNS}
			initialState={{
				sorting: {
				  sortModel: [{ field: 'lastName', sort: 'asc' }],
				}
			}}			
			disableSelectionOnClick = {true}
			components = {{Toolbar:GridToolbar}}		
			density = "compact"
			autoHeight
			pageSize = {10}
			rowsPerPageOptions = {[10, 25, 50]}
			{...props}		
		/>
	);
}

export default function ManageUsers() {
	const history = useHistory();

	const [users, setUsers] = useState([]);
	//States related to data fetching:
	const [isLoading, setIsLoading] = useState(false);
	const [deleteDisabled, setDeleteDisabled] = useState(true);
	const [updateGrid, setUpdateGrid] = useState(0);
	const [selected, setSelected] = useState([]);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    //Status message:
	var { StatusManager } = useContext(StatusMessageContext);

	const isMounted = useRef(false)
	useEffect(() => {
		isMounted.current = true;
		return () => { isMounted.current = false }
	}, []);

	useEffect(() => {
        if(isMounted.current){
            if(!["oted_pa", "superadmin"].includes(getLoggedInRole())){
                StatusManager.setStatus("Access denied. You have been returned to the dashboard home screen.");
                history.push("/dashboard");
            }    
        }

		async function fetchData(){
			setIsLoading(true);
			try {
				var adapter = await getAdapter();
				var users = await adapter.user.listUsers();
			} 
			catch (error) {
				console.error(error);
			}
			if(isMounted.current){
				users = users.map(idToGuidMapper);
				users.forEach((e) => e.friendlyRole = roleToFriendlyRole(e.role));
				setUsers(users);
				setIsLoading(false);
			}
		};

		fetchData();

	}, [setIsLoading, 
		updateGrid]);		


	function actionsColumnRenderCell(params){
		function onViewEditBtn(params){
			var userId = params.guid;
			history.push("/dashboard/users/view_edit/" + userId);
		}

		return (
			<React.Fragment>
				<button
					style={{marginRight:theme.spacing(1), cursor:"pointer"}}
					onClick={() => onViewEditBtn(params.row)}
				>
					View/Edit
				</button>						
			</React.Fragment>
		);
	}

	async function onCreateUserBtn(){
		history.push("/dashboard/users/create/");
	}

	function onDeleteUserBtn(){
		setDeleteDialogOpen(true);
	}

	function onConfirmDelete(){
		deleteUser();
	}	

	async function deleteUser(){
		setIsLoading(true);
		var success = true;
		selected.forEach(async function(user){
			try{
				var adapter = await getAdapter();
				await adapter.user.delete(user);
			}
			catch(error){
				console.error(error);
				success = false;
			}
		});
		if(isMounted.current){
			var successStr = success ? "successful." : "failed.";
			console.log("Delete " + successStr );
			StatusManager.setStatus("Delete user " + successStr);
			setUpdateGrid(updateGrid+1);
		}
	}

	function onSelectionChange(selectionModel) {
		if(setDeleteDisabled != null) {
			setDeleteDisabled(selectionModel.length === 0);
		}
		setSelected(selectionModel);
	}

	return (
		<Container component="main" maxWidth="lg">
			<Paper style={{minHeight:'70vh'}}  padding="large">
				<ViewTitle>Manage Users</ViewTitle>
				{/*Delete Confirmation Dialog*/}
				<ConfirmationDialog
					open={deleteDialogOpen}
					setOpen={setDeleteDialogOpen}
					dialogTitle="Confirm Deletion"
					dialogContent="Are you sure you want to delete?"
					leftButtonLabel="No"
					leftButtonCallback={()=>{}}
					rightButtonLabel="Yes"
					rightButtonCallback={onConfirmDelete}
				/>				
				<p style={{marginBottom:0}}>Listed below are the user accounts within the system.</p>
				<ul>
					<li style={{marginBottom:theme.spacing(1)}}>Select the <strong>Create User</strong> button to create a new user account.
					<ul><li style={{marginTop:theme.spacing(1)}}><strong>NOTE:</strong> The newly-created user will receive an email with instructions on how to access their account.</li></ul></li>
					<li style={{marginBottom:theme.spacing(1)}}>Select the <strong>View/Edit</strong> button to view or edit information about the corresponding user account.</li>					
					<li style={{marginBottom:theme.spacing(1)}}>After selecting the checkbox next to one or more users from the list, select the <strong>Delete User</strong> button to delete the selected user account(s).
					<ul><li style={{marginTop:theme.spacing(1)}}><strong>NOTE:</strong> This will permanently delete the selected user account(s).</li></ul></li>
				</ul>
				<Grid container justifyContent="flex-end">
					<CreateButton onClick={onCreateUserBtn}>Create User</CreateButton>
					<DeleteButton onClick={onDeleteUserBtn} disabled={deleteDisabled}>Delete User</DeleteButton>					
				</Grid>
				<UserGrid
					actionsColumnRenderCell = {actionsColumnRenderCell}
					loading = {isLoading}
					rows = {users}
					checkboxSelection={true}
					onSelectionModelChange={onSelectionChange}
				/>
			</Paper>
		</Container>
	);
}

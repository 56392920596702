import React from 'react';
import { DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle 
            sx={{ m: 0, p: 2 }} 
            {...other}
        >
            {children}
            {
                onClose && 
                (
                    <IconButton
                        aria-label="Close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8                            
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                )
            }
        </DialogTitle>
    );
};
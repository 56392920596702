import React, { useEffect, useRef } from 'react';
import { Link, Redirect, Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { Assignment, Attachment, ChevronLeft, Group, Home, Help, LocalLibrary, Logout, Menu, Person, School } from '@mui/icons-material';
import { AppBar as MuiAppBar, Badge, Box, CssBaseline, Divider, Drawer as MuiDrawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Paper, Popper, styled, Toolbar, Typography } from '@mui/material/';
import { getAdapter } from './adapters/adapter';
import * as baseAdapter from './adapters/baseAdapter';
import ApplicationStatusDrilldown from './reports/ApplicationStatusDrilldown';
import ConfirmationDialog from './ConfirmationDialog';
import CourseDrilldown from './reports/CourseDrilldown';
import CourseFulfillment from './CourseFulfillment';
import CreateCourse from './CreateCourse';
import CreateInstructor from './CreateInstructor';
import CreateOrganization from './CreateOrganization';
import CreateUser from './CreateUser';
import DashboardHome, { DashboardHomePOC, DashboardHomeQI } from './DashboardHome';
import FDALogo from "./images/gov-fda-new-white.svg";
import ICDApplicationForm from './ICDApplicationForm';
import ICDApplicationFormCloseout from './ICDApplicationForm_Closeout';
import ListApplications from './ListApplications';
import ListCourses from './ListCourses';
import ManageApplications from './ManageApplications';
import ManageCourses from './ManageCourses';
import ManageInstructors from './ManageInstructors';
import ManageOrganizations from './ManageOrganizations';
import ManageUsers from './ManageUsers';
import MessageApp from './MessageApp';
import NotificationsButton from './Notifications';
import OrganizationDrilldown from './reports/OrganizationDrilldown';
import SessionExpiryMessage from './SessionExpiryMessage';
import StatusMessage, { StatusManager, StatusMessageContext } from './StatusMessage';
import theme from './theme/PortalTheme';
import UserDrilldown from './reports/UserDrilldown';
import ViewCourse from './ViewCourse';
import ViewEditCourse from './ViewEditCourse';
import ViewEditCourseMaterials from './ViewEditCourseMaterials';
import ViewEditInstructor from './ViewEditInstructor';
import ViewEditOrganization from './ViewEditOrganization';
import ViewEditProfile from './ViewEditProfile';
import ViewEditUser from './ViewEditUser';
import ImportInstructors from './ImportInstructors';
import LibraryContainer from './library/Library';
import './Message.css';

const drawerWidth = 240;
const propFilter = {shouldForwardProp: (prop) => prop !== 'open' };

const AppBar = styled(MuiAppBar, propFilter)(
	({ theme, open }) =>
	({
		zIndex: theme.zIndex.drawer + 1,
		backgroundColor: theme.palette.fdaDarkBlue.main,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		...(open && {
			marginLeft: drawerWidth,
			width: `calc(100% - ${drawerWidth}px)`,
			transition: theme.transitions.create(['width', 'margin'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen
			}),
		}),
	})
);

const Drawer = styled(MuiDrawer, propFilter)(
	({ theme, open }) =>
	({
		'& .MuiDrawer-paper': {
			position: 'relative',
			whiteSpace: 'nowrap',
			width: drawerWidth,
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
			boxSizing: 'border-box',
			...(!open && {
				overflowX: 'hidden',
				transition: theme.transitions.create('width', {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.leavingScreen,
				}),
				width: theme.spacing(7),
				[theme.breakpoints.up('sm')]: {width: theme.spacing(9)}
			}),
		},
	}),
);

function SidebarButton({icon, text, to="#", onClick=()=>{}}) {

	return (
		<ListItem button component={Link} to={to} onClick={onClick} title={text}>
			<ListItemIcon>
				{/* add the passed-in icon element */}
				{icon}
			</ListItemIcon>
			<ListItemText primary={text} />
		</ListItem>
	)
}


//Determines the Dashboard sidebar options available to a user:
function SidebarOptionsProvider(props){

	//roles not listed here will be granted buttons in "all" list
	const SIDEBAR_OPTIONS = {
		"ab-initio": [],
		"oted_pa": ["home", "manageCourses", "courseFulfillment", "organizations", "manageApplications", "applicationMessages", "users", "instructors", "divider", "library", "profile", "logout"],
		"superadmin": ["home", "manageCourses", "courseFulfillment", "organizations", "manageApplications", "applicationMessages", "users", "instructors", "divider", "library", "profile", "logout"],
		"applicant_poc": ["home", "listCourses", "listApplications", "applicationMessages", "divider", "library", "profile", "logout"],
		"qualified_instructor": ["home", "divider", "library", "profile", "logout"],
		"oted_ff": ["courseFulfillment", "library", "profile", "logout"]
	};

	//map each list of options to an object where the keys are assigned to "true"
	//just some trickery that allows you to select e.g. options.courses instead of options.includes("courses")
	Object.entries(SIDEBAR_OPTIONS).forEach(([k, v]) => {
		var newOpts = {};
		v.forEach((e) => newOpts[e] = true);
		SIDEBAR_OPTIONS[k] = newOpts;
	})

	var options = (props.role in SIDEBAR_OPTIONS) ? SIDEBAR_OPTIONS[props.role] : SIDEBAR_OPTIONS["ab-initio"];

	return(
		<>
			{options.home && <SidebarButton text="Home" icon={<Home />} to="/dashboard" />}
			{options.listCourses && <SidebarButton text="Courses" icon={<School />} to="/dashboard/courses/list" />}
			{options.manageCourses && <SidebarButton text="Courses" icon={<School />} to="/dashboard/courses/manage" />}
			{options.courseFulfillment && <SidebarButton text="Course Fulfillment" icon={<Attachment/>} to="/dashboard/courses/fulfillment" />}
			{options.organizations && <SidebarButton text="Organizations" icon={<Group />} to="/dashboard/organizations/manage" />}
			{options.listApplications && <SidebarButton text="Applications" icon={<Assignment />} to="/dashboard/applications/list" />}
			{options.manageApplications && <SidebarButton text="Applications" icon={<Assignment />} to="/dashboard/applications/manage" />}
			{/* {options.applicationMessages && <SidebarButton text="Messages" icon={<Message />} to="/dashboard/messages/manage" />} */}
			{options.users && <SidebarButton text="Users" icon={<Person />} to="/dashboard/users/manage/" />}
			{options.instructors && <SidebarButton text="Instructors" icon={<LocalLibrary />} to="/dashboard/instructors/manage" />}
			{options.divider && <Divider variant="middle" sx={{my:3}} />}
			{options.library && <SidebarButton text="Library" icon={<Help />} to="/dashboard/library" />}
			{options.profile && <SidebarButton text="My Profile" icon={<Person />} to="/dashboard/users/profile" />}
			{options.logout && <SidebarButton text="Logout" icon={<Logout />} onClick={props.onLogoutBtn} />}
		</>
	);
}

function DashboardContent() {
	const {url} = useRouteMatch();
	var history = useHistory();

	//States:
	const [sidebarOpen, setSidebarOpen] = React.useState(true);
	const [role, setRole] = React.useState('ab-initio');
	const [logoutDialogOpen, setLogoutDialogOpen ] = React.useState(false);

	//Status Message:
	const [statusMessage, setStatusMessage] = React.useState("");
	const [numMessages, setNumMessages] = React.useState(0);
	
	//const [intervalId, setIntervalId] = React.useState(0);
	var statusManager = new StatusManager(statusMessage, setStatusMessage, numMessages, setNumMessages);

	//Toggle sidebar menu:
	const toggleSidebar = () => {
		setSidebarOpen(!sidebarOpen);
	};

    //Is component currently mounted?
    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false }
    }, []);

	//Hooks:
	useEffect(() => {
		return (async() => {
			//get adapter
			var adapter = await getAdapter();
			try {
				//get role of logged-in user
				var role = adapter.getLoggedInRole();
				if( isMounted.current){
					setRole(role);
				}
			} 
			catch (err) {
				console.log(err.message);
				history.push("/login");
				return;
			}
			
		})();
	}, []);


	/*Logout Button and Logout Confirmation Dialog Behavior*/
	function onLogoutBtn(){
		setLogoutDialogOpen(true);
	}

	async function onConfirmLogout(){
		var adapter = await getAdapter();
		await adapter.logout();
		history.push(`/login/session-terminated`);
	}

	function populateDashboardHome(){
		switch (role){
			case "applicant_poc":
				return (<DashboardHomePOC/>);
			
			//case "qualified_instructor":
			//	return (<DashboardHomeQI/>);
			case "oted_pa":
			case "superadmin":
				return (<DashboardHome/>);
			case "oted_ff":
				return (
					<Redirect
						to={{
							pathname: "/dashboard/courses/fulfillment",
						}}
					/>
				);
		}
		return null;
	}

	return (
		<StatusMessageContext.Provider value={{ StatusManager: statusManager }}>
			<Box sx={{ display: 'flex' }}>
				<CssBaseline />
				{/*Top Application Bar/Header*/}
				<AppBar
					position="absolute"
					open={sidebarOpen}
				>
					<Toolbar
						sx={{
							pr: '24px', // keep right padding when drawer closed,
						}}
						id="oted-portal-toolbar"
					>
						<IconButton
							edge="start"
							color="lightOnDark"
							aria-label="open drawer"
							onClick={toggleSidebar}
							sx={{
								marginRight: '36px',
								...(sidebarOpen && { display:'none' }),
							}}
						>
							<Menu />
						</IconButton>
						<img src={FDALogo} alt="FDA Logo" style={{height:"50px"}}/>
						<Typography
							component="h1"
							variant="h5"
							color="#FFF"
							noWrap
							sx={{ flexGrow: 1, paddingLeft:4}}
						>
							ICD Management Portal
						</Typography>
						{/*Notifications*/
							<NotificationsButton />
						}
					</Toolbar>
				</AppBar>
				{/*Sidebar*/}
				<Drawer variant="permanent" open={sidebarOpen}>
					<Toolbar
						sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-end',
						px: [1],
						}}
					>
						<IconButton onClick={toggleSidebar} >
							<ChevronLeft />
						</IconButton>
					</Toolbar>
					<Divider />
					<List>
						<SidebarOptionsProvider role={role} onLogoutBtn={onLogoutBtn}/>
					</List>
				</Drawer>
				{/*Main View/Content Area of Dashboard*/}
				<Box
					sx={{
						bgcolor: theme.palette.background.dashboardContent,
						flexGrow: 1,
						height: '100vh',
						overflow: 'auto',
					}}
				>
					<Toolbar />
					<div>						
						{/* Render based on url route */}
						{/* see nested routing example here: https://reactrouter.com/web/example/nesting */}
						<Switch>
							{/* Add dashboard home content here */}
							<Route exact path={`${url}/`}>
								{ populateDashboardHome() }
							</Route>
1
							{/* Course management */}
							<Route exact path={`${url}/courses/list`}><ListCourses/></Route>
							<Route exact path={`${url}/courses/manage`}><ManageCourses/></Route>
							<Route exact path={`${url}/courses/fulfillment`}><CourseFulfillment/></Route>
							<Route path={`${url}/courses/view/:courseId`}><ViewCourse/></Route>
							<Route path={`${url}/courses/view_edit/:courseId`}><ViewEditCourse/></Route>
							<Route path={`${url}/courses/materials/:courseId`}><ViewEditCourseMaterials/></Route>
							<Route path={`${url}/courses/create`}><CreateCourse/></Route>

							{/* Organization management */}
							<Route exact path={`${url}/organizations/manage`}><ManageOrganizations/></Route>
							<Route path={`${url}/organizations/view_edit/:organizationId`}><ViewEditOrganization/></Route>
							<Route path={`${url}/organizations/create`}><CreateOrganization/></Route>

							{/* Application management */}
							<Route exact path={`${url}/message/messages/:applicationId`}><MessageApp/></Route>
							<Route exact path={`${url}/message/internalmessages/:applicationId`}><MessageApp internal="1"/></Route>
							<Route exact path={`${url}/applications/list`}><ListApplications/></Route>
							<Route exact path={`${url}/applications/manage`}><ManageApplications/></Route>
							<Route path={`${url}/applications/view_edit/:applicationId/:currentStep/`}><ICDApplicationForm/></Route>
							{/* Application Closeout*/}
							<Route exact path={`${url}/applications/closeout/:applicationId/`}><ICDApplicationFormCloseout/></Route>

							{/* User management */}
							<Route exact path={`${url}/users/manage`}><ManageUsers/></Route>
							{/* add guid (user id) to the url so that ViewEditUser can pull the appropriate user from the adapter */}
							<Route path={`${url}/users/view_edit/:userId`}><ViewEditUser/></Route>
							{/* Allow users to edit their own profile: */}
							<Route path={`${url}/users/profile`}><ViewEditProfile /></Route>
							<Route path={`${url}/users/create`}><CreateUser/></Route>

							{/* Instructor management */}
							<Route exact path={`${url}/instructors/manage`}><ManageInstructors/></Route>
							<Route path={`${url}/instructors/view_edit/:instructorId`}><ViewEditInstructor/></Route>
							<Route path={`${url}/instructors/create`}><CreateInstructor/></Route>
							<Route path={`${url}/instructors/import`}><ImportInstructors/></Route>
							
							{/*Drilldown Reports */}
							<Route exact path={`${url}/reports/application_status`}><ApplicationStatusDrilldown/></Route>
							<Route exact path={`${url}/reports/users`}><UserDrilldown/></Route>
							<Route exact path={`${url}/reports/courses`}><CourseDrilldown/></Route>
							<Route exact path={`${url}/reports/organizations`}><OrganizationDrilldown/></Route>

							{/*Library*/}
							<Route exact path={`${url}/library`}><LibraryContainer/></Route>

						</Switch>
						<StatusMessage StatusManager={statusManager}/>
						<SessionExpiryMessage/>
						
						{/*Logout Confirmation Dialog*/}
						<ConfirmationDialog
							open={logoutDialogOpen}
							setOpen={setLogoutDialogOpen}
							dialogTitle="Are you sure you want to logout?"
							dialogContent="This will end your user session."
							leftButtonLabel="Cancel"
							leftButtonCallback={()=>{}}
							rightButtonLabel="Logout"
							rightButtonCallback={onConfirmLogout}
						/>
					</div>
				</Box>
			</Box>
		</StatusMessageContext.Provider>		
	);
}

export default function Dashboard() {
	var history = useHistory();
	try {
		baseAdapter.getLoggedInRole();
		var ttl = baseAdapter.getSessionTtl();
		if(ttl <= 0) {
			console.log("expired session");
			history.push("/login");
			return null;	
		}
	} catch(err) {
		//"No token present, is user logged in?"
		console.log(err.message);
		history.push("/login");
		return null;
	}
	try {
		return <DashboardContent />;
	} catch(err) {
		baseAdapter.logEvent(err, true);
	}
}

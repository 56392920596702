import React from 'react';
import ReactDOM from 'react-dom';
import App from "./App.js";
import "typeface-roboto";
import { configureAxios } from './adapters/baseAdapter';

//configure axios for api requests
configureAxios();

ReactDOM.render(
	<App/>,
	document.getElementById('root')
);

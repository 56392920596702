import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme/PortalTheme';
import Dashboard from './Dashboard';
import Login from './Login';
import Reset from './Reset';
import Forgot from './Forgot';

export class App extends React.Component {
	render() {
		return (
		<ThemeProvider theme={theme}>
			<CssBaseline/>
			<Router>
				<Switch>
					<Route exact path="/"><Redirect to="/login" /></Route>
					<Route path="/dashboard"><Dashboard /></Route>
					<Route path="/login/:status?"><Login /></Route>
					<Route path="/reset"><Reset /></Route>
					<Route path="/forgot"><Forgot /></Route>
					<Route path="/*"><Redirect to="/login" /></Route>
				</Switch>
			</Router>
		</ThemeProvider>
		);
	}
}

export default App;

import React, { useEffect, useState, useRef } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ViewTitle from './ViewTitle';
import LoadingIndicator from './LoadingIndicator';
import { getAdapter } from './adapters/adapter';
import BetterFormField from './FormField';
import { useParams, useHistory } from "react-router-dom";
import { BackButton } from './theme/PortalTheme';
import useForm from './UseForm';

function CourseForm(props){

    const { courseId } = useParams();
    const history = useHistory();

	//Is component currently mounted?
    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false }
    }, []);
    
	//Loading/Saving states:
	const [isLoading, setIsLoading] = useState(false);

	//Initial Form Values:
	const initialFormValues = {
		courseNumber: "",
        courseTitle: "",
        courseDeliveryMode: "ILT",
		courseDescription: ""
	};

	//Form-related vars:
	const {
		formValues,
		setFormValue,
		errors,
		handleInputChange,
	} = useForm(initialFormValues, false, ()=>{});

	//Create refs to mutable objects that would otherwise be passed as dependencies to useEffect hook, to prevent triggering re-renders unnecessarily.
	const setFormValueRef = useRef(setFormValue);

	//On view load:
    useEffect(() => {
        let setFormValue = setFormValueRef.current;
        async function fetchData(){
            setIsLoading(true);
            try {
                var adapter = await getAdapter();               
                var course = await adapter.course.get(courseId);
            } 
            catch (error) {
                console.error(error);	
            }
            if(isMounted.current){
                setFormValue("courseNumber", course.courseNumber || "");
                setFormValue("courseTitle", course.courseTitle || "");
                setFormValue("courseDeliveryMode", course.isVirtualILT === true ? "vILT" : "ILT");
                setFormValue("courseDescription", course.description || "");
                setFormValue("courseICDEligible", course.icdEligible || true);
                setIsLoading(false);
            }
        }        
        fetchData();
    },
    [
        setIsLoading,
        courseId
    ]);

    //Button handlers:
    function onBackBtn(){
        history.goBack();
    }

    //Render:
	return (
        isLoading ? <LoadingIndicator/> :
		<>
            <Grid container spacing={1}>
                <Grid item xs={3}><BetterFormField variant="filled" readOnly={true} label="FDA Course Number" name="courseNumber" value={formValues.courseNumber} error={errors.courseNumber} onChange={handleInputChange} /></Grid>
                <Grid item xs={9}><BetterFormField variant="filled" readOnly={true} label="Course Title" name="courseTitle" value={formValues.courseTitle} error={errors.courseTitle} onChange={handleInputChange} /></Grid>
                <Grid item xs={12}><BetterFormField variant="filled" readOnly={true} label="Course Delivery Mode" name="courseDeliveryMode" value={formValues.courseDeliveryMode} error={errors.courseDeliveryMode} onChange={handleInputChange}/></Grid>                
                <Grid item xs={12} mt={1}><BetterFormField variant="filled" readOnly={true} multiline={true} rows={6} label="Course Description" name="courseDescription" value={formValues.courseDescription} error={errors.courseDescription} onChange={handleInputChange}/></Grid>						
            </Grid>
            <Grid container justifyContent="center" alignItems="bottom" sx={{marginTop:4}}>
                <BackButton onClick={onBackBtn} />
			</Grid>
		</>
	);
}

export default function ViewCourse() {
	return (
		<Container component="main" maxWidth="lg">
			<Paper style={{minHeight:'70vh'}} padding="large">
				<ViewTitle>View Course</ViewTitle>
                <CourseForm />
			</Paper>
		</Container>
    );
}
import React, { useState, useEffect, useRef } from 'react';
import { getAdapter } from './adapters/adapter';
import Audit from './Audit';
import Timeline from "@material-ui/lab/Timeline";
import { getFormattedDateTime } from './Utility';

const AuditApp = (props) => {

    const [applicationAudits, setApplicationAudits] = useState([]);
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false }
    }, []);

    useEffect(() => {
        async function fetchData(){
            try {
                var adapter = await getAdapter();
                var recordset = await adapter.application.getApplicationAudit(props.applicationId);
                await Promise.all(recordset.map(async (element) => {
                    element.pretty_date = getFormattedDateTime(new Date(element.created_on));
                    element.created_by_name = element.first_name + " " + element.last_name;
                }));
                if(isMounted.current){
                    setApplicationAudits(recordset);
                }                
            } 
            catch (error) {
                console.error(error);
            }      
        }
       fetchData();
    }, []);

    return(
        <React.Fragment>
            <Timeline position="alternate">
               { applicationAudits && 
                applicationAudits.map((audit, i) => {
                    return (
                        <Audit 
                            key={i} 
                            map_index={i}
                            table_action={audit["table_action"]}
                            delta={audit["delta"]}     
                            created_on={audit["created_on"]}
                            pretty_date={audit["pretty_date"]}
                            created_by={audit["created_by"]}
                            created_by_name={audit["created_by_name"]}         
                            application_guid={audit["application_guid"]}                   
                        />
                    );
                })
                }
            </Timeline>
        </React.Fragment>
	);
}

export default AuditApp

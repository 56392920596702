import React, { useEffect, useRef, useState } from 'react';
import {Grid, Button, Card, CardActions, CardContent, Typography, Avatar} from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import SchoolIcon from '@mui/icons-material/School';
import { getAdapter } from '../adapters/adapter';
import LoadingIndicator from '../LoadingIndicator';
import { useHistory } from "react-router-dom";

export default function NumberOfCoursesCard(props){
   
    //Is component currently mounted?
	const isMounted = useRef(false)
	useEffect(() => {
		isMounted.current = true;
		return () => { isMounted.current = false }
	}, []);

    //States:
	const [isLoading, setIsLoading] = useState(false);
    const [numberOfCourses, setNumberOfCourses] = useState(0);

    //Initialize view:
    useEffect(()=>{
		async function fetchData(){
			setIsLoading(true);
			try {
				var adapter = await getAdapter();
                //Make some call to adapter:
                var result = await adapter.course.listCoursesMetrics();
			} 
			catch (error) {
				console.error(error)
			}
			if(isMounted.current){
                //Set some state:
                setNumberOfCourses(result.coursesCount);
				setIsLoading(false);
			}
		};
		
		fetchData();
    }, []);

    const history = useHistory();

    function onViewReportBtn(){
        history.push("/dashboard/reports/courses");
    }

    //Render:
    return(
        <Card {...props}>
            <CardContent sx={{marginBottom:2}}>
                <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
                    <Grid item>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="overline"
                        >
                            Courses
                        </Typography>
                        { isLoading ?
                            <LoadingIndicator/> : 
                            <Typography
                                color="textPrimary"
                                variant="h4"
                            >
                                {isLoading ? "-" :numberOfCourses}
                            </Typography>
                        }
                    </Grid>
                    <Grid item>
                        <Avatar
                            sx={{
                                backgroundColor: '#14B8A6',
                                height: 56, 
                                width: 56
                            }}
                        >
                            <SchoolIcon />
                        </Avatar>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions sx={{padding:1}}>
                <Button 
                    size="small"
                    onClick={ onViewReportBtn}
                >
                    View Report
                </Button>
            </CardActions>
        </Card>
    );
}
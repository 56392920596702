import React from 'react';
import {Box, Button, Card, CardHeader, CardActions, CardContent, Container, Divider, Grid} from '@mui/material';
import NumberOfOrganizationsCard from "./reports/NumberOfOrganizationsCard";
import NumberOfCoursesCard from "./reports/NumberOfCoursesCard";
import NumberOfUsersCard from "./reports/NumberOfUsersCard";
import CertificationCard from "./reports/CertificationCard";
import ApplicationStatusCard from "./reports/ApplicationStatusCard";
import Library from "./library/Library.js";

export default function DashboardHome(props) {
	return (
        <Box sx={{ flexGrow: 1, py: 6 }}>
            <Container maxWidth={false}>
                <Grid container spacing={3}>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <NumberOfOrganizationsCard sx={{height:"100%"}}/>
                    </Grid>
                    <Grid item xl={3} lg={3} sm={6} xs={12}>
                        <NumberOfCoursesCard sx={{height:"100%"}}/>
                    </Grid>
                    <Grid item xl={6} lg={6} sm={12} xs={12}>
                        <NumberOfUsersCard sx={{height:"100%"}}/>
                    </Grid>                    
                    <Grid item lg={6} md={6} xl={6} sm={12} xs={12}>
                        <CertificationCard sx={{ height: '100%' }}/>
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} sm={12} xs={12}>
                        <ApplicationStatusCard sx={{ height: '100%' }}/>
                    </Grid>             
                </Grid>
            </Container>
        </Box>
	);
}

//For "applicant_poc" (point of contact)users:
function DashboardHomePOC(props){
    return(
        <Box sx={{ flexGrow: 1, py: 6 }}>
            <Container maxWidth={false}>
                <Grid container justifyContent="space-around" spacing={3}>
                    <Grid item lg={6} md={6} xl={6} sm={12} xs={12}>
                        <ApplicationStatusCard sx={{ height: '100%' }}/>
                    </Grid>
                </Grid>
            </Container>
        </Box>      
    );
}

//For "qualified_instructor" users:
function DashboardHomeQI(props){
    return(
        <Box sx={{ flexGrow: 1, py: 6 }}>
            <Container maxWidth={false}>
                <Grid container justifyContent="space-around" spacing={3}>
                    <Grid item lg={6} md={6} xl={6} sm={12} xs={12}>
                        <AnnualRequirementsCard sx={{ height: '100%' }}/>
                    </Grid>
                </Grid>
            </Container>
        </Box>      
    );
}

function AnnualRequirementsCard(props){
    //Render:
    return(
        <Card {...props}>
            <CardHeader title="Annual Requirements"/>
            <Divider sx={{width:"100%"}} />          
            <CardContent>
                <p>Listed below are the annual requirements for maintaining your QI status. These requirements must be satisfied for each course you wish to remain eligible to teach.</p>
                <p>Prior to September 30th, you must attest that you have satisfied these requirements, and select the <strong>Submit</strong> button.</p>
                <p>ATTESTATION WIDGET LIST GOES HERE (ONE FOR EACH COURSE QUALIFICATION)</p>
            </CardContent>
        </Card>
    );   
}

export {DashboardHomePOC, DashboardHomeQI};
export const ALT_TOPIC_1_1 = `An illustrative thumbnail of the ICD Management Portal home page. The menu button is highlighted.`;
export const ALT_TOPIC_1_2 = `An illustrative thumbnail of the ICD Management Portal home screen with the navigation bar open. The Applications tab is highlighted.`;
export const ALT_TOPIC_1_3 = `An illustrative thumbnail of the Applications page.`;
export const ALT_TOPIC_1_4 = `An illustrative thumbnail of the Applications page. The Create Application button is highlighted.`;
export const ALT_TOPIC_1_5 = `An illustrative thumbnail of the Create ICD Application - Delivery Information page.`;
export const ALT_TOPIC_1_6 = `An illustrative thumbnail of the Create ICD Application - Delivery Information page. The Save and Continue button is highlighted.`;
export const ALT_TOPIC_1_7 = `An illustrative thumbnail of the Create ICD Application - Instructors page.`;
export const ALT_TOPIC_1_8 = `An illustrative thumbnail of the Create ICD Application - Instructors page. The Select Qualified Instructor(s) button is highlighted.`;
export const ALT_TOPIC_1_9 = `An illustrative thumbnail of the Create ICD Application - Instructors page; the Add New Instructor button is highlighted.`;
export const ALT_TOPIC_1_10 = `An illustrative thumbnail of the Create ICD Application - Instructors page; the Save and Continue button is highlighted.`;
export const ALT_TOPIC_1_11 = `An illustrative thumbnail of the Create ICD Application - Contact page.`;
export const ALT_TOPIC_1_12 = `An illustrative thumbnail of the Create ICD Application - Agreements page.`;
export const ALT_TOPIC_1_13 = `An illustrative thumbnail of the Create ICD Application - Agreements page; the Submit Application button is highlighted.`;
export const ALT_TOPIC_2_1 = `An illustrative thumbnail of the ICD Management Portal home page. The menu button is highlighted.`;
export const ALT_TOPIC_2_2 = `An illustrative thumbnail of the ICD Management Portal home screen with the navigation bar open. The My Profile tab is highlighted.`;
export const ALT_TOPIC_2_3 = `An illustrative thumbnail of the My Profile page.`;
export const ALT_TOPIC_2_4 = `An illustrative thumbnail of the My Profile. The Send Notification Emails button is highlighted.`;
export const ALT_TOPIC_2_5 = `An illustrative thumbnail of the My Profile page. The Save button is highlighted.`;
export const ALT_TOPIC_3_1 = `An illustrative thumbnail of the ICD Management Portal home page. The Menu button is highlighted`;
export const ALT_TOPIC_3_2 = `An illustrative thumbnail of the ICD Management Portal home page with the navigation bar open. The Courses tab is highlighted in the navigation bar.`;
export const ALT_TOPIC_3_3 = `An illustrative thumbnail of the Available Courses page.`;
export const ALT_TOPIC_3_4 = `An illustrative thumbnail of the View Course page. The View button that corresponds with the first course in the list is highlighted.`;
export const ALT_TOPIC_3_5 = `An illustrative thumbnail of the View Course page for FD190. The page contains the FDA course number, course title, course delivery mode, and course description.`;
export const ALT_TOPIC_3_6 = `An illustrative thumbnail of the View Course page for FD190. The page contains the FDA course number, course title, course delivery mode, and course description. The Back button is highlighted.`;
export const ALT_TOPIC_4_1 = `An illustrative thumbnail of the ICD Management Portal home page. The Bell button is highlighted.`;
export const ALT_TOPIC_4_2 = `An illustrative thumbnail of the ICD Management Portal home page. The unread notification is highlighted.`;
export const ALT_TOPIC_5_1 = `An illustrative thumbnail of the ICD Management Portal home page. The Menu button is highlighted.`;
export const ALT_TOPIC_5_2 = `An illustrative thumbnail of the ICD Management Portal home page with the navigation bar open. The Applications tab is highlighted in the navigation bar.`;
export const ALT_TOPIC_5_3 = `An illustrative thumbnail of the Applications page with current ICD applications listed.`;
export const ALT_TOPIC_5_4 = `An illustrative thumbnail of the Applications page. The Messages button is highlighted.`;
export const ALT_TOPIC_5_5 = `An illustrative thumbnail of the Messages page.`;
export const ALT_TOPIC_5_6 = `An illustrative thumbnail of the Messages page. The Send and Attachment button is highlighted.`;
export const ALT_TOPIC_6_1 = `An illustrative thumbnail of the ICD Management Portal home page. The Menu button is highlighted.`;
export const ALT_TOPIC_6_2 = `An illustrative thumbnail of the ICD Management Portal home page with the navigation bar open. The Applications tab is highlighted in the navigation bar.`;
export const ALT_TOPIC_6_3 = `An illustrative thumbnail of the ICD Management Portal Applications page.`;
export const ALT_TOPIC_6_4 = `An illustrative thumbnail of the ICD Management Portal Applications page. The View/Edit button of an existing application is highlighted.`;
export const ALT_TOPIC_6_5 = `An illustrative thumbnail of the ICD Application. The Save and Continue button is highlighted.`;
export const ALT_TOPIC_6_6 = `An illustrative thumbnail of the ICD Application. The Back and Next button is highlighted.`;
export const ALT_TOPIC_7_1 = `An illustrative thumbnail of the ICD Management Portal home page. The View Report button is highlighted.`;
export const ALT_TOPIC_7_2 = `An illustrative thumbnail of the Application Status Report page.`;
export const ALT_TOPIC_7_3 = `An illustrative thumbnail of the Application Status page. The View link is highlighted.`;
export const ALT_TOPIC_7_4 = `An illustrative thumbnail of the Application Status page. The Export Report as PDF button is highlighted.`;
export const ALT_TOPIC_8_1 = `An illustrative thumbnail of the ICD Management Portal login page.`;
export const ALT_TOPIC_8_2 = `An illustrative thumbnail of an automatic email sent to a user after logging in. A one-time verification code is highlighted.`;
export const ALT_TOPIC_8_3 = `An illustrative thumbnail of the ICD Management Portal login page; the six-digit code entry field is visible. The entry field for the six-digit code is highlighted, as well as the resend code link.`;
export const ALT_TOPIC_8_4 = `An illustrative thumbnail of the ICD Management Portal login page. The "Don't ask me again for 24 hours" check box is highlighted.`;
export const ALT_TOPIC_9_1 = `An illustrative thumbnail of the ICD Management Portal login page. The Forgot Password link is highlighted.`;
export const ALT_TOPIC_9_2 = `An illustrative thumbnail of ICD Management Portal Forgot Password page. The email address entry field is highlighted.`;
export const ALT_TOPIC_9_3 = `An illustrative thumbnail of the ICD Management Portal login page; the six-digit code entry field is visible. The entry field for the six-digit code is highlighted, as well as the resend code link.`;
export const ALT_TOPIC_9_4 = `An illustrative thumbnail of the ICD Management Portal Reset Password page; the current password entry field is highlighted.`;
export const ALT_TOPIC_9_5 = `An illustrative thumbnail of the ICD Management Portal Reset Page. The reset successful message is highlighted.`;
export const ALT_TOPIC_9_6 = `An illustrative thumbnail of the ICD Management Portal Reset Password page. In the "Reset Successful" message box. The Login hyperlink is highlighted.`;
export const ALT_TOPIC_10_1 = `An illustrative thumbnail of the ICD Management Portal home page. The Menu button is highlighted. `;
export const ALT_TOPIC_10_2 = `An illustrative thumbnail of the ICD Management home page with the navigation bar open. The Applications tab is highlighted.`;
export const ALT_TOPIC_10_3 = `An illustrative thumbnail of the Applications page. In the Status column of the Applications page, the Processed status is highlighted.`;
export const ALT_TOPIC_10_4 = `An illustrative thumbnail of the Applications page. Under the Actions column, the Course Materials button is being highlighted. `;
export const ALT_TOPIC_10_5 = `An illustrative thumbnail of the View Course Materials page. The Course Materials section of the page title is highlighted.  `;
export const ALT_TOPIC_10_6 = `An illustrative thumbnail of the Applications page. The Messages button is highlighted in the Messages column.`;
export const ALT_TOPIC_11_1 = `An illustrative thumbnail of the ICD Management Portal home page. The Menu button is highlighted.`;
export const ALT_TOPIC_11_2 = `An illustrative thumbnail of the ICD Management Portal home page with the navigation bar open. The Course Fulfillment tab is highlighted.`;
export const ALT_TOPIC_11_3 = `An illustrative thumbnail of the Course Fulfillment page.`;
export const ALT_TOPIC_11_4 = `An illustrative thumbnail of the Course Fulfillment page. The View/Edit Course Materials button that corresponds with the first course, in the list, is highlighted.`;
export const ALT_TOPIC_11_5 = `An illustrative thumbnail of the View/Edit Course Materials page.`;
export const ALT_TOPIC_11_6 = `An illustrative thumbnail of the View/Edit Course Materials page. The Add File(s) to Queue button is highlighted.`;
export const ALT_TOPIC_11_7 = `An illustrative thumbnail of a pop-up window. The Open button is highlighted.`;
export const ALT_TOPIC_11_8 = `An illustrative thumbnail of the View/Edit Course Materials page. The rename file and description fields is highlighted.`;
export const ALT_TOPIC_11_9 = `An illustrative thumbnail of the View/Edit Course Materials page. The Upload File(s) button is highlighted.`;
export const ALT_TOPIC_11_10 = `An illustrative thumbnail of the View/Edit Course Materials page. The expand arrow of a course is highlighted.`;
export const ALT_TOPIC_11_11 = `An illustrative thumbnail of the View/Edit Course Materials page that displays the course detail box. The trash can icon is highlighted within the course detail box.`;
export const ALT_TOPIC_11_12 = `An illustrative thumbnail of the View/Edit Course Materials page that displays the course detail box. The pencil icon is highlighted within the course detail box.`;
export const ALT_TOPIC_11_13 = `An illustrative thumbnail of the View/Edit Course Materials page. The Save button is highlighted.`;
export const ALT_TOPIC_12_1 = `An illustrative thumbnail of the ICD Management Portal Home page. The Menu button is highlighted.`;
export const ALT_TOPIC_12_2 = `An illustrative thumbnail of the ICD Management Portal Home page that displays the menu bar. The Instructor tab is highlighted.`;
export const ALT_TOPIC_12_3 = `An illustrative thumbnail of the ICD Management Portal Manage Instructors page.`;
export const ALT_TOPIC_12_4 = `An illustrative thumbnail of the ICD Management Portal Manage Instructor page. The Bulk Instructor Upload button is highlighted.`;
export const ALT_TOPIC_12_5 = `An illustrative thumbnail of the ICD Management Portal Bulk Import Instructors page. The Select Course button is highlighted.`;
export const ALT_TOPIC_12_6 = `An illustrative thumbnail of the Select Course pop-up window. The Select button that corresponds with the first course in the list is highlighted.`;
export const ALT_TOPIC_12_7 = `An illustrative thumbnail of the Bulk Import Instructor Template.`;
export const ALT_TOPIC_12_8 = `An illustrative thumbnail of a pop-up window in front of the Bulk Import Instructor page. On the Bulk Import Instructor page, the Select File button is highlighted. On the pop-up window, the Open button is highlighted.`;
export const ALT_TOPIC_12_9 = `An illustrative thumbnail of the Bulk Import Instructor page. The Import button is highlighted.`;
export const ALT_TOPIC_12_10 = `An illustrative thumbnail of the Bulk Import Instructor page with a message stating that the import was successful.`;

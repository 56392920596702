import React, {useState, useEffect, useRef, useContext } from 'react';
import { useHistory } from "react-router-dom";
import ViewTitle from './ViewTitle';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { getAdapter } from './adapters/adapter';
import theme, {CreateButton} from './theme/PortalTheme';
import LoadingIndicator from './LoadingIndicator';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { idToGuidMapper } from './Utility';
import { StatusMessageContext } from './StatusMessage';
import { getLoggedInRole } from './adapters/baseAdapter';

export function CourseGrid(props){
	//Course Columns:
	const COURSE_COLUMNS = [
		{ field: 'courseNumber', headerName: 'Course Number', minWidth:140, disableColumnMenu: true, resizeable: true },
		{ field: 'courseTitle', headerName: 'Course Title', flex:1.5, disableColumnMenu: true, resizeable: true },
		{ 
			field: 'isVirtualILT', 
			headerName: 'Delivery Mode',
			width: 150,
			disableColumnMenu: true,
			resizeable:true,
			renderCell: function(params){
				return(
					params.row.isVirtualILT ? "vILT" : "ILT"
				);
			}
		}
	];
	if (props.actionsColumnRenderCell != null){
		COURSE_COLUMNS.push({
			field: "",
			filterable:false,
			disableColumnMenu:true,
			flex:1,
			headerName: "Actions",
			sortable: false,
			disableClickEventBubbling: true,
			renderCell: props.actionsColumnRenderCell
		});
	}

	return(
		<DataGrid
			columns = {COURSE_COLUMNS}
			initialState={{
				sorting: {
				  sortModel: [{ field: 'courseNumber', sort: 'asc' }],
				}
			}}			
			disableSelectionOnClick = {true}
			components = {{Toolbar:GridToolbar}}		
			density = "compact"
			autoHeight
			pageSize = {10}
			rowsPerPageOptions = {[10, 25, 50]}
			{...props}		
		/>
	);
}

export default function CourseFulfillment() {
	var stateRefs = {};
	const history = useHistory();

	//States related to data fetching:
	const [isLoading, setIsLoading] = stateRefs.isLoading = useState(false);
	const [courses, setCourses] = useState([]);
	// eslint-disable-next-line	
	const [selected, setSelected] = stateRefs.selected = useState([]);
	// eslint-disable-next-line
	const [pleaseWait, setPleaseWait] = stateRefs.pleaseWait = useState(false);
	stateRefs.deleteDisabled = useState(true);
	//simplest solution to retrigger the onload useeffect and properly cleanup from a button press
	const [updateGrid, setUpdateGrid] = useState(0);
    //Status message:
	var { StatusManager } = useContext(StatusMessageContext);

	const isMounted = useRef(false);
	useEffect(() => {
		isMounted.current = true;
		return () => { isMounted.current = false }
	}, []);

	useEffect(() => {

        if(isMounted.current) {
            if(!["oted_ff", "oted_pa", "superadmin"].includes(getLoggedInRole())){
                StatusManager.setStatus("Access denied. You have been returned to the dashboard home screen.");
                history.push("/dashboard");
            }
		}

		async function fetchData(){
			setIsLoading(true);
			try {
				var adapter = await getAdapter();
				var courseList = await adapter.course.list();
			}
			catch (error) {
				console.error(error);	
			}
			if(isMounted.current){
				courseList = courseList.map(idToGuidMapper);
				//Fix visual display of empty fields to say "Incomplete":
				courseList.map(course=>{
					Object.entries(course).forEach(([key,value])=>{
						if(typeof(value)==="string" && value.length === 0){
							course[key] = "Incomplete"
						}					
					});
					return course;
				});
				setCourses(courseList);
				setIsLoading(false);
			}
		}
		
		fetchData();

	}, [setIsLoading, updateGrid]);

	async function onCreateCourseBtn(){
		history.push("/dashboard/courses/create/");
	};

	function actionsColumnRenderCell(params){		

        function onViewBtn(params){
			var courseId = params.guid;
			history.push("/dashboard/courses/materials/" + courseId);
		}

		async function onDeleteBtn(params){
			var success = true;
			setIsLoading(true);
			try{
				var courseId = params.guid;
				var adapter = await getAdapter();
				await adapter.course.delete(courseId);
			}
			catch(error){
				success = false;
				console.error(error);		
			}
			if(isMounted.current) {
				var successStr = success ? "successful." : "failed.";
				console.log("Delete " + successStr );
				StatusManager.setStatus("Delete course " + successStr);
				setUpdateGrid(updateGrid + 1);
				setIsLoading(false);				
			}	
		}

		const buttonSpacing = theme.spacing(1);

		//to access course data in callback (eg params.row.guid), pass the row to the callback
		return (
			<React.Fragment>

				<button
					style={{marginRight:buttonSpacing, cursor:"pointer"}}
					onClick={() => onViewBtn(params.row)}
				>
					View/Edit Course Materials
				</button>
			</React.Fragment>
		);
	}

	var creatingApplicationMarkup = (
		<>
			<Grid container justifyContent="center" p={4}>
				<Typography variant="h6" color={"primary"}>Creating Application...</Typography>
				<LoadingIndicator padding={4}/>
			</Grid>
		</>
	);	

	return (
		<Container maxWidth="lg">
			{
				pleaseWait ? creatingApplicationMarkup :  
				<>
					<Paper style={{minHeight:'70vh'}} padding="large">				
						<ViewTitle>Course Fulfillment</ViewTitle>					
						<p style={{marginBottom:0}}>Listed below are the courses that may be delivered as an Indepedent Course Delivery (ICD).</p>
						<ul>	
							<li>Select the <strong>View/Edit Course Materials</strong> button to view or edit materials for a course.</li>
						</ul>
						<CourseGrid
							actionsColumnRenderCell = {actionsColumnRenderCell}
							isMounted = {isMounted}
							stateRefs = {stateRefs}
							loading = {isLoading}
							rows = {courses}
						/>
					</Paper>	
				</>				
			}
		</Container>
	);
}

import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory } from "react-router-dom";
import ViewTitle from './ViewTitle';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { getAdapter} from './adapters/adapter';
import theme, { DeleteButton, CreateButton } from './theme/PortalTheme';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import LoadingIndicator from './LoadingIndicator';
import { StatusMessageContext } from './StatusMessage';
import { handleError } from './adapters/utils';
import { getLoggedInRole } from './adapters/baseAdapter';
import ConfirmationDialog from './ConfirmationDialog';
import moment from 'moment';

export function ApplicationGrid(props){
	const { excludeColumns = []} = props;
	//Application Columns:
	const APPLICATION_COLUMNS = [
		{ field: 'id', headerName: 'ID', width: 100, disableColumnMenu: true, resizeable: true, hide: true },
		{ field: 'status', headerName: 'Status', width: 100, disableColumnMenu: true, resizeable: true},
		{ field: 'course', headerName: 'Course', width: 100, disableColumnMenu: true, resizeable: true },
		{ field: 'organization', headerName: 'Organization', flex:2, minWidth:150,  disableColumnMenu: true, resizeable: true, hide: excludeColumns.includes("organization")},		
		{ field: 'startDate', headerName: 'Start', width: 100, disableColumnMenu: true, resizeable: true, hide:false },
		{ field: 'endDate', headerName: 'End', width: 100, disableColumnMenu: true, resizeable: true, hide:true },
		{ field: 'poc', headerName: 'Point of Contact', minWidth:250, disableColumnMenu: true, resizeable:true},
	];

	if (['oted_pa','superadmin','applicant_poc'].includes(props.role)) 
		APPLICATION_COLUMNS.push({ 
			field: 'messages', 
			headerName: 'Messages', 
			flex:1, minWidth:90, 
			disableColumnMenu: true, 
			resizeable:true,
			renderCell: props.actionsColumnRenderCellMessage
		});
	if (['oted_pa','superadmin'].includes(props.role)) 
		APPLICATION_COLUMNS.push({ 
			field: 'inernalMessages', 
			headerName: 'Internal Messages', 
			flex:1, 
			minWidth:150, 
			disableColumnMenu: true, 
			resizeable:true,
			renderCell: props.actionsColumnRenderCellInternalMessage
		});
	if (props.actionsColumnRenderCell != null) 
		APPLICATION_COLUMNS.push({
			field: "",
			filterable:false,
			disableColumnMenu:true,
			flex: 1, minWidth:320,
			headerName: "Actions",
			sortable: false,
			disableClickEventBubbling: true,
			renderCell: props.actionsColumnRenderCell
		});

	return(
		<DataGrid
			columns = {APPLICATION_COLUMNS}
			initialState={{
				sorting: {
				  sortModel: [{ field: 'status', sort: 'desc' }],
				}
			}}
			disableSelectionOnClick = {true}
			components = {{Toolbar:GridToolbar}}		
			density = "compact"
			autoHeight
			pageSize = {10}

			rowsPerPageOptions = {[10, 25, 50]}
			{...props}		
		/>
	);
}

export default function ManageApplications() {
	
	const history = useHistory();
	const [deleteDisabled, setDeleteDisabled] = useState(true);
	// eslint-disable-next-line
	const [isLoading, setIsLoading] = useState(false);
	const [pleaseWait, setPleaseWait] = useState(false);
	const [applications, setApplications] = useState([]);
	const [selected, setSelected] = useState([]);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	//simplest solution to retrigger the onload useeffect and properly cleanup from a button press
	const [updateGrid, setUpdateGrid] = useState(0);
	
    //Status message:
	var { StatusManager } = useContext(StatusMessageContext);

	const createUrl = "/dashboard/applications/view_edit/";
	
	const isMounted = useRef(false);
	useEffect(() => {
		isMounted.current = true;
		return () => { isMounted.current = false }
	}, []);

	useEffect(() => {
		if(isMounted.current){
			setApplications([]);
			//Must be an "applicant_poc" to view this page:
			if(!["oted_pa", "superadmin"].includes(getLoggedInRole())){
				StatusManager.setStatus("Access denied. You have been returned to the dashboard home screen.");
				history.push("/dashboard");
			}			
		}
		async function fetchData(){
			if(isMounted.current){
				setIsLoading(true);
			}
			try {
				var adapter = await getAdapter();
				var applications = await adapter.application.getApplicationMeta({startDate: 0, endDate:0});
			}
			catch (error) {
				console.error(error);
			}
			if(isMounted.current) {
				var statusMap = {
					"in-progress" : "In-Progress",
					"submitted" : "Submitted",
					"processed" : "Processed",					
					"approved" : "Approved",
					"closeout": "Closeout",
					"completed" : "Completed"
				}

				applications.forEach((e) => {
					e.id = e.guid;
					e.status = statusMap[e.status] || "N/A";
					e.organization = e.organization || "Incomplete";
					e.startDate = e.startDate ? new Date(e.startDate).toLocaleDateString() : "Not Specified";
					e.endDate = e.endDate ? new Date(e.endDate).toLocaleDateString() : "Not Specified";
					e.poc = e.poc || "Incomplete";
					e.course = e.course || "Incomplete";
				});
				setApplications(applications);
				setIsLoading(false);
			}
		}

		fetchData();

	}, [updateGrid]);

	function onSelectionChange(selectionModel) {
		if(setDeleteDisabled != null) {
			setDeleteDisabled(selectionModel.length === 0);
		}
		setSelected(selectionModel);
	}

	async function onCreateApplicationBtn(){
		if(isMounted.current){
			setPleaseWait(true);
		}
		try{
			var adapter = await getAdapter();
			var applicationId = await adapter.application.create();
			history.push(createUrl + `${applicationId}/1`);	
		}
		catch(error){
			if(isMounted.current){
				setPleaseWait(false);
				setIsLoading(false);
				handleError(error, StatusManager);
			}
		}
	}

	function onDeleteApplicationBtn(){
		setDeleteDialogOpen(true);
	}

	function onConfirmDelete(){
		deleteApplication();
	}

	async function deleteApplication(){
		var success = true;
		setIsLoading(true);
		try{
			var adapter = await getAdapter();
			await adapter.application.delete(selected);
		}
		catch(error){
			console.error(error);
			success = false;
		}
		if(isMounted.current) {
			var successStr = success ? "successful." : "failed.";
			console.log("Delete " + successStr );
			StatusManager.setStatus("Delete application " + successStr);
			setUpdateGrid(updateGrid + 1);
			setIsLoading(false);
		}
	}

	function actionsColumnRenderCell(params){
		function onViewEditBtn(params){
			var applicationId = params.id;
			history.push("/dashboard/applications/view_edit/" + applicationId + "/1");
		}
		
		function onCloseoutBtn(params){
			var applicationId = params.id;
			history.push("/dashboard/applications/closeout/" + applicationId );			
		}

		function onCourseMaterialsBtn(params){
			var courseId = params.courseID;
			history.push("/dashboard/courses/materials/" + courseId );
		}		

		function canViewCourseMaterials(params){
			//There must be a course for the application:
			var cond1 = (params.course && params.courseID);
			return cond1;
		}
		
		let showCloseout =  false; //["Approved", "Closeout"].includes(params.row.status);

		return (
			<React.Fragment>
				<button
					style={{marginRight:theme.spacing(1), cursor:"pointer"}}
					onClick={() => onViewEditBtn(params.row)}
				>
					View/Edit
				</button>
				{ canViewCourseMaterials(params.row) &&
				<button
					style={{marginRight:theme.spacing(1), cursor:"pointer"}}
					onClick={() => onCourseMaterialsBtn(params.row)}
				>
					Course Materials
				</button> }				
				{ showCloseout &&
				<button
					style={{marginRight:theme.spacing(1), cursor:"pointer"}}
					onClick={() => onCloseoutBtn(params.row)}
				>
					Closeout
				</button> }
			</React.Fragment>
		);
	}

	function actionsColumnRenderCellMessage(params){
		function onViewEditBtn(params){
			var applicationId = params.id;
			history.push("/dashboard/message/messages/" + applicationId);
		}

		return (
			<React.Fragment>
				<button
					style={{marginRight:theme.spacing(1), cursor:"pointer"}}
					onClick={() => onViewEditBtn(params.row)}
				>
					Messages
				</button>
			</React.Fragment>
		);
	}

	function actionsColumnRenderCellInternalMessage(params){
		function onViewEditBtn(params){
			var applicationId = params.id;
			history.push("/dashboard/message/internalmessages/" + applicationId);
		}

		return (
			<React.Fragment>
				<button
					style={{marginRight:theme.spacing(1), cursor:"pointer"}}
					onClick={() => onViewEditBtn(params.row)}
				>
					Internal Messages
				</button>
			</React.Fragment>
		);
	}

	var creatingApplicationMarkup = (
		<>
			<Grid container justifyContent="center" p={4}>
				<Typography variant="h6" color={"primary"}>Creating Application...</Typography>
				<LoadingIndicator padding={4}/>
			</Grid>
		</>
	);

	return (
		<Container maxWidth="xl">
			{
				pleaseWait ? creatingApplicationMarkup :  
				<>
					<Paper style={{minHeight:'70vh'}} padding="large">
						<ViewTitle>Manage Applications</ViewTitle>
						{/*Delete Confirmation Dialog*/}
						<ConfirmationDialog
							open={deleteDialogOpen}
							setOpen={setDeleteDialogOpen}
							dialogTitle="Confirm Deletion"
							dialogContent="Are you sure you want to delete?"
							leftButtonLabel="No"
							leftButtonCallback={()=>{}}
							rightButtonLabel="Yes"
							rightButtonCallback={onConfirmDelete}
						/>
						<p style={{marginBottom:0}}>Listed below are the current Independent Course Delivery (ICD) Applications.</p>
						<ul>
							<li style={{marginBottom:theme.spacing(1)}}>Select the <strong>Create Application</strong> button to initiate a new ICD Application.</li>
							<li style={{marginBottom:theme.spacing(1)}}>After selecting the checkbox next to one or more ICD Applications from the list, select the <strong>Delete Application</strong> button to delete the selected application(s).
							<ul><li style={{marginTop:theme.spacing(1)}}><strong>NOTE:</strong> This will permanently delete the selected application(s).</li></ul></li>											
							<li style={{marginBottom:theme.spacing(1)}}>Select the <strong>View/Edit</strong> button to view or edit the corresponding ICD Application.</li>
							<li style={{marginBottom:theme.spacing(1)}}>Select the <strong>Messages</strong> button to message the Organization POC and access the message history related to the corresponding ICD Application.</li>
						</ul>										
						<Grid container justifyContent="flex-end">
							<CreateButton onClick={onCreateApplicationBtn}>Create Application</CreateButton>
							<DeleteButton onClick={onDeleteApplicationBtn} disabled={deleteDisabled}>Delete Application</DeleteButton>					
						</Grid>
						<ApplicationGrid
							actionsColumnRenderCell = {actionsColumnRenderCell}
							actionsColumnRenderCellMessage = {actionsColumnRenderCellMessage}
							actionsColumnRenderCellInternalMessage = {actionsColumnRenderCellInternalMessage}
							loading = {isLoading}
							rows = {applications}
							checkboxSelection={true}
							role={getLoggedInRole()}
							onSelectionModelChange={onSelectionChange}
						/>
					</Paper>
				</>				
			}
		</Container>
	);
}

import axios from 'axios';
import {parseResponse, appRoute, API_URL} from "../utils";
import { getAdapter } from '../adapter';

function qualifiedRoute(appID)
{
  return appRoute(appID) + "/qualified";
}

/** adds an existing known qualified instructor to the application */
export async function add(applicationID, instructors)
{
  return parseResponse(await axios.post(qualifiedRoute(applicationID), {instructors: instructors}, (await getAdapter()).getAuthHeader));
}

export async function set(applicationID, instructors)
{
  //coerce to array
  instructors = Array.isArray(instructors) ? instructors : [instructors];

  //this logic should be implemented as a single route in the backend
  await clear(applicationID);
  return add(applicationID, instructors);
}

export async function list(applicationID)
{
  var instructors = parseResponse(await axios.get(qualifiedRoute(applicationID), (await getAdapter()).getAuthHeader));
  return instructors;
}

/** clears the list of qualified instructors from the given application */
export async function clear(applicationID)
{
  return parseResponse(await axios.delete(qualifiedRoute(applicationID), (await getAdapter()).getAuthHeader));
}

import React, {useContext, useEffect, useRef, useState } from 'react';
import { Container, Grid, Paper } from '@mui/material';
import ViewTitle from './ViewTitle';
import BetterFormField from './FormField';
import { FormSection, FormSectionHeading } from "./theme/PortalTheme";
import { getAdapter } from './adapters/adapter';
import LoadingIndicator from './LoadingIndicator';
import SavingIndicator from './SavingIndicator';
import { getLoggedInRole } from './adapters/baseAdapter';
import { useHistory, useParams, generatePath } from "react-router-dom";
import useForm from './UseForm';
import { BackButton, DownloadButton, SaveButton, UploadButton } from './theme/PortalTheme';
import { StatusMessageContext } from './StatusMessage';
import { FileEntry } from './ICDApplicationForm_ApplicationStatus';
import { handleInvalidPermissions } from './Utility';
export default function ICDApplicationFormCloseout(){

    const {applicationId} = useParams();
	const history = useHistory();

	//Is component currently mounted?
	const isMounted = useRef(false)
	useEffect(() => {
		isMounted.current = true;
		return () => { isMounted.current = false }
	}, []);

    //Status message:
	var { StatusManager } = useContext(StatusMessageContext);
	
	//Loading/Saving states:
	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
    const [isUploading, setIsUploading] = useState(false);	
	const [applicationInfo, setApplicationInfo] = useState({});
	const [deliveryInfo, setDeliveryInfo] = useState({});

    //Update signal:
    var [updateSignal, setUpdateSignal] = React.useState(0);	
	
    //Reference to the hidden file input element:
    const hiddenFileInputReport = React.useRef(null);
    const hiddenFileInputResponse = React.useRef(null);

	//Form validation:
	function validate(){
		let _errors = { ...errors };
		console.log("Validating");
		console.log(formValues);
		
		var intParticipants = parseInt(formValues.actualParticipants, 10);


		_errors.actualParticipants = (!isNaN(intParticipants)) ? "" : "Please enter the number of actual participants who completed this course delivery."
		
		setErrors({
			..._errors
		});

		var result = Object.values(_errors).every(x => x === "");
		console.log("Validation " + (result ? "SUCCESS" : "FAILED"));
		return result;	
	}

	//Initial Form Values:
	const initialFormValues = {
		actualParticipants: "",
		certificatesAllowed: ""
	};

	//Form-related vars:
	const {
		formValues,
		setFormValue,
		errors,
		setErrors,
		handleInputChange,
	} = useForm(initialFormValues, false, validate);

	//Create refs to mutable objects that would otherwise be passed as dependencies to useEffect hook, to prevent triggering re-renders unnecessarily.
	const setFormValueRef = useRef(setFormValue);

    //Reference to the hidden file input element:
    const hiddenFileInput = React.useRef(null);

	//On view load:
	useEffect(() => {

		//Must be an "applicant_poc" to view this page:
		if(getLoggedInRole() != "applicant_poc"){
			StatusManager.setStatus("Access denied. You have been returned to the dashboard home screen.");
			history.push("/dashboard");
		}

		let setFormValue = setFormValueRef.current;
		async function fetchData(){
			setIsLoading(true);
			try {
				let adapter = await getAdapter();
				let _applicationInfo = await adapter.application.get(applicationId);			
				let _deliveryInfo = await adapter.application.delivery.get(applicationId);

				//Must be in "completed" or "closeout" status to view this page:
				if(!["completed", "closeout"].includes(_applicationInfo.status)){
					StatusManager.setStatus("Access denied. You have been returned to the dashboard home screen.");
					history.push("/dashboard")					
				}

                if(isMounted.current){
					let statusMap = {
						"in-progress" : "In-Progress",
						"submitted" : "Submitted",
						"approved" : "Approved",
						"processed" : "Processed",
						"closeout" : "Closeout",
						"completed" : "Completed"
					}
					_applicationInfo.status = statusMap[_applicationInfo.status];
					console.log(_applicationInfo);
					console.log(_deliveryInfo);
                    setApplicationInfo(_applicationInfo);
					setFormValue("actualParticipants", _deliveryInfo.actualParticipants || "");
					setFormValue("certificatesAllowed", _deliveryInfo.certificatesAllowed || "");
					setDeliveryInfo(_deliveryInfo);
                }
			}
			catch (error) {
				handleInvalidPermissions(error, history, StatusManager);
				console.error(error);
			}
			if(isMounted.current){
				setIsLoading(false);
			}
		}
		
		fetchData();

	}, [applicationId, updateSignal]);	

	function onSaveBtn(){
		if(validate()){		
			saveData(()=>{
				//onBackBtn();
			});
		}
	}

    //Reset file picker:
    function resetFilePicker(e){
        e.target.value = null;
    }	

	//Save:
	async function saveData(successCallback = () => {} ){
		console.log("Updating information for [ applicationId: " + applicationInfo.guid + " ]");
		var success = true;
		if(isMounted.current){
			setIsSaving(true);
		}
		try {
			var adapter = await getAdapter();
			let deliveryInfo = {
				actualParticipants: (formValues.actualParticipants == null) ? null : parseInt(formValues.actualParticipants, 10)
			};			
			await adapter.application.delivery.update(applicationInfo.guid, deliveryInfo);
		} 
		catch (error) {
			success = false;
			console.error(error);
		}
		var successStr = success ? "successful." : "failed.";
		if (success) StatusManager.setStatus("Save application closeout information " + successStr);
		if(isMounted.current){
			setIsSaving(false);
			if(success){
				successCallback();
			}
		}
	}

	function onBackBtn(){
		let role = getLoggedInRole();
		if(["oted_pa", "superadmin"].includes(role)){
			history.push("/dashboard/applications/manage/");
		}
		else if (["applicant_poc"].includes(role)){
			history.push("/dashboard/applications/list/");
		}
	}

    async function uploadOEReport(file){
        console.log("UPLOAD REPORT");
        var success = true;
        if(isMounted.current){
            setIsUploading(true);
        }
        try{
            let adapter = await getAdapter();
            let response = await adapter.application.delivery.uploadOtedOeReport(applicationId, file);                          
        }
        catch(error){
			StatusManager.setStatus(`There was an error uploading the OE report: ${error.response?.data?.data?.response}`);
            console.log(error);
			//handleError(error, StatusManager, error.response?.data?.data?.response);
            success = false;
        }
        if(isMounted.current){
            setIsUploading(false);
            setUpdateSignal(updateSignal + 1);
        }
    }

    async function uploadOEResponse(file){
        console.log("UPLOAD RESPONSE");
        var success = true;
        if(isMounted.current){
            setIsUploading(true);
        }

        try{
            let adapter = await getAdapter();
            let response = await adapter.application.delivery.uploadOeResponse(applicationId, file);                          
        }
        catch(error){
			StatusManager.setStatus(`There was an error uploading the OE response: ${error.response?.data?.data?.response}`);
            console.log(error);
			//handleError(error, StatusManager, error.response?.data?.data?.response);
            success = false;
        }
        if(isMounted.current){
            setIsUploading(false);
            setUpdateSignal(updateSignal + 1);
        }
    }    

    //When a file is selected:
    async function handleChangeOEReport(e){
        let filesToUpload = e.target.files;
        if(!filesToUpload || filesToUpload.length == 0) return;
        
        let fileToUpload = filesToUpload[0];
        var re = /(?:\.([^.]+))?$/;
        var extension = re.exec(fileToUpload.name)[1];
        if(extension){
            if(['pdf'].includes(extension.toLowerCase())) {
                //Upload begins here:
                await saveData();
                await uploadOEReport(fileToUpload);
            }
        }
    }

    async function handleChangeOEResponse(e){
        let filesToUpload = e.target.files;
        if(!filesToUpload || filesToUpload.length == 0) return;
        
        let fileToUpload = filesToUpload[0];
        var re = /(?:\.([^.]+))?$/;
        var extension = re.exec(fileToUpload.name)[1];
        if(extension){
            if(['pdf'].includes(extension.toLowerCase())) {
                //Upload begins here:
                await saveData();
                await uploadOEResponse(fileToUpload);
            }
        }
    } 	

    async function onDeleteOeReport(){
        try{
            let adapter = await getAdapter();
            let response = await adapter.application.delivery.deleteOtedOeReport(applicationId);
        }
        catch(error){
            console.log(error);
        }
    }

    async function onDeleteOeResponse(){
        try{
            let adapter = await getAdapter();
            let response = await adapter.application.delivery.deleteOeResponse(applicationId);
        }
        catch(error){
            console.log(error);
        }        
    }


    async function onUploadOEReportBtn(e){
        hiddenFileInputReport.current.click();
    }

    async function onUploadOEResponseBtn(e){
        hiddenFileInputResponse.current.click();
    }
	
	async function onGenerateCertificatesBtn(e){
		try{
			let adapter = await getAdapter();
			var response = await adapter.application.getLatestCertificationFile(applicationId);
			let blob=new Blob([response.data]);
			let link=document.createElement('a');
			let url=window.URL.createObjectURL(blob); 
			link.href = url;
			link.download=`applicationCert_${applicationId}.pdf`;
			link.click();
			link.remove();
			setTimeout(() => window.URL.revokeObjectURL(url), 100);
		} 
		catch(err){
			//TODO handle this, give to status manager or something
			console.error(err);
		}
	}

	//Application info is available, application status is a valid closeout status, and user has permissions to view this screen:
	let isCloseoutUnlocked = (
		applicationInfo != null && 
		applicationInfo.status != null && 
		["Approved", "Closeout", "Completed"].includes(applicationInfo.status) && 
		["applicant_poc", "oted_pa", "superadmin"].includes(getLoggedInRole())
	);

	//Conditional props for Actual Participants:
	let conditionalProps = ["applicant_poc", "superadmin"].includes(getLoggedInRole()) ? {} : {variant: "filled", readOnly: true};

    //Hide OE-related features (set to "false" to show O&E-related functionality):
    const hideOEFeatures = true;

	//Render:
	return (
		<Container component="main" maxWidth="lg">
			<Paper style={{minHeight:'70vh'}} padding="large">
				<Grid container alignItems="center" justifyContent="center">
					<ViewTitle>Closeout</ViewTitle>
				</Grid>
				{ isLoading ? <LoadingIndicator/> : 
                <>
					{ isCloseoutUnlocked &&
						<>
							{/* <FormSectionHeading>Participant Number Confirmation</FormSectionHeading>
							<FormSection>
								<p>Once the delivery of the ICD course is complete, specify the actual number of participants that successfully completed the course in the following field. OTED requires this information to generate Certificates of Completion.</p>
								<Grid container spacing={1}>
									<Grid item xs={4}>
										<BetterFormField 
											{...conditionalProps}
											InputLabelProps={{ shrink: true }}
											required
											maxLength={3}
											label="Actual Number of Participants Completed" 
											name="actualParticipants" 
											value={formValues.actualParticipants} 
											error={errors.actualParticipants}
											onChange={(e)=>{
												
												//	Only allow numeric characters. 
												//	This is necessary, because type="number" on an MUI TextField still allows mathematical alphabet characters such as "e" per the HTML5 technical spec. 
												//	Furthermore, when an MUI TextField has type="number" set, it ignores max character length restrictions.
												//
												const re = /^[0-9\b]+$/;
												// if value is not blank, then test the regex
												if (e.target.value === '' || re.test(e.target.value)) {
												setFormValue(e.target.name, e.target.value);
												}
											}}
										/>
									</Grid>
								</Grid>								
							</FormSection> */}
							{ !hideOEFeatures && deliveryInfo.oeReportRequired != null && deliveryInfo.oeReportRequired == true &&
							<>
								<FormSectionHeading>Read OTED's O&E Report</FormSectionHeading>
								<FormSection>
									<p>If an OTED Observation and Evaluation (O&E) Team was present at the delivery of this ICD course, OTED will provide you with an O&E Report that summarize the course delivery. When it becomes available, a .PDF copy can be downloaded below.</p>
                                    { deliveryInfo.otedOeReport != null &&
                                        <FileEntry
                                            id="oeReport"
                                            applicationId={applicationId}
                                            file={deliveryInfo.otedOeReport}
                                            canDelete={["oted_pa", "superadmin"].includes(getLoggedInRole())}
                                            updateSignal={updateSignal}
                                            setUpdateSignal={setUpdateSignal}
                                            onDelete={onDeleteOeReport}
                                        />
                                    }
                                    { !isUploading && deliveryInfo.otedOeReport == null && ["oted_pa", "superadmin"].includes(getLoggedInRole()) &&
										<>
											<Grid container alignItems="center" spacing={1}>                
												<Grid item><UploadButton disabled={isUploading} onClick={onUploadOEReportBtn}>Upload File</UploadButton></Grid>
											</Grid>
											<input
												type="file"
												ref={hiddenFileInputReport}
												onChange={handleChangeOEReport}
												onClick={resetFilePicker}
												style={{display: 'none'}}
												accept=".pdf" 
											/>
										</> 
									}
								</FormSection>
							</>	}
							{ !hideOEFeatures && deliveryInfo.oeReportRequired != null && deliveryInfo.oeReportRequired == true &&
							<>
								<FormSectionHeading>Submit O&E Report Response</FormSectionHeading>
								<FormSection>
									<p>If OTED provides an O&E Report, you must submit a formal written response on behalf of your organization. An example template can be provided upon request. At a minimum, this report must be in .PDF format and:</p>
									<ul>
										<li>acknowledge that you have read OTED's O&E Report</li>
										<li>indicate that you agree with the report's findings OR provide additional details as necessary</li>
									</ul>
									{ deliveryInfo.oeResponse != null &&
										<FileEntry
											id="oeResponse"
											applicationId={applicationId}
											file={deliveryInfo.oeResponse}
											onDelete={onDeleteOeResponse}
											canDelete={["applicant_poc", "superadmin"].includes(getLoggedInRole())}
											updateSignal={updateSignal}
											setUpdateSignal={setUpdateSignal}
										/>
									}
									{ !isUploading && deliveryInfo.oeResponse == null && ["applicant_poc", "superadmin"].includes(getLoggedInRole()) &&
										<>
											<Grid container alignItems="center" spacing={1}>                
												<Grid item><UploadButton disabled={isUploading} onClick={onUploadOEResponseBtn}>Upload File</UploadButton></Grid>
											</Grid>
											<input
												type="file"
												ref={hiddenFileInputResponse}
												onChange={handleChangeOEResponse}
												onClick={resetFilePicker}
												style={{display: 'none'}}
												accept=".pdf" 
											/>
										</>
									}
								</FormSection>
							</>	}
							{ formValues.certificatesAllowed == true &&
							<>
								<FormSectionHeading>Generate Certificates</FormSectionHeading>
								<FormSection>
									<p>Select the button below to download certificates of completion for this course.</p>
									<ul>
										<li>Certificates will be downloaded as a .ZIP file containing certificates in .PDF format.</li>
									</ul>
									<DownloadButton variant="contained" color="primary" size="large" onClick={onGenerateCertificatesBtn}>Generate Certificates</DownloadButton>
								</FormSection>
							</>	}		
							{ formValues.certificatesAllowed != true && 
							<>
								<FormSectionHeading>Generate Certificates</FormSectionHeading>
								<FormSection>
									<p>There are currently not any certificates available for this course.</p>
								</FormSection>
								</>	}							
							{ isSaving ? <SavingIndicator/> :
							<Grid sx={{marginTop: 4}} container alignItems="center" justifyContent="center">
								<SaveButton variant="contained" color="primary" size="large" onClick={onSaveBtn}/>
								<BackButton variant="contained" color="primary" size="large" onClick={onBackBtn}/>
							</Grid>	}	
						</>
					}
					{ !isCloseoutUnlocked &&
						<p><strong>Closeout is not eligible for this particular application.</strong></p>
					}
                </>
				}
			</Paper>
		</Container>
    );
}
import React, {useState, useEffect, useRef} from 'react';
import Dialog from '@mui/material/Dialog';
import { DialogContent } from '@mui/material';
import { BootstrapDialogTitle } from './BootStrapDialogTitle';
import { InstructorGrid } from './ManageInstructors';
import { CreateButton } from './theme/PortalTheme';
import Grid from '@mui/material/Grid';

export default function SelectInstructorDialog(props){
    var { open, setOpen, onSelectQualifiedInstructorsCallback, unattachedQIs, isLoading } = props;
    
    const [addInstructorBtnDisabled, setAddInstructorBtnDisabled] = useState(true);
	const [unattachedQISelectionModel, setUnattachedQISelectionModel] = useState([]);
    const [availableInstructors, setAvailableInstructors] = useState([]);

    const isMounted = useRef(false)
	useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false }
	}, []);

    useEffect(()=>{
        if(isMounted.current){
            setAvailableInstructors(unattachedQIs);
        }
    }, [unattachedQIs])

	function onSelectionModelChange(selectionModel) {
		setUnattachedQISelectionModel(selectionModel);
		setAddInstructorBtnDisabled(selectionModel.length === 0);
	}

    function onConfirmSelectionBtn(){
		//Get the selected instructor objects based on their ids:
		var selectedInstructors = unattachedQIs.filter(instructor => unattachedQISelectionModel.includes(instructor.guid));
        //Pass the selected instructors to the callback:
        onSelectQualifiedInstructorsCallback(selectedInstructors);
        //Close the dialog:
        onDialogClose();
    }

    function onDialogClose(){
        setOpen(false);
    }

    return (
        <Dialog
            maxWidth={"lg"}
            fullWidth={true}
            open={open}    
            container={() => document.getElementById('dialogParent')}
        >            
            <BootstrapDialogTitle color="primary" onClose={onDialogClose}>Select Qualified Instructor(s)</BootstrapDialogTitle>
            <DialogContent>
                <InstructorGrid
                    rows = {availableInstructors}
                    loading = {isLoading}
                    checkboxSelection = {true}
					onSelectionModelChange={onSelectionModelChange}
                />
				<Grid container justifyContent="flex-end">
					<CreateButton
                        onClick = {onConfirmSelectionBtn}
                        disabled = {addInstructorBtnDisabled}
                    >
                        Confirm Selection
                    </CreateButton>
				</Grid>
            </DialogContent>
        </Dialog>
    )
}
import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid, Card, CardHeader, CardContent, Typography, Divider} from '@mui/material';
import { getAdapter } from '../adapters/adapter';
import { Bar } from 'react-chartjs-2';
import theme from "../theme/PortalTheme";

export default function CertificationCard(props){
    
    /*
        # of certificates issued via ICD ??? 
        # of QI certificates issued ??? 
        # of QI successfully recertified ??? 
    */

    //Is component currently mounted?
	const isMounted = useRef(false)
	useEffect(() => {
		isMounted.current = true;
		return () => { isMounted.current = false }
	}, []);

    //States:
	const [isLoading, setIsLoading] = useState(false);
    const [totalInstructors, setTotalInstructors] = useState(0);
    const [instructorsWithCerts, setInstructorsWithCerts] = useState(0);
    const [newQIsThisYear, setNewQIsThisYear] = useState(0);


    //Initialize view:
    useEffect(()=>{
		async function fetchData(){
			setIsLoading(true);
			try {
				var adapter = await getAdapter();
                //Make some call to adapter:
                let response = await adapter.qualification.getQualificationsMeta();
                if(isMounted.current){
                    //console.log(response);
                    if(response.instructors){ 
                        setTotalInstructors(response.instructors.length);
                    }
                    if(response.qualifications) { 
                        setInstructorsWithCerts(Object.keys(response.qualifications).length);
                    }
                    if(response.createdThisYear){
                        setNewQIsThisYear(Object.keys(response.createdThisYear).length);
                    }
                }
			} 
			catch (error) {
				console.error(error)
			}
			if(isMounted.current){
                //Set some state:
				setIsLoading(false);
			}
		};
		
		fetchData();
    }, []);

    const data = {
        datasets: [
            {
                backgroundColor: '#3F51B5',
                barPercentage: 0.5,
                barThickness: 12,
                borderRadius: 4,
                categoryPercentage: 0.5,
                data: [totalInstructors, instructorsWithCerts, newQIsThisYear],
                label: 'Certificates Issued',
                maxBarThickness: 10
            }
        ],
        labels: ['Total QIs', 'QIs w/ Valid Certifications', 'New QIs this Year']
    };

    const options = {
        animation: true,
        cornerRadius: 20,
        layout: { padding: 0 },
        legend: { display: false },
        maintainAspectRatio: false,
        responsive: true,
        xAxes: [
            {
                ticks: {
                    fontColor: theme.palette.text.secondary
                },
                gridLines: {
                    display: false,
                    drawBorder: false
                }
            }
        ],
        yAxes: [
            {
                ticks: {
                    fontColor: theme.palette.text.secondary,
                    beginAtZero: true,
                    min: 0
                },
                gridLines: {
                    borderDash: [2],
                    borderDashOffset: [2],
                    color: theme.palette.divider,
                    drawBorder: false,
                    zeroLineBorderDash: [2],
                    zeroLineBorderDashOffset: [2],
                    zeroLineColor: theme.palette.divider
                }
            }
        ],
        tooltips: {
            backgroundColor: theme.palette.background.paper,
            bodyFontColor: theme.palette.text.secondary,
            borderColor: theme.palette.divider,
            borderWidth: 1,
            enabled: true,
            footerFontColor: theme.palette.text.secondary,
            intersect: false,
            mode: 'index',
            titleFontColor: theme.palette.text.primary
        }
    };

    //Render:
    return(
        <Card {...props}>
            <CardHeader title="Instructor Certification Breakdown"/>
            <Divider sx={{width:"100%"}} />
            <CardContent>
                <Box
                    sx={{
                        height: 300,
                        position: 'relative'
                    }}
                >
                    <Bar
                        data={data}
                        options={options}
                    />
                </Box>
                <Divider sx={{width:"100%", my:3}} />                
                <Box>
                    <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
                        <Grid item sx={{textAlign:"center"}}>
                            <Typography
                                color="textSecondary"
                                gutterBottom
                                variant="overline"
                            >
                                Total Qualified Instructors
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="h4"
                            >
                                {totalInstructors}
                            </Typography>
                        </Grid>
                        <Grid item sx={{textAlign:"center"}}>
                            <Typography
                                color="textSecondary"
                                gutterBottom
                                variant="overline"
                            >
                                Qualified Instructors w/ Valid Certifications
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="h4"
                            >
                                {instructorsWithCerts}
                            </Typography>
                        </Grid>
                        <Grid item sx={{textAlign:"center"}}>
                            <Typography
                                color="textSecondary"
                                gutterBottom
                                variant="overline"
                            >
                                New Qualified Instructors This Year
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="h4"
                            >
                                {newQIsThisYear}
                            </Typography>
                        </Grid>                    
                    </Grid>                                   
                </Box>
            </CardContent>
        </Card>
    );
}
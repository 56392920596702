import axios from 'axios';
import {appRoute} from "../utils";
import * as delivery from "./deliveryAdapter";
import * as poc from "./pocAdapter";
import * as qualified from "./qualifiedAdapter";
import * as unqualified from "./unqualifiedAdapter";
import {parseResponse, API_URL} from "../utils";
import { getAdapter } from '../adapter';

export async function get(id)
{
	var data = await axios.get(appRoute(id));
	return parseResponse(data);
}

export async function getExtended(id)
{
	return parseResponse(await axios.get(`${API_URL}/api/applications/extended/${id}`, (await getAdapter()).getAuthHeader));
}

export async function getCourse(id)
{
	var data = await axios.get(appRoute(id) + "/course", (await getAdapter()).getAuthHeader);
	return parseResponse(data);
}

/** data can be {courseID}, if courseID is provided,
	the backend will attempt to prefil the newly-created application
*/
export async function create(data)
{
	return parseResponse(await axios.post(`${API_URL}/api/applications`, data, (await getAdapter()).getAuthHeader));
}

export async function list()
{
	var data = parseResponse(await axios.get(`${API_URL}/api/applications`, (await getAdapter()).getAuthHeader));
	return data.map((e) => formatForDataGrid(e));
}

export async function listPaginated(offset, fetch)
{
	var data = parseResponse(await axios.get(`${API_URL}/api/applications/paginated/${offset}/${fetch}`, (await getAdapter()).getAuthHeader));
	return data.map((e) => formatForDataGrid(e));
}

export async function listApplicationMetrics()
{
	var data = parseResponse(await axios.get(`${API_URL}/api/applications/statuscounts`, (await getAdapter()).getAuthHeader));
	return data;
}


/**
 * filter expects an object, example below
 * {startDate: '1970-01-01T01:00:00Z',endDate:'2024-07-18T05:00:00Z'}
 */

export async function getApplicationMeta(filter)
{
  return parseResponse(await axios.get(`${API_URL}/api/applications/meta/${filter.startDate}/${filter.endDate}`));
}

export async function getApplicationAudit(application_id)
{
  return parseResponse(await axios.get(`${API_URL}/api/applications/audit/${application_id}`));
}

/** format point of contact as `First Last (email@example.com)`;
	if firstName, lastName, and email are blank, returns null
*/
function formatPOC(poc) {
	//coerce each to a string and trim spaces
	var first = (poc.firstName || "").trim();
	var last = (poc.lastName || "").trim();
	var email = (poc.email || "").trim();

	//if they're all empty, return null
	if(first === "" && last === "" && email === "") {
		return null;
	}

	//otherwise, format properly
	return `${first} ${last} (${email})`;
}

/** given a composite application object, map it to a form friendly for management/list views */
export function formatForDataGrid(e) {
	return {
		id: e.application.guid,
		guid: e.application.guid,
		status: e.application.status,
		course: e.courseInfo,
		startDate: e.delivery.startDate,
		endDate: e.delivery.endDate,
		organization: e.organization == null ? null : e.organization.name,
		poc: formatPOC(e.poc),
		qualified: e.qualified,
		unqualified: e.unqualified
	};
}

export async function listByOrganization(organizationID)
{
	var data = parseResponse(await axios.get(`${API_URL}/api/applications/by-organization/${organizationID}`, (await getAdapter()).getAuthHeader));
	return data.map((e) => formatForDataGrid(e));
}

/*  stepID should be one of 'DeliveryInformation','Instructors','ContactInformation','Agreements','Approval'
	stepIsAdd can be set to false if you wish to remove a step
*/
export async function updateStep(id, stepID, stepIsAdd=true)
{
	return parseResponse(await axios.post(appRoute(id) + "/step", {stepID, stepIsAdd}, (await getAdapter()).getAuthHeader));
}

//true/false
export async function updateAgreements(id, agreements_ack, agreements)
{
	return parseResponse(await axios.post(appRoute(id) + "/agreements", {agreements_ack, agreements}, (await getAdapter()).getAuthHeader));
}

/** one of "completed, processed, in-progress, submitted, or approved" */
export async function updateStatus(id, status, status_reason=null)
{
	return parseResponse(await axios.post(appRoute(id) + "/status", {status, status_reason}, (await getAdapter()).getAuthHeader));
}

export async function updateCourse(applicationID, courseID)
{
	return parseResponse(await axios.post(appRoute(applicationID) + "/course", {courseID}, (await getAdapter()).getAuthHeader));
}

async function _delete(ids)
{
	//coerce to array
	ids = Array.isArray(ids) ? ids : [ids];

	return parseResponse(await axios.post(appRoute() + "delete-multiple", ids, (await getAdapter()).getAuthHeader));
}

export async function getMessage(guid) {
	return await axios.get(`${API_URL}/api/applications/message/` + guid, (await getAdapter()).getAuthHeader);
}

export async function getMessageFile(filename) {
	var headers = (await getAdapter()).getAuthHeader();
	headers['responseType']='arraybuffer';
	return await axios.get(`${API_URL}/api/applications/message/file/` + filename, headers);
}

export async function getMessages(application_id) {
	return await axios.get(`${API_URL}/api/applications/message/messages/` + application_id, (await getAdapter()).getAuthHeader);
}

export async function getMessagesInternal(application_id) {
	return await axios.get(`${API_URL}/api/applications/message/internal/messages/` + application_id, (await getAdapter()).getAuthHeader);
}

export async function createMessage(body, application_id, file=null) {
	return createMessageHelper(body, application_id, 0, file);
}

export async function createMessageInternal(body, application_id, file=null) {
	return createMessageHelper(body, application_id, 1, file);
}

/*message_type 0 for regular, 1 for internal*/
async function createMessageHelper(body, application_id, message_type, file) {
	if(file) {
		var headers = (await getAdapter()).getAuthHeader();
		headers.headers['Content-Type']='multipart/form-data';
		var formData = new FormData();
    	formData.append('file', file);
    	formData.append('fileName', file.name);
		formData.append('body', body);
		formData.append('application_id', application_id); 
		formData.append('message_type', message_type); 

		return await axios.post(`${API_URL}/api/applications/message`, 
			formData, headers);
	} else {
		return await axios.post(`${API_URL}/api/applications/message`, {
			body,
			application_id,
			message_type
		}, (await getAdapter()).getAuthHeader);
	}
}

export async function acknowledgeMessage(message_guid) {
	return await axios.patch(`${API_URL}/api/applications/message`, {message_guid}, (await getAdapter()).getAuthHeader);
}

export async function getLatestCertificationFile(application_id) {
	var headers = (await getAdapter()).getAuthHeader();
	headers['responseType']='arraybuffer';
	return await axios.get(`${API_URL}/api/applications/certification/fetch/` + application_id, headers);
}

// export async function getCertification(certification_id) {
// 	return await axios.get(`${API_URL}/api/applications/certification/` + certification_id, (await getAdapter()).getAuthHeader);
// }

// export async function getCertificationFile(filename) {
// 	var headers = (await getAdapter()).getAuthHeader();
// 	headers['responseType']='arraybuffer';
// 	return await axios.get(`${API_URL}/api/applications/certification/file/` + filename, headers);
// }

// export async function getCertifications(application_id) {
// 	return await axios.get(`${API_URL}/api/applications/certification/certifications/` + application_id, (await getAdapter()).getAuthHeader);
// }

// /**
//  * 
//  * @param names:[{firstName:firstName,lastName:lastName,middleInitial:middleInitial}]
//  */
// export async function createCertifications(application_id, numberOfCertificates=1, names=null) {
// 	let body = {};
// 	body.numberOfCertificates = numberOfCertificates;
// 	body.applicationID = application_id;
// 	body.names = names;
// 	return await axios.post(`${API_URL}/api/applications/certification`, body, (await getAdapter()).getAuthHeader);
// }

// export async function createAndFetchCertification(application_id) {
// 	var header = (await getAdapter()).getAuthHeader;
// 	return await axios({
// 	  method:'get',
// 	  url:`${API_URL}/api/applications/certification/createandfetch/${application_id}`,
// 	  responseType:'blob',
// 	  header: header
// 	})
// }

// export async function updateCertification(body, certification_id) {
// 	return await axios.patch(`${API_URL}/api/applications/certification/`+certification_id, {body}, (await getAdapter()).getAuthHeader);
// }

// export async function deleteCertification(certification_id) {
// 	return await axios.delete(`${API_URL}/api/applications/certification/certifications/` + certification_id, (await getAdapter()).getAuthHeader);
// }

export {
	_delete as delete,
	delivery,
	poc,
	qualified,
	unqualified
}

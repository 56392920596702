import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { getAdapter } from '../adapters/adapter';
import { Doughnut } from 'react-chartjs-2';
import { Chart, registerables  } from 'chart.js';
import theme from "../theme/PortalTheme";
import LoadingIndicator from '../LoadingIndicator';


export default function ApplicationStatusChart(props){
    //To get chartjs working:
    Chart.register(...registerables);
    
    //Is component currently mounted?
	const isMounted = useRef(false)
	useEffect(() => {
		isMounted.current = true;
		return () => { isMounted.current = false }
	}, []);

    //States:
    /*
        Possible application statuses:
        "In-Progress", "Submitted", "Processed", "Approved", "Completed"
    */
	const [isLoading, setIsLoading] = useState(false);
    const [appInProgress, setAppInProgress] = useState(0);
    const [appSubmitted, setAppSubmitted] = useState(0);
    const [appProcessed, setAppProcessed] = useState(0);
    const [appApproved, setAppApproved] = useState(0);
    const [appCompleted, setAppCompleted] = useState(0);

    //Initialize view:
    useEffect(()=>{
		async function fetchData(){
			setIsLoading(true);
			try {
				var adapter = await getAdapter();
                //Make some call to adapter:
                var result = await adapter.application.listApplicationMetrics();
			} 
			catch (error) {
				console.error(error)
			}
			if(isMounted.current){
                if(result){
                    setAppInProgress(result.inProgress);
                    setAppSubmitted(result.submitted);
                    setAppProcessed(result.processed);
                    setAppApproved(result.approved);
                    setAppCompleted(result.completed);
                }
                //Set some state:
				setIsLoading(false);
			}
		};
		
		fetchData();
    }, []);

    const data = {
        datasets: [
            {
                data: [ appInProgress, appSubmitted, appProcessed, appApproved, appCompleted ],
                backgroundColor: ['#3F51B5', '#e53935', '#FB8C00', "#14B8A6", "#33B30a" ],
                borderWidth: 8,
                borderColor: '#FFFFFF',
                hoverBorderColor: '#FFFFFF'
            }
        ],
        labels: ["In-Progress", "Submitted", "Processed", "Approved", "Completed"]
    };
    let isAppListEmpty = (appInProgress + appSubmitted + appProcessed + appApproved + appCompleted) == 0;
    
    const options = {
        animation:{
            animateRotate:true
        },
        cutoutPercentage: 80,
        layout: { padding: 0 },
        legend: {
            display: false
        },
        maintainAspectRatio: false,
        responsive: true,
        tooltips: {
            backgroundColor: theme.palette.background.paper,
            bodyFontColor: theme.palette.text.secondary,
            borderColor: theme.palette.divider,
            borderWidth: 0,
            enabled: true,
            footerFontColor: theme.palette.text.secondary,
            intersect: false,
            mode: 'index',
            titleFontColor: theme.palette.text.primary
        }
    };



    //Render:
    return(
        <>
            { isLoading ? <LoadingIndicator/> :
            <>
                { isAppListEmpty && <p>No applications to display.</p> }
                <>
                    <Box
                        sx={{
                            height: isAppListEmpty ? 50: 300,
                            position: 'relative'
                        }}
                    >
                        <Doughnut
                            data={data}
                            options={options}
                        />
                    </Box>
                    <Box sx={{textAlign:"center"}}>
                        <ul style={{listStyleType:"none", paddingLeft:20, display:"inline-block"}}>
                            <li>In-Progress: { isLoading ? "-" :  appInProgress }</li>
                            <li>Submitted: { isLoading ? "-" :  appSubmitted }</li>
                            <li>Processed: { isLoading ? "-" :  appProcessed }</li>
                            <li>Approved: { isLoading ? "-" :  appApproved }</li>
                            <li>Completed: { isLoading ? "-" :  appCompleted }</li>
                        </ul>
                    </Box>
                </>
            </>
            }
        </>
    );
}
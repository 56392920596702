import React, {useState, useEffect, useRef} from 'react';
import Dialog from '@mui/material/Dialog';
import { DialogContent } from '@mui/material';
import { BootstrapDialogTitle } from './BootStrapDialogTitle';
import { CourseGrid } from './ManageCourses';
import { CreateButton } from './theme/PortalTheme';
import { getAdapter } from './adapters/adapter';
import { idToGuidMapper } from './Utility';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';
import { Box, Button, Card, CardHeader, CardActions, CardContent, Divider, Grid, TextField } from '@mui/material';
import useForm from './UseForm';

export default function SelectQualificationDialog(props){
    var { open, setOpen, qualifications,onSelectQualificationsCallback } = props;
    
	const [selectionModel, setSelectionModel] = useState([]);
    const [availableQualifications, setAvailableQualifications] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const isMounted = useRef(false)
	useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false }
	}, []);


	//Form validation:
    function validate(){
        let _errors = { ...errors };
		console.log("Validating");
		//console.log(formValues);
		_errors.certifiedOn = (formValues.certifiedOn === "Invalid date") ? "Invalid date." : "";
        _errors.expiresOn = (formValues.expiresOn === "Invalid date") ? "Invalid date." : "";

        if (!(formValues.certifiedOn === "Invalid date") && !(formValues.expiresOn === "Invalid date")){
            var isEndDateAtLeastOneDayAfterStartDate = moment(formValues.expiresOn).isAfter(formValues.certifiedOn, 'day');
			_errors.expiresOn = !(isEndDateAtLeastOneDayAfterStartDate) ? "Expiration date must be at least one day after certification date." : "";
        }

        setErrors({
            ..._errors
        });

		var result = Object.values(_errors).every(x => x === "");
		console.log("Validation " + (result ? "SUCCESS" : "FAILED"));
		return result;
		
	}
 

	//Initial Form Values:
	const initialFormValues = {
        certifiedOn: new moment.utc().format(),
        expiresOn: new moment.utc().format()
	};

	//Form-related vars:
	const {
        formValues,
		setFormValue,
        errors,
        setErrors,
        handleInputChange,
    } = useForm(initialFormValues, false, validate);

	//If the attached qualifications change, then recalculate the unattached qualifications:
	useEffect(() => {
        async function fetchData(){
            if(isMounted.current){
                setIsLoading(true);
                setAvailableQualifications([]);
            }
            try{
                let adapter = await getAdapter();
                let courses = await adapter.course.list();
                if(isMounted.current){
                    let qualificationsFromDB = courses.map(idToGuidMapper);
                    //Filter out qualifications already belonging to this instructor from the list of available qualifications:
                    let _availableQualifications = qualificationsFromDB.filter(course1=> !qualifications.some(course2 => course1.guid === course2.guid));
                    //console.log(_availableQualifications);
                    setAvailableQualifications(_availableQualifications);
                }
            }
            catch(error){
                console.log(error);
                //Something went wrong, empty the list:
                if(isMounted.current){
                    setAvailableQualifications([]);
                }
            }
            if(isMounted.current){
                setIsLoading(false);
            }               
        }
        if(open) fetchData();
	}, [qualifications,
        open,
		setAvailableQualifications]);

	function onSelectionModelChange(_selectionModel) {
        if (_selectionModel.length > 1) {
            const selectionSet = new Set(selectionModel);
            const result = _selectionModel.filter((s) => !selectionSet.has(s));
            setSelectionModel(result);
        } 
        else {
            setSelectionModel(_selectionModel);
        }
	}

    function onConfirmSelectionBtn(){
        if(validate()){
            //Get the selected course (qualification) objects based on their ids:
            var selectedQualification = availableQualifications.filter(course => selectionModel.includes(course.guid));
            selectedQualification[0].certifiedOn = moment.utc(formValues.certifiedOn).format();
            selectedQualification[0].expiresOn = moment.utc(formValues.expiresOn).format();
            selectedQualification[0].isNew = true;
            //Pass the selected course (qualifications) to the callback:
            onSelectQualificationsCallback(selectedQualification);
            //Close the dialog:
            onDialogClose();
        }
    }

    function onDialogClose(){
        setOpen(false);
    }

    return (
        <Dialog
            maxWidth={"lg"}
            fullWidth={true}
            open={open}    
            container={() => document.getElementById('dialogParent')}
        >            
            <BootstrapDialogTitle color="primary" onClose={onDialogClose}>Select Qualification</BootstrapDialogTitle>
            <DialogContent>
                <CourseGrid
                    rows = {availableQualifications}
                    loading = {isLoading}
                    checkboxSelection = {true}
					onSelectionModelChange={onSelectionModelChange}
                    selectionModel={selectionModel}
                    disableMultipleSelection
                />
                <Grid container justifyContent="flex-start" spacing={1} sx={{mt:2}}>
                    <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                disableToolbar
                                renderInput={(params) => {
                                    return(
                                        <TextField {...params} {...(errors.certifiedOn && {error:true, helperText:errors.certifiedOn})}/>
                                    );
                                }}
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="date-picker-certified-on"
                                label="Certified On"
                                name="certifiedOn"
                                value={formValues.certifiedOn}
                                onChange={(newValue) => {
                                    if(isMounted.current){
                                        setFormValue("certifiedOn", moment.utc(newValue).format()); 
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                disableToolbar
                                renderInput={(params) => {
                                    return(
                                        <TextField {...params} {...(errors.expiresOn && {error:true, helperText:errors.expiresOn})}/>
                                    );
                                }}
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="date-picker-expires-on"
                                label="Expires On"
                                name="expiresOn"
                                value={formValues.expiresOn}
                                onChange={(newValue) => {
                                    if(isMounted.current){
                                        setFormValue("expiresOn", moment.utc(newValue).format());
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>                
				<Grid container justifyContent="flex-end">
					<CreateButton
                        onClick = {onConfirmSelectionBtn}
                        disabled = {selectionModel.length === 0}
                    >
                        Confirm Selection
                    </CreateButton>
				</Grid>
            </DialogContent>
        </Dialog>
    )
}
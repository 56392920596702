import React, { useContext, useEffect, useState, useRef } from 'react';
import { Box, Button, Card, CardHeader, CardActions, CardContent, Divider, Grid, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import { getLoggedInRole } from '../adapters/baseAdapter';
import { LibraryTopics } from './LibraryTopics';
import theme from '../theme/PortalTheme';

function LibraryContent(props){
    const { filteredTopics } = props;

    return (
        <Box sx={{padding:2}}>
        { filteredTopics && filteredTopics.length > 0 && filteredTopics.map((item) => (
            <Box sx={{mb:1}} key={item.id}>{item.component}</Box>
        ))}
        </Box>     
    );
}

export default function LibraryContainer(props){
    return (
        <Box sx={{p:2, height:"90vh", width:"100%"}}>
            <Library sx={{height:"100%", overflowY:'auto'}}/>
        </Box>
    );
}

export function Library(props){

    //Is component currently mounted?
	const isMounted = useRef(false);
	useEffect(() => {
		isMounted.current = true;

		return () => { isMounted.current = false }
	}, []);

    const [searchCriteria, setSearchCriteria] = useState("");
    const [filteredTopics, setFilteredTopics] = useState(LibraryTopics);
    const [viewAll, setViewAll] = useState(false);

    function handleChange(e){
        if(isMounted.current){
            let userSearchTerm = e.target.value.toLowerCase();
            setSearchCriteria(userSearchTerm);
        }        
    }   

    useEffect(()=>{
        const _filteredTopics = LibraryTopics.filter((topic) => {
            //If the topic is available to all roles, or allowed to be viewed by current user's role:
            if(topic.roles.includes("all") || topic.roles.includes(getLoggedInRole())){
                //If no criteria is entered:
                if(searchCriteria === '' ){
                    /*
                        Return all topics suitable for logged in user based on their role.
                    */
                    return topic;
                }
                //Otherwise filter by topic title based on searchCriteria:
                else{
                    return topic.title.toLowerCase().includes(searchCriteria);
                }
            }         
        });
        if(isMounted.current){
            setFilteredTopics(_filteredTopics); 
        }  
        
    }, [searchCriteria]);

    return (
        <Card {...props}>
            <CardHeader title="Library"/>
            <Divider sx={{width:"100%"}} />          
            <CardContent>
                { ["oted_pa", "superadmin"].includes(getLoggedInRole()) && <p>The following topics include detailed step-by-step guidance for non-FDA organization Points of Contact (POCs) on performing tasks within the ICD Management Portal.</p> }
                <p>Use the search field below to filter available help topics. Click on a topic in the filtered results to expand it.</p>
                <Grid container>
                    {/*Search field*/}
                    <Grid item xs={12} md={12} lg={8} xl={6}>
                        <Grid container alignItems="flex-end">
                            <TextField
                                margin="normal"
                                variant = "outlined"
                                fullWidth
                                label="Search"
                                value={searchCriteria}
                                InputProps={{
                                    endAdornment: <Search/>
                                }}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                    {/*Matches*/}
                    <Grid item xs={12}>
                        <LibraryContent
                            filteredTopics={filteredTopics}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>    
    );
}
import axios from 'axios';
import * as baseAdapter from "./baseAdapter";
import {API_URL} from "./utils";

//set json as default content type for all requests
axios.defaults.headers.common['Content-Type'] = 'application/json';

var theAdapter = null;
// eslint-disable-next-line
var debugMode = false;

//attempts to connect to backend; if successful, returns adapter instance, otherwise no adapter is loaded
export async function getAdapter()
{
  //attempt to connect
  if(theAdapter == null)
  {
    try
    {
      await axios.get(`${API_URL}/api/ping`);
      theAdapter = baseAdapter;
    }
    catch
    {
        throw new Error("Could not set adapter");
    }

  }

  return theAdapter;
}

export async function delayTest()
{
  return (await fetch(`${API_URL}/api/delay-test`)).data;
}

export function enableDebugMode(debug=true)
{
  debugMode = debug;
}

import React, {useState, useEffect, useRef, useContext} from 'react';
import { useHistory } from "react-router-dom";
import ViewTitle from './ViewTitle';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { getAdapter } from './adapters/adapter';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import theme from './theme/PortalTheme';
import Grid from '@mui/material/Grid';
import { CreateButton, DeleteButton, UploadButton } from './theme/PortalTheme';
import { idToGuidMapper } from './Utility';
import { StatusMessageContext } from './StatusMessage';
import ConfirmationDialog from './ConfirmationDialog';
import { getLoggedInRole } from './adapters/baseAdapter';

export function InstructorGrid(props){
	//Instructor Columns:
	const INSTRUCTOR_COLUMNS = [
		{ field: 'id', headerName: 'ID', width: 100, disableColumnMenu: true, resizeable: true, hide: true},
		{ field: 'lastName', headerName: 'Last Name', width: 200, disableColumnMenu: true, resizeable: true},
		{ field: 'firstName', headerName: 'First Name', width: 200, disableColumnMenu: true, resizeable: true},
		{ field: 'email', headerName:'Email', width:250, disableColumnMenu: true, resizeable: true}
	];

	if (props.actionsColumnRenderCell != null) {
		INSTRUCTOR_COLUMNS.push({
			field: "",
			filterable:false,
			disableColumnMenu:true,
			flex:1,
			minWidth:100,
			headerName: "Actions",
			sortable: false,
			disableClickEventBubbling: true,
			renderCell: props.actionsColumnRenderCell
		});	
	}

	return(
		<DataGrid
			columns = {INSTRUCTOR_COLUMNS}
			initialState={{
				sorting: {
				  sortModel: [{ field: 'lastName', sort: 'asc' }],
				}
			}}			
			disableSelectionOnClick = {true}
			components = {{Toolbar:GridToolbar}}		
			density = "compact"
			autoHeight
			pageSize = {10}
			rowsPerPageOptions = {[10, 25, 50]}
			{...props}		
		/>
	);
}

export default function ManageInstructors () {
	const history = useHistory();
	const [deleteDisabled, setDeleteDisabled] = useState(true);
	// eslint-disable-next-line
	const [isLoading, setIsLoading] = useState(false);
	const [instructors, setInstructors] = useState([]);
	const [selected, setSelected] = useState([]);
	//simplest solution to retrigger the onload useeffect and properly cleanup from a button press
	const [updateGrid, setUpdateGrid] = useState(0);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    //Status message:
	var { StatusManager } = useContext(StatusMessageContext);

	const isMounted = useRef(false)
	useEffect(() => {
		isMounted.current = true;
		return () => { isMounted.current = false }
	}, []);

	useEffect(() => {
		if(isMounted.current) {
			setInstructors([]);
            if(!["oted_pa", "superadmin"].includes(getLoggedInRole())){
                StatusManager.setStatus("Access denied. You have been returned to the dashboard home screen.");
                history.push("/dashboard");
            }
		}	
		async function fetchData(){
			setIsLoading(true);
			try {
				var adapter = await getAdapter();
				var instructors = await adapter.instructor.list();
			}
			catch (error) {
				console.error(error);
			}
			if(isMounted.current) {
				instructors = instructors.map(idToGuidMapper);
				setInstructors(instructors);
				setIsLoading(false);
			}
		};

		fetchData();

	}, [updateGrid]);

	function actionsColumnRenderCell(params){
		function onViewEditBtn(params){
			var instructorId = params.guid;
			history.push("/dashboard/instructors/view_edit/" + instructorId);
		}

		return (
			<React.Fragment>
				<button
					style={{marginRight:theme.spacing(1), cursor:"pointer"}}
					onClick={() => onViewEditBtn(params.row)}
				>
					View/Edit
				</button>						
			</React.Fragment>
		);
	}

	function onImportInstructorsBtn(){
		history.push("/dashboard/instructors/import/");		
	}

	function onCreateInstructorBtn(){
		history.push("/dashboard/instructors/create/");
	}

	function onDeleteInstructorBtn(){
		setDeleteDialogOpen(true);
	}

	function onConfirmDelete(){
		deleteInstructor();
	}	

	async function deleteInstructor(){
		var success = true;
		setIsLoading(true);
		try{
			var adapter = await getAdapter();
			await adapter.instructor.delete(selected);
		}
		catch(error){
			success = false;
			console.error(error);
		}
		if(isMounted.current) {
			var successStr = success ? "successful." : "failed.";
			console.log("Delete " + successStr );
			StatusManager.setStatus("Delete instructor " + successStr);
			setUpdateGrid(updateGrid+1);
			setIsLoading(false);		
		}
	}

	function onSelectionChange(selectionModel) {
		//if # of selected rows > 0, then enable delete button
		if(setDeleteDisabled != null) {
			setDeleteDisabled(selectionModel.length === 0);
		}
		setSelected(selectionModel);
	}

	return (
		<Container component="main" maxWidth="lg">
			<Paper style={{height:'70vh'}} padding="large">
				<ViewTitle>Manage Instructors</ViewTitle>
				{/*Delete Confirmation Dialog*/}
				<ConfirmationDialog
					open={deleteDialogOpen}
					setOpen={setDeleteDialogOpen}
					dialogTitle="Confirm Deletion"
					dialogContent="Are you sure you want to delete?"
					leftButtonLabel="No"
					leftButtonCallback={()=>{}}
					rightButtonLabel="Yes"
					rightButtonCallback={onConfirmDelete}
				/>					
				<p style={{marginBottom:0}}>Listed below are all instructors currently teaching ICD-eligible courses.</p>
				<ul>
					<li style={{marginBottom:theme.spacing(1)}}>Select the <strong>Create Instructor</strong> button to create a new instructor listing.</li>
					<li style={{marginBottom:theme.spacing(1)}}>Select the <strong>View/Edit</strong> button to view or edit information about the corresponding instructor listing.</li>
					<li style={{marginBottom:theme.spacing(1)}}>Select the <strong>Bulk Import Instructors</strong> button to upload multiple instructors at once using a .CSV file.</li>					
					<li style={{marginBottom:theme.spacing(1)}}>After selecting the checkbox next to one or more instructors from the list, select the <strong>Delete Instructor</strong> button to delete the selected instructor listing(s).
					<ul><li style={{marginTop:theme.spacing(1)}}><strong>NOTE:</strong> This will permanently delete the selected instructor listing(s).</li></ul></li>
				</ul>
				<Grid container justifyContent="flex-end">
					<UploadButton onClick={onImportInstructorsBtn}>Bulk Import Instructors</UploadButton>
					<CreateButton onClick={onCreateInstructorBtn}>Create Instructor</CreateButton>
					<DeleteButton onClick={onDeleteInstructorBtn} disabled={deleteDisabled}>Delete Instructor</DeleteButton>					
				</Grid>
				<InstructorGrid
					actionsColumnRenderCell = {actionsColumnRenderCell}
					loading = {isLoading}
					rows = {instructors}
					checkboxSelection={true}
					onSelectionModelChange={onSelectionChange}
				/>
			</Paper>
		</Container>
	);
}

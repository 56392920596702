import React from 'react';
import { Grid} from '@mui/material';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import useForm from './UseForm';
import moment from 'moment';
import { GetReportButton } from './theme/PortalTheme';

export default function ReportDateRangeSelector(props){

    const { onGenerateReportBtn, startDate, endDate } = props;

    //Form validation:
    function validate(){
        const result = true;
        //TO DO (JEK 7/25/2022): Implement later...
		return result;		
	}

	//Initial Form Values:
	const initialFormValues = {
		startDate: startDate,
        endDate: endDate
	};

	//Form-related vars:
	const {
        formValues,
		setFormValue,
        errors,
    } = useForm(initialFormValues, false, validate);

    function onGenerateReportBtnLocal(){
        if(validate){
            onGenerateReportBtn(formValues.startDate, formValues.endDate);
        }
    }

    return(
        <Grid container spacing={1} rowSpacing={2}>
            <Grid item>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        disableToolbar
                        renderInput={(params) => {
                            return(
                                <TextField {...params} {...(errors.startDate && {error:true, helperText:errors.startDate})}/>
                            );
                        }}
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-start"
                        label="Start Date"
                        name="startDate"
                        value={formValues.startDate}
                        onChange={(newValue) => setFormValue("startDate", moment.utc(newValue).format())}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        disableToolbar
                        renderInput={(params) => {
                            return(
                                <TextField {...params} {...(errors.eEndDate && {error:true, helperText:errors.endDate})}/>
                            );
                        }}
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-end"
                        label="End Date"
                        name="endDate"
                        value={formValues.endDate}
                        onChange={(newValue) => setFormValue("endDate", moment.utc(newValue).format())}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item><GetReportButton onClick={onGenerateReportBtnLocal}>Generate Report</GetReportButton></Grid>
        </Grid>	
    );
}

import { TextField, InputAdornment } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

export default function BetterFormField(props) {
	var {label, variant="outlined", readOnly=false, value, setValue, disabled, error=null, maxLength = null, ...rest} = props;

	function handleChange(event){
		setValue(event.target.value);
	}
	
	return (
		<TextField
			margin="normal"
			variant = {variant}
			disabled = {disabled}
			fullWidth
			label={label}
			value={value}
			inputProps={{
				...(maxLength && {maxLength: maxLength})
			}}
			InputProps={{
				readOnly:(readOnly === true),
				...(readOnly && {endAdornment: (
					<InputAdornment position="end">
						<LockIcon/>
			  		</InputAdornment>
				)})
			}}
			onChange={handleChange}
			{...(error && {error:true, helperText:error})}
			{...rest}
		/>
	);
}
import React, { useEffect, useState, useRef, useContext } from 'react';
import { Button, Grid, TextField, Paper, Box } from '@mui/material/';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { getAdapter } from './adapters/adapter';
import ViewTitle from './ViewTitle';
import BetterFormField from './FormField';
import { FormSection, FormSectionHeading } from "./theme/PortalTheme";
import LoadingIndicator from './LoadingIndicator';
import theme from './theme/PortalTheme';
import SelectCourseDialog from './SelectCourseDialog';
import { StatusMessageContext } from './StatusMessage';
import SavingIndicator from './SavingIndicator';
import useForm from './UseForm';
import { isValidState, isValidZipCode } from './Utility';
import moment from 'moment';
import { Save, ManageSearchOutlined } from '@mui/icons-material';
import { useHistory } from "react-router-dom";
import { getLoggedInRole } from './adapters/baseAdapter';

export default function ICDApplicationFormDeliveryInfo(props) {

	const {application, setApplication, stepLogic } = props;
	const history = useHistory();

	//Is component currently mounted?
	const isMounted = useRef(false)
	useEffect(() => {
		isMounted.current = true;
		return () => { isMounted.current = false }
	}, []);

    //Status message:
	var { StatusManager } = useContext(StatusMessageContext);
	
	//Loading/Saving states:
	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	//Is "Select Course" dialog open?
	const [courseDialogOpen, setCourseDialogOpen] = useState(false);	
	
	//Form validation:
    function validate(){
        let _errors = { ...errors };
		console.log("Validating");
		//console.log(formValues);
		
		//Course selection required:
		_errors.courseNumber = (formValues.course.guid === undefined) ? "Please select a course." : "";
		_errors.courseTitle = (formValues.course.guid === undefined) ? " " : "";

		//Facility information required:
		_errors.facilityName = (formValues.facilityName === "") ? " " : "";
		_errors.facilityStreet = (formValues.facilityStreet === "") ? " " : "";
		_errors.facilityCity = (formValues.facilityCity === "") ? " " : "";

		//Is facilityState valid?
		_errors.facilityState = (isValidState(formValues.facilityState))? "" : " ";
		//Is facilityZip valid?
		_errors.facilityZip = (isValidZipCode(formValues.facilityZip)) ? "" : " ";
		//Start/End date:
		_errors.selectedStartDate = (formValues.selectedStartDate === "Invalid date") ? "Invalid date." : "";

		if(formValues.selectedEndDate === "Invalid date"){
			_errors.selectedEndDate = "Invalid date.";
		}
		//End date must be at least one day after start date:
		else{
			var isEndDateAtLeastOneDayAfterStartDate = moment(formValues.selectedEndDate).isAfter(formValues.selectedStartDate, 'day');
			_errors.selectedEndDate = !(isEndDateAtLeastOneDayAfterStartDate) ? "End date must be at least one day after start date." : "";
		}
		
		_errors.estimatedParticipants = (String(formValues.estimatedParticipants).length > 0)? "" : "You must enter a number between 1 and 99";
		
		if(formValues.estimatedParticipants.length > 0){
			let intVal = parseInt(formValues.estimatedParticipants, 10);
			let failCondition = isNaN(intVal) || (intVal < 1) || (intVal > 99);
			_errors.estimatedParticipants = (!failCondition)? "" : "You must enter a number between 1 and 99";
		}

        setErrors({
            ..._errors
        });

		var result = Object.values(_errors).every(x => x === "");
		console.log("Validation " + (result ? "SUCCESS" : "FAILED"));
		return result;
		
	}

	//Initial Form Values:
	const initialFormValues = {
		course:{},
		selectedStartDate: moment.utc().format(),
		selectedEndDate: moment.utc().format(),
		facilityName: "",
		facilityStreet: "",
		facilityCity: "",
		facilityState: "",
		facilityZip: "",
		estimatedParticipants: ""
	};

	//Form-related vars:
	const {
        formValues,
		setFormValue,
        errors,
        setErrors,
        handleInputChange,
    } = useForm(initialFormValues, false, validate);

	//Create refs to mutable objects that would otherwise be passed as dependencies to useEffect hook, to prevent triggering re-renders unnecessarily.
	const setFormValueRef = useRef(setFormValue);

	//On view load:
	useEffect(() => {		
		let setFormValue = setFormValueRef.current;

		async function fetchData(){
			setIsLoading(true);
			try {
				var adapter = await getAdapter();
				if(application.courseID != null) {
					var courseInfo = await adapter.course.get(application.courseID);
				}
				var deliveryInfo = await adapter.application.delivery.get(application.guid);
				console.log(deliveryInfo);
			} 
			catch (error) {
				console.error(error);
			}
			if(isMounted.current){	
				if(courseInfo) setFormValue("course", courseInfo);
				setFormValue("facilityName", deliveryInfo.facilityName || "");
				setFormValue("facilityStreet", deliveryInfo.addressStreet || "");
				setFormValue("facilityCity", deliveryInfo.addressCity || "");
				setFormValue("facilityState", deliveryInfo.addressState || "");
				setFormValue("facilityZip", deliveryInfo.addressZip || "");
				setFormValue("selectedStartDate", deliveryInfo.startDate || moment.utc().format());
				setFormValue("selectedEndDate", deliveryInfo.endDate ||  moment.utc().format());
				setFormValue("estimatedParticipants", deliveryInfo.estimatedParticipants || "");
				setIsLoading(false);
			}
		};
		
		fetchData();

	}, []);

	//Debugging:
	useEffect(() => {
		//console.log(formValues);
    }, [ formValues ]);

	//Save:
	async function saveData(successCallback = () => {} ){
		console.log("Updating information for [ application.guid: " + application.guid + " ]");
		var success = true;
		if(isMounted.current){
			setIsSaving(true);
		}
		try {
			var adapter = await getAdapter();

			if(formValues.course.guid) await adapter.application.updateCourse(application.guid, formValues.course.guid);
			let deliveryInfo = {
				facilityName: formValues.facilityName,
				addressStreet: formValues.facilityStreet,
				addressCity: formValues.facilityCity,
				addressState: formValues.facilityState,
				addressZip: formValues.facilityZip,
				startDate: formValues.selectedStartDate,
				endDate: formValues.selectedEndDate,
				estimatedParticipants: formValues.estimatedParticipants
			};
			//console.log(deliveryInfo);
			await adapter.application.delivery.update(application.guid, deliveryInfo);
			await adapter.application.updateStep(application.guid, "DeliveryInformation");
			stepLogic.completeCurrentStep();

		} 
		catch (error) {
			success = false;
			console.error(error);
		}
		var successStr = success ? "successful." : "failed.";
		StatusManager.setStatus("Save application delivery information " + successStr);
		if(isMounted.current){
			setIsSaving(false);
			if(success){
				successCallback();
			}
		}
	}

	function onSelectCourseBtn(){
		setCourseDialogOpen(true);
	}

	function onSaveBtn(){
		if(validate()){		
			saveData(()=>{
				stepLogic.nextStep();
			});
		}
	}

	//Application should be locked if the user is a POC and the application has been submitted:
	let isApplicationLocked = (getLoggedInRole()==="applicant_poc" && application.status !="in-progress");
	let conditionalProps = isApplicationLocked ? {variant: "filled", readOnly: true}: {};

	return (
		<React.Fragment>
			<Paper padding="huge" sx={{margin:0}} elevation={8}>
				<Grid container alignItems="center" justifyContent="center">
					<ViewTitle>Create ICD Application &ndash; {stepLogic.getCurrentStepName()}</ViewTitle>
				</Grid>
				{ isLoading ? <LoadingIndicator padding={4}/> :
					<>
						{ !isApplicationLocked &&
						<SelectCourseDialog
							open = {courseDialogOpen} 
							setOpen = {setCourseDialogOpen}
							setCourse={(value) => {
								setFormValue("course", value);
							}}
						/> }
						{/* FDA Course Information */} 
						<FormSectionHeading>Course Information</FormSectionHeading>
						<FormSection>
							<p><strong>Course Requested for Delivery</strong></p>
							<Grid container spacing={1}>
								<Grid item xs={3}><BetterFormField required readOnly={true} variant="filled" label="FDA Course Number" name="courseNumber" value={(formValues.course && formValues.course.courseNumber) || ""} error={errors.courseNumber}/></Grid>
								<Grid item xs><BetterFormField required readOnly={true} variant="filled" label="Course Title" name="courseTitle" value={(formValues.course && formValues.course.courseTitle) || ""} error={errors.courseTitle}/></Grid>
								{ ! isApplicationLocked &&
								<Grid item xs="auto" alignItems="center" direction="column" container>
									<Button
										variant="outlined"
										startIcon={<ManageSearchOutlined/>} 
										style={{marginRight:theme.spacing(1), cursor:"pointer"}} 
										onClick={onSelectCourseBtn}
									>
										Select Course
									</Button>
								</Grid>
								}
							</Grid>
						</FormSection>
						{/* Proposed Delivery Information */}
						<FormSectionHeading>Proposed Delivery Information</FormSectionHeading>
						<FormSection>
							<p><strong>Training Location</strong></p>
							<Grid container spacing={1}>
								<Grid item xs={12}><BetterFormField {...conditionalProps} required label="Name of Facility/Location" name="facilityName" value={formValues.facilityName} onChange={handleInputChange} error={errors.facilityName}/></Grid>
								<Grid item xs={12}><BetterFormField {...conditionalProps} required label="Street" name="facilityStreet" value={formValues.facilityStreet} onChange={handleInputChange} error={errors.facilityStreet}/></Grid>
								<Grid item xs={5}><BetterFormField {...conditionalProps} required label="City" name="facilityCity" value={formValues.facilityCity} onChange={handleInputChange} error={errors.facilityCity}/></Grid>
								<Grid item xs={2}><BetterFormField {...conditionalProps} required label="State" name="facilityState" value={formValues.facilityState} onChange={handleInputChange} error={errors.facilityState}/></Grid>
								<Grid item xs={5}><BetterFormField {...conditionalProps} required label="Zip" name="facilityZip" value={formValues.facilityZip} onChange={handleInputChange} error={errors.facilityZip}/></Grid>	
							</Grid>
							<Grid container spacing={3}>
								<Grid item>
									<p><strong>Proposed Training Delivery Dates</strong></p>
									<Grid container spacing={1} rowSpacing={2}>
										<Grid item>
											{ isApplicationLocked &&
												<BetterFormField {...conditionalProps} required label="Proposed Training Start Date" name="selectedStartDate" value={moment.utc(formValues.selectedStartDate).local().format("MM/DD/YYYY")} onChange={(newValue) => setFormValue("selectedStartDate", moment.utc(newValue).format())} error={errors.selectedStartDate}/>
											}
											{ !isApplicationLocked &&
											<LocalizationProvider dateAdapter={AdapterDateFns}>
												<DatePicker
													disableToolbar
													renderInput={(params) => {
														return(
															<TextField required {...params} {...(errors.selectedStartDate && {error:true, helperText:errors.selectedStartDate})}/>
														);
													}}
													variant="inline"
													format="MM/dd/yyyy"
													margin="normal"
													id="date-picker-delivery-start"
													label="Proposed Training Start Date"
													name="selectedStartDate"
													value={formValues.selectedStartDate}
													onChange={(newValue) => setFormValue("selectedStartDate", moment.utc(newValue).format())}
												/>
											</LocalizationProvider> }
										</Grid>
										<Grid item>
											{ isApplicationLocked &&
												<BetterFormField {...conditionalProps} required label="Proposed Training End Date" name="selectedEndDate" value={moment.utc(formValues.selectedEndDate).local().format("MM/DD/YYYY")} onChange={(newValue) => setFormValue("selectedEndDate", moment.utc(newValue).format())} error={errors.selectedEndDate}/>
											}						
											{ !isApplicationLocked &&
											<LocalizationProvider dateAdapter={AdapterDateFns}>
												<DatePicker
													disableToolbar
													renderInput={(params) => {
														return(
															<TextField required {...params} {...(errors.selectedEndDate && {error:true, helperText:errors.selectedEndDate})}/>
														);
													}}
													variant="inline"
													format="MM/dd/yyyy"
													margin="normal"
													id="date-picker-delivery-end"
													label="Proposed Training End Date"
													name="selectedEndDate"
													value={formValues.selectedEndDate}
													onChange={(newValue) => setFormValue("selectedEndDate", moment.utc(newValue).format())}
												/>
											</LocalizationProvider> }
										</Grid>
									</Grid>
								</Grid>
								<Grid item>
									<p><strong>Anticipated Number of Participants</strong></p>
									<BetterFormField 
										{...conditionalProps}
										InputLabelProps={{ shrink: true }}
										maxLength={2}
										required label="# of Participants" 
										name="estimatedParticipants" 
										value={formValues.estimatedParticipants} 
										error={errors.estimatedParticipants}
										sx={{maxWidth:150}}
										onChange={(e)=>{
											/*
												Only allow numeric characters. 
												This is necessary, because type="number" on an MUI TextField still allows mathematical alphabet characters such as "e" per the HTML5 technical spec. 
												Furthermore, when an MUI TextField has type="number" set, it ignores max character length restrictions.
											*/
											const re = /^[0-9\b]+$/;
											// if value is not blank, then test the regex
											if (e.target.value === '' || re.test(e.target.value)) {
											   setFormValue(e.target.name, e.target.value);
											}
										}}
									/>
								</Grid>
							</Grid>
								
						</FormSection>
					</>					
				}
			</Paper>
			{
				isSaving ? <SavingIndicator/> :
				<Grid sx={{marginTop: 4}} container alignItems="center" justifyContent="center">
					{ stepLogic.canNext() && !isApplicationLocked && <Button variant="contained" color="primary" size="large" onClick={onSaveBtn} startIcon={<Save/>}>Save and Continue</Button>}
					{ isApplicationLocked &&
					<Box sx={{backgroundColor: theme.palette.background.statusMessage, px:2, border:"1px solid #CCC", textAlign:"center"}}>
						<p>This application has been submitted and is locked.</p>
					</Box>}
				</Grid>
			}
		</React.Fragment>		
	);
}



import axios from 'axios';
import {parseResponse, API_URL} from "./utils";
import { getAdapter } from './adapter';

function instructorRoute(id) {
	var route = `${API_URL}/api/instructors/`;

	if(id != null) {
		route += id;
	}

	return route;
}

export async function create(data)
{
	return parseResponse(await axios.post(instructorRoute(), data, (await getAdapter()).getAuthHeader));
}

export async function list()
{
	return parseResponse(await axios.get(instructorRoute(), (await getAdapter()).getAuthHeader));
}

export async function get(guid)
{
	var instructor = parseResponse(await axios.get(instructorRoute(guid), (await getAdapter()).getAuthHeader));
	
	if(instructor == null)
	{
		throw new Error("The given GUID does not exist.");
	}

	return instructor;
}

export async function update(guid, data)
{
	return parseResponse(await axios.patch(instructorRoute(guid), data, (await getAdapter()).getAuthHeader));
}

async function _delete(ids)
{
	//coerce to array
	ids = Array.isArray(ids) ? ids : [ids];

	return parseResponse(await axios.post(instructorRoute() + "delete-multiple", ids, (await getAdapter()).getAuthHeader));
}

export async function createFromCsv(courseID, file) {
	var headers = (await getAdapter()).getAuthHeader();
	headers.headers['Content-Type']='multipart/form-data';
	var formData = new FormData();
	formData.append('courseID',courseID);
	formData.append('file', file);
	formData.append('fileName', file.name);
	// form_data.append("file", fs.createReadStream(file.path));

	return await axios.post(`${API_URL}/api/instructors/createfromcsv`, formData, headers);
}

export {
	_delete as delete
}

import React, { useEffect, useRef, useContext } from 'react';
import { CheckCircle, CheckCircleOutline, Construction, Delete, Email, Notifications, NotificationsTwoTone } from '@mui/icons-material';
import { adaptV4Theme, Badge, Box, Divider, Grid, IconButton, Link, Popper } from '@mui/material/';
import * as baseAdapter from './adapters/baseAdapter';
import { getAdapter } from './adapters/adapter';
import socketIOClient from "socket.io-client";
import { SOCKET_URL } from "./adapters/utils";
import LoadingIndicator from './LoadingIndicator';
import { StatusMessageContext } from './StatusMessage';
import moment from 'moment-timezone';

//Simple notification component:
function Notification(props) {
	const { notification } = props;

    //Status message:
    const { StatusManager } = useContext(StatusMessageContext);

	function getLocalTimeStamp(date){	
		const dateFormat = "MM/DD/YYYY";
		const timeFormat = "hh:mm:ss A";
		var dateStr = moment.utc(date).local().format(dateFormat);
		var timeStr = moment.utc(date).local().format(timeFormat) + " (" + moment.tz(moment.tz.guess()).zoneAbbr()+")";
		return {dateStr: dateStr, timeStr:timeStr};
	}

	if(notification.created_on) notification['local_timestamp'] = getLocalTimeStamp(notification.created_on);
	if(notification.application_id) notification['application_link'] = `/dashboard/applications/view_edit/${notification.application_id}/1`;
	if(notification.message_id) notification['message_link'] = `/dashboard/message/messages/${notification.application_id}`;
	if(notification.course_id) notification['course_link'] = `/dashboard/courses/view_edit/${notification.course_id}`;
    //Event handlers:

	//Dismiss notification:
    async function onDismissNotification(e, notification){
        //console.log(notification);
        let success = true;
		try {
			var adapter = await getAdapter();
			await adapter.notification.removeNotification(notification.guid);

		} 
		catch (error) {
			success = false;
			console.error(error);
		}
		var successStr = success ? "successful." : "failed.";
        if(!success){
            StatusManager.setStatus("Delete notification " + successStr);
        }
    }

	//Acknowledge Notification:
	async function onAcknowledgeNotification(e, notification){
		//console.log("Acknowledged Notification.");
		//console.log(notification);
		let adapter = await getAdapter();
		await adapter.notification.acknowledgeNotification(notification.guid);
	}


	//Render:
	return (
		<React.Fragment>
			<div 
				style={{
					display: 'flex',
					padding:'10px',
					borderBottom:'1px solid #CCC',
					...(!notification.acknowledged_on && {backgroundColor:"#fbfffe" }) //Acknowledged notification styles
				}}
			>
				<Badge variant={ !notification.acknowledged_on ? "dot" : ""} color="secondary">
					<Email style={{'paddingRight':"2px"}}/>
				</Badge>
                <Grid container style={{paddingLeft:20}}>
                    <Grid item xs={10}>
                        <Box color="text.primary">{notification.title}</Box>
                        <Box color="text.secondary">{notification.content}</Box>
						{notification.application_link && <Box color="text.primary"><a href={notification.application_link}>View Application</a></Box>}
						{notification.message_link && <Box color="text.primary"><a href={notification.message_link}>View Message</a></Box>}
						{notification.course_link && <Box color="text.primary"><a href={notification.course_link}>View Course</a></Box>}						
						{notification.local_timestamp && 
						<>
							<Box color="text.secondary" style={{textAlign:"right", fontSize:12}}>{notification.local_timestamp.dateStr}</Box>
							<Box color="text.secondary" style={{textAlign:"right", fontSize:12}}>{notification.local_timestamp.timeStr}</Box>
						</>}
                    </Grid>
                    <Grid item xs={2}>
                        <Grid container justifyContent="flex-end">
							{
								!notification.acknowledged_on && 
								<IconButton onClick={(e)=>{onAcknowledgeNotification(e, notification)}}>                                
									<CheckCircleOutline />								
                            	</IconButton>
							}
                            <IconButton onClick={(e)=>{onDismissNotification(e, notification)}}>
                                <Delete/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
			</div>

		</React.Fragment>
	);
}

//Button AND tray:
export default function NotificationsButton() {

	//States:
	const [anchorEl, setAnchorEl] = React.useState(null); //MUI5 popper component uses anchorEl property to determine which DOM object to anchor to:
	const [open, setOpen] = React.useState(false);
	const [placement, setPlacement] = React.useState("bottom-end");
	const [notifications, setNotifications] = React.useState([]);
	const [isLoading, setIsLoading] = React.useState(false);

    //Status message:
    const { StatusManager } = useContext(StatusMessageContext);
	var socket;

	//Is component currently mounted?:
	const isMounted = useRef(false);
	useEffect(() => {
		isMounted.current = true;
		return () => { 
			isMounted.current = false;
			if(socket) socket.close();
		 }
	}, []);

	//On load:
	useEffect(() => {
		if(isMounted.current){
			if(!anchorEl) setAnchorEl(document.getElementById("oted-portal-toolbar"));
			setIsLoading(true);
		}

		//See socket.io documentation:
		socket = socketIOClient(`${SOCKET_URL}`, {transports: ['websocket', 'polling', 'flashsocket']});
		
		//On connection established:
		socket.on('connect', ()=>{
			socket.emit('authenticate_notification', {token: baseAdapter.getAuthHeader()});	
		});
		
		//On receiving notifications from server:
		socket.on('notifications', (data)=> {
			var notificationData = data.map((e) => ({
				guid: e.guid,
				title: e.title,
				content: e.body,
				user_id: e.user_id,
				application_id: e.application_id,
				message_id: e.message_id,
				course_id: e.course_id,
				acknowledged_on: e.acknowledged_on,
				created_on: e.created_on,
				updated_at: e.updated_on,
				is_active: e.is_active,
				type: "System Message",
				data: {}
				}));
			/*
				These ones that map to themselves can be removed.  I just left them there so you can see the fields available.
				The applications_id and message_id are nullable, but can link back to the origin of the notification.
				is_active will be set to 0 when you click to remove the notification.
				acknowledged_on will be set when you want to show they have seen it.
			*/
			if(isMounted.current){
				setNotifications(notificationData);
				setIsLoading(false);
			}
		});

		//On connection error:
		socket.on('connect_error', ()=>{
			console.log("socket connection error");
			socket.close();
			//Attempt to reconnect every 10 seconds:
			// setTimeout(()=>socket.connect(),10000);
		});

		// //On disconnect:
		// socket.on('disconnect',()=> {
		// 	console.log('server disconnected');	
		// 	//Attempt to reconnect every 10 seconds:
		// 	setTimeout(()=>socket.connect(),10000); 
		// });		
	}, []);

	//Open notifications tray:
	function onNotificationsBtn(event){
		if(isMounted.current){
			setOpen(!open);
		}
	}

    //Add debug notifications:
    async function onDebugBtn(event){
        for(var i = 1; i <= 5; i++){
            await createDebugNotification(i);
        }
    }

    async function createDebugNotification(counter){
        /*
            let data = {
                title: req.body.title,
                body: req.body.body,
                application_id: req.body.application_id,
                message_id: req.body.message_id,
                user_id: session.data.id
            };
        */
        let success = true;
		try {
			var adapter = await getAdapter();
            var user = await adapter.getLoggedInUser();
			await adapter.notification.create({
                title:"Debug Notification " + counter,
                body:"This is a debug notification designed to test acknowledge/remove notification functionality.",
                application_id: 'F87E1A70-5D9A-4DCA-9B2B-4DABD10EDBA8',
                message_id: 'B2420AAE-6EBA-4293-BEDE-554B00A96125',
				course_id: null,
                user_id: user.guid
            });
		} 
		catch (error) {
			success = false;
			console.error(error);
		}
		var successStr = success ? "successful." : "failed.";
        StatusManager.setStatus("Create notification " + successStr);
    }

	//Populate Notifications:
	function populateNotifications(){
        if(notifications.length > 0){
            let content = [];
            for(var i = 0; i < notifications.length; i++) {
				//console.log(notifications);
                content.push(<Notification key={i} notification={notifications[i]} />);
            }
            return content;
        }
        else{
            return <Box sx={{padding:2}}>No notifications.</Box>
        }

	}

	async function onClearNotificationsBtn(){
		try{
			var adapter = await getAdapter();
			var response = await adapter.notification.removeAllNotifications();
		}
		catch(error){
			console.error(error);
		}
	}

	const debugMode = false;

	//Render:
	return (
		<React.Fragment>
			{/*Notifications Button*/}
			<IconButton color="lightOnDark" aria-describedby={'notifications-tray'} onClick={onNotificationsBtn} aria-label="Notifications">
				<Badge badgeContent={notifications.length > 99 ? "99+" : notifications.length.toString()} color="secondary">
					<Notifications />
				</Badge>
			</IconButton>
			{ debugMode &&
            <IconButton color="lightOnDark" onClick={onDebugBtn} aria-label="Debug">
                <Construction />
			</IconButton>
			}
			{/*Notifications Tray*/}
			<Popper
				open={open}
				anchorEl={anchorEl}
				placement={placement}	
				sx={{maxWidth:"20vw", minWidth:'350px', boxShadow:3}}
				modifiers={[
					{
						name: 'flip',
						enabled: false,
						options: {
							altBoundary: true,
							rootBoundary: 'document',
							padding: 8
						}
					},
					{
						name: "offset",
						options: {
							offset: [-3, 3]
						}
					},
					{
						name: 'preventOverflow',
						enabled: true,
						options: {
						  altAxis: false,
						  altBoundary: false,
						  tether: false,
						  rootBoundary: 'document',
						  
						}
					}
				]}

			>
				{ notifications.length > 0 &&
				<Box sx={{backgroundColor:"#fff", padding:1}}>			
					<Link component="button" onClick={onClearNotificationsBtn}>Clear All Notifications</Link>
				</Box>
				}
				<Box sx={{backgroundColor:"#fffcf7"}} style={{maxHeight:'80vh', overflowY:'auto', minHeight:60}}>
					{	
						isLoading ? <LoadingIndicator/> : 
						populateNotifications()
					}
				</Box>
			</Popper>
		</React.Fragment>
	);
}
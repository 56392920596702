import React, { useContext, useEffect, useState, useRef } from 'react';
import { Accordion, AccordionSummary, AccordionDetails} from '@mui/material';
import theme, { FormSection, FormSectionHeading } from "../theme/PortalTheme";
import { ExpandMore } from '@mui/icons-material';
import { Link } from "react-router-dom";
import ExamplePdf from "../media/bulk_import_instructors_template.csv";

const alts = require('../Alts.js');

const bulletStyle={
    marginBottom: theme.spacing(2)
};

const appImageStyle={
    maxWidth: '80%',
    height: 'auto',
    border: '1px solid #000'
};

const appImageContainerStyle={
    display: 'flex',
    justifyContent: 'left'
}

export const LibraryTopics = [
    {
        roles:["all"],
        title:"How to Sign Into the ICD Management Portal",
        id:"topic8",
        component:<Topic8/>
    },
    {
        roles:["all"],
        title:"How to Reset Your ICD Management Portal Password",
        id:"topic9",
        component:<Topic9/>
    },
    {
        roles:["all"],
        title:"How to Update Your Profile Information",
        id:"topic2",
        component:<Topic2/>
    },
    {
        roles:["all"],
        title:"How to View Your Notifications",
        id:"topic4",
        component:<Topic4/>
    },
    {
        roles:["applicant_poc", "oted_pa", "superadmin"],
        title:"How to View Available ICD Courses",
        id:"topic3",
        component:<Topic3/>
    },                     
    {
        roles:["applicant_poc", "oted_pa", "superadmin"],
        title:"How to Apply to Deliver an ICD Course",
        id:"topic1",
        component:<Topic1/>
    },
    {
        roles:["applicant_poc", "oted_pa", "superadmin"],
        title:"How to View or Edit an ICD Application",
        id:"topic6",
        component:<Topic6/>
    },
    {
        roles:["applicant_poc", "oted_pa", "superadmin"],
        title:"How to Message Your ICD Program Administrator",
        id:"topic5",
        component:<Topic5/>
    },
    {
        roles:["applicant_poc", "oted_pa", "superadmin"],
        title:"How to Access Course Materials",
        id:"topic10",
        component:<Topic10/>
    },
    {
        roles:["oted_pa", "oted_ff", "superadmin"],
        title:"How to Manage Course Materials",
        id:"topic11",
        component:<Topic11/>
    },    
    {
        roles:["applicant_poc", "oted_pa", "superadmin"],
        title:"How to View or Export an Application Status Report",
        id:"topic7",
        component:<Topic7/>
    },
    {
        roles:["oted_pa", "superadmin"],
        title:"How to Create Multiple Qualified Instructor (QI) Entries",
        id:"topic12",
        component:<Topic12/>
    }
];

export function TopicContainer(props){
    let controls = props.id + "-content";
    let id = props.id + "-header";
    return(
        <Accordion>
            <AccordionSummary sx={{color:theme.palette.primary.main}}
                 expandIcon={<ExpandMore />}
                 aria-controls={controls}
                 id={id}
            >
                {props.title}
            </AccordionSummary>
            <AccordionDetails>
                {props.children}
            </AccordionDetails>
        </Accordion>
    )
}

export function Topic1(){
    return(
        <TopicContainer title="How to Apply to Deliver an ICD Course" id="topic1">
            <FormSection>
            <p>You can apply to deliver an Independent Course Delivery of an OTED course directly within the ICD Management Portal.</p>
            <p>To initiate an ICD Application:</p>
            <ol>
                <li style={bulletStyle}>After logging into the ICD Management Portal, select the <strong>Menu</strong> button to open the navigation bar.
                    <TopicContainer title="Display Example Image" id="topic_1_1"><img src={require('../images/topic_1_1.png')} style={appImageStyle} alt={alts.ALT_TOPIC_1_1}/></TopicContainer>
                </li>
                <li style={bulletStyle}>Select the <strong>Applications</strong> option from the options that appear within the navigation bar.
                    <TopicContainer title="Display Example Image" id="topic_1_2"><img src={require('../images/topic_1_2.png')} style={appImageStyle} alt={alts.ALT_TOPIC_1_2}/></TopicContainer>
                </li>
                <li style={bulletStyle}>The <strong>Applications</strong> page will appear. If you have already initiated or completed any ICD Applications, they will all be listed here.
                    <TopicContainer title="Display Example Image" id="topic_1_3"><img src={require('../images/topic_1_3.png')} style={appImageStyle} alt={alts.ALT_TOPIC_1_3}/></TopicContainer>
                </li>
                <li style={bulletStyle}>From this page, select the <strong>Create Application</strong> button to begin the process of applying to deliver an OTED course.
                    <TopicContainer title="Display Example Image" id="topic_1_4"><img src={require('../images/topic_1_4.png')} style={appImageStyle} alt={alts.ALT_TOPIC_1_4}/></TopicContainer>
                </li>
                <li style={bulletStyle}>The <strong>Create ICD Application &ndash; Delivery Information</strong> page will appear. On this page, you will select the FDA course your organization wishes to deliver and specify the training location and proposed training delivery dates.
                    <TopicContainer title="Display Example Image" id="topic_1_5"><img src={require('../images/topic_1_5.png')} style={appImageStyle} alt={alts.ALT_TOPIC_1_5}/></TopicContainer>
                </li>
                <li style={bulletStyle}>Once you have finished entering this information, select the <strong>Save and Continue</strong> button to move forward. The information you have entered on this page will be saved.
                    <TopicContainer title="Display Example Image" id="topic_1_6"><img src={require('../images/topic_1_6.png')} style={appImageStyle} alt={alts.ALT_TOPIC_1_6}/></TopicContainer>
                </li>
                <li style={bulletStyle}>The <strong>Create ICD Application &ndash; Instructors</strong> page will appear. From this page, you will be able to either select Qualified Instructors that have already been approved to teach the selected FDA course, or enter the names of new instructors that are not yet qualified to teach the selected FDA course.
                    <TopicContainer title="Display Example Image" id="topic_1_7"><img src={require('../images/topic_1_7.png')} style={appImageStyle} alt={alts.ALT_TOPIC_1_7}/></TopicContainer>
                </li>
                <ol style={{"listStyleType": "lower-alpha"}}>
                    <li style={{marginTop:theme.spacing(2), ...bulletStyle}}>Select the <strong>Select Qualified Instructor(s)</strong> button to select the names of the instructor(s) that are already qualified to teach this course that you would like to use for your delivery.
                        <TopicContainer title="Display Example Image" id="topic_1_8"><img src={require('../images/topic_1_8.png')} style={appImageStyle} alt={alts.ALT_TOPIC_1_8}/></TopicContainer>
                    </li>
                    <p><strong><em>Note:</em></strong><em> You are responsible for contacting and making arrangements with these individuals before selecting them from this list. Selecting them from this list does not confirm their availability to teach your course!</em></p>
                    <li style={bulletStyle}>Select the <strong>Add New Instructor</strong> button if you would like to use new instructors who have not yet attained Qualified Instructor status from OTED to teach this course. You will need to provide their first name, last name, and email address.
                        <TopicContainer title="Display Example Image" id="topic_1_9"><img src={require('../images/topic_1_9.png')} style={appImageStyle} alt={alts.ALT_TOPIC_1_9}/></TopicContainer>
                    </li>
                </ol>
                <li style={bulletStyle}>When you have finished entering information on this page, select the <strong>Save and Continue</strong> button to move forward. The information you have entered on this page will be saved.
                    <TopicContainer title="Display Example Image" id="topic_1_10"><img src={require('../images/topic_1_10.png')} style={appImageStyle} alt={alts.ALT_TOPIC_1_10}/></TopicContainer>
                </li>
                <li style={bulletStyle}>The <strong>Create ICD Application &ndash; Contact</strong> Information page will appear. Your organization name, and the point of contact name and email address within the system will automatically populate. Confirm your phone number and work mailing address, and select the <strong>Save and Continue</strong> button to move forward.
                    <TopicContainer title="Display Example Image" id="topic_1_11"><img src={require('../images/topic_1_11.png')} style={appImageStyle} alt={alts.ALT_TOPIC_1_11}/></TopicContainer>
                </li>
                <li style={bulletStyle}>The final page of the ICD Application is the <strong>Create ICD Application &ndash; Agreements</strong> page. On this page, you must select the <strong>I Agree</strong> checkbox next to each statement listed on this page to confirm your organization's agreement with OTED's Independent Course Delivery terms.
                    <TopicContainer title="Display Example Image" id="topic_1_12"><img src={require('../images/topic_1_12.png')} style={appImageStyle} alt={alts.ALT_TOPIC_1_12}/></TopicContainer>
                </li>
                <li style={bulletStyle}>Once you have finished confirming all agreements, select the <strong>Submit Application</strong> button to submit your ICD Application to OTED.
                <p>An OTED ICD Program Administrator (ICD PA) will begin reviewing your ICD Application, and you will receive a notification once the application is approved or if further action is needed.</p>
                    <TopicContainer title="Display Example Image" id="topic_1_13"><img src={require('../images/topic_1_13.png')} style={appImageStyle} alt={alts.ALT_TOPIC_1_13}/></TopicContainer>
                </li>
            </ol>
            </FormSection>
        </TopicContainer>
    )
}

export function Topic2(){
    return(
        <TopicContainer title="How to Update Your Profile Information" id="topic2">
            <FormSection>
            <p>You can update your personal information within the ICD Management Portal at any time within the <strong>My Profile</strong> page.</p>
            <p>To make updates to your personal information:</p>
            <ol>
                <li style={bulletStyle}>After logging into the ICD Management Portal, select the <strong>Menu</strong> button to open the navigation bar.
                    <TopicContainer title="Display Example Image" id="topic_2_1"><img src={require('../images/topic_2_1.png')} style={appImageStyle} alt={alts.ALT_TOPIC_2_1}/></TopicContainer>
                </li>
                <li style={bulletStyle}>Select the <strong>My Profile</strong> option from the options that appear within the navigation bar.
                    <TopicContainer title="Display Example Image" id="topic_2_2"><img src={require('../images/topic_2_2.png')} style={appImageStyle} alt={alts.ALT_TOPIC_2_2}/></TopicContainer>
                </li>
                <li style={bulletStyle}>The <strong>My Profile</strong> page will appear. From this page, you can make changes to the following information fields:
                    <ol style={{"listStyleType": "lower-alpha"}}>
                        <li style={{marginTop:theme.spacing(2), ...bulletStyle}}>Last Name</li>
                        <li style={bulletStyle}>Middle Initial</li>
                        <li style={bulletStyle}>First Name</li>
                        <li style={bulletStyle}>Phone Number</li>
                        <li style={bulletStyle}>Street</li>
                        <li style={bulletStyle}>City</li>
                        <li style={bulletStyle}>State</li>
                        <li style={bulletStyle}>Zip</li>
                    </ol>
                    <p ><strong><em>Note:</em></strong> <em>If you need to change your organization name or your contact email address, please contact your ICD Program Administrator.</em></p>
                    <TopicContainer title="Display Example Image" id="topic_2_3"><img src={require('../images/topic_2_3.png')} style={appImageStyle} alt={alts.ALT_TOPIC_2_3}/></TopicContainer>
                    <p>You can also designate whether you want your ICD Management Portal notifications to also be sent to your email address by selecting the <strong>Send Notification Emails</strong> button.</p>
                    <TopicContainer title="Display Example Image" id="topic_2_4"><img src={require('../images/topic_2_4.png')} style={appImageStyle} alt={alts.ALT_TOPIC_2_4}/></TopicContainer>
                </li>
                <li style={bulletStyle}>Select the <strong>Save</strong> button to save any changes.
                    <TopicContainer title="Display Example Image" id="topic_2_5"><img src={require('../images/topic_2_5.png')} style={appImageStyle} alt={alts.ALT_TOPIC_2_5}/></TopicContainer>
                </li>
            </ol>
            </FormSection>
        </TopicContainer>
    )
}

export function Topic3(){
    return(
        <TopicContainer title="How to View Available ICD Courses" id="topic3">
            <FormSection>
            <p>You can view the list of FDA courses available for Independent Course Delivery within the <strong>Available Courses</strong> page.</p>
            <p>To view the list of current ICD-eligible courses:</p>
            <ol>
                <li style={bulletStyle}>After logging into the ICD Management Portal, select the <strong>Menu</strong> button to open the navigation bar.
                    <TopicContainer title="Display Example Image" id="topic_3_1"><img src={require('../images/topic_3_1.png')} style={appImageStyle} alt={alts.ALT_TOPIC_3_1}/></TopicContainer>
                </li>
                <li style={bulletStyle}>Select the <strong>Courses</strong> option from the options that appear within the navigation bar.
                    <TopicContainer title="Display Example Image" id="topic_3_2"><img src={require('../images/topic_3_2.png')} style={appImageStyle} alt={alts.ALT_TOPIC_3_2}/></TopicContainer>
                </li>
                <li style={bulletStyle}>The <strong>Available Courses </strong>page will appear. From this page, you can view a list of all FDA courses currently available for Independent Course Delivery.
                    <TopicContainer title="Display Example Image" id="topic_3_3"><img src={require('../images/topic_3_3.png')} style={appImageStyle} alt={alts.ALT_TOPIC_3_3}/></TopicContainer>
                </li>
                <li style={bulletStyle}>Select the <strong>View</strong> button next to any course title to access the <strong>View Course</strong> page.
                <TopicContainer title="Display Example Image" id="topic_3_4"><img src={require('../images/topic_3_4.png')} style={appImageStyle} alt={alts.ALT_TOPIC_3_4}/></TopicContainer>
                    <p>From this page, you can view the following information for the selected course:</p>
                    <ol style={{"listStyleType": "lower-alpha"}}>
                        <li style={{marginTop:theme.spacing(2), ...bulletStyle}}>FDA Course Number</li>
                        <li style={bulletStyle}>Course Title</li>
                        <li style={bulletStyle}>Course Delivery Mode</li>
                        <li style={bulletStyle}>Course Description</li>
                    </ol>
                    <TopicContainer title="Display Example Image" id="topic_3_5"><img src={require('../images/topic_3_5.png')} style={appImageStyle} alt={alts.ALT_TOPIC_3_5}/></TopicContainer>
                </li>
                <li style={bulletStyle}>Select the <strong>Back</strong> button to return to the <strong>Available Courses</strong> page.
                    <TopicContainer title="Display Example Image" id="topic_3_6"><img src={require('../images/topic_3_6.png')} style={appImageStyle} alt={alts.ALT_TOPIC_3_6}/></TopicContainer>
                </li>
            </ol>
            </FormSection>
        </TopicContainer>
    )
}

export function Topic4(){
    return(
        <TopicContainer title="How to View Your Notifications" id="topic4">
            <FormSection>
            <p>You will automatically receive notifications whenever a significant action happens within your ICD Management Portal account, such as OTED approving your ICD Application, or OTED providing you with a document for review.</p>
            <p>To view your system notifications:</p>
            <ol>
                <li style={bulletStyle}>Select the <strong>Bell</strong> icon from the dashboard. The number of unread notifications that you have will be displayed on the icon.
                    <TopicContainer title="Display Example Image" id="topic_4_1"><img src={require('../images/topic_4_1.png')} style={appImageStyle} alt={alts.ALT_TOPIC_4_1}/></TopicContainer>
                </li>
                <li style={bulletStyle}>All unread notifications will appear. Once you have finished reading a notification, you may optionally delete it by selecting the <strong>Trash</strong> icon.
                    <TopicContainer title="Display Example Image" id="topic_4_2"><img src={require('../images/topic_4_2.png')} style={appImageStyle} alt={alts.ALT_TOPIC_4_2}/></TopicContainer>
                </li>
            </ol>
            </FormSection>
        </TopicContainer>
    )
}

export function Topic5(){
    return(
        <TopicContainer title="How to Message Your ICD Program Administrator" id="topic5">
            <FormSection>
            <p>Once you have submitted an ICD Application, you can message the OTED ICD Program Administrator (ICD PA) who will be supporting you through the ICD Application and course delivery process with any questions you may have.</p>
            <p>To message your ICD PA, you must first select the application related to your question/message:</p>
            <ol>
                <li style={bulletStyle}>After logging into the ICD Management Portal, select the <strong>Menu</strong> button to open the navigation bar.
                    <TopicContainer title="Display Example Image" id="topic_5_1"><img src={require('../images/topic_5_1.png')} style={appImageStyle} alt={alts.ALT_TOPIC_5_1}/></TopicContainer>
                </li>
                <li style={bulletStyle}>Select the <strong>Applications</strong> option from the options that appear within the navigation bar.
                    <TopicContainer title="Display Example Image" id="topic_5_2"><img src={require('../images/topic_5_2.png')} style={appImageStyle} alt={alts.ALT_TOPIC_5_2}/></TopicContainer>
                </li>
                <li style={bulletStyle}>The <strong>Applications</strong> page will appear. On this page, all of your organization's current ICD applications will be listed.
                    <TopicContainer title="Display Example Image" id="topic_5_3"><img src={require('../images/topic_5_3.png')} style={appImageStyle} alt={alts.ALT_TOPIC_5_3}/></TopicContainer>
                </li>
                <li style={bulletStyle}>From this page, select the <strong>Messages</strong> button next to the application related to your question/message.
                    <TopicContainer title="Display Example Image" id="topic_5_4"><img src={require('../images/topic_5_4.png')} style={appImageStyle} alt={alts.ALT_TOPIC_5_4}/></TopicContainer>
                </li>
                <li style={bulletStyle}>The <strong>Messages</strong> page will appear. From this page, you can directly send the ICD PA messages and attachments. This page will also house a running log of all messages and files sent between you and the ICD PA throughout the lifespan of this ICD Application and course delivery process.
                    <TopicContainer title="Display Example Image" id="topic_5_5"><img src={require('../images/topic_5_5.png')} style={appImageStyle} alt={alts.ALT_TOPIC_5_5}/></TopicContainer>
                </li>
                <li style={bulletStyle}>To send a message, simply type in the messages field and select the <strong>Send</strong> button when finished. To attach a file, select the <strong>Attachment</strong> button and select the file you would like to send.
                    <TopicContainer title="Display Example Image" id="topic_5_6"><img src={require('../images/topic_5_6.png')} style={appImageStyle} alt={alts.ALT_TOPIC_5_6}/></TopicContainer>
                </li>
            </ol>
            </FormSection>
        </TopicContainer>
    )
}

export function Topic6(){
    return(
        <TopicContainer title="How to View or Edit an ICD Application" id="topic6">
            <FormSection>
            <p>Once you have started an ICD Application, you can view it at any time. Until you submit your ICD Application to OTED, you can also edit your application information freely.</p>
            <p>To view or edit an ICD Application:</p>
            <ol>
                <li style={bulletStyle}>After logging into the ICD Management Portal, select the <strong>Menu</strong> button to open the navigation bar.
                    <TopicContainer title="Display Example Image" id="topic_6_1"><img src={require('../images/topic_6_1.png')} style={appImageStyle} alt={alts.ALT_TOPIC_6_1}/></TopicContainer>
                </li>
                <li style={bulletStyle}>Select the <strong>Applications</strong> option from the options that appear within the navigation bar.
                    <TopicContainer title="Display Example Image" id="topic_6_2"><img src={require('../images/topic_6_2.png')} style={appImageStyle} alt={alts.ALT_TOPIC_6_2}/></TopicContainer>
                </li>
                <li style={bulletStyle}>The <strong>Applications</strong> page will appear. On this page, all of your organization's current ICD applications will be listed.
                    <TopicContainer title="Display Example Image" id="topic_6_3"><img src={require('../images/topic_6_3.png')} style={appImageStyle} alt={alts.ALT_TOPIC_6_3}/></TopicContainer>
                </li>
                <li style={bulletStyle}>From this page, select the <strong>View/Edit</strong> button next to the application you wish to view or edit.
                    <TopicContainer title="Display Example Image" id="topic_6_4"><img src={require('../images/topic_6_4.png')} style={appImageStyle} alt={alts.ALT_TOPIC_6_4}/></TopicContainer>
                </li>
                <li style={bulletStyle}>Your ICD Application will appear. You can make any changes to the information fields as desired. Select the <strong>Save and Continue</strong> button when you have finished making changes on a page so that your updates are saved.
                    <TopicContainer title="Display Example Image" id="topic_6_5"><img src={require('../images/topic_6_5.png')} style={appImageStyle} alt={alts.ALT_TOPIC_6_5}/></TopicContainer>
                </li>
                <li style={bulletStyle}>If you want to navigate between the pages of your ICD Application, select the <strong>Back</strong> and <strong>Next</strong> buttons by the ICD Application timeline.
                    <TopicContainer title="Display Example Image" id="topic_6_6"><img src={require('../images/topic_6_6.png')} style={appImageStyle} alt={alts.ALT_TOPIC_6_6}/></TopicContainer>
                </li>
            </ol>
            </FormSection>
        </TopicContainer>
    )
}

export function Topic7(){
    return(
        <TopicContainer title="How to View or Export an Application Status Report" id="topic7">
            <FormSection>
                <p>You can access and download an <strong>Application Status Report, </strong>which contains an at-a-glance listing of the status of all of your organization's ICD Applications, at any time from your dashboard.</p>
                <p>To access and download your Application Status Report:</p>
                <ol>
                    <li style={bulletStyle}>Select the <strong>View Report</strong> link, located at the bottom of the <strong>Application Status Breakdown</strong> dashboard card.
                        <TopicContainer title="Display Example Image" id="topic_7_1"><img src={require('../images/topic_7_1.png')} style={appImageStyle} alt={alts.ALT_TOPIC_7_1}/></TopicContainer>
                    </li>
                    <li style={bulletStyle}>Your <strong>Application Status Report</strong> page will appear, containing a complete listing of all of your organization's ICD Applications
                        <TopicContainer title="Display Example Image" id="topic_7_2"><img src={require('../images/topic_7_2.png')} style={appImageStyle} alt={alts.ALT_TOPIC_7_2}/></TopicContainer>
                    </li>
                    <li style={bulletStyle}>Select the <strong>View</strong> link next to any of the listed ICD Applications to navigate directly to that ICD Application.
                        <TopicContainer title="Display Example Image" id="topic_7_3"><img src={require('../images/topic_7_3.png')} style={appImageStyle} alt={alts.ALT_TOPIC_7_3}/></TopicContainer>
                    </li>
                    <li style={bulletStyle}>Select the <strong>Export Report as PDF</strong> button to download your Application Status Report as a .pdf document.
                        <TopicContainer title="Display Example Image" id="topic_7_4"><img src={require('../images/topic_7_4.png')} style={appImageStyle} alt={alts.ALT_TOPIC_7_4}/></TopicContainer>
                    </li>
                </ol>
            </FormSection>
        </TopicContainer>
    )
}

export function Topic8(){
    return(
        <TopicContainer title="How to Sign Into the ICD Management Portal" id="topic8">
            <FormSection>
                <p>Once an account has been created for you, you can log into the ICD Management Portal online at any time.</p>
                <p>To log into the ICD Management Portal:</p>

                <ol>
                    <li style={bulletStyle}>Go to <a href='https://oted.e-paga.com/login'>https://oted.e-paga.com/login</a> and enter email and password that is registered to your OTED ICD Management Portal account. 
                        <TopicContainer title="Display Example Image" id="topic_8_1"><img src={require('../images/topic_8_1.png')} style={appImageStyle} alt={alts.ALT_TOPIC_8_1}/></TopicContainer>
                    </li>
                    <li style={bulletStyle}>If login is a success, your email connected to the OTED Management will receive a <strong>one-time 6-digit confirmation code.</strong> 
                        <TopicContainer title="Display Example Image" id="topic_8_2"><img src={require('../images/topic_8_2.png')} style={appImageStyle} alt={alts.ALT_TOPIC_8_2}/></TopicContainer>
                    </li>
                    <li style={bulletStyle}>Enter the 6-digit code on <strong>Sign In</strong> page. If you did not receive or see a code in your inbox, select the <strong>Resend code</strong> link for another. 
                        <TopicContainer title="Display Example Image" id="topic_8_3"><img src={require('../images/topic_8_3.png')} style={appImageStyle} alt={alts.ALT_TOPIC_8_3}/></TopicContainer>
                    </li>
                    <li style={bulletStyle}>Once the code is entered correctly, you will have successfully signed into your ICD Management Portal account. 
                    <br/><strong>NOTE:</strong> If you do not want to continuously enter a code, select the <strong>Don’t ask me again for 24 hours</strong> box before entering the 6-digit code. 

                        <TopicContainer title="Display Example Image" id="topic_8_4"><img src={require('../images/topic_8_4.png')} style={appImageStyle} alt={alts.ALT_TOPIC_8_4}/></TopicContainer>
                    </li>
                </ol>
            </FormSection>
        </TopicContainer>
    )
}

export function Topic9(){
    return(
        <TopicContainer title="How to Reset Your ICD Management Portal Password" id="topic9">
            <FormSection>
                <p>In the event that you forget your ICD Management Portal password, you can access your account by following these steps:</p>

                <ol>
                    <li style={bulletStyle}>On OTED ICD Management Portal <strong>Sign In</strong> page, select the <strong>Forgot password?</strong> link at the bottom of the page. 
                        <TopicContainer title="Display Example Image" id="topic_9_1"><img src={require('../images/topic_9_1.png')} style={appImageStyle}  alt={alts.ALT_TOPIC_9_1}/></TopicContainer>
                    </li>
                    <li style={bulletStyle}>You will be redirected to the <strong>Forgot Password</strong> page. Enter email address used for ICD Management Portal account and click the <strong>Reset Password</strong> link. An email will be sent to the email address entered.  
                        <TopicContainer title="Display Example Image" id="topic_9_2"><img src={require('../images/topic_9_2.png')} style={appImageStyle}  alt={alts.ALT_TOPIC_9_2}/></TopicContainer>
                    </li>
                    <li style={bulletStyle}>Once email from E-PAGA Webmaster arrives in your inbox, use the temporary password, and select the <strong>click here</strong> link. The hyperlink will redirect you to the <strong>Reset Password</strong> page.  
                        <TopicContainer title="Display Example Image" id="topic_9_3"><img src={require('../images/topic_9_3.png')} style={appImageStyle} alt={alts.ALT_TOPIC_9_3}/></TopicContainer>
                    </li>
                    <li style={bulletStyle}>Input your ICD Management Portal email, current password <strong>(the temporary password sent by email)</strong>, new password <strong>(of your choosing, that meets the 8-character minimum)</strong> and retype new password into the <strong>Verifying New Password</strong> field.
                        <TopicContainer title="Display Example Image" id="topic_9_4"><img src={require('../images/topic_9_4.png')} style={appImageStyle} alt={alts.ALT_TOPIC_9_4}/></TopicContainer>
                    </li>
                    <li style={bulletStyle}>At the bottom of the <strong>Reset Password</strong> page, a notification should appear stating that your password has reset successfully. 
                        <TopicContainer title="Display Example Image" id="topic_9_5"><img src={require('../images/topic_9_5.png')} style={appImageStyle} alt={alts.ALT_TOPIC_9_5}/></TopicContainer>
                    </li>
                    <li style={bulletStyle}>Select the <strong>Login</strong> button which will redirect you back to the <strong>Sign In</strong> page and enter your ICD Management Portal email address and new password. Signing in should now be a success!
                        <TopicContainer title="Display Example Image" id="topic_9_6"><img src={require('../images/topic_9_6.png')} style={appImageStyle} alt={alts.ALT_TOPIC_9_6}/></TopicContainer>
                    </li>
                </ol>
            </FormSection>
        </TopicContainer>
    )
}

export function Topic10(){
    return(
        <TopicContainer title="How to Access Course Materials" id="topic10">
            <FormSection>
                <p>Shortly after your ICD Application is processed, you will be able to retrieve the course materials to review and share with your course instructors.</p>
                <ul>
                    <li style={bulletStyle}><strong>NOTE:</strong> Because OTED regularly updates their course materials, be sure that you <strong><em>only</em></strong> use the course materials provided after your ICD Application has been processed; do not retain and use course materials from previous course deliveries.</li>
                </ul>
                <p>To access course materials:</p>
                <ol>
                    <li style={bulletStyle}>After logging into the ICD Management Portal, select the <strong>Menu</strong> button to open the navigation bar.
                        <TopicContainer title="Display Example Image" id="topic_10_1"><img src={require('../images/topic_10_1.png')} style={appImageStyle} alt={alts.ALT_TOPIC_10_1}/></TopicContainer>
                    </li>                    
                    <li style={bulletStyle}>Select the <strong>Applications</strong> option from the options that appear with the navigation bar.
                        <TopicContainer title="Display Example Image" id="topic_10_2"><img src={require('../images/topic_10_2.png')} style={appImageStyle} alt={alts.ALT_TOPIC_10_2}/></TopicContainer>
                    </li> 
                    <li style={bulletStyle}>The <strong>Applications</strong> page will appear. Once an application is reviewed and approved by OTED, the status of that application will show as <strong>Processed</strong>.
                        <TopicContainer title="Display Example Image" id="topic_10_3"><img src={require('../images/topic_10_3.png')} style={appImageStyle} alt={alts.ALT_TOPIC_10_3}/></TopicContainer>
                    </li>                     
                    <li style={bulletStyle}>Under the <strong>Actions</strong> column, select the <strong>Course Materials</strong> button.
                        <TopicContainer title="Display Example Image" id="topic_10_4"><img src={require('../images/topic_10_4.png')} style={appImageStyle} alt={alts.ALT_TOPIC_10_4}/></TopicContainer>
                    </li>                    
                    <li style={bulletStyle}>The Course Materials page will appear for that course.
                        <TopicContainer title="Display Example Image" id="topic_10_5"><img src={require('../images/topic_10_5.png')} style={appImageStyle}  alt={alts.ALT_TOPIC_10_5}/></TopicContainer>
                        <ol style={{"listStyleType": "lower-alpha"}}>
                            <li style={{marginTop:theme.spacing(2), ...bulletStyle}}><strong>NOTE:</strong> It may take several days after application processing for the course materials to become available. If the Course Materials button does not appear under the Actions column after several days,  feel free to message your ICD Program Administrator (ICD PA). You can contact the ICD PA by selecting the <strong>Messages</strong> button on the Application page.
                            <TopicContainer title="Display Example Image" id="topic_10_6"><img src={require('../images/topic_10_6.png')} style={appImageStyle} alt={alts.ALT_TOPIC_10_6}/></TopicContainer>
                            </li>
                        </ol>
                    </li>                    
                </ol>
            </FormSection>
        </TopicContainer>
    )
}

export function Topic11(){
    return(
        <TopicContainer title="How to Manage Course Materials" id="topic11">
            <FormSection>
                <p>Once OTED has authorized a State or external partner organization to host an Independent Course Delivery (ICD) of an OTED course, the course’s materials must be uploaded for the State or external partner organization to retrieve and share with their ICD Instructors.</p>                
                <p>The course materials for all ICD-eligible courses can be easily uploaded to and managed within the ICD Management Portal via the following steps.</p>
                <ul>
                    <li style={bulletStyle}><strong>NOTE:</strong><em> For security purposes, all course materials must be uploaded to the ICD Management Portal in either .pptx or .pdf format. Please convert any .doc/.docx files into an accessible .pdf format prior to upload.</em></li>
                </ul>
                <p style={{marginTop:theme.spacing(4)}}><strong>To upload and manage course materials within the ICD Management Portal:</strong></p>
                <ol>
                    <li style={bulletStyle}>After logging into the ICD Management Portal, select the <strong>Menu</strong> button to open the navigation bar.
                        <TopicContainer title="Display Example Image" id="topic_11_1"><img src={require('../images/topic_11_1.png')} style={appImageStyle} alt={alts.ALT_TOPIC_11_1}/></TopicContainer>
                    </li>                    
                    <li style={bulletStyle}>Select the <strong>Course Fulfillment</strong> option from the options that appear within the navigation bar.
                        <TopicContainer title="Display Example Image" id="topic_11_2"><img src={require('../images/topic_11_2.png')} style={appImageStyle} alt={alts.ALT_TOPIC_11_2}/></TopicContainer>
                    </li> 
                    <li style={bulletStyle}>The <strong>Course Fulfillment</strong> page will appear. A list of all OTED courses that are currently ICD-eligible will be displayed.
                        <TopicContainer title="Display Example Image" id="topic_11_3"><img src={require('../images/topic_11_3.png')} style={appImageStyle} alt={alts.ALT_TOPIC_11_3}/></TopicContainer>
                    </li>                     
                    <li style={bulletStyle}>From this page, select the <strong>View/Edit Course Materials</strong> button that corresponds with the course for which you would like to upload or edit course materials.
                        <TopicContainer title="Display Example Image" id="topic_11_4"><img src={require('../images/topic_11_4.png')} style={appImageStyle} alt={alts.ALT_TOPIC_11_4}/></TopicContainer>
                    </li>                    
                    <li style={bulletStyle}>The <strong>View/Edit Course Materials</strong> page will appear. If any course files have previously been uploaded to this course page, they will be visible at the bottom of the page.
                        <TopicContainer title="Display Example Image" id="topic_11_5"><img src={require('../images/topic_11_5.png')} style={appImageStyle} alt={alts.ALT_TOPIC_11_5}/></TopicContainer>
                    </li>
                </ol>                
                <p style={{marginTop:theme.spacing(4)}}><strong>To upload course materials for an ICD course:</strong></p>
                <ol>
                    <li style={bulletStyle}>Select the <strong>Add File(s) to Queue</strong> button at the bottom of the View/Edit Course Materials page.
                        <TopicContainer title="Display Example Image" id="topic_11_6"><img src={require('../images/topic_11_6.png')} style={appImageStyle} alt={alts.ALT_TOPIC_11_6}/></TopicContainer>
                    </li>                    
                    <li style={bulletStyle}>Within the popup window that appears, select the file(s) you wish to upload, and then select the <strong>Open</strong> button.
                        <TopicContainer title="Display Example Image" id="topic_11_7"><img src={require('../images/topic_11_7.png')} style={appImageStyle} alt={alts.ALT_TOPIC_11_7}/></TopicContainer>
                    </li> 
                    <li style={bulletStyle}><strong>[OPTIONAL]</strong> You may rename the file or add a description of the file at this time if you desire.
                        <TopicContainer title="Display Example Image" id="topic_11_8"><img src={require('../images/topic_11_8.png')} style={appImageStyle} alt={alts.ALT_TOPIC_11_8}/></TopicContainer>
                    </li>                     
                    <li style={bulletStyle}>The files you selected will be displayed on the page. Select the <strong>Upload File(s)</strong> button to complete the upload process.
                        <TopicContainer title="Display Example Image" id="topic_11_9"><img src={require('../images/topic_11_9.png')} style={appImageStyle} alt={alts.ALT_TOPIC_11_9}/></TopicContainer>
                    </li>                    
                </ol>
                <p style={{marginTop:theme.spacing(4)}}><strong>To edit or delete course materials:</strong></p>
                <ol>
                    <li style={bulletStyle}>Select the <strong>expand arrow</strong> that corresponds with the course you wish to edit or delete. More course details will appear.
                        <TopicContainer title="Display Example Image" id="topic_11_10"><img src={require('../images/topic_11_10.png')} style={appImageStyle} alt={alts.ALT_TOPIC_11_10}/></TopicContainer>
                    </li>                    
                    <li style={bulletStyle}>To <strong>delete</strong> the course file, select the <strong>trash icon</strong> in the lower right corner of the course details box. You will be asked to confirm before the file is deleted permanently.
                        <TopicContainer title="Display Example Image" id="topic_11_11"><img src={require('../images/topic_11_11.png')} style={appImageStyle} alt={alts.ALT_TOPIC_11_11}/></TopicContainer>
                    </li> 
                    <li style={bulletStyle}>To <strong>edit</strong> the course file, select the <strong>pencil icon</strong> in the lower right corner of the course details box.
                        <TopicContainer title="Display Example Image" id="topic_11_12"><img src={require('../images/topic_11_12.png')} style={appImageStyle}  alt={alts.ALT_TOPIC_11_12}/></TopicContainer>
                    </li>                     
                    <li style={bulletStyle}>You will be able to add or edit the course description. Select the <strong>Save</strong> button to save your changes.
                        <TopicContainer title="Display Example Image" id="topic_11_13"><img src={require('../images/topic_11_13.png')} style={appImageStyle} alt={alts.ALT_TOPIC_11_13}/></TopicContainer>
                    </li>                    
                </ol>
            </FormSection>
        </TopicContainer>
    )
}

export function Topic12(){
    return(
        <TopicContainer title="How to Create Multiple Qualified Instructor (QI) Entries" id="topic12">
            <FormSection>
                <p>When inputting Qualified Instructor (QI) information into the ICD Management Portal, you can enter the information for multiple QIs at one time using the <strong>Bulk Import Instructors</strong> feature.</p>                
                <ul>
                    <li style={bulletStyle}><strong>NOTE:</strong> Multiple QIs can only be entered for one course at a time.</li>
                </ul>
                <p>To input the information for more than one QI:</p>
                <ol>
                    <li style={bulletStyle}>After logging into the ICD Management Portal, select the <strong>Menu</strong> button to open the navigation bar.
                        <TopicContainer title="Display Example Image" id="topic_12_1"><img src={require('../images/topic_12_1.png')} style={appImageStyle} alt={alts.ALT_TOPIC_12_1}/></TopicContainer>
                    </li>                    
                    <li style={bulletStyle}>Select the <strong>Instructors</strong> option from the options that appear within the navigation bar.
                        <TopicContainer title="Display Example Image" id="topic_12_2"><img src={require('../images/topic_12_2.png')} style={appImageStyle} alt={alts.ALT_TOPIC_12_2}/></TopicContainer>
                    </li> 
                    <li style={bulletStyle}>The <strong>Manage Instructors</strong> page will appear. All QIs that have been previously entered into the system are listed here.
                        <TopicContainer title="Display Example Image" id="topic_12_3"><img src={require('../images/topic_12_3.png')} style={appImageStyle} alt={alts.ALT_TOPIC_12_3}/></TopicContainer>
                    </li>                     
                    <li style={bulletStyle}>From this page, select the <strong>Bulk Import Instructors</strong> button to begin the process of entering the information for multiple QIs at once.
                        <TopicContainer title="Display Example Image" id="topic_12_4"><img src={require('../images/topic_12_4.png')} style={appImageStyle} alt={alts.ALT_TOPIC_12_4}/></TopicContainer>
                    </li>                    
                    <li style={bulletStyle}>The <strong>Bulk Import Instructors</strong> page will appear. Select the <strong>Select Course</strong> button.
                        <TopicContainer title="Display Example Image" id="topic_12_5"><img src={require('../images/topic_12_5.png')} style={appImageStyle} alt={alts.ALT_TOPIC_12_5}/></TopicContainer>
                    </li>
                    <li style={bulletStyle}>The <strong>Select Course</strong> pop-up window will appear. Locate the ICD-eligible course you wish to enter multiple QIs for, and then select the <strong>Select</strong> button.
                        <TopicContainer title="Display Example Image" id="topic_12_6"><img src={require('../images/topic_12_6.png')} style={appImageStyle} alt={alts.ALT_TOPIC_12_6}/></TopicContainer>
                    </li>
                    <li style={bulletStyle}>Select this link to download the <a href={ExamplePdf} download="Bulk-Import-Instructors-Template" style={{cursor:"pointer"}} target="_blank" rel="noreferrer">Bulk Import Instructor Template</a>, which is in .csv format. Populate this file with all of the required information for the QIs to be entered.
                        <TopicContainer title="Display Example Image" id="topic_12_7"><img src={require('../images/topic_12_7.png')} style={appImageStyle}  alt={alts.ALT_TOPIC_12_7}/></TopicContainer>
                        <p><strong>NOTE:</strong> When saving this file, be sure that you save it as a .csv file, and NOT an .xls file.</p>
                        <p style={{marginTop:theme.spacing(4)}}>The QI data must be entered in the proper format in order for the file to properly upload.</p>
                        <ol style={{"listStyleType": "lower-alpha"}}>
                            <li style={{marginTop:theme.spacing(2), ...bulletStyle}}><strong>Phone numbers</strong> must be entered without spaces or dashes (ex. 5551119999)</li>
                            <li style={bulletStyle}><strong>Course Numbers</strong> must be entered as FD### (ex. FD190)</li>
                            <li style={bulletStyle}><strong>Certified On</strong> and <strong>Expires On</strong> dates must be entered in XX/XX/XXXX format (ex. 10/28/2023)</li>
                        </ol>
                        <p style={{marginTop:theme.spacing(4)}}><strong>NOTE:</strong> If you input new profile information (i.e., name, email, phone number, address) for a QI that already has an email address in the ICD Management Portal, the new profile information entered in this spreadsheet will <em>overwrite</em> the information in their existing profile.</p>
                        <p><strong>NOTE:</strong> If you input a new course qualification for a QI that already has an email address in the ICD Management Portal, the new course qualification specified in this spreadsheet will be <em>added</em> to their profile; they will not lose their existing course qualification(s).</p>
                    </li>
                    <li style={bulletStyle}>When you have finished entering information in the template, save the file to your computer. Select the <strong>Select File</strong> button on the Bulk Import Instructor page. Locate your template file and select <strong>Open</strong>.
                        <TopicContainer title="Display Example Image" id="topic_12_8"><img src={require('../images/topic_12_8.png')} style={appImageStyle} alt={alts.ALT_TOPIC_12_8}/></TopicContainer>
                    </li>
                    <li style={bulletStyle}>Select the <strong>Import</strong> button to import the selected file.
                        <TopicContainer title="Display Example Image" id="topic_12_9"><img src={require('../images/topic_12_9.png')} style={appImageStyle} alt={alts.ALT_TOPIC_12_9}/></TopicContainer>
                    </li> 
                    <li style={bulletStyle}>A message will appear confirming that the import operation was successful.
                        <TopicContainer title="Display Example Image" id="topic_12_10"><img src={require('../images/topic_12_10.png')} style={appImageStyle} alt={alts.ALT_TOPIC_12_10}/></TopicContainer>
                    </li>                                         
                </ol>
            </FormSection>
        </TopicContainer>
    )

}

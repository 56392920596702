import React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Grid from '@mui/material/Grid';
import { Button, IconButton } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import StepButton from '@mui/material/StepButton';

export class StepLogicController{
    constructor(currentStep, stepLabels, completedSteps, setCompletedSteps, navigationCallback){
        this.currentStep = parseInt(currentStep, 10);
        this.navigationCallback = navigationCallback;
        this.minSteps = 1;
        this.stepLabels = stepLabels;
        this.completedSteps = completedSteps;
        this.setCompletedSteps = setCompletedSteps;
    }

    completeCurrentStep = function(){
        var temp = [...this.completedSteps];
        temp[this.currentStep-1]=true;
        this.setCompletedSteps(temp);
    }

    getCurrentStepName= function(){
        if(this.currentStep > 0){
            return this.stepLabels[this.currentStep-1];
        }
        else{
            return this.stepLabels[0];
        }
    }

    getCurrentStep = function(){
        return this.currentStep;
    }

    //Navigate to specified step if valid:
    gotoStep = function(stepNum){
        if(this.isValidStep(stepNum)){
            this.navigationCallback(stepNum);
        }
    }

    //Navigate to previous step:
    prevStep = function(){
        this.gotoStep(this.currentStep - 1);
    }

    //Navigate to next step:
    nextStep = function(){
        this.gotoStep(this.currentStep + 1);
    }

    //Return true if the step is valid (within range):
    isValidStep = function(targetStep){     
        targetStep = parseInt(targetStep, 10);
        return (targetStep >= this.minSteps && targetStep <= this.stepLabels.length);
    }

    //Return true is can navigate to a previous step:
    canPrev = function(){
        return this.isValidStep(this.currentStep - 1);
    }

    //Return true if can navigate to a next step:
    canNext = function(){
        return this.isValidStep(this.currentStep + 1);
    }

    //Is current step completed:
    isCurrentStepCompleted = function(){
        return this.completedSteps[Math.max(this.currentStep-1, 0)] == true;
    }
}

export function StepNavigator(props){
    return (
        null
    );
}

//Stepper:
/*
    Styling is kind of complicated in MUI 5 for the stepper, because it involves magic class names:
    https://stackoverflow.com/questions/40326565/how-do-you-change-the-stepper-color-on-react-material-ui
*/
export function CustomStepper(props){
    const {activeStep, steps, handleStep, completed=[], stepLogic} = props;
    //console.log(stepLogic);
    
	return(
		//MUI Stepper Component, passed current step:
        <>
        <Grid container justifyContent="center"  alignItems="center">
            <Grid item>
               <Button
                    variant="contained"
                    color="primary"
                    startIcon={<ArrowBack/>}
                    disabled={!stepLogic.canPrev()}
                    onClick={()=>{stepLogic.prevStep()}}
                >
                    Back
                </Button>
            </Grid>
            <Grid item>
                <Stepper nonLinear sx={{marginBottom:2}} activeStep={activeStep-1} alternativeLabel>
                    {steps.map((label, index) => (                
                        <Step 
                            key={label}
                            disabled={!completed[Math.max(index-1, 0)]}
                            completed={completed[index]}
                            sx={{
                                '& .MuiStepLabel-root .Mui-completed': {
                                    color: 'primary.main', // circle color (COMPLETED)
                                },
                                '& .MuiStepLabel-root .Mui-active': {
                                    color: 'secondary.main', // circle color (ACTIVE)
                                }
                            }}
                        >                
                            <StepButton title={label} onClick={()=>{handleStep(steps[index], index)}}>{label}</StepButton>
                        </Step>
                    ))}
                </Stepper>
            </Grid>
            <Grid item>
               <Button
                    variant="contained"
                    color="primary"
                    endIcon={<ArrowForward/>}
                    disabled={!stepLogic.canNext() || !stepLogic.isCurrentStepCompleted()}
                    onClick={()=>{stepLogic.nextStep()}}
                >
                    Next
                </Button>
            </Grid>
        </Grid>
        </>
	);
}
import axios from 'axios';
import jwtDecode from "jwt-decode";

import * as application from "./application/applicationAdapter";
import * as course from "./courseAdapter";
import * as instructor from "./instructorAdapter";
import * as organization from "./organizationAdapter";
import * as user from "./userAdapter";
import * as qualification from "./qualificationAdapter";
import * as notification from "./notificationAdapter";
import {isSuccessResponse, parseResponse, API_URL} from "./utils";

//if localStorage, will persist login state between browser sessions
const storage = sessionStorage; //localStorage;

export function configureAxios() {
  //fetch token from storage, if present
  var token = storage.getItem("token");
  if(token == null) {
    return;
  }
  //set bearer token
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

export async function logEvent(message, isError=false) {
  await isSuccessResponse(await axios.post(`${API_URL}/api/logEvent`, {message: message, isError: isError}));
}

//currently unused, but may be used in the future to populate the list of roles
export async function listRoles()
{
  return parseResponse(await axios.get(`${API_URL}/api/roles`));
}

export async function getRole(role_id)
{
  return parseResponse(await axios.get(`${API_URL}/api/roles/${role_id}`));
}

export async function forgot(email)
{
  await logout();
  try {
    var response = await axios.post(`${API_URL}/api/login/forgot`, {email});
    return response.data;
  } catch(err) {
    return err.response.data;
  }
}

export async function reset(email, password, new_password)
{
  await logout();
  try {
    var response = await axios.post(`${API_URL}/api/login/reset`, {email, password, new_password});
    return response.data;
  } catch(err) {
    return err.response.data;
  }
}

//return the status of the login (either login-success, login-otp, or login-failure)
export async function login(email, password, otp, rememberOtp, rememberMe)
{
  await logout();
  //replace blank OTPs with undefined
  otp = otp == null || otp.trim() === "" ? undefined : otp;

  //send login request
  var result = {};
  try {
    result = (await axios.post(`${API_URL}/api/login`, {email, password, otp, rememberOtp, rememberMe})).data;
  } catch(err) {
    result = err.response.data;
    console.log(err);
  }

  //if login is successful, save login data to storage
  if(result.status === "login-success")
  {
    updateSession(result.data.jwt);
  }

  return result;
}

export function updateSession(jwt) {
  if(jwt) {
    console.log("updating session");
    storage.setItem("token", jwt);
    //add bearer token to all axios requests:
    axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
  }
}

export function getSessionTtl() {
  var token = getToken();
  if(token == null) return 0;
  var exp = token.exp - (Date.now() / 1000);
  return exp > 0 ? exp : 0;
}

export async function resetFailedAttempts(email) {
  var result = await axios.post(`${API_URL}/api/login/resetfailedattempts`, {email});
  return result;
}

export async function resendOtp(email, password)
{
  var result = await axios.post(`${API_URL}/api/login/resendotp`, {email, password});
  return result;
}

export function getTokenData()
{
  var token = getToken();
  return token.data;
}

export function getToken()
{
  var token = storage.getItem("token");

  if(token == null)
  {
    throw new Error("No token present, is user logged in?");
  }

  return jwtDecode(token);
}

export function getJWT()
{
  var token = storage.getItem("token");

  if(token == null)
  {
    throw new Error("No token present, is user logged in?");
  }

  return token;
}

export function getAuthHeader() 
{
  var jwt = getJWT();
  return {
    headers: {
      'Authorization': 'Bearer ' + jwt
    }
  };
}

//returns the role of the logged-in user or null if not logged in
export function getLoggedInRole()
{
  return getTokenData().role;
}

/** returns user info of the currently logged in user */
export async function getLoggedInUser()
{
  var token = getToken();
  var userId = token.data.id;

  if((Date.now() / 1000) > token.exp) {
    await logout();
    throw new Error("Token expired");
  }

  return await user.get(userId);
}

export async function logout()
{

  try {
    var token = getToken();
    if(token && token.data) {
      logEvent(`user ${token.data.email} has been logged out`);
    }
  } catch (err) {
    //
  } 
  //remove JWT from storage
  storage.removeItem("token");
  //clear bearer token:
  delete axios.defaults.headers.common["Authorization"];
}

//export sub-adapters as properties of this adapter
export {application, course, instructor, organization, user, qualification, notification};

//aliases
// export {
//   get as getInstructor,
//   list as listInstructors
// } from "./instructorAdapter"

import axios from 'axios';
import {parseResponse, appRoute, API_URL} from "../utils";
import { getAdapter } from '../adapter';

function deliveryRoute(appID)
{
  return appRoute(appID) + "/delivery";
}

export async function get(applicationID)
{
  return parseResponse(await axios.get(deliveryRoute(applicationID), (await getAdapter()).getAuthHeader));
}

export async function update(applicationID, data)
{
  return parseResponse(await axios.post(deliveryRoute(applicationID), data, (await getAdapter()).getAuthHeader));
}

export async function getOtedOeReport(applicationID, filename) {
	var headers = (await getAdapter()).getAuthHeader();
	headers['responseType']='arraybuffer';
	return await axios.get(`${deliveryRoute(applicationID)}/otedoereport/${filename}`, headers);
}

export async function getOeResponse(applicationID, filename) {
	var headers = (await getAdapter()).getAuthHeader();
	headers['responseType']='arraybuffer';
	return await axios.get(`${deliveryRoute(applicationID)}/oeresponse/${filename}`, headers);
}

export async function deleteOtedOeReport(applicationID) {
	var headers = (await getAdapter()).getAuthHeader();
	return parseResponse(await axios.delete(`${deliveryRoute(applicationID)}/otedoereport`, (await getAdapter()).getAuthHeader));
}

export async function deleteOeResponse(applicationID) {
	var headers = (await getAdapter()).getAuthHeader();
  return parseResponse(await axios.delete(`${deliveryRoute(applicationID)}/oeresponse`, (await getAdapter()).getAuthHeader));
}

export async function uploadOtedOeReport(application_id, file) {
  var headers = (await getAdapter()).getAuthHeader();
  headers.headers['Content-Type']='multipart/form-data';
  var formData = new FormData();
  formData.append('file', file);
  formData.append('fileName', file.name);
  formData.append('application_id', application_id); 

  return await axios.post(`${deliveryRoute(application_id)}/otedoereport`, formData, headers);
}

export async function uploadOeResponse(application_id, file) {
  var headers = (await getAdapter()).getAuthHeader();
  headers.headers['Content-Type']='multipart/form-data';
  var formData = new FormData();
  formData.append('file', file);
  formData.append('fileName', file.name);
  formData.append('application_id', application_id); 

  return await axios.post(`${deliveryRoute(application_id)}/oeresponse`, formData, headers);
}

import React, { useEffect, useRef } from 'react';
import theme from './theme/PortalTheme';
import Paper from '@mui/material/Paper';
import InfoIcon from '@mui/icons-material/Info';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useHistory, Redirect } from "react-router-dom";
import LoginIcon from '@mui/icons-material/Login';
import * as baseAdapter from './adapters/baseAdapter';

export default function SessionExpiryMessage(props) {

    const [sessionTtl, setSessionTtl] = React.useState(Number.MAX_SAFE_INTEGER);
    const [sessionLoop, setSessionLoop] = React.useState();
    const [message, setMessage] = React.useState();
    
    const history = useHistory();

    function handleClick() {
        history.push("/login");
    }

    //Is component currently mounted?
    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false }
    }, []);

	//Hooks:
	useEffect(() => {
        if( isMounted.current){    
            setSessionLoop(setInterval(() => {
                var sessionTtl = Math.floor(baseAdapter.getSessionTtl());
                const pluralize = (sessionTtl !== 1) ? "s" : "";
                setMessage((sessionTtl/60 < 1) 
                    ? "Your session has expired" 
                    : "Your session will expire in " + Math.trunc(sessionTtl/60) + " minute" + pluralize);

                if(sessionTtl < 60) {
                    history.push("/login#expired");   
                    // return <Redirect push to="/login#expired" />
                }
                setSessionTtl(sessionTtl);
            }, 30000));
        }
	}, []);

    
	useEffect(() => {
		if(sessionLoop) {
			return function cleanup() {
				clearInterval(sessionLoop);
			};
		}
		
	}, [sessionLoop]);

    return(
         sessionTtl < 3600 &&
        <Paper padding="large" sx={{backgroundColor: theme.palette.background.sessionExpiryMessage}}>
            <Stack direction="row" alignItems="center" gap={1}>
                <InfoIcon color="primary"/>
                <Typography variant="body1">{message}</Typography>
                <span style={{"marginLeft": "auto", "marginRight": 0}} title="Login"><a href="#" onClick={() => handleClick()}><LoginIcon color="primary"/></a></span>
            </Stack>
        </Paper>   
	);
}

import React, { useContext } from 'react';
import { Avatar, Button } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DownloadIcon from '@mui/icons-material/Download';
import { StatusMessageContext } from './StatusMessage';
import { handleError } from './adapters/utils';
import { getAdapter } from './adapters/adapter';

const Message = (props) => {

  var { StatusManager } = useContext(StatusMessageContext);

  async function downloadMessageFile() {
    try {
      var adapter = await getAdapter();        
      var response = await adapter.application.getMessageFile(props.message.attachment);
      
      let blob=new Blob([response.data]);
      let link=document.createElement('a');
      let url=window.URL.createObjectURL(blob);
      link.href = url;
      link.download=props.message.attachment;
      link.click();
      link.remove();
      setTimeout(() => window.URL.revokeObjectURL(url), 100);
    } catch(err) {
      console.error(err);
      handleError(err, StatusManager);
    }
  }

  try {
    const nameParts = props.message.author.split(" "); 
    var avatar = (nameParts[0].at(0) + '' + nameParts[1].at(0)).toLocaleUpperCase();

    var acknowledged = "";
    if(props.message.acknowledged == 'true') {
      acknowledged = <span title="Viewed"><CheckCircleOutlineIcon fontSize="smallest" /></span>;
    }

    var attachment = "";
    if(props.message.attachment) {
      attachment = <Button variant="outlined" size="small" title="download" style={{cursor:"pointer"}} onClick={downloadMessageFile} >Attachment<DownloadIcon fontSize="smallest"/></Button>;
    }
  } catch(err) {
    console.error(err);
    handleError(err, StatusManager);
  }

  return (
    <div>
      <div className="row no-gutters">
        
          { props.message.role === "applicant_poc" 
            ? <div className="col-md-3 offset-md-9">
                <div className="message-no-chat-bubble">
                    <div className="message-left">
                      <Avatar sx={{ bgcolor: 'orange' }} title="Point of Contact">{avatar}</Avatar>
                    </div>
                    <div className="message-right">{props.message.body}
                      <div className="message-meta">Author:&nbsp;{props.message.author}</div>
                      <div className="message-meta">Date:&nbsp;{props.message.pretty_date}&nbsp;&nbsp;{acknowledged}&nbsp;&nbsp;{attachment}</div>
                    </div>
                    <br className="clear-message" />
                </div>
              </div>
            : <div className="col-md-3">
                <div className="message-no-chat-bubble">
                  <div className="message-left">
                    <Avatar sx={{ bgcolor: '#007dbc' }} title="Admin">{avatar}</Avatar>
                  </div>
                  <div className="message-right">{props.message.body}
                   <div className="message-meta">Author:&nbsp;{props.message.author}</div>
                   <div className="message-meta">Date:&nbsp;{props.message.pretty_date}&nbsp;&nbsp;{acknowledged}&nbsp;&nbsp;{attachment}</div>
                  </div>
                  <br className="clear-message" />
                </div>
              </div>
          }
      </div>
    </div>
    )
}

export default Message

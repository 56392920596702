import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory } from "react-router-dom";
import ViewTitle from './ViewTitle';
import { Typography, Grid, Container, Paper } from '@mui/material';
import { getAdapter} from './adapters/adapter';
import theme, { CreateButton, DeleteButton } from './theme/PortalTheme';
import { ApplicationGrid } from './ManageApplications';
import { StatusMessageContext } from './StatusMessage';
import LoadingIndicator from './LoadingIndicator';
import { handleError } from './adapters/utils';
import moment from 'moment';
import { getLoggedInRole } from './adapters/baseAdapter';
import ConfirmationDialog from './ConfirmationDialog';

export default function ListApplications() {
	const history = useHistory();
	const [deleteDisabled, setDeleteDisabled] = useState(true);
	// eslint-disable-next-line
	const [isLoading, setIsLoading] = useState(false);
	const [applications, setApplications] = useState([]);
	const [pleaseWait, setPleaseWait] = useState(false);	
	const [selected, setSelected] = useState([]);
	//simplest solution to retrigger the onload useeffect and properly cleanup from a button press
	const [updateGrid, setUpdateGrid] = useState(0);
	const [role, setRole] = useState();
	const createUrl = "/dashboard/applications/view_edit/";
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);	
	
	//Status message:
	var { StatusManager } = useContext(StatusMessageContext);
	
	const isMounted = useRef(false);
	useEffect(() => {
		isMounted.current = true;
		return () => { isMounted.current = false }
	}, []);

	useEffect(() => {
		if(isMounted.current){
			setApplications([]);
		}
		async function fetchData(){
			setIsLoading(true);
			
			try {
				var adapter = await getAdapter();
				var user = await adapter.getLoggedInUser();
				var applications = await adapter.application.listByOrganization(user.organizationID);
				console.log(applications);
				
				var statusMap = {
					"in-progress" : "In-Progress",
					"submitted" : "Submitted",
					"processed" : "Processed",					
					"approved" : "Approved",
					"closeout": "Closeout",
					"completed" : "Completed"
				}
				
				applications.forEach((e) => {
					e.id = e.guid;
					e.status = statusMap[e.status] || "N/A";
					e.organization = e.organization || "Incomplete";
					e.startDateRaw = e.startDate;
					e.startDate = e.startDate ? new Date(e.startDate).toLocaleDateString() : "Not Specified";
					e.endDateRaw = e.endDate;
					e.endDate = e.endDate ? new Date(e.endDate).toLocaleDateString() : "Not Specified";
					e.poc = e.poc || "Incomplete";
					e.courseInfo = e.course;
					e.course = e.course.courseNumber || "Incomplete"; //Necessary because the InstructorGrid is shared between multiple views, but the response from adapter calls in these views is not consistent.
					
				});

				if(isMounted.current){
					setRole(user.role);
					setApplications(applications);
				}				
			}
			catch (error) {
				console.error(error);
			}
			if(isMounted.current) {
				setIsLoading(false);
			}
		}

		fetchData();

	}, [updateGrid]);

	function onSelectionChange(selectionModel) {
		if(setDeleteDisabled != null) {
			setDeleteDisabled(selectionModel.length === 0);
		}
		setSelected(selectionModel);
	}

	async function onCreateApplicationBtn(){
		if(isMounted.current){
			setPleaseWait(true);
		}
		try{
			var adapter = await getAdapter();
			var applicationId = await adapter.application.create();
			history.push(createUrl + `${applicationId}/1`);	
		}
		catch(error){
			setPleaseWait(false);
			setIsLoading(false);
			handleError(error, StatusManager);
		}
	}

	function onDeleteApplicationBtn(){
		setDeleteDialogOpen(true);
	}

	function onConfirmDelete(){
		deleteApplication();
	}

	async function deleteApplication(){
		var success = true;
		setIsLoading(true);
		try{
			var adapter = await getAdapter();
			await adapter.application.delete(selected);
		}
		catch(error){
			success = false;
			console.error(error);
		}
		if(isMounted.current) {
			var successStr = success ? "successful." : "failed.";
			console.log("Delete " + successStr );
			StatusManager.setStatus("Delete application " + successStr);
			setUpdateGrid(updateGrid+1);
		}
	}

	function actionsColumnRenderCell(params){
		function onViewEditBtn(params){
			var applicationId = params.id;
			history.push("/dashboard/applications/view_edit/" + applicationId + "/1");
		}

		function onCourseMaterialsBtn(params){
			var courseId = params.courseInfo.guid;
			history.push("/dashboard/courses/materials/" + courseId );
		}

		function canViewCourseMaterials(params){
			//There must be a course for the application:
			var cond1 = params.courseInfo && params.courseInfo.courseNumber;
			//The status must be valid:
			var cond2 = ["Processed", "Approved", "Closeout", "Completed"].includes(params.status);
			var cond3 = false;
			//If we have a startDate and endDate
			if(params.endDateRaw != null && params.startDateRaw != null){
				var startDate = params.startDateRaw;
				var adjustedEndDate = moment.utc(params.endDateRaw).add(7, "days");
				var currentDate = moment.utc();		
				var daysSinceStart = currentDate.diff(startDate, "days");
				var daysSinceAdjustedEndDate = currentDate.diff(adjustedEndDate, "days");
				//console.log(params.course + " daysSinceStart: " + daysSinceStart + " daysSinceAdjustedEndDate: " + daysSinceAdjustedEndDate);
				//if(daysSinceStart >= 0 && daysSinceAdjustedEndDate <= 0){ //Per Tori, we changed the logic so that as long as the application is Processed, the matierial is immediately available
				if(daysSinceAdjustedEndDate <= 0){	
					cond3 = true;
				}
			}
			return cond1 && cond2 && cond3;
		}

		function onCloseoutBtn(params){
			var applicationId = params.id;
			history.push("/dashboard/applications/closeout/" + applicationId );			
		}
		
		let showCloseout = ["Closeout"].includes(params.row.status);

		return (
			<React.Fragment>
				<button
					style={{marginRight:theme.spacing(1), cursor:"pointer"}}
					onClick={() => onViewEditBtn(params.row)}
				>
					View/Edit
				</button>
				{ canViewCourseMaterials(params.row) &&
				<button
					style={{marginRight:theme.spacing(1), cursor:"pointer"}}
					onClick={() => onCourseMaterialsBtn(params.row)}
				>
					Course Materials
				</button> }
				{ showCloseout &&
				<button
					style={{marginRight:theme.spacing(1), cursor:"pointer"}}
					onClick={() => onCloseoutBtn(params.row)}
				>
					Closeout
				</button> }
			</React.Fragment>
		);
	}

	function actionsColumnRenderCellMessage(params){
		function onViewEditBtn(params){
			var applicationId = params.id;
			history.push("/dashboard/message/messages/" + applicationId);
		}

		return (
			<React.Fragment>
				<button
					style={{marginRight:theme.spacing(1), cursor:"pointer"}}
					onClick={() => onViewEditBtn(params.row)}
				>
					Messages
				</button>						
			</React.Fragment>
		);
	}

	var creatingApplicationMarkup = (
		<>
			<Grid container justifyContent="center" p={4}>
				<Typography variant="h6" color={"primary"}>Creating Application...</Typography>
				<LoadingIndicator padding={4}/>
			</Grid>
		</>
	);

	return (
		<Container maxWidth="lg">
			{
				pleaseWait ? creatingApplicationMarkup : 			
				<Paper style={{minHeight:'70vh'}} padding="large">
					<ViewTitle>Applications</ViewTitle>
					{/*Delete Confirmation Dialog*/}
					<ConfirmationDialog
						open={deleteDialogOpen}
						setOpen={setDeleteDialogOpen}
						dialogTitle="Confirm Deletion"
						dialogContent="Are you sure you want to delete?"
						leftButtonLabel="No"
						leftButtonCallback={()=>{}}
						rightButtonLabel="Yes"
						rightButtonCallback={onConfirmDelete}
					/>					
					<p style={{marginBottom:0}}>Listed below are your current Independent Course Delivery (ICD) Applications.</p>
						<ul>
							<li style={{marginBottom:theme.spacing(1)}}>Select the <strong>Create Application</strong> button to initiate a new ICD Application.</li>
							<li style={{marginBottom:theme.spacing(1)}}>After selecting the checkbox next to one or more ICD Applications from the list, select the <strong>Delete Application</strong> button to delete the selected application(s).
							<ul><li style={{marginTop:theme.spacing(1)}}><strong>NOTE:</strong> This will permanently delete the selected application(s).</li></ul></li>											
							<li style={{marginBottom:theme.spacing(1)}}>Select the <strong>View/Edit</strong> button to view or edit the corresponding ICD Application.</li>
							<li style={{marginBottom:theme.spacing(1)}}>Select the <strong>Messages</strong> button to message your ICD PA or access the message history related to the corresponding ICD Application.</li>
						</ul>									
					<Grid container justifyContent="flex-end">
						<CreateButton onClick={onCreateApplicationBtn}>Create Application</CreateButton>					
						<DeleteButton onClick={onDeleteApplicationBtn} disabled={deleteDisabled}>Delete Application</DeleteButton>					
					</Grid>
					<ApplicationGrid
						excludeColumns = {["organization"]}
						actionsColumnRenderCell = {actionsColumnRenderCell}
						actionsColumnRenderCellMessage = {actionsColumnRenderCellMessage}
						loading = {isLoading}
						rows = {applications}
						checkboxSelection={true}
						onSelectionModelChange={onSelectionChange}
						role={getLoggedInRole()}
					/>
				</Paper>
			}
		</Container>
	);
}

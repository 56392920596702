import React, { useEffect, useState, useRef, useContext } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Box, Button, Container, Grid, IconButton, LinearProgress, Paper, Typography, createSvgIcon } from '@mui/material';
import { AutoDelete, Edit, FilePresent, FileDownload, ExpandMore, PictureAsPdf, UploadFile, Delete } from '@mui/icons-material/';
import ViewTitle from './ViewTitle';
import LoadingIndicator from './LoadingIndicator';
import { getAdapter } from './adapters/adapter';
import BetterFormField from './FormField';
import { useParams, useHistory } from "react-router-dom";
import { BackButton, FormSection } from './theme/PortalTheme';
import { StatusManager, StatusMessageContext } from './StatusMessage';
import useForm from './UseForm';
import { CancelButton, CreateButton, DeleteButton, UploadButton, SaveButton } from './theme/PortalTheme';
import theme from './theme/PortalTheme';
import ConfirmationDialog from './ConfirmationDialog';
import { getLoggedInRole } from './adapters/baseAdapter';

//File Queue Entry Component:
function FileQueueEntry(props){
    const { id, file, removeCallback, onChange, init} = props;

    //Is component currently mounted?
    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false }
    }, []);

    //Form validation:
    function validate(formValuesRef){
        let _errors = { ...errors };
        let formValues = formValuesRef;

        //Name field required:
        _errors.name = (formValues.name === "") ? "Name field is required." : "";

        //If name is somehow > 128 characters, warn and auto-truncate:
        if(formValues.name.length > 64){
            _errors.name = "Name field is limited to 64 characters. Name has been automatically truncated."
            formValues.name = formValues.name.slice(0, 64);
        }

        if(isMounted.current){
            setErrors({
                ..._errors
            });
        }

        var result = Object.values(_errors).every(x => x === "");
        console.log("Validation " + (result ? "SUCCESS" : "FAILED"));
        return result;
    }

    //Initial Form Values:
    const initialFormValues = {
        name:file.name.split('.').slice(0, -1).join('.').substring(0,64),
        description:"",
    };

    //Form-related vars:
    const {
        formValues,
        setFormValue,
        errors,
        setErrors,
        handleInputChange,
    } = useForm(initialFormValues, false, validate);

    //Register with parent:
    useEffect(()=>{
        onChange(id, formValues, validate);
    }, [formValues, onChange]);


    return(
        <Box sx={{backgroundColor:"#fcfeff", padding:2, border:"1px solid #000", marginBottom:1}}>
            <Grid container wrap="nowrap">
                <Grid item><UploadFile style={{marginRight:8, fontSize:32}}/></Grid>
                <Grid item><span style={{marginRight:8, fontWeight:"bold", color:theme.palette.primary.main}}>{file.name}</span></Grid>              
            </Grid>
            <Grid container justifyContent="flex-start" alignItems="center" sx={{padding:1}} spacing={1}>
                <Grid item xs={12}>
                    <BetterFormField maxLength={64} required label="Name" name="name" value={formValues.name} error={errors.name} onChange={handleInputChange} />
                </Grid>
                <Grid item xs={12}>
                    <BetterFormField label="Description" name="description" multiline={true} rows={2} value={formValues.description} error={errors.description} onChange={handleInputChange} />
                </Grid>                         
            </Grid>
            <Grid container justifyContent="flex-end" alignItems="center" sx={{padding:1}} spacing={1}>
                <IconButton title="Remove from Queue" onClick={()=>{removeCallback(file)}}>
                    <Delete />
                </IconButton>
            </Grid>                            
        </Box>
    );
}

//Course Material List Entry Component:
function CourseMaterialsEntry(props){
    const { id, file, onDelete, updateSignal, setUpdateSignal } = props;
    const [expanded, setExpanded] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [editMode, setEditMode] = useState(false);

    const PPTIcon = createSvgIcon(<path d="M 12 3 L 2 5 L 2 19 L 12 21 L 12 3 z M 14 5 L 14 7 L 16 7 L 18 7 L 20 7 L 20 9 L 20 11 L 20 13 L 20 15 L 20 17 L 18 17 L 16 17 L 14 17 L 14 19 L 21 19 C 21.552 19 22 18.552 22 18 L 22 6 C 22 5.448 21.552 5 21 5 L 14 5 z M 3.9882812 8 L 7.0605469 8 C 7.9575469 8 8.6692656 8.2322656 9.1972656 8.6972656 C 9.7252656 9.1632656 9.9882812 9.780875 9.9882812 10.546875 C 9.9882812 11.331875 9.7318438 11.941906 9.2148438 12.378906 C 8.6988437 12.815906 7.9749219 13.033203 7.0449219 13.033203 L 5.5 13.033203 L 5.5 16 L 3.9882812 16 L 3.9882812 8 z M 16 8 C 14.895 8 14 8.895 14 10 C 14 11.105 14.895 12 16 12 C 17.105 12 18 11.105 18 10 L 16 10 L 16 8 z M 5.5 9.1875 L 5.5 11.736328 L 6.96875 11.736328 C 7.43575 11.736328 7.7911094 11.629969 8.0371094 11.417969 C 8.2831094 11.205969 8.40625 10.899047 8.40625 10.498047 C 8.40625 10.103047 8.28125 9.7887344 8.03125 9.5527344 C 7.78325 9.3157344 7.4418594 9.1935 7.0058594 9.1875 L 5.5 9.1875 z M 14 13 L 14 15 L 18 15 L 18 13 L 14 13 z"></path>);

    //Is component currently mounted?
    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false }
    }, []);  

    //Status message:
    var { StatusManager } = useContext(StatusMessageContext);

    //Form validation:
    function validate(){
        let _errors = { ...errors };

        //Name field required:
        _errors.name = (formValues.name === "") ? "Name field is required." : "";

        //If name is somehow > 128 characters, warn and auto-truncate:
        if(formValues.name.length > 128){
            _errors.name = "Name field is limited to 128 characters. Name has been automatically truncated."
            formValues.name = formValues.name.slice(0, 128);
        }       

        if(isMounted.current){
            setErrors({
                ..._errors
            });
        }

        var result = Object.values(_errors).every(x => x === "");
        console.log("Validation " + (result ? "SUCCESS" : "FAILED"));
        return result;
    }

    //Initial Form Values:
    const initialFormValues = {
        name:file.title.slice(0, 128),
        filename:file.filename,
        description:file.description,
    };

    //Form-related vars:
    const {
        formValues,
        setFormValue,
        errors,
        setErrors,
        handleInputChange,
    } = useForm(initialFormValues, false, validate);    

    function handleChange(e){
        if(isMounted.current){
            setExpanded(!expanded);
        }
    }

    async function onSaveBtn(){
        if(validate()){
            let success = true;
            try{
                let adapter = await getAdapter();
                let data = {title:formValues.name, description: formValues.description};
                console.log(data);

                let response = await adapter.course.updateCourseContent(id, data);
            }
            catch(error){
                console.log(error);
                success = false;
            }
            if(isMounted.current){
                let successStr = success ? "successful." : "failed.";
                StatusManager.setStatus("Update course content " + successStr);
                setUpdateSignal(updateSignal+1);
            }            
        }
    }

    function onCancelBtn(){
        if(isMounted.current){
            setEditMode(false);
        }
    }

    function onEditBtn(){
        if(isMounted.current){
            setEditMode(true);
        }
    }

    async function onDownloadBtn(){
        try{
            let adapter = await getAdapter();
            let response = await adapter.course.downloadCourseContent(file.guid);
            const blob = new Blob([response.data], {type: response.headers['content-type']});
            
            let link = document.createElement('a');
            let url = window.URL.createObjectURL(blob);
            link.href = url;
            link.download = file.filename;
            link.click();
            link.remove();
            setTimeout(() => window.URL.revokeObjectURL(url), 100);     
          
            // let blob = new Blob([response.data],{type:'application/zip'});
            // let link = document.createElement('a');
            // let url = window.URL.createObjectURL(blob);
            // link.href = url;
            // link.download = response.headers.filename;
            // link.click();
            // link.remove();
            // setTimeout(() => window.URL.revokeObjectURL(url), 100);
        }
        catch(error){
            console.log(error);
        }

    }

    function onDeleteBtn(){
        setDeleteDialogOpen(true);
    }

    async function onConfirmDeletion(file){
        if(isMounted.current){
            setIsDeleting(true);
        }
        await onDelete(file);
        if(isMounted.current){
            setIsDeleting(false);
        }
    }

    //If editMode = true:
    const editModeMarkup = (
        <Box sx={{backgroundColor:"#fffcf7", padding:2, marginBottom:1, border:"1px dashed black"}}>
            <Grid container justifyContent="flex-start" alignItems="center" sx={{padding:1}} spacing={1}>             
                <Grid item xs={12}>
                    <BetterFormField maxLength={64} variant="filled" readOnly={true} required label="Name" name="name" value={formValues.name} error={errors.name} onChange={handleInputChange} />
                </Grid>
                <Grid item xs={12}>
                    <BetterFormField label="Description" name="description" multiline={true} rows={2} value={formValues.description} error={errors.description} onChange={handleInputChange} />
                </Grid>
                <Grid item xs={12}><p style={{marginTop:0, wordBreak: "break-all"}}><strong>Filename:</strong> {file.filename}</p></Grid>
            </Grid>
            <Grid container justifyContent="flex-end" alignItems="center" sx={{padding:1}} spacing={1}>
                <Grid item>
                    <SaveButton title="Save Changes" onClick={onSaveBtn}>Save Changes</SaveButton>
                </Grid>
                <Grid item>
                    <CancelButton title="Cancel Changes" onClick={onCancelBtn}>Cancel Changes</CancelButton>
                </Grid>
            </Grid>
        </Box>
    );

    //Determine icon to show based on file extension:
    let re = /(?:\.([^.]+))?$/;
    let extension = re.exec(file.filename)[1];
    //Generic icon as default (should not be necessary if validation works, but adding as a fallback anyway):
    let fileTypeIcon = <FilePresent sx={{marginRight:1, fontSize:32}}/>;
    //Otherwise, if recognized type, use specific icon:
    if(extension){
        if(['ppt','pptx'].includes(extension.toLowerCase())){
            fileTypeIcon = <PPTIcon sx={{marginRight:1, fontSize:32}}/>;
        }
        if(['pdf'].includes(extension.toLowerCase())){
            fileTypeIcon = <PictureAsPdf sx={{marginRight:1, fontSize:32}}/>;
        }
    }
    
    //If editMode = false:
    const defaultModeMarkup = (                    
        <Accordion disableGutters={true} sx={{backgroundColor:"#fffcf7", padding:0, marginBottom:1, border:"1px dashed black"}} expanded={expanded} onChange={handleChange}>
            <AccordionSummary
                expandIcon={ isDeleting ? null : <ExpandMore/>}
            >
                <Grid container justifyContent="flex-start" alignItems="center" sx={{padding:1}}>
                    { fileTypeIcon }<span style={{color:theme.palette.primary.main, fontWeight:"bold"}}>{file.title}</span>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container justifyContent="flex-start" alignItems="center" sx={{padding:0}}>
                    <Grid item xs={12}><p style={{marginTop:0}}><strong>Description:</strong> {file.description.length > 0 ? file.description : "None."}</p></Grid>
                    <Grid item xs={12}><p style={{marginTop:0, wordBreak: "break-all"}}><strong>Filename:</strong> {file.filename}</p></Grid>                    
                </Grid>
                <Grid container justifyContent="flex-end" alignItems="center" sx={{padding:1}} spacing={1}>
                        { getLoggedInRole() != "applicant_poc" &&
                        <Grid item>
                            <IconButton title="Edit" onClick={onEditBtn}>
                                <Edit/>
                            </IconButton>
                        </Grid> }
                        <Grid item>
                            <IconButton title="Download" onClick={onDownloadBtn}>
                                <FileDownload />
                            </IconButton>
                        </Grid>
                        { getLoggedInRole() != "applicant_poc" &&                        
                        <Grid item>
                            <IconButton title="Delete" onClick={onDeleteBtn}>
                                <Delete />
                            </IconButton>
                        </Grid> }
                </Grid>
            </AccordionDetails>
        </Accordion>
    );

    return(
        <>
            <ConfirmationDialog
                open={deleteDialogOpen}
                setOpen={setDeleteDialogOpen}
                dialogTitle="Please Confirm"
                dialogContent="Are you sure you want to delete this file?"
                leftButtonLabel="Cancel"
                leftButtonCallback={()=>{}}
                rightButtonLabel="Delete"
                rightButtonCallback={()=>{onConfirmDeletion(file)}}
            />
            { isDeleting ? 
                <Box sx={{backgroundColor:"#fffcf7", padding:2, marginBottom:1, border:"1px dashed black"}}>
                    <Grid container justifyContent="center" alignItems="center" sx={{padding:1}}>
                        <AutoDelete sx={{mr:1, fontSize:40}}/>                        
                    </Grid>
                    <p style={{textAlign:"center", marginTop:0, fontWeight:"bold"}}>Deleting <span style={{color:theme.palette.primary.main}}>{file.filename}</span></p>
                    <LoadingIndicator/>
                </Box> :
                <> { editMode ? editModeMarkup : defaultModeMarkup }</>
            }            
        </> 
    );
}

//Upload Progress Indicator Component:
function UploadProgress(props){
    const { uploaded, total, currentFile } = props;
    let progress = isNaN(uploaded/total) ? "0" : (uploaded/total *100);
    return(
        <>
            <Box>
                <p style={{textAlign:"center"}}><strong>Uploading. Please wait...</strong></p>
                <p style={{textAlign:"center", color:theme.palette.primary.main, fontWeight:"bold"}}>{currentFile}</p>
            </Box>
            <Box>
                <Typography sx={{textAlign:"center"}} variant="h3" color={"#000"}>{ uploaded } / { total }</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" value={progress} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">
                        { Math.round(progress)}%
                    </Typography>
                </Box>
            </Box>
        </>
    );
}

//File Upload Component:
function FileUploadComponent(props){

    const { courseId, updateSignal, setUpdateSignal } = props;

    //Is component currently mounted?
    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false }
    }, []);

    //File to upload:
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadedCount, setUploadedCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [currentFile, setCurrentFile] = useState("");

    const fileEntries = useRef({});

    //Status message:
	var { StatusManager } = useContext(StatusMessageContext);

    //Reference to the hidden file input element:
    const hiddenFileInput = React.useRef(null);

    //Event handlers:
    function onAddFilesToQueueBtn(e){
        hiddenFileInput.current.click();
    }
    
    //Upload files in queue:
    async function onUploadBtn(e){

        let validated = true;       
        //Validate form information for file component (e.g., for each file in queue):
        for (var i in fileEntries.current){
            let FileQueueEntry = fileEntries.current[i];
            if(!FileQueueEntry.validate(FileQueueEntry.formValues)){
                validated = false;
            }
        }
        
        //Form validated at this point, okay to proceed:
        if(validated){
            let uploaded = 0;
            if(isMounted.current){
                setUploadedCount(0);
            }
            
            if(isMounted.current){
                setIsUploading(true);
                setTotalCount(selectedFiles.length);
            }

            //Utility debug function, simulate wait:
            const delay = ms => new Promise(res => setTimeout(res, ms));
            var success = true;
            var failureReason = "";
            //Post files:
            for (i = 0 ; i < selectedFiles.length; i++){
                /*For code readability, prep parameters to be passed to adapter method here:*/
                let file = selectedFiles[i];
                let id = file.name + "_" + file.lastModified;
                let formValues = fileEntries.current[id].formValues;

                if(isMounted.current){
                    setCurrentFile(file.name);
                }

                try{
                    let adapter = await getAdapter();
                    let response = await adapter.course.postCourseContent(file, courseId, formValues.name, formValues.description);                
                }
                catch(error){
                    console.log(error);
                    success = false;
                    failureReason = error?.response?.data?.data?.response;
                    break;
                }
                if(isMounted.current){
                    //Must update state based on local variable "uploaded" to avoid race condition:
                    setUploadedCount(++uploaded);
                }
            }

            if(isMounted.current){
                setIsUploading(false);
                setSelectedFiles([]);
                if(success) {
                    StatusManager.setStatus("Successfully uploaded course materials.");
                } else {
                    StatusManager.setStatus("Failed to upload course materials: " + failureReason);
                }
                setUpdateSignal(updateSignal + 1);
            }           
        }
        //If form fails to validate:
        else{
            /*
                For some reason, setting the status with the line below removes the red "error" formatting on the textfields. 
                This is probably something super complicated related to rendering order. So for now, we'll just not set status
                in the event of an error.
            */
            //StatusManager.setStatus("Please correct form errors before proceeding.");
        }
    }

    function onClearQueueBtn(e){
        if(isMounted.current){
            setSelectedFiles([]);
            fileEntries.current = {};
        }
    }

    //Reset file picker:
    function resetFilePicker(e){
        e.target.value = null;
    }

    //When a file is selected:
    function handleChange(e){
        let filesToUpload = e.target.files;
        if(!filesToUpload || filesToUpload.length == 0) return;
        
        var temp = selectedFiles.slice();

        for (var i = 0; i < filesToUpload.length; i++){
            let fileToUpload = filesToUpload[i];
            var re = /(?:\.([^.]+))?$/;
            var extension = re.exec(fileToUpload.name)[1];
            if(extension){
                if(['pdf','ppt','pptx'].includes(extension.toLowerCase())) {
                    //Prevent duplicates from being queued for upload:
                    temp = temp.filter(file => (file.name != fileToUpload.name) && (file.lastModified != fileToUpload.lastModified));          
                    temp.push(fileToUpload);
                }
            }
        }
        if(isMounted.current){
            setSelectedFiles(temp);
        }
    }

    //Callback to remove a specific file from the queue:
    function removeFileFromQueue(fileToRemove){
        var temp = selectedFiles.slice();

        //Remove file from upload queue:
        temp = temp.filter(file => (file.name != fileToRemove.name) && (file.lastModified != fileToRemove.lastModified));        
        setSelectedFiles(temp);

        //Clean up fileEntries object:
        let id = fileToRemove.name + "_" + fileToRemove.lastModified;
        if(fileEntries.current[id] != null){
            delete fileEntries.current[id];
            console.log(fileEntries.current);
        }
    } 

    //Register FileQueueEntry with parent to give access to validation methods:
    function onFileQueueEntryFormChange(id, formValues, validate, setUploaded){
        fileEntries.current[id]= {formValues: formValues, validate: validate, setUploaded: setUploaded};
    }
    
    //Render helper:
    function displayQueuedFiles(){
        let content = [];
        selectedFiles.forEach(file=>{
            let id = file.name + "_" + file.lastModified;
            content.push(<FileQueueEntry key={id} id={id} file={file} removeCallback={removeFileFromQueue} onChange={onFileQueueEntryFormChange} init={fileEntries.current[id] && fileEntries.current[id].formValues}/>);
        });

        return content.length == 0 ? <p>No files queued for upload.</p> : <><p style={{marginTop:0}}><strong>Upload Queue:</strong></p> { content } </>;
    }

    //Render:
    return (
        <>
            <Box sx={{backgroundColor:"#fffcf7", padding:2, marginBottom:1, border:"1px dashed black"}}>
                { isUploading ? <UploadProgress uploaded={uploadedCount} total={totalCount} currentFile={currentFile}/> : displayQueuedFiles() }                
            </Box>
            <Grid container justifyContent="flex-end">
                <CreateButton disabled={ isUploading } title="Add Course Materials" onClick={ onAddFilesToQueueBtn }>Add File(s) to Queue</CreateButton>
                <DeleteButton disabled={ selectedFiles.length == 0 || isUploading } title="Clear Queue" onClick={ onClearQueueBtn }>Clear Queue</DeleteButton>
                <UploadButton disabled={ selectedFiles.length == 0 || isUploading } title="Uploaded Files" onClick={ onUploadBtn }>Upload File(s)</UploadButton>
            </Grid>
            {/*Hidden file input. We'll use a ref to reach it.*/}
            <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                onClick={resetFilePicker}
                style={{display: 'none'}}
                accept=".pdf,.ppt,.pptx" 
                multiple
            />
        </>
    );

}

//Course Materials List Component:
function CourseMaterialsList(props){
    const { courseId, updateSignal, setUpdateSignal } = props;

    //Is component currently mounted?
    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false }
    }, []);
    
    //Status message:
	var { StatusManager } = useContext(StatusMessageContext);

    //States:
    const [courseMaterials, setCourseMaterials] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    async function onDeleteFile(file){
        var success = true;
        try{
            let adapter = await getAdapter();
            let response = await adapter.course.deleteCourseContent(file.guid);
        }
        catch(error){
            console.log(error);
            success = false;
        }
        if(isMounted.current){
            let successStr = success ? "successful." : "failed.";
            StatusManager.setStatus("Delete course content " + successStr);
            setUpdateSignal(updateSignal+1);
        }
    }

    //On view load for Course Materials list:
    useEffect(() => {
		async function fetchData(){
			if(isMounted.current){
				setIsLoading(true);
			}
			try {
				let adapter = await getAdapter();
                let courseContent = await adapter.course.listCourseContent(courseId);
                if(isMounted.current) {
                    setCourseMaterials(courseContent);
                    setIsLoading(false);
                }
			}
			catch (error) {
				console.error(error);
			}
		}

        fetchData();

	}, [updateSignal]);

    //Render helper:
    function displayCourseMaterials(){
        let content = [];
        courseMaterials.forEach(file=>{
            content.push(<CourseMaterialsEntry key={file.guid} id={file.guid} file={file} onDelete={onDeleteFile} updateSignal={updateSignal} setUpdateSignal={setUpdateSignal}/>)
        });

        return content.length == 0 ? <p>None.</p> : <>{ content }</>;
    }

    async function onDownloadAllBtn(){
        console.log(courseId);
        try{
            let adapter = await getAdapter();
            let response = await adapter.course.getZippedCourseContent(courseId);             
            let blob = new Blob([response.data],{type:'application/zip'});
            let link = document.createElement('a');
            let url = window.URL.createObjectURL(blob);
            link.href = url;
            link.download = response.headers.filename || `${courseId}.zip`;
            link.click();
            link.remove();
            setTimeout(() => window.URL.revokeObjectURL(url), 100);
        }
        catch(error){
            console.log(error);
        }     
    }

    return(
        <>
            { isLoading ? <LoadingIndicator/> : 
            <>
                { courseMaterials && courseMaterials.length > 0 &&
				<Grid container justifyContent="flex-end">
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<FileDownload/>}
                        onClick={ onDownloadAllBtn }
                    >
                        Download All
                    </Button>
                </Grid> }
                { displayCourseMaterials() }
            </>           
            }
        </>
    );
}

//Course Materials Form:
function CourseMaterialsForm(props){

    const { courseId } = useParams();
    const history = useHistory();

	//Is component currently mounted?
    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false }
    }, []);
    
    //Status message:
	var { StatusManager } = useContext(StatusMessageContext);

	//Loading/Saving states:
	const [isLoading, setIsLoading] = useState(false);
	const [updateSignal, setUpdateSignal] = useState(0);
 
	//Form validation:
    function validate(){
        let _errors = { ...errors };
		//console.log("Validating");
		//console.log(formValues);

		_errors.courseNumber = (formValues.courseNumber === "") ? " " : "";
		_errors.courseTitle = (formValues.courseTitle === "") ? " " : "";

        setErrors({
            ..._errors
        });

		var result = Object.values(_errors).every(x => x === "");
		console.log("Validation " + (result ? "SUCCESS" : "FAILED"));
		return result;
	}

	//Initial Form Values:
	const initialFormValues = {
		courseNumber: "",
        courseTitle: "",
        courseDeliveryMode: "ILT",
		courseDescription: ""
	};

	//Form-related vars:
	const {
		formValues,
		setFormValue,
		errors,
		setErrors,
		handleInputChange,
	} = useForm(initialFormValues, false, validate);

	//Create refs to mutable objects that would otherwise be passed as dependencies to useEffect hook, to prevent triggering re-renders unnecessarily.
	const setFormValueRef = useRef(setFormValue);

	//On view load:
    useEffect(() => {
        let setFormValue = setFormValueRef.current;
        async function fetchData(){
            setIsLoading(true);
            try {
                var adapter = await getAdapter();               
                var course = await adapter.course.get(courseId);
            } 
            catch (error) {
                console.error(error);	
            }
            if(isMounted.current){
                setFormValue("courseNumber", course.courseNumber || "");
                setFormValue("courseTitle", course.courseTitle || "");
                setFormValue("courseDeliveryMode", course.isVirtualILT === true ? "vILT" : "ILT");
                setFormValue("courseDescription", course.description || "");
                setFormValue("courseICDEligible", course.icdEligible || true);
                setIsLoading(false);
            }
        }        
        fetchData();
    },
    [
        setIsLoading,
        courseId
    ]);

    //Button handlers:
    function onBackBtn(){
        history.goBack();
    }

    //Render:
	return (
        isLoading ? <LoadingIndicator/> :
		<>
            <p><strong>Course Information</strong></p>
            <FormSection>
                <Grid container spacing={1}>
                    <Grid item xs={3}><BetterFormField variant="filled" readOnly={true} label="FDA Course Number" name="courseNumber" value={formValues.courseNumber} error={errors.courseNumber} /></Grid>
                    <Grid item xs={9}><BetterFormField variant="filled" readOnly={true} label="Course Title" name="courseTitle" value={formValues.courseTitle} error={errors.courseTitle} /></Grid>
                    <Grid item xs={3}><BetterFormField variant="filled" readOnly={true} label="Course Delivery Mode" name="courseDeliveryMode" value={formValues.courseDeliveryMode} error={errors.courseDeliveryMode} /></Grid>                
                    <Grid item xs={12} mt={1}><BetterFormField variant="filled" readOnly={true} multiline={true} rows={6} label="Course Description" name="courseDescription" value={formValues.courseDescription} error={errors.courseDescription}/></Grid>						
                </Grid>
            </FormSection>
            { getLoggedInRole() != "applicant_poc"  && 
            <>
            <p><strong>Add Course Materials</strong></p>
            <FormSection>
                <ul>
                    <li style={{marginBottom:theme.spacing(1)}}>Select the <strong>Add Files to Queue</strong> button to upload new course materials.</li>
                    <li style={{marginBottom:theme.spacing(1)}}>Selected files will be queued for upload, but will only be uploaded once you select the <strong>Upload Files</strong> button.</li>
                    <li style={{marginBottom:theme.spacing(1)}}>Select the <strong>Clear Queue</strong> button to delete all files within the queue.</li>
                    <li style={{marginBottom:theme.spacing(1)}}>Since filenames can be ambiguous, you must enter a unique name for each file with the <strong>Name</strong> field.</li>
                    <li style={{marginBottom:theme.spacing(4)}}>You may optionally add a description for each file within the <strong>Description</strong> field.</li>
                </ul>                    
                <FileUploadComponent courseId={courseId} updateSignal={updateSignal} setUpdateSignal={setUpdateSignal}/>
            </FormSection> 
            </> }            
            <p><strong>Current Course Materials</strong></p>
            <FormSection>
                <CourseMaterialsList courseId={courseId} updateSignal={updateSignal} setUpdateSignal={setUpdateSignal}/>
            </FormSection>
            <Grid container justifyContent="center" alignItems="bottom" sx={{marginTop:4}}>
                <>
                    <BackButton onClick={onBackBtn} />
                </>
			</Grid>
		</>
	);
}

//Parent:
export default function ViewEditCourseMaterials() {
    let title = getLoggedInRole() != "applicant_poc" ? "View/Edit Course Materials" : "View Course Materials";
	return (
		<Container component="main" maxWidth="lg">
			<Paper style={{minHeight:'70vh'}} padding="large">
				<ViewTitle>{title}</ViewTitle>
                <CourseMaterialsForm />
			</Paper>
		</Container>
    );
}

import React from 'react';
import { Button, Card, CardHeader, CardActions, CardContent, Divider } from '@mui/material';
import { useHistory } from "react-router-dom";
import ApplicationStatusChart from "./ApplicationStatusChart";

export default function ApplicationStatusCard(props){
    const history = useHistory();

    function onViewReportBtn(){
        history.push("/dashboard/reports/application_status");
    }

    //Render:
    return(
        <Card {...props}>
            <CardHeader title="Application Status Breakdown"/>
            <Divider sx={{width:"100%"}} />          
            <CardContent>
                <ApplicationStatusChart/>
            </CardContent>
            <CardActions sx={{padding:1}}>
                <Button 
                    size="small"
                    onClick={ onViewReportBtn}
                >
                    View Report
                </Button>
            </CardActions>
        </Card>
    );    
}
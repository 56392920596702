import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory } from "react-router-dom";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ViewTitle from './ViewTitle';
import BetterFormField from './FormField';
import SavingIndicator from './SavingIndicator';
import { getAdapter } from './adapters/adapter';
import theme, {CancelButton, SaveButton} from './theme/PortalTheme';
import { StatusMessageContext } from './StatusMessage';
import useForm from './UseForm';
import { isValidState, isValidZipCode } from './Utility';
import { getLoggedInRole } from './adapters/baseAdapter';

function InstructorForm(props) {
    var history = useHistory();

	//Is component currently mounted?
    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false }
    }, []);
	
	//Status message:
	var { StatusManager } = useContext(StatusMessageContext);

	//Loading/Saving states:
	const [isSaving, setIsSaving] = useState(false);
	
	useEffect(() => {
        if(isMounted.current){
            if(!["oted_pa", "superadmin"].includes(getLoggedInRole())){
                StatusManager.setStatus("Access denied. You have been returned to the dashboard home screen.");
                history.push("/dashboard");
            }    
        }
	}, []);	

	//Form validation:
    function validate(){
        let _errors = { ...errors };
		console.log("Validating");
		console.log(formValues);

		_errors.instructorLast = (formValues.instructorLast === "") ? " " : "";
		_errors.instructorFirst = (formValues.instructorFirst === "") ? " " : "";
		_errors.instructorEmail = (formValues.instructorEmail === "") ? " " : "";
        _errors.instructorPhone = (formValues.instructorPhone === "") ? " " : "";
        _errors.instructorStreet = (formValues.instructorStreet === "") ? " " : "";
        _errors.instructorCity = (formValues.instructorCity === "") ? " " : "";
        //Is state valid?
        _errors.instructorState = (isValidState(formValues.instructorState))? "" : " ";
		//Is zip valid?
		_errors.instructorZip = (isValidZipCode(formValues.instructorZip)) ? "" : " ";
        _errors.instructorRole = (formValues.userRole === "") ? " " : "";
        setErrors({
            ..._errors
        });

		var result = Object.values(_errors).every(x => x === "");
		console.log("Validation " + (result ? "SUCCESS" : "FAILED"));
		return result;
	}	

	//Initial Form Values:
	const initialFormValues = {
		instructorLast: "",
		instructorMiddle: "",
		instructorFirst: "",
		instructorEmail: "",
		instructorPhone: "",
		instructorStreet: "",
		instructorCity: "",
		instructorState: "",
		instructorZip: ""
	};

	//Form-related vars:
	const {
		formValues,
		errors,
		setErrors,
		handleInputChange,
	} = useForm(initialFormValues, false, validate);	

	//Save:
	async function saveData( successCallback = () => {} ){
		var success = true;
		var isError = false;
		setIsSaving(true);
		try {
			var adapter = await getAdapter();
			var instructor = {
				lastName: formValues.instructorLast,
				middleInitial: formValues.instructorMiddle,
				firstName: formValues.instructorFirst,
				email: formValues.instructorEmail,
				phone: formValues.instructorPhone,
				addressStreet: formValues.instructorStreet,
				addressCity: formValues.instructorCity,
				addressState: formValues.instructorState,
				addressZip: formValues.instructorZip
			};
			await adapter.instructor.create(instructor);
		} 
		catch(error){
			success = false;
			isError = true;

			if(isMounted.current && error.response?.data?.data?.response) {
				isError = true;
				StatusManager.setStatus("Save instructor failed: " + error.response.data.data.response);	
			}
			//If an instructor with this email already exists within the system:
			// if(error.response.data.status === "duplicate instructor" && isMounted.current){
            //     setErrors({
            //         ...errors,
            //         instructorEmail: "Invalid email. An instructor with this email address already exists within the system."
            //     });
            // }			
		}
		if(isMounted.current){
			setIsSaving(false);
			var successStr = success ? "successful." : "failed.";
			console.log("Save " + successStr );
			if(!isError) StatusManager.setStatus("Save instructor " + successStr);
            if(success) successCallback();
		}
	}

    //Button handlers:
	function onCancelBtn(){
		history.goBack();
	}

    function onSaveBtn(){
		if(validate()){
			saveData(()=>{
				history.goBack();
			});
		}
    }

	//Render:
	return (
        <> 
			<p style={{marginBottom:theme.spacing(1)}}><strong>Name</strong></p>
			<Grid container spacing={1}>
				<Grid item xs={5}><BetterFormField required label="Last Name" name="instructorLast" value={formValues.instructorLast} error={errors.instructorLast} onChange={handleInputChange} /></Grid>
				<Grid item xs={2}><BetterFormField label="Middle Initial" name="instructorMiddle" value={formValues.instructorMiddle} error={errors.instructorMiddle} onChange={handleInputChange} /></Grid>
				<Grid item xs={5}><BetterFormField required label="First Name" name="instructorFirst" value={formValues.instructorFirst} error={errors.instructorFirst} onChange={handleInputChange} /></Grid>
				<Grid item xs={12}></Grid>
			</Grid>
			<p style={{marginBottom:theme.spacing(1)}}><strong>Contact</strong></p>
			<Grid container spacing={1}>
				<Grid item xs={6}><BetterFormField required label="Email" name="instructorEmail" value={formValues.instructorEmail} error={errors.instructorEmail} onChange={handleInputChange} /></Grid>
				<Grid item xs={6}><BetterFormField required label="Phone" name="instructorPhone" value={formValues.instructorPhone} error={errors.instructorPhone} onChange={handleInputChange} /></Grid>
				<Grid item xs={12}><BetterFormField required label="Street" name="instructorStreet" value={formValues.instructorStreet} error={errors.instructorStreet} onChange={handleInputChange} /></Grid>
				<Grid item xs={5}><BetterFormField required label="City" name="instructorCity" value={formValues.instructorCity} error={errors.instructorCity} onChange={handleInputChange} /></Grid>
				<Grid item xs={2}><BetterFormField required label="State" name="instructorState" value={formValues.instructorState} error={errors.instructorState} onChange={handleInputChange} /></Grid>
				<Grid item xs={5}><BetterFormField required label="Zip" name="instructorZip" value={formValues.instructorZip} error={errors.instructorZip} onChange={handleInputChange} /></Grid>
			</Grid>   
            <Grid container justifyContent="center" alignItems="bottom" sx={{marginTop:4}}>
				{ isSaving ? <><SavingIndicator /></>:
                    <>
                        <CancelButton onClick={onCancelBtn} />                                
                        <SaveButton onClick={onSaveBtn} />
                    </>
                }
			</Grid>
        </>
	);
}

export default function CreateInstructor(){
	return (
		<Container component="main" maxWidth="lg">
			<Paper style={{minHeight:'70vh'}} padding="large">
				<ViewTitle>Create Instructor</ViewTitle>
				<InstructorForm />
			</Paper>
		</Container>
	);
}
import React, {useState, useEffect, useRef} from 'react';
import { useHistory } from "react-router-dom";
import ViewTitle from './ViewTitle';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { getAdapter } from './adapters/adapter';
import theme from './theme/PortalTheme';
import LoadingIndicator from './LoadingIndicator';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { idToGuidMapper } from './Utility';
import { CourseGrid } from './ManageCourses';
import { getLoggedInRole } from './adapters/baseAdapter';

export default function ListCourses () {
	var stateRefs = {};
	const history = useHistory();

	//States related to data fetching:
	const [isLoading, setIsLoading] = stateRefs.isLoading = useState(false);
	// eslint-disable-next-line
	const [isError, setIsError] = useState(false);
	const [courses, setCourses] = useState([]);
	// eslint-disable-next-line	
	const [selected, setSelected] = stateRefs.selected = useState([]);
	// eslint-disable-next-line
	const [pleaseWait, setPleaseWait] = stateRefs.pleaseWait = useState(false);
	stateRefs.deleteDisabled = useState(true);

	const isMounted = useRef(false);
	useEffect(() => {
		isMounted.current = true;
		return () => { isMounted.current = false }
	}, []);

	useEffect(() => {
		async function fetchData(){
			setIsError(false);
			setIsLoading(true);
			try {
				var adapter = await getAdapter();
				var courseList = await adapter.course.list();
				console.log(courseList);
			}
			catch (error) {
				if(isMounted.current){
					setIsError(true);
				}
				console.error(error);	
			}
			if(isMounted.current){
				courseList = courseList.map(idToGuidMapper);
				//Fix visual display of empty fields to say "Incomplete":
				courseList.map(course=>{
					Object.entries(course).forEach(([key,value])=>{
						if(typeof(value)==="string" && value.length === 0){
							course[key] = "Incomplete"
						}					
					});
					return course;
				});
				setCourses(courseList);
				setIsLoading(false);
			}
		}
		
		fetchData();

	}, [setIsLoading, 
		setIsError]);

	function actionsColumnRenderCell(params){		

		async function onApplyBtn(params){
			if(isMounted.current){
				stateRefs.pleaseWait[1](true);
			}
			//create new application
			var adapter = await getAdapter();
			var courseId = params.guid;
			var applicationId = await adapter.application.create();
			//Update (pre-populate) the application with the information for the selected course:
			await adapter.application.updateCourse(applicationId, courseId);
			if(isMounted.current){
				stateRefs.pleaseWait[1](false);
				history.push("/dashboard/applications/view_edit/" +  applicationId + "/1");
			}
		}

		function onViewBtn(params){
			var courseId = params.guid;
			history.push("/dashboard/courses/view/" + courseId);
		}

		const buttonSpacing = theme.spacing(1);

		//to access course data in callback (eg params.row.guid), pass the row to the callback
		return (
			<React.Fragment>
				{
					!["qualified_instructor"].includes(getLoggedInRole()) &&				
					<button 
						style={{marginRight:(buttonSpacing), cursor:"pointer"}}
						onClick={() => onApplyBtn(params.row)}
					>
						Apply
					</button>
				}
				<button
					style={{marginRight:buttonSpacing, cursor:"pointer"}}
					onClick={() => onViewBtn(params.row)}
				>
					View
				</button>			
			</React.Fragment>
		);
	}

	var creatingApplicationMarkup = (
		<>
			<Grid container justifyContent="center" p={4}>
				<Typography variant="h6" color={"primary"}>Creating Application...</Typography>
				<LoadingIndicator padding={4}/>
			</Grid>
		</>
	);	

	return (
		<Container component="main" maxWidth="lg">
			{
				pleaseWait ? creatingApplicationMarkup :  
				<>
					<Paper style={{minHeight:'70vh'}} padding="large">				
						<ViewTitle>Available Courses</ViewTitle>					
						<p style={{marginBottom:0}}>Listed below are the FDA courses that may be delivered as an Indepedent Course Delivery (ICD).</p>
						<ul>
							<li>Select the <strong>Apply</strong> button to start a new ICD Application.</li>					
							<li>Select the <strong>View</strong> button to view information about a course.</li>
						</ul>
						<CourseGrid
							actionsColumnRenderCell = {actionsColumnRenderCell}
							isMounted = {isMounted}
							stateRefs = {stateRefs}
							loading = {isLoading}
							rows = {courses}
						/>
					</Paper>	
				</>				
			}
		</Container>
	);
}

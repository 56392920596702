import React, { useEffect, useState, useRef, useContext } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { StepNavigator } from './StepLogic';
import { getAdapter } from './adapters/adapter';
import ViewTitle from './ViewTitle';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { Box } from '@mui/material';
import theme, { FormSection, FormSectionHeading } from "./theme/PortalTheme";
import { FormControlLabel } from '@mui/material';
import BetterFormField from './FormField';
import LoadingIndicator from './LoadingIndicator';
import SavingIndicator from './SavingIndicator';
import { useHistory } from 'react-router-dom';
import { StatusMessageContext } from './StatusMessage';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import LockIcon from '@mui/icons-material/Lock';
import useForm from "./UseForm";
import { Palette, Save } from '@mui/icons-material';
import { Button } from '@mui/material';
import { getLoggedInRole } from './adapters/baseAdapter';

function AgreementsBlock(props){
	var { checkedState, setCheckedState, error } = props;
	
	function handleChange(evt){
		setCheckedState(!checkedState);
	}

	//Uncomment below for validate-on-change behavior, and also add "validate" to expected props:
	/*
	useEffect(()=>{
		validate();
	}, [checkedState]);
	*/

	return(
		<Grid container>
			<Grid item xs>
				{props.children}
			</Grid>
			<Grid item>
				<Box paddingLeft={8}>
					<FormControl {...(error && {error:true})}>
						<FormControlLabel 
							sx={{marginTop:'8px'}} 
							control={
								props.locked ? 
								<><LockIcon sx={{color:"#666"}}/><CheckBoxIcon color="primary" sx={{marginRight:1}}/></> : 
								<Checkbox checked={checkedState} onChange={handleChange}/>
							} 
							label={<strong>I Agree</strong>} 
						/>
						<FormHelperText>{error}</FormHelperText>
					</FormControl>
				</Box>
			</Grid>
		</Grid>
	);
}

export default function ICDApplicationFormAgreements(props) {

	const { application, stepLogic } = props;
	var history = useHistory();

	//Is component currently mounted?
	const isMounted = useRef(false)
	useEffect(() => {
		isMounted.current = true;
		return () => { isMounted.current = false }
	}, []);

    //Status message:
	var { StatusManager } = useContext(StatusMessageContext);

	//Loading/Saving states:
	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	//Form validation:
    function validate(){
        let _errors = { ...errors };
		console.log("Validating");
		console.log(formValues);

		_errors.agreementResponse1 = formValues.agreements.agreementResponse1 !== true ? "Required" : "";
		_errors.agreementResponse2 = formValues.agreements.agreementResponse2 !== true ? "Required" : "";
		_errors.agreementResponse3 = formValues.agreements.agreementResponse3 !== true ? "Required" : "";
		_errors.agreementResponse4 = formValues.agreements.agreementResponse4 !== true ? "Required" : "";
		_errors.agreementResponse5 = formValues.agreements.agreementResponse5 !== true ? "Required" : "";
		_errors.agreementResponse6 = formValues.agreements.agreementResponse6 !== true ? "Required" : "";
		_errors.agreementResponse7 = formValues.agreements.agreementResponse7 !== true ? "Required" : "";

        setErrors({
            ..._errors
        });

		var result = Object.values(_errors).every(x => x === "");
		console.log("Validation " + (result ? "SUCCESS" : "FAILED"));
		return result;	
	}

	//Initial Form Values:
	const initialFormValues = {
		course: {},
		agreements: {},
		agreementsLocked: false
	};

	//Form-related vars:
	const {
		formValues,
		setFormValue,
		errors,
		setErrors,
	} = useForm(initialFormValues, false, validate);	
		
	//Has user agreed to everything?
	function checkAgreements(agreements){
		for (var i in agreements){
			if(agreements[i] !== true){
				return false;
			}
		}
		return true;
	}

	//Create refs to mutable objects that would otherwise be passed as dependencies to useEffect hook, to prevent triggering re-renders unnecessarily.
	const setFormValueRef = useRef(setFormValue);
	const checkAgreementsRef = useRef(checkAgreements);

	//On view load:
	useEffect(() => {		
		let setFormValue = setFormValueRef.current;
		let checkAgreements = checkAgreementsRef.current;

		async function fetchData(){
			setIsLoading(true);
			try {
				var adapter = await getAdapter();
				var course = null;
				if(application.courseID){
					course = await adapter.course.get(application.courseID);
				}
			} 
			catch (error) {
				console.error(error);
			}
			if(isMounted.current){
				if(course != null){
					setFormValue("course", course)
				}
				setFormValue("agreements", application.agreements);
				setFormValue("agreementsLocked", checkAgreements(application.agreements));
				setIsLoading(false);
			}
		};
		
		fetchData();

	}, [ application ]);

	//Debugging:
	useEffect(() => {
		//console.log(formValues);
    }, [ formValues ]);

	//Save application. If newStatus is specified, application status is updated:
	async function saveData( successCallback = () => {} ){
		console.log("Updating information for [ application.guid: " + application.guid + " ]");
		if(isMounted.current){
			setIsSaving(true);
		}
		var success = true;	
		try {
			var adapter = await getAdapter();
			await adapter.application.updateAgreements(application.guid, true, formValues.agreements);
			let checkAgreements = checkAgreementsRef.current;
			if(checkAgreements(formValues.agreements)){
				await adapter.application.updateStep(application.guid, "Agreements");
				stepLogic.completeCurrentStep();
			}
			if(getLoggedInRole() === "applicant_poc"){
				await adapter.application.updateStatus(application.guid, "submitted");
			}		
		} 
		catch (error) {
			success = false;
			console.error(error);
		}		
		if(isMounted.current){
			setIsSaving(false);
			var successStr = success ? "successful." : "failed.";
			if(getLoggedInRole()==="applicant_poc"){
				StatusManager.setStatus("Submit application " + successStr);
			}
			else{
				StatusManager.setStatus("Save application agreements information " + successStr);
			}
			if(success) successCallback();
		}	
	};

	function onContinueBtn(){
		stepLogic.nextStep();		
	}

	function onSaveBtn(){
		if(validate()){		
			saveData(()=>{
				stepLogic.nextStep();
			});
		}		
	}

	function onSubmitBtn(){
		if(validate()){		
			saveData(()=>{
				if(getLoggedInRole() === "applicant_poc"){
					history.push("/dashboard/applications/list/");
				}
				//If PA or Admin, go to Application Status screen:
				else{
					stepLogic.nextStep();
				}
			});
		}
	}

	function setCheckedState(name, value){
		let agreements = {...formValues.agreements};
			agreements[name]=value;
		setFormValue("agreements", agreements);
	}

	var listItemLeftPadding = theme.spacing(2);
	var hideNext = !(stepLogic.canNext());
	
	//Application should be locked if the user is a POC and the application has been submitted:
	let isApplicationLocked = (getLoggedInRole()==="applicant_poc" && application.status !="in-progress");
	let conditionalProps = isApplicationLocked ? {variant: "filled", readOnly: true}: {};
	
	return (
		<React.Fragment>
			<Paper padding="huge" sx={{margin:0}}>
				<Grid container alignItems="center" justifyContent="center">
					<ViewTitle>Create ICD Application &ndash; {stepLogic.getCurrentStepName()}</ViewTitle>
				</Grid>
				{ isLoading ? <LoadingIndicator padding={4}/> :
					<>
						<FormSectionHeading>Course Requested for Delivery</FormSectionHeading>
						<FormSection>
							<Grid container spacing={1}>
								<Grid item xs={3}><BetterFormField required readOnly={true} variant="filled" label="FDA Course Number" name="courseNumber" value={(formValues.course && formValues.course.courseNumber) || ""}/></Grid>
								<Grid item xs={9}><BetterFormField required readOnly={true} variant="filled" label="Course Title" name="courseTitle" value={(formValues.course && formValues.course.courseTitle) || ""}/></Grid>
							</Grid>
						</FormSection>
						<FormSectionHeading>Agreements</FormSectionHeading>
						<FormSection>
							<p>By submitting this form, I/my organization is requesting an Independent Course Delivery (ICD) of the course listed above and agree to the following statements:</p>
							<Box px={4}>
								<AgreementsBlock
									checkedState={formValues.agreements.agreementResponse1 === true}
									setCheckedState={(value) => { 
										setCheckedState("agreementResponse1", value);
									}}
									locked={ formValues.agreementsLocked }
									validate={validate}
									error={errors.agreementResponse1} 
								>
									<ol start="1"><li style={{paddingLeft:listItemLeftPadding}}>I confirm the date of the proposed course delivery is at minimum 12 weeks after the date of submission of this form.</li></ol>
								</AgreementsBlock>
								<AgreementsBlock
									checkedState={formValues.agreements.agreementResponse2 === true}
									setCheckedState={(value) => { 
										setCheckedState("agreementResponse2", value);
									}}
									locked={ formValues.agreementsLocked }
									validate={validate}
									error={errors.agreementResponse2} 
								>
									<ol start="2"><li style={{paddingLeft:listItemLeftPadding}}>I acknowledge FDA as the official sponsor for this course.<ol type="a"><li style={{paddingLeft:listItemLeftPadding}}>If the course will be offered by another entity on behalf of the requestor, all advertisements and announcements must note the course is an FDA course being held at their facility.</li></ol></li></ol>
								</AgreementsBlock>
								<AgreementsBlock
									checkedState={formValues.agreements.agreementResponse3 === true}
									setCheckedState={(value) => { 
										setCheckedState("agreementResponse3", value);
									}}
									locked={ formValues.agreementsLocked }
									validate={validate}
									error={errors.agreementResponse3} 
								>
									<ol start="3"><li style={{paddingLeft:listItemLeftPadding}}>I understand that my organization and the ICD Instructors:<ol type="a"><li style={{paddingLeft:listItemLeftPadding}}>Must not delete or alter content from the original course material.</li><li style={{paddingLeft:listItemLeftPadding}}>Must fully cover all course content that has been provided.</li><li style={{paddingLeft:listItemLeftPadding}}>Must adhere to the course equipment, supply, and facility requirements provided by OTED.</li></ol></li></ol>
								</AgreementsBlock>
								<AgreementsBlock
									checkedState={formValues.agreements.agreementResponse4 === true}
									setCheckedState={(value) => { 
										setCheckedState("agreementResponse4", value);
									}}
									locked={ formValues.agreementsLocked }
									validate={validate}
									error={errors.agreementResponse4} 
								>
									<ol start="4"><li style={{paddingLeft:listItemLeftPadding}}>I/my organization will retain the following information and items after completion of the course, and understand that OTED could request this information in the future: <ol type="a"><li style={{paddingLeft:listItemLeftPadding}}>Daily Sign-In Sheet: A copy of the Daily Course Sign-In Sheet with signatures from all participants is needed to confirm attendance.</li><li style={{paddingLeft:listItemLeftPadding}}>Course Records/Artifacts: All pertinent course records and artifacts must be retained for verification of student success.</li></ol></li></ol>
								</AgreementsBlock>
								<AgreementsBlock
									checkedState={formValues.agreements.agreementResponse5 === true}
									setCheckedState={(value) => { 
										setCheckedState("agreementResponse5", value);
									}}
									locked={ formValues.agreementsLocked }
									validate={validate}
									error={errors.agreementResponse5} 
								>
									<ol start="5"><li style={{paddingLeft:listItemLeftPadding}}>(If applicable to course and provided by OTED) I/my organization will promptly return the Course Equipment Kit after completion of the course. <ol type="a"><li style={{paddingLeft:listItemLeftPadding}}><strong>PLEASE NOTE</strong>: If there are any materials that are damaged beyond repair, lost, or stolen, the organization requesting the course is responsible for paying any cost to replace the materials. </li></ol></li></ol>
								</AgreementsBlock>
								<AgreementsBlock
									checkedState={formValues.agreements.agreementResponse6 === true}
									setCheckedState={(value) => { 
										setCheckedState("agreementResponse6", value);
									}}
									locked={ formValues.agreementsLocked }
									validate={validate}
									error={errors.agreementResponse6} 
								>
									<ol start="6"><li style={{paddingLeft:listItemLeftPadding}}>I understand that enrollment of participants for this training course will be limited to state, local, and tribal regulatory partners from within the jurisdiction of my organization. However, if seats are not filled, they may be offered to students from neighboring jurisdictions.</li></ol>
								</AgreementsBlock>
								<AgreementsBlock
									checkedState={formValues.agreements.agreementResponse7 === true}
									setCheckedState={(value) => { 
										setCheckedState("agreementResponse7", value);
									}}
									locked={ formValues.agreementsLocked }
									validate={validate}
									error={errors.agreementResponse7} 
								>
									<ol start="7"><li style={{paddingLeft:listItemLeftPadding}}>I understand that this proposed course is not approved for delivery until I/my organization receives a formal ICD Application Package Approval Notice from FDA OTED.</li></ol>
								</AgreementsBlock>					
							</Box>
						</FormSection>
					</>
	 			}
			</Paper>
			{
				isSaving ? <SavingIndicator/> :
				<Grid sx={{marginTop: 4}} container alignItems="center" justifyContent="center">
					{ isApplicationLocked &&
					<Box sx={{backgroundColor: theme.palette.background.statusMessage, px:2, border:"1px solid #CCC", textAlign:"center"}}>
						<p>This application has been submitted and is locked.</p>
					</Box> }
					{ !isApplicationLocked && (application.status === "in-progress") && (getLoggedInRole() !== "applicant_poc") && stepLogic.canNext() && <Button variant="contained" color="primary" size="large" onClick={onSaveBtn} startIcon={<Save/>}>Save and Continue</Button> }
					{ !isApplicationLocked && (application.status === "in-progress") && (getLoggedInRole() === "applicant_poc") && <Button variant="contained" color="primary" size="large" onClick={onSubmitBtn}>Submit Application</Button> }	
					{ !isApplicationLocked &&  (application.status !== "in-progress" ) && stepLogic.canNext() && <Button variant="contained" color="primary" size="large" onClick={onContinueBtn}>Continue</Button>}
				</Grid>
			}
		</React.Fragment>		
	);
}

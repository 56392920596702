import React, {useState, useEffect, useRef} from 'react';
import { getAdapter } from './adapters/adapter';
import { CourseGrid } from './ManageCourses.js';
import { idToGuidMapper } from './Utility';
import theme from './theme/PortalTheme';
import Dialog from '@mui/material/Dialog';
import { DialogContent } from '@mui/material';
import { BootstrapDialogTitle } from './BootStrapDialogTitle';

export default function SelectCourseDialog(props){

    var { open, setOpen, setCourse } = props;

    const isMounted = useRef(false)
	useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false }
	}, []);

    const [isLoading, setIsLoading] = useState(false);
    // eslint-disable-next-line
	const [isError, setIsError] = useState(false);
    const [courses, setCourses] = useState([]);

    function actionsColumnRenderCell(params){

        function onSelectBtn(params){
            setCourse(params);
            setOpen(false);
        }

        return (
            <React.Fragment>
                <button
                    style={{marginRight:theme.spacing(1), cursor:"pointer"}}
                    onClick={() => onSelectBtn(params.row)}
                >
                    Select
                </button>
            </React.Fragment>
        );
    }

    useEffect(() => {
        async function fetchData(){
            setIsError(false);
            setIsLoading(true);
            try {
                var adapter = await getAdapter();               
                var courseList = await adapter.course.list();
                courseList = courseList.map(idToGuidMapper);
            } 
            catch (error) {
                if(isMounted.current){
                    setIsError(true);
                }
                console.error(error);
            }
            if(isMounted.current){
                setCourses(courseList);
                setIsLoading(false);
            }
        }
        
        fetchData();
        
    }, [setIsError, 
        setIsLoading, 
        setCourses]);

    function onDialogClose(){
        setOpen(false);
    }

    return (
        <Dialog
            maxWidth={"lg"}
            fullWidth={true}
            open={open}    
            container={() => document.getElementById('dialogParent')}
        >            
            <BootstrapDialogTitle color="primary" onClose={onDialogClose}>Select Course</BootstrapDialogTitle>
            <DialogContent>
                <CourseGrid
                    actionsColumnRenderCell = {actionsColumnRenderCell}
                    rows = {courses}
                    loading = {isLoading}
                />
            </DialogContent>           
        </Dialog>
    )
}
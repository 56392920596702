import React, { useEffect, useState, useRef } from 'react';
import {Container, Paper, Typography } from '@mui/material';
import { getAdapter } from '../adapters/adapter';
import ViewTitle from '../ViewTitle';
import moment from 'moment';
import ReportDateRangeSelector from '../ReportDateRangeSelector';
import { FormSection } from '../theme/PortalTheme';
import LoadingIndicator from '../LoadingIndicator';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import LinkIcon from '@mui/icons-material/Link';
import {Link } from "react-router-dom";
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import { ExportReportButton } from '../theme/PortalTheme';

export default function CourseDrilldown(props){

    //Is component currently mounted?
	const isMounted = useRef(false)
	useEffect(() => {
		isMounted.current = true;
		return () => { isMounted.current = false }
	}, []);

    //States:
    const [isLoading, setIsLoading] = useState(false);
    const [metrics, setMetrics] = useState({});
    const [startDate, setStartDate] = useState(moment.utc().subtract(30, 'days').local().format());
    const [endDate, setEndDate] = useState(moment.utc().local().format());
    const [runReportCounter, setRunReportCounter] = useState(0); //Explicitly used to trigger re-render via useEffect when necessary.
    
    //Initialize view:
    useEffect(()=>{
        async function fetchData(){
            setIsLoading(true);
            try {
                var adapter = await getAdapter();
                //Make some call to adapter:
                var result = await adapter.course.getCourseMeta({startDate: startDate, endDate: endDate});
            } 
            catch (error) {
                console.error(error)
            }
            if(isMounted.current){
                setMetrics(result);
                setIsLoading(false);
            }
        };
        
        fetchData();
    }, [runReportCounter]);

    //Button handlers:
    function onGenerateReportBtn(_startDate, _endDate){
        console.log("Generating Report [ Start: " + _startDate + " ] - [ End: " + _endDate + " ]");
        if(isMounted.current){
            setStartDate(_startDate);
            setEndDate(_endDate);
            //Force re-render via useEffect by adjusting a state:
            setRunReportCounter(runReportCounter+1);
        }
    }

    //When export report button is pressed:
    function onExportReportBtn(e){
        
        //Calculate X coordinate in order to center text on page:
        function getXOffset(doc, text){
            return (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(text) * doc.internal.getFontSize() / 2);
        }

        //Create new jsPDF document:
        const doc = new jsPDF("p", "pt", "a4");
        doc.setFontSize(10);

        //Used to store last y Coordinate of PDF writing cursor in order to correctly place elements on page:
        let cursorY = 30;

        //Report title and date:
        const reportTitle = "FDA OTED Portal - Course Report";
        doc.text(reportTitle, getXOffset(doc, reportTitle), cursorY);
        cursorY+=20;
        const reportDate =  moment.utc(startDate).local().format("MM/DD/YYYY") + " - " + moment.utc(endDate).local().format("MM/DD/YYYY");
        doc.text(reportDate, getXOffset(doc, reportDate), cursorY);
        cursorY+=30;
        doc.setFontSize(12);
        
        //Dynamically generates a report section using Auto-Table plugin for jsPDF:
        function generateReportSection(doc, sectionTitle, rows){
            const columnHeads = ["Course Number", "Course Title", "Delivery Method", "Created Date"];
            sectionTitle += " (" + rows.length + ")" + ":";
            doc.setFont(undefined,"bold").text(sectionTitle, 40, cursorY).setFont(undefined,"normal");
            cursorY += 8;
            var data = {head: [columnHeads], body: [], startY: cursorY};
            rows.forEach((row)=>{
                data.body.push([
                    row.courseNumber || "Not specified", 
                    row.courseTitle || "Not specified",
                    row.isVirtualILT ? "vILT" : "ILT",   
                    moment.utc(row.createdOn).local().format('MM/DD/yyyy')
                ]);
            });

            //Display "None." if a report section is empty
            if(rows.length == 0){
                const noneStr = "None.";
                cursorY += 7;
                doc.setFontSize(10).text(noneStr, 40, cursorY).setFontSize(12);
                cursorY += 30;
            }
            //Otherwise display the datatable:
            else{
                autoTable(doc, data);
                cursorY = doc.lastAutoTable.finalY + 30;
            }
        }

        //Write report contents to PDF document:
        generateReportSection(doc, "Courses", metrics);
       
        //Derive filename using start and end date of report scope:
        const filenameStartDate = moment.utc(startDate).local().format("YYYY_MM_DD");
        const filenameEndDate = moment.utc(endDate).local().format("YYYY_MM_DD");
        const filenameDateSuffix = filenameStartDate + "_" + filenameEndDate;
        const fileExt = ".pdf";
        const filename = "course_report_" + filenameDateSuffix + fileExt;
        
        //Save file:
        doc.save(filename);
    }

    //Render:
    return(
		<Container component="main" maxWidth="lg">
			<Paper style={{minHeight:'30vh'}} padding="large">
				<ViewTitle>Course Report</ViewTitle>
                <h4>Report Scope</h4>
                <ReportDateRangeSelector onGenerateReportBtn={onGenerateReportBtn} startDate={startDate} endDate={endDate}/>
                <h4>Report Contents</h4>
                {isLoading ? <LoadingIndicator/> : 
                    <>
                        <ExportReportButton onClick={onExportReportBtn}>Export Report as PDF</ExportReportButton>
                        <CourseReportSection label="Courses" data={metrics} />
                    </>
                }
			</Paper>
		</Container>
    );
}

function CourseReportSection(props){
    var { label, data } = props;

    //Render:
    return(
        <FormSection sx={{marginTop:2}}>
            <Typography color="primary">{label}</Typography>
            { data && data.length > 0 ?
                <CourseReportTable tableName={label} rows={data}/>
                :
                <p>None.</p>
            }
        </FormSection>
    );
}

function CourseReportTable(props){
    const { rows, tableName } = props;
    const url = "/dashboard"

    //Render:
    return(
        <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label={props.tableName}>
            <TableHead>
                <TableRow>
                    <TableCell align="left"><strong>Course Number</strong></TableCell>
                    <TableCell align="left"><strong>Course Title</strong></TableCell>
                    <TableCell align="left"><strong>Delivery Method</strong></TableCell>
                    <TableCell align="left"><strong>Created Date</strong></TableCell>
                    <TableCell align="left"><strong><LinkIcon aria-label="Link"/></strong></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    rows.map((row) => (
                        <TableRow
                            key={row.guid}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="left">{row.courseNumber}</TableCell>
                            <TableCell align="left">{row.courseTitle}</TableCell>
                            <TableCell align="left">{row.isVirtualILT ? "vILT" : "ILT"}</TableCell>
                            <TableCell align="left">{moment.utc(row.createdOn).local().format('MM/DD/yyyy')}</TableCell>
                            <TableCell align="left">
                                <Link
                                    to={{
                                        pathname: url + "/courses/view_edit/" + row.guid + "/1"
                                    }}
                                >
                                    View
                                </Link>
                            </TableCell>
                        </TableRow>
                    ))
                }
            </TableBody>
        </Table>
        </TableContainer>
    );
}
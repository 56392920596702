import React, { useState, useEffect, useRef, useContext } from 'react';
import Message from './Message';
import { useHistory, useParams } from "react-router-dom";
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { getAdapter} from './adapters/adapter';
import * as baseAdapter from './adapters/baseAdapter';
import { getFormattedDate } from './Utility';
import {Box, Container} from '@mui/material';
import ViewTitle from './ViewTitle';
import Paper from '@mui/material/Paper';
import LoadingIndicator from './LoadingIndicator';
import { StatusMessageContext } from './StatusMessage';
import { handleError } from './adapters/utils';
import socketIOClient from "socket.io-client";
import { SOCKET_URL } from "./adapters/utils";
import moment from 'moment';

function MessageApp(props) {
  var { applicationId } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  // const [application, setApplication] = useState();
  const [applicationCourse, setApplicationCourse] = useState();
  const [applicationStartDate, setApplicationStartDate] = useState();
  const [applicationOrg, setApplicationOrg] = useState();
  const [messages, setMessages] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [invalidFile, setInvalidFile] = useState(false);
  var { StatusManager } = useContext(StatusMessageContext);

  const internal = props.internal === "1" ? true : false;
  const title = (internal) ? "Internal Messages" : "Messages";
  var socket;

  const isMounted = useRef(false);
	useEffect(() => {
		isMounted.current = true;
		return () => { 
      isMounted.current = false;
      if(socket) socket.close();
     }
	}, []);

  useEffect(() => {

    

    async function fetchData(){
			try {
        if(isMounted.current) {
          var adapter = await getAdapter();
          var application = await adapter.application.getExtended(applicationId);
          var messages = await getMessages(applicationId);
          // setApplication(application);
          setApplicationCourse(application.courseInfo.courseNumber);
          setApplicationOrg(application.organization.name);
          if(application.delivery.startDate){
            setApplicationStartDate(moment.utc(application.delivery.startDate).local().format("MM/DD/YYYY"));
          }
          
          setMessages(messages);

          socket = socketIOClient(`${SOCKET_URL}`,{transports: ['websocket', 'polling', 'flashsocket']});

          //On connection established:
          socket.on('connect', ()=>{            
            socket.emit('authenticate_message', {token: baseAdapter.getAuthHeader(), applicationId: applicationId, internal: internal});	
          });
         
          socket.on('messages', (data)=> {
            parseMessages(data).then(results => {
            setMessages(results)})
          });

          //On connection error:
          socket.on('connect_error', ()=>{
            console.log("socket connection error");
            socket.close();
            //Attempt to reconnect every 10 seconds:
            // setTimeout(()=>socket.connect(),10000);
          });

          //On disconnect:
          // socket.on('disconnect',()=> {
          //   console.log('server disconnected');	
          //   //Attempt to reconnect every 10 seconds:
          //   setTimeout(()=>socket.connect(),10000); 
          // });	
        }
			}
			catch (err) {
        handleError(err, StatusManager);
			}
		}

		fetchData();
	}, []);

  function handleClick() {
    history.push("/dashboard/applications/view_edit/"+applicationId+"/1");
  }

  function handleAttachmentClick(event) {
    event.preventDefault();
    try {
      if(!event.target.files[0]) return;
      var fileName = event.target.files[0].name;
      if(fileName.split('.').pop() === 'pdf') {
        setSelectedFile(event.target.files[0]);
        setInvalidFile(false);
      } else {
        setSelectedFile();
        setInvalidFile(true);
      }
    } catch (err) {
      handleError(err, StatusManager);
    }
  }

  useEffect( () => {
    setSelectedFile();
    setInvalidFile(false);
  }, [messages]);

  async function parseMessages(results) {
    var adapter = await getAdapter();
    var user = await adapter.getLoggedInUser();

    await Promise.all(results.map(async (element) => {
      var element_user_id = element.created_by;
      var element_user = await adapter.user.get(element_user_id)
      element.role = element_user.role;
      element.author = element_user.firstName + " " + element_user.lastName + " (" + element_user.email + ")";
      
      if(!element.acknowledged_by && shouldSetAcknowledged(user, element_user)) {
        adapter.application.acknowledgeMessage(element.guid);
        element.acknowledged = "true";
      } else {
        element.acknowledged = element.acknowledged_by ? "true" : "false";
      }
      element.pretty_date = getFormattedDate(new Date(element.created_on));
      element.viewer_role = user.role;
    }));
    return results;
  }

  async function getMessages(application_id) {
    setIsLoading(true);
    if(!application_id) return [];
    var adapter = await getAdapter();
    var res = internal 
      ? await adapter.application.getMessagesInternal(applicationId) 
      : await adapter.application.getMessages(application_id);
    var results = res.data.data.response;

    var toReturn = parseMessages(results);
    setIsLoading(false);
    return toReturn;
  }

  function shouldSetAcknowledged(user, element_user) {
    if(internal && user.email !== element_user.email) {
      return true;
    } else if(internal) {
      return false;
    }

    if((element_user.role === 'superadmin' || element_user.role === 'oted_pa') && user.role === 'applicant_poc') {
      return true;
    }
    if(element_user.role === 'applicant_poc' && (user.role === 'superadmin' || user.role === 'oted_pa')) {
      return true;
    }
    
    return false;
  }

  async function createMessage(event) {
    event.preventDefault();
    const errors = [];
    
    try {
      let body = event.target["body"].value;
      let application_id = event.target["application_id"].value;
      
      if (body.length === 0) {
          errors.push("Body can't be empty");
          console.log("Body can't be empty");
          return;
      }

      var file = !invalidFile ? event.target["file"].files[0] : null;
      event.target.reset();
      var adapter = await getAdapter();
      if(internal) {
        await adapter.application.createMessageInternal(body, application_id, file);
      } else {
        await adapter.application.createMessage(body, application_id, file);
      }

      var newMessages = await getMessages(application_id);
      setMessages(newMessages);
    } catch (err) {
      event.target.reset();
      setSelectedFile();
      setInvalidFile(false);
      handleError(err, StatusManager, err.response?.data?.data?.response);
    }
  }

  return (
    <Container maxWidth="lg">
        <Paper style={{minHeight:'70vh'}} padding="large" sx={{...(internal && {backgroundColor:"#fffbe4"})}}>
            <ViewTitle>{title}</ViewTitle>	
            { isLoading
            ? <LoadingIndicator padding={4}/>			  
            : <Box className="current-message-container" >
            <div>
            <div className='message-container'>
              <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"></link>
              <div className="col-md-8">
                <div className="settings-tray">
                  <div className="friend-drawer no-gutters friend-drawer--grey">
                    <div className="text">
                      <p className="text-muted"><strong>Course:</strong> {applicationCourse}</p>
                      <p><strong>Organization:</strong> {applicationOrg}</p>
                      <p><strong>Start Date:</strong> {applicationStartDate}</p>
                    </div>
                    <span className="settings-tray--right">
                      <a href="#" onClick={() => handleClick()}><i className="material-icons" title="Manage Application">assignment</i></a>
                    </span>
                  </div>
                </div>
                <div>
                <div className="chat-panel">

                { messages && messages.length > 0 ?
                  messages.map((message, i) => {
                    return <Message key={i} message={message} />
                  }) : <div style={{paddingLeft : 10}}><p>Welcome!  Please submit your message</p></div>
                }
                
                </div>
                  <div className="row">
                    <div className="col-12">
                      <form className="form__container" onSubmit={e => createMessage(e) }>
                        <div className="chat-box-tray">
                          <input id="application_id" name="application_id" type="hidden" value={applicationId} />
                          <input id="body" name="body" type="text" placeholder="Type your message here..."  required />
                          <label htmlFor="file"><span><i className="material-icons" type="file" name="file" title="Attach PDF">attachment</i></span></label>
                          <input type="file" id="file" style={{display: "none"}} onChange={e => handleAttachmentClick(e)} />
                          <button type="submit" className="message-fabutton" >
                            <i className="material-icons" type="submit" name="submit" title="Send" >send</i>
                          </button>
                        </div>
                      </form>
                      { selectedFile && 
                          <div>
                            <p>Selected: {selectedFile.name}&nbsp;&nbsp;
                              <span title="Valid File"><CheckIcon fontSize="smallest"/></span>
                            </p>
                          </div>
                      }              
                      { invalidFile && 
                          <div>
                            <p>Invalid filetype.  Only PDFs are supported&nbsp;&nbsp;
                              <span title="Invalid File"><ErrorOutlineIcon fontSize="smallest"/></span>
                            </p>
                          </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
            </Box> }
        </Paper>
    </Container>
  );
}

export default MessageApp;

import { useState } from 'react'

export default function useForm(initialformValues, validateOnChange = false, validate) {
    const [formValues, setFormValues] = useState(initialformValues);
    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setFormValue(name,value);       
    }

    const setFormValue = (name, value) => {
        var modifiedProperty = {[name]: value};
        //console.log({...formValues,...modifiedProperty});
        setFormValues(formValues => {
            return {
                ...formValues,
                ...modifiedProperty
            }
        });
        if (validateOnChange) validate({ [name]: value });
    }

    const resetForm = () => {
        setFormValues(initialformValues);
        setErrors({});
    }

    return {
        formValues,
        setFormValue,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    }
}
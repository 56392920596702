import React, {useState, useEffect, useRef} from 'react';
import { getAdapter } from './adapters/adapter';
import { OrganizationGrid } from './ManageOrganizations.js';
import { idToGuidMapper } from './Utility';
import theme from './theme/PortalTheme';
import Dialog from '@mui/material/Dialog';
import { DialogContent } from '@mui/material';
import { BootstrapDialogTitle } from './BootStrapDialogTitle';

export default function SelectOrganizationDialog(props){

    var { open, setOpen, setOrganization } = props;

    const isMounted = useRef(false)
	useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false }
	}, []);

    const [isLoading, setIsLoading] = useState(false);
    // eslint-disable-next-line
	const [isError, setIsError] = useState(false);
    const [organizations, setOrganizations] = useState([]);

    function actionsColumnRenderCell(params){

        function onSelectBtn(params){
            setOrganization(params);
            setOpen(false);
        }

        return (
            <React.Fragment>
                <button
                    style={{marginRight:theme.spacing(1), cursor:"pointer"}}
                    onClick={() => onSelectBtn(params.row)}
                >
                    Select
                </button>
            </React.Fragment>
        );
    }

    useEffect(() => {
        async function fetchData(){
            setIsError(false);
            setIsLoading(true);
            try {
                var adapter = await getAdapter();               
                var organizationList = await adapter.organization.list();
                organizationList = organizationList.map(idToGuidMapper);
            } 
            catch (error) {
                if(isMounted.current){
                    setIsError(true);
                }
                console.error(error);
            }
            if(isMounted.current){
                setOrganizations(organizationList);
                setIsLoading(false);
            }
        };
                
        fetchData();

    }, [setIsError, 
        setIsLoading, 
        setOrganizations]);

    function onDialogClose(){
        setOpen(false);
    }

    return (
        <Dialog
            maxWidth={"lg"}
            fullWidth={true}
            open={open}    
            container={() => document.getElementById('dialogParent')}
        >            
            <BootstrapDialogTitle color="primary" onClose={onDialogClose}>Select Organization</BootstrapDialogTitle>
            <DialogContent>
                <OrganizationGrid
                    actionsColumnRenderCell = {actionsColumnRenderCell}
                    rows = {organizations}
                    loading = {isLoading}
                />
            </DialogContent>           
        </Dialog>
    )
}
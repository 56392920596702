import React, { useEffect, useState, useRef, useContext } from 'react';
import { Box, Button, Grid, Paper } from '@mui/material';
import { StepNavigator } from './StepLogic';
import { getAdapter } from './adapters/adapter';
import ViewTitle from './ViewTitle';
import BetterFormField from './FormField';
import { FormSection, FormSectionHeading } from "./theme/PortalTheme";
import theme from './theme/PortalTheme';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import SelectOrganizationDialog from './SelectOrganizationDialog';
import SelectPOCUserDialog from './SelectPOCUserDialog';
import LoadingIndicator from './LoadingIndicator';
import { StatusMessageContext } from './StatusMessage';
import SavingIndicator from './SavingIndicator';
import useForm from './UseForm';
import { isValidState, isValidZipCode, isValidPhoneNumberFormat, isValidEmailFormat } from './Utility';
import { Save } from '@mui/icons-material';
import { set } from 'date-fns';
import { getLoggedInRole } from './adapters/baseAdapter';

export default function ICDApplicationFormContactInfo(props) {

	const {application, stepLogic } = props;
	
	//Is component currently mounted?
	const isMounted = useRef(false)
	useEffect(() => {
		isMounted.current = true;
		return () => { isMounted.current = false }
	}, []);

    //Status message:
	var { StatusManager } = useContext(StatusMessageContext);
	
	//Loading/Saving states:
	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [pocUser, setPocUser] = useState({});
	const [isUpdatingUser, setIsUpdatingUser] = useState(false);

	//Is "Select Organization" dialog open?
	var [organizationDialogOpen, setOrganizationDialogOpen] = useState(false);
	//Is "Select POC" dialog open?
	var [pocDialogOpen, setPocDialogOpen] = useState(false);

	//Form validation:
    function validate(){
        let _errors = { ...errors };
		console.log("Validating");
		console.log(formValues);
		
		//Organization required:
		_errors.organization = (formValues.organization.guid === undefined) ? "Please select an organization." : "";

		//Required Fields:
		_errors.pocLast = (formValues.pocLast === "") ? " " : "";
		_errors.pocFirst = (formValues.pocFirst === "") ? " " : "";
		_errors.pocEmail = (isValidEmailFormat(formValues.pocEmail)) ? "" : " ";
		_errors.pocPhone = (isValidPhoneNumberFormat(formValues.pocPhone)) ? "" : " ";		
		_errors.pocStreet= (formValues.pocStreet === "") ? " " : "";
		_errors.pocCity = (formValues.pocCity === "") ? " " : "";
		//Is pocState valid?
		_errors.pocState = (isValidState(formValues.pocState))? "" : " ";
		//Is pocZip valid?
		_errors.pocZip = (isValidZipCode(formValues.pocZip)) ? "" : " ";
		
        setErrors({
            ..._errors
        });

		var result = Object.values(_errors).every(x => x === "");
		console.log("Validation " + (result ? "SUCCESS" : "FAILED"));
		return result;	
	}

	//Initial Form Values:
	const initialFormValues = {
		organization: {},
		pocLast: "",
		pocMiddle: "",
		pocFirst: "",
		pocEmail: "",
		pocPhone: "",
		pocStreet: "",
		pocCity: "",
		pocState: "",
		pocZip: "",
		poc: {}
	};

	//Form-related vars:
	const {
		formValues,
		setFormValue,
		errors,
		setErrors,
		handleInputChange,
	} = useForm(initialFormValues, false, validate);

	//Create refs to mutable objects that would otherwise be passed as dependencies to useEffect hook, to prevent triggering re-renders unnecessarily.
	const setFormValueRef = useRef(setFormValue);

	//On view load:
	useEffect(() => {
		let setFormValue = setFormValueRef.current;

		async function fetchData(){
			setIsLoading(true);
			try {
				var adapter = await getAdapter();
				var applicationPocInfo = await adapter.application.poc.get(application.guid);
				var organizationInfo = null;
				if(applicationPocInfo.organizationID != null){
					organizationInfo = await adapter.organization.get(applicationPocInfo.organizationID);
				}
			}
			catch (error) {
				console.error(error);
			}
			if(isMounted.current){
				setFormValue("pocLast", applicationPocInfo.lastName || "");
				setFormValue("pocMiddle", applicationPocInfo.middleInitial || "");
				setFormValue("pocFirst", applicationPocInfo.firstName || "");
				setFormValue("pocEmail", applicationPocInfo.email || "");
				setFormValue("pocPhone", applicationPocInfo.phone || "");
				setFormValue("pocStreet", applicationPocInfo.addressStreet || "");
				setFormValue("pocCity", applicationPocInfo.addressCity || "");
				setFormValue("pocState", applicationPocInfo.addressState || "");
				setFormValue("pocZip", applicationPocInfo.addressZip || "");
				if(organizationInfo != null){
					setFormValue("organization", organizationInfo);
				}
				setIsLoading(false);
			}
		}

		fetchData();

	}, [ application.guid ]);

	//Debugging:
	useEffect(() => {
		async function fetchUserData(){
			if(isMounted.current){
				setIsUpdatingUser(true);
			}
			try{
				let adapter = await getAdapter();
				var userData = {};
				if(pocUser.guid) {
					userData = await adapter.user.get(pocUser.guid)
				}
				if(isMounted.current){
					setFormValue("pocLast", userData.lastName || "");
					setFormValue("pocMiddle", userData.middleInitial || "");
					setFormValue("pocFirst", userData.firstName || "");
					setFormValue("pocEmail", userData.email || "");
					setFormValue("pocPhone", userData.phone || "");
					setFormValue("pocStreet", userData.addressStreet || "");
					setFormValue("pocCity", userData.addressCity || "");
					setFormValue("pocState", userData.addressState || "");
					setFormValue("pocZip", userData.addressZip || "");	
				}
			}
			catch(error){
				console.log(error);
			}
			if(isMounted.current){
				setIsUpdatingUser(false);
			}
		}
		fetchUserData();
    }, [ pocUser ]);
	
	//Save:
	async function saveData( successCallback = () => {} ){
		console.log("Updating information for [ application.guid: " + application.guid + " ]");
		var success = true;
		if(isMounted.current){
			setIsSaving(true);
		}		
		try {
			var adapter = await getAdapter();
			let applicationPocInfo = {
				organizationID: (formValues.organization && formValues.organization.guid) ? formValues.organization.guid : null,
				firstName: formValues.pocFirst,
				lastName: formValues.pocLast,
				middleInitial: formValues.pocMiddle,
				email: formValues.pocEmail,
				phone: formValues.pocPhone,
				addressStreet: formValues.pocStreet,
				addressCity: formValues.pocCity,
				addressState: formValues.pocState,
				addressZip: formValues.pocZip
			};
			await adapter.application.poc.update(application.guid, applicationPocInfo);
			await adapter.application.updateStep(application.guid, "ContactInformation");
			stepLogic.completeCurrentStep();
			successCallback();
		}
		catch (error) {
			success = false;
			console.error(error);
		}
		if(isMounted.current){
			setIsSaving(false);			
			var successStr = success ? "successful." : "failed.";
			StatusManager.setStatus("Save application contact information " + successStr);	
		}
	};

	function onSelectOrganizationBtn(){
        setOrganizationDialogOpen(true);
    }

	function onSelectPocBtn(){
        setPocDialogOpen(true);
    }	

	function onSaveBtn(){
		if(validate()){		
			saveData(()=>{
				stepLogic.nextStep();
			});
		}
	}
	
	//Application should be locked if the user is a POC and the application has been submitted:
	let isApplicationLocked = (getLoggedInRole()==="applicant_poc" && application.status !="in-progress");
	let conditionalProps = isApplicationLocked ? {variant: "filled", readOnly: true}: {};	
	
	return (
		<React.Fragment>
			<Paper padding="huge" sx={{margin:0}} elevation={8}>
				<Grid container alignItems="center" justifyContent="center">
					<ViewTitle>Create ICD Application &ndash; {stepLogic.getCurrentStepName()}</ViewTitle>
				</Grid>
				{ isLoading ? <LoadingIndicator padding={4}/> :
				<>
					{ getLoggedInRole() != "applicant_poc" &&
					<>
						<SelectOrganizationDialog 
							open={organizationDialogOpen}
							setOpen={setOrganizationDialogOpen}
							setOrganization={(value) => {
								setPocUser({});
								setFormValue("organization", value);
							}}
						/>
						<SelectPOCUserDialog 
							organization={formValues.organization}
							open={pocDialogOpen}
							setOpen={setPocDialogOpen}
							setUser={setPocUser}
						/>
					</> }
					{/*Point of Contact Information*/}
					<FormSectionHeading>Point of Contact Information</FormSectionHeading>
					<FormSection>				
						<p style={{marginBottom:0}}>The following information for the identified Point of Contact (POC) for this proposed Independent Course Delivery (ICD) must be provided below:</p>
						<p><strong>Organization</strong></p>
						{!(formValues.organization && formValues.organization.name) && 
							<Box><p>You must first select an <strong>Organization</strong> before you will be allowed to select a <strong>Point of Contact</strong>.</p></Box>
						}						
						<Grid container spacing={1}>
							<Grid item container xs alignItems="center" >
								<BetterFormField required readOnly={true} variant="filled" label="Organization" name="organization" value={(formValues.organization && formValues.organization.name) || ""} error={errors.organization}/>
							</Grid>
							{/*Only allow users to Select Organization if they have appropriate permissions*/}
							{["oted_pa", "superadmin"].includes(getLoggedInRole()) &&
							<Grid item xs="auto" alignItems="center" direction="column" container>
								<Button
									variant="outlined"
									startIcon={<ManageSearchOutlinedIcon/>}
									style={{marginRight:theme.spacing(1), cursor:"pointer"}}
									onClick={onSelectOrganizationBtn}
								>
									Select Organization
								</Button>
							</Grid>
							}
						</Grid>
						<p><strong>Point of Contact</strong></p>
						{ isUpdatingUser ? <LoadingIndicator/> :
						<>				
						<Grid container spacing={1}>
							<Grid item container xs alignItems="center">
								<BetterFormField variant="filled" readOnly={true} required label="Email" name="pocEmail" value={formValues.pocEmail} onChange={handleInputChange} error={errors.pocEmail}/>
							</Grid>
							{/*Only allow users to Select POC if they have appropriate permissions*/}
							{["oted_pa", "superadmin"].includes(getLoggedInRole()) &&
							<Grid item xs="auto" alignItems="center" direction="column" container>
								<Button
									variant="outlined"
									startIcon={<ManageSearchOutlinedIcon/>}
									style={{marginRight:theme.spacing(1), cursor:"pointer"}}
									onClick={onSelectPocBtn}
									disabled={!(formValues.organization && formValues.organization.name)}
								>
									Select Point of Contact
								</Button>
							</Grid>
							}
						</Grid>
						<Grid container spacing={1}>
							<Grid item xs={5}><BetterFormField variant="filled" readOnly={true} required label="Last Name" name="pocLast" value={formValues.pocLast} onChange={handleInputChange} error={errors.pocLast}/></Grid>
							<Grid item xs={2}><BetterFormField variant="filled" readOnly={true} label="Middle Initial" name="pocMiddle" value={formValues.pocMiddle} onChange={handleInputChange} error={errors.pocMiddle}/></Grid>
							<Grid item xs={5}><BetterFormField variant="filled" readOnly={true} required label="First Name" name="pocFirst" value={formValues.pocFirst} onChange={handleInputChange} error={errors.pocFirst}/></Grid>
							<Grid item xs={5}><BetterFormField {...conditionalProps} required label="Phone" name="pocPhone" value={formValues.pocPhone} onChange={handleInputChange} error={errors.pocPhone}/></Grid>
						</Grid>
						<p><strong>Complete Mailing Address</strong></p>
						<Grid container spacing={1}>
							<Grid item xs={12}><BetterFormField {...conditionalProps} required label="Street" name="pocStreet" value={formValues.pocStreet} onChange={handleInputChange} error={errors.pocStreet}/></Grid>
							<Grid item xs={5}><BetterFormField {...conditionalProps} required label="City" name="pocCity" value={formValues.pocCity} onChange={handleInputChange} error={errors.pocCity}/></Grid>
							<Grid item xs={2}><BetterFormField {...conditionalProps} required label="State" name="pocState" value={formValues.pocState} onChange={handleInputChange} error={errors.pocState}/></Grid>
							<Grid item xs={5}><BetterFormField {...conditionalProps} required label="Zip" name="pocZip" value={formValues.pocZip} onChange={handleInputChange} error={errors.pocZip}/></Grid>
						</Grid>
						</>
						}
					</FormSection>
				</>
				}
			</Paper>
			{
				isSaving ? <SavingIndicator/> :
				<Grid sx={{marginTop: 4}} container alignItems="center" justifyContent="center">
					{ stepLogic.canNext() && !isApplicationLocked && <Button variant="contained" color="primary" size="large" onClick={onSaveBtn} startIcon={<Save/>}>Save and Continue</Button>}
					{ isApplicationLocked &&
					<Box sx={{backgroundColor: theme.palette.background.statusMessage, px:2, border:"1px solid #CCC", textAlign:"center"}}>
						<p>This application has been submitted and is locked.</p>
					</Box>}
				</Grid>
			}
		</React.Fragment>
	);
}

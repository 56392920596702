import React, { useEffect, useState, useRef, useContext } from 'react';
import { Box, Grid, Paper, Button } from '@mui/material';
import { Save } from '@mui/icons-material';
import theme, { CreateButton, DeleteButton } from './theme/PortalTheme';
import { getAdapter } from './adapters/adapter';
import ViewTitle from './ViewTitle';
import { FormSection, FormSectionHeading } from "./theme/PortalTheme";
import LoadingIndicator from './LoadingIndicator';
import SelectInstructorDialog from './SelectInstructorDialog';
import AddUnqualifiedInstructorDialog from './AddUnqualifiedInstructorDialog';
import { InstructorGrid  } from './ManageInstructors';
import { idToGuidMapper } from './Utility';
import { StatusMessageContext } from './StatusMessage';
import SavingIndicator from './SavingIndicator';
import { getLoggedInRole } from './adapters/baseAdapter';


function AddQualifiedInstructors(props){
	const { stateRefs, isApplicationLocked } = props;
	const [removeInstructorBtnDisabled, setRemoveInstructorBtnDisabled] = useState(true);
	
	const isMounted = useRef(false)
	useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false }
	}, []);

	function onSelectionModelChange(selectionModel) {
		if(isMounted.current){
			stateRefs.attachedQISelectionModel[1](selectionModel);
			setRemoveInstructorBtnDisabled(selectionModel.length === 0);
		}
	}

	function onSelectQualifiedInstructorBtn(){
		//console.log("onSelectQualifiedInstructorBtn reached.")
		if(isMounted.current){
			stateRefs.selectQIDialogOpen[1](true);	
		}
	}

	function onRemoveInstructorBtn(){
		//console.log("onRemoveInstructorsBtn reached.")
		//Form an array of instructor objects to remove:
		var instructorsToRemove = stateRefs.attachedQIs[0].filter(instructor => stateRefs.attachedQISelectionModel[0].includes(instructor.guid));
		//Remove those instructors from the Attached Qualified Instructors grid:
		var newPool = stateRefs.attachedQIs[0].filter( instructor => !instructorsToRemove.includes(instructor));
		//Avoids a race condition where the MUI grid tries to set focus to the cell row that was removed.  *known MUI bug*
		setTimeout(() => {
			if(isMounted.current){
				stateRefs.attachedQIs[1](newPool);
			}
		});
	}

	return (
		<React.Fragment>
			<FormSectionHeading>Qualified Instructors</FormSectionHeading>
			<FormSection>
				<p>The table below shows <strong>Qualified Instructors</strong> that will deliver the training for this ICD application.</p>
				<p><strong>NOTE</strong>: You are responsible for contacting and making arrangements with any Qualified Instructor before selecting them from this list.</p>
				{ !isApplicationLocked &&					
				<Grid container justifyContent="flex-end">
					<CreateButton onClick={onSelectQualifiedInstructorBtn}>Select Qualified Instructor(s)</CreateButton>
					<DeleteButton
						disabled={removeInstructorBtnDisabled}
						onClick={onRemoveInstructorBtn}
					>
						Remove Qualified Instructor(s)
					</DeleteButton>
				</Grid> }
				<InstructorGrid
					rows={stateRefs.attachedQIs[0]}
					onSelectionModelChange={onSelectionModelChange}
					checkboxSelection = {true}
					selectionModel={stateRefs.attachedQISelectionModel[0]}
				/>
			</FormSection>
		</React.Fragment>
	);
}

function AddUnqualifiedInstructors(props){
	const { stateRefs, isApplicationLocked } = props;
	const [removeInstructorBtnDisabled, setRemoveInstructorBtnDisabled] = useState(true);
	
	const isMounted = useRef(false)
	useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false }
	}, []);

	function onSelectionModelChange(selectionModel) {
		if(isMounted.current){
			stateRefs.attachedUISelectionModel[1](selectionModel);
			setRemoveInstructorBtnDisabled(selectionModel.length === 0);
		}
	}

	function onAddNewInstructorBtn(){
		//console.log("onAddNewInstructorsBtn reached.")
		if(isMounted.current){
			stateRefs.addUIDialogOpen[1](true);	
		}
	}

	function onRemoveInstructorBtn(){
		//console.log("onRemoveInstructorsBtn reached.")
		//Form an array of instructor objects to remove:
		var instructorsToRemove = stateRefs.attachedUIs[0].filter(instructor => stateRefs.attachedUISelectionModel[0].includes(instructor.id));
		//Remove those instructors from the Attached Unqualified Instructors grid:
		var temp = stateRefs.attachedUIs[0].filter( instructor => !instructorsToRemove.includes(instructor));
		//Avoids a race condition where the MUI grid tries to set focus to the cell row that was removed.  *known MUI bug*
		setTimeout(() => {
			if(isMounted.current){
				stateRefs.attachedUIs[1](temp);
			}
		});
	}

	return (
		<React.Fragment>
			<FormSectionHeading>New Instructors</FormSectionHeading>
			<FormSection>
				<p>The table below shows <strong>New Instructors</strong> that will deliver the training for this ICD application.</p>
				{ !isApplicationLocked && 
				<Grid container justifyContent="flex-end">
					<CreateButton onClick={onAddNewInstructorBtn}>Add New Instructor</CreateButton>
					<DeleteButton
						disabled={removeInstructorBtnDisabled}
						onClick={onRemoveInstructorBtn}
					>
						Remove New Instructor(s)
					</DeleteButton>
				</Grid> }
				<InstructorGrid
					rows={stateRefs.attachedUIs[0]}
					onSelectionModelChange={onSelectionModelChange}
					checkboxSelection = {true}
					selectionModel={stateRefs.attachedUISelectionModel[0]}
				/>
			</FormSection>
		</React.Fragment>
	);
}

export default function ICDApplicationFormInstructorInfo(props) {
	const { application, stepLogic } = props;

	const isMounted = useRef(false)
	useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false }
	}, []);

	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	var stateRefs = {};

    //All instructors from DB:
    var instructorsFromDB = useRef([]);

    //Status message:
	var { StatusManager } = useContext(StatusMessageContext);

	/*
		Qualified Instructor states and related variables:
	*/
	//Attached Qualified Instructors:
	const [attachedQIs, setAttachedQIs] = stateRefs.attachedQIs = useState([]);
	//Unattached Qualified Instructors:
	const [unattachedQIs, setUnattachedQIs] = stateRefs.unattachedQIs = useState([]);
	//Selection model of the Attached Qualified Instructors Grid:
	stateRefs.attachedQISelectionModel = useState([]);
	//Is the Add Qualified Instructor dialog open?
    const [selectQIDialogOpen, setSelectQIDialogOpen] = stateRefs.selectQIDialogOpen = useState(false);

	/*
		Unqualified (aka "New") Instructor states and related variables:
	*/
	const [attachedUIs, setAttachedUIs] = stateRefs.attachedUIs = useState([]);
	//Selection model of the Attached Unqualified Instructors Grid:
	stateRefs.attachedUISelectionModel = useState([]);
	//Is the Add Unqualified Instructor dialog open?
    const [addUIDialogOpen, setAddUIDialogOpen] = stateRefs.addUIDialogOpen = useState(false);
	
	useEffect(() => {

		async function fetchData(){
			setIsLoading(true);
			try {
				var adapter = await getAdapter();
				//List of all instructors qualified to teach the selected course:
				instructorsFromDB.current = await adapter.qualification.listByCourse(application.courseID);
				if(instructorsFromDB.current){
					instructorsFromDB.current = instructorsFromDB.current.filter(instructor => instructor.isCurrent);
				}
				//List of qualified instructors attached to the application:
				var attachedQIsFromDB = await adapter.application.qualified.list(application.guid);
				//List of unqualified instructors attached to the application:
				var attachedUIsFromDB = await adapter.application.unqualified.list(application.guid);
			}
			catch (error) {
				console.error(error)
			}
			if(isMounted.current){
				//GUID to ID mapping:
				instructorsFromDB.current = instructorsFromDB.current.map(idToGuidMapper);
				attachedQIsFromDB = attachedQIsFromDB.map(idToGuidMapper);
				attachedUIsFromDB = attachedUIsFromDB.map(idToGuidMapper);
				//Set the attached Qualified Instructors:
				setAttachedQIs(attachedQIsFromDB);
				//Set the attached Unqualified Instructors:
				setAttachedUIs(attachedUIsFromDB);
				//Determine unattached instructors by finding all instructors in the system MINUS the ones attached to this application:
				var unattachedInstructors = instructorsFromDB.current.filter(instructor1=> !attachedQIsFromDB.some(instructor2 => instructor1.guid === instructor2.guid));
				//Set the unattached instructors:
				setUnattachedQIs(unattachedInstructors);			
				setIsLoading(false);
			}
		};
		if(application.courseID){
			fetchData();
		}
	}, [
		application.courseID,
		setIsLoading,
		setUnattachedQIs,
		setAttachedQIs,
		setAttachedUIs]);

	//If the attached qualified instructors change, then recalculate the unattached instructors:
	useEffect(() => {
		//Unattached instructors = all instructors in the system EXCEPT for the ones attached to this application:
		var unattachedInstructors = instructorsFromDB.current.filter(instructor1=> !attachedQIs.some(instructor2 => instructor1.guid === instructor2.guid));
		//setTimeout used here to avoid race condition where row focus is interrupted because the DataGrid source changes:
		setTimeout(() => {            
			if(isMounted.current){         
				setUnattachedQIs(unattachedInstructors);
			}
		});
	}, [attachedQIs,
		setUnattachedQIs]);
	
	//Callback for when user has confirmed selections from the Select Qualified Instructor(s) dialog:
	function onSelectQualifiedInstructorsCallback(selectedInstructors){
		//console.log("onSelectQualifiedInstructorBtn callback reached.")
		if(isMounted.current){
			//Attach the selected instructors to the "Attached Qualified Instructors" DataGrid ONLY if they aren't already there (i.e., no duplicates):
			var unique = [...new Set(attachedQIs.concat(selectedInstructors))];
			setAttachedQIs(unique);		
		}

	}

	//Callback for when user has added a new instructor from the Add Unqualified Instructor dialog:
	function onAddUnqualifiedInstructorBtn(newInstructor){
		//console.log("onAddNewInstructorBtn callback reached.")
		if(!attachedUIs.some(instructor=>instructor.id === newInstructor.id)){
			if(isMounted.current){
				setAttachedUIs(attachedUIs.concat(newInstructor));
			}
		}
	}

	async function saveData(successCallback = () => {} ){
		console.log("Updating information for [ application.guid: " + application.guid + " ]");
		var success = true;
		if(isMounted.current){
			setIsSaving(true);
		}
		try {
			var adapter = await getAdapter();
			await adapter.application.qualified.set(application.guid, attachedQIs);
			await adapter.application.unqualified.set(application.guid, attachedUIs);
			await adapter.application.updateStep(application.guid, "Instructors");
			stepLogic.completeCurrentStep();
			successCallback();
		}
		catch (error) {
			success = false;
			console.error(error);
		}
		var successStr = success ? "successful." : "failed.";
		StatusManager.setStatus("Save application instructor information " + successStr);
		if(isMounted.current){
			setIsSaving(false);
		}
	}

	function onSaveBtn(){
		if(validate()){		
			saveData(()=>{
				stepLogic.nextStep();
			});
		}
	}

    function validate(){
		if(attachedQIs.length == 0 && attachedUIs.length == 0) {
			StatusManager.setStatus("Please select at least one instructor for this application.");
			return false;
		}

		return true;	
	}

	//Application should be locked if the user is a POC and the application has been submitted:
	let isApplicationLocked = (getLoggedInRole()==="applicant_poc" && application.status !="in-progress");
	let conditionalProps = isApplicationLocked ? {variant: "filled", readOnly: true}: {};

	if(!application.courseID){
		return null;
	}
	else return (
		<React.Fragment>
			<Paper padding="huge" sx={{margin:0}}>
				<Grid container alignItems="center" justifyContent="center">
					<ViewTitle>Create ICD Application &ndash; {stepLogic.getCurrentStepName()}</ViewTitle>
				</Grid>
				{ isLoading ? <LoadingIndicator padding={4}/> :
				<>
					<SelectInstructorDialog
						open={selectQIDialogOpen}
						setOpen={setSelectQIDialogOpen}
						unattachedQIs={unattachedQIs}
						onSelectQualifiedInstructorsCallback={onSelectQualifiedInstructorsCallback}
						isLoading={isLoading}
					/>
					<AddQualifiedInstructors stateRefs={stateRefs} isApplicationLocked={isApplicationLocked}/>
					<AddUnqualifiedInstructorDialog
						open={addUIDialogOpen}
						setOpen={setAddUIDialogOpen}
						onAddInstructorBtnCallback={onAddUnqualifiedInstructorBtn}
						isLoading={isLoading}
					/>
					<AddUnqualifiedInstructors stateRefs={stateRefs} isApplicationLocked={isApplicationLocked}/>
				</>
				}
			</Paper>
			{
				isSaving ? <SavingIndicator/> :
				<Grid sx={{marginTop: 4}} container alignItems="center" justifyContent="center">
					{ stepLogic.canNext() && !isApplicationLocked && <Button variant="contained" color="primary" size="large" onClick={onSaveBtn} startIcon={<Save/>}>Save and Continue</Button>}
					{ isApplicationLocked &&
					<Box sx={{backgroundColor: theme.palette.background.statusMessage, px:2, border:"1px solid #CCC", textAlign:"center"}}>
						<p>This application has been submitted and is locked.</p>
					</Box>}
				</Grid>
			}
		</React.Fragment>		
	);

}
